import { ISeveridad } from '../../models/interfaces/catalogos/ISeveridad';

export const SeveridadMock: ISeveridad[] = [
  {
    nombre: 'Alta',
    valor: 1,
  },
  {
    nombre: 'Media',
    valor: 2,
  },
  {
    nombre: 'Baja',
    valor: 3,
  },
];
