import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { FormularioStepperProps } from '@app/models/types/formulario-stepper.type';

export default function FormularioAuditoria(props: FormularioStepperProps) {
	const { avance, formik, isEditar } = props;
	const [repetirMeta, setRepetirMeta] = useState<boolean>(false);

	return (
		<>
			<Row>
				<Col
					xs={{ offset: 2, span: 2 }}
					sm={{ offset: 2, span: 2 }}
					md={{ offset: 2, span: 2 }}
					lg={{ offset: 2, span: 2 }}
				>
					<Form.Group>
						<Form.Label>
							Número Semana<span className="meta__required">*</span>
						</Form.Label>
						<Form.Control
							type="number"
							value={formik.values.semana}
							onChange={formik.handleChange}
							name="semana"
							isInvalid={!!formik.errors.semana}
							min={1}
							max={52}
							disabled={isEditar}
						/>
						<Form.Control.Feedback className="d-block" type="invalid">
							{formik.errors.semana}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col xs={2} sm={2} md={2} lg={2} className="meta__checkbox">
					<Form.Group>
						<Form.Check
							inline
							type="checkbox"
							name="repetirMeta"
							label="¿Repetir Meta?"
							onChange={() => {
								setRepetirMeta((est: boolean) => {
									formik.setFieldValue('repetirMeta', !est);
									return !est;
								});
							}}
							checked={repetirMeta}
							disabled={isEditar}
						/>
					</Form.Group>
				</Col>
				<Col xs={2} sm={2} md={2} lg={2}>
					<Form.Group>
						<Form.Label>Semana Inicial</Form.Label>
						<Form.Control
							type="number"
							value={formik.values.semanaInicio}
							onChange={formik.handleChange}
							name="semanaInicio"
							isInvalid={!!formik.errors.semanaInicio}
							min={1}
							max={52}
							disabled={!repetirMeta || isEditar}
						/>
						<Form.Control.Feedback className="d-block" type="invalid">
							{formik.errors.semanaInicio}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col xs={2} sm={2} md={2} lg={2}>
					<Form.Group>
						<Form.Label>Semana Termino</Form.Label>
						<Form.Control
							type="number"
							value={formik.values.semanaFin}
							onChange={formik.handleChange}
							name="semanaFin"
							isInvalid={!!formik.errors.semanaFin}
							min={1}
							max={52}
							disabled={!repetirMeta || isEditar}
						/>
						<Form.Control.Feedback className="d-block" type="invalid">
							{formik.errors.semanaFin}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col
					xs={{ offset: 2, span: 2 }}
					sm={{ offset: 2, span: 2 }}
					md={{ offset: 2, span: 2 }}
					lg={{ offset: 2, span: 2 }}
				>
					<Form.Group>
						<Form.Label>
							Auditorías Libres<span className="meta__required">*</span>
						</Form.Label>
						<Form.Control
							type="number"
							value={formik.values.libres}
							onChange={formik.handleChange}
							name="libres"
							isInvalid={!!formik.errors.libres}
						/>
						<Form.Control.Feedback className="d-block" type="invalid">
							{formik.errors.libres}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col xs={2} sm={2} md={2} lg={2}>
					<Form.Group>
						<Form.Label>
							Reforzamientos Positivos<span className="meta__required">*</span>
						</Form.Label>
						<Form.Control
							type="number"
							value={formik.values.reforzamiento}
							onChange={formik.handleChange}
							name="reforzamiento"
							isInvalid={!!formik.errors.reforzamiento}
						/>
						<Form.Control.Feedback className="d-block" type="invalid">
							{formik.errors.reforzamiento}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col xs={2} sm={2} md={2} lg={2}>
					<Form.Group>
						<Form.Label>
							Año<span className="meta__required">*</span>
						</Form.Label>
						<Form.Control
							type="number"
							value={formik.values.anio}
							onChange={formik.handleChange}
							name="anio"
							isInvalid={!!formik.errors.anio}
							min="1900"
							max="2099"
							step="1"
							disabled={isEditar}
						/>
						<Form.Control.Feedback className="d-block" type="invalid">
							{formik.errors.anio}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
			</Row>
			<Row className="mt-4 mb-3">
				<Col
					xs={{ offset: 2, span: 4 }}
					sm={{ offset: 2, span: 4 }}
					md={{ offset: 2, span: 4 }}
					lg={{ offset: 2, span: 4 }}
				>
					<p className="mx-0 fw-bold">
						Los campos con <span className="meta__required">*</span> son requeridos.
					</p>
				</Col>
				<Col className="d-flex align-items-end justify-content-end" lg={4} md={4} sm={4} xs={4}>
					<Button
						variant="secondary"
						disabled={
							formik.errors.libres ||
							!formik.values.libres ||
							formik.errors.reforzamiento ||
							!formik.values.reforzamiento ||
							formik.errors.semana ||
							!formik.values.semana ||
							formik.errors.semanaInicio ||
							!formik.values.semanaInicio ||
							formik.errors.semanaFin ||
							!formik.values.semanaFin
						}
						onClick={() => avance(1, 'siguiente')}
					>
						Asignar Auditorías Dirigídas
					</Button>
				</Col>
			</Row>
		</>
	);
}
