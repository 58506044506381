import { createContext } from 'react';
import { DEFAULT_AUDITORIA_DATA_CONTEXT } from '../constants/defaultAuditoriaDataContext';
import { DEFAULT_FPS_DATA_CONTEXT } from '../constants/defaultFpsDataContext';
import { IDataContext } from '../models/IDataContext';

const DataContext = createContext<IDataContext>({
	dataUser: {
		usuario: null,
		roles: [],
	},
	dataFpsContext: DEFAULT_FPS_DATA_CONTEXT,
	dataAuditoriaContext: DEFAULT_AUDITORIA_DATA_CONTEXT,
	setDataUser: () => undefined,
	setDataFpsContext: () => undefined,
	setDataAuditoriaContext: () => undefined,
});

export default DataContext;
