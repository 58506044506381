import { ReactElement } from 'react';
import { matchPath, Navigate, useLocation } from 'react-router-dom';

import { ROUTES } from '@app/constants/routes';

export default function useNavegacion(pagina: ReactElement) {
	const credenciales = JSON.parse(localStorage.getItem('credenciales'));
	const rolesUsuario = credenciales?.roles.map((role: string) => role.toLowerCase()) || [];
	const pathActual = useLocation();

	//info Se agregan las rutas que pueden acceder dependiendo del role que tenga el usuario (dentro de los 'if')
	if (!!credenciales?.usuario) {
		if (
			rolesUsuario?.includes('auditor') &&
			pathActual.pathname !== ROUTES.TABLA_AUDITORIAS &&
			pathActual.pathname !== ROUTES.AUDITORIA_LIBRE &&
			pathActual.pathname !== ROUTES.CATALOGOS &&
			pathActual.pathname !== ROUTES.CAMBIAR_CONTRASENA &&
			!matchPath(`${ROUTES.AUDITORIA_LIBRE}/:id`, pathActual.pathname)
		) {
			return <Navigate to={ROUTES.TABLA_AUDITORIAS} />;
		}
		if (
			!rolesUsuario?.includes('usuarioexterno') &&
			pathActual.pathname === ROUTES.CAMBIAR_CONTRASENA
		) {
			return <Navigate to={ROUTES.HOME} />;
		}

		return pagina;
	}
	return <Navigate to={ROUTES.ROOT} />;
}
