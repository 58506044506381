import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';

import Loading from '@app/components/Loading';
import Title from '@app/components/Title';
import useError from '@app/hooks/useError';
import { ICambiarContrasena } from '@app/models/interfaces/usuarios/ICambiarContrasena';
import { cambiarContrasenaService } from '@app/services/usuarios.service';
import { cambiarContrasenaValidationSchema } from '@app/utils/validations/cambiar-contrasena.validation';

export default function CambiarContrasena() {
	const [seCambioContrasena, setSeCambioContrasena] = useState<boolean>(false);
	const { handleSubmit, handleChange, values, errors, setFieldValue } = useFormik({
		initialValues: {
			oldPassword: '',
			newPassword: '',
		},
		validationSchema: cambiarContrasenaValidationSchema,
		onSubmit: (values: ICambiarContrasena) => {
			mutate(values);
		},
	});
	const { setError, ErrorAlert } = useError();

	const { mutate, isLoading } = useMutation({
		mutationFn: cambiarContrasenaService,
		onSuccess: () => {
			setSeCambioContrasena(true);
			setError('');
			setFieldValue('oldPassword', '', false);
			setFieldValue('newPassword', '', false);
		},
		onError: error => {
			setSeCambioContrasena(false);
			setError(error);
		},
	});

	return (
		<Container className="d-flex flex-column">
			<ErrorAlert />
			{seCambioContrasena && (
				<Alert
					className="mt-4"
					variant="success"
					onClose={() => setSeCambioContrasena(false)}
					dismissible
				>
					Se cambio la contraseña de manera exitosa.
				</Alert>
			)}
			{isLoading && <Loading mensaje="Cambiando Contraseña" />}
			<Title titulo="Cambiar Contraseña" />
			<Form onSubmit={handleSubmit}>
				<Row className="mt-5">
					<Col
						className="my-4"
						xs={{ offset: 2, span: 8 }}
						sm={{ offset: 2, span: 8 }}
						md={{ offset: 3, span: 6 }}
					>
						<Form.Group>
							<Form.Label>Anterior Contraseña</Form.Label>
							<Form.Control
								name="oldPassword"
								isInvalid={!!errors.oldPassword}
								value={values.oldPassword}
								onChange={handleChange}
								type="password"
								placeholder="Anterior Contraseña"
							/>
							<Form.Control.Feedback type="invalid" className="d-block">
								{errors?.oldPassword}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col
						className="my-4"
						xs={{ offset: 2, span: 8 }}
						sm={{ offset: 2, span: 8 }}
						md={{ offset: 3, span: 6 }}
					>
						<Form.Group>
							<Form.Label>Nueva Contraseña</Form.Label>
							<Form.Control
								name="newPassword"
								isInvalid={!!errors.newPassword}
								value={values.newPassword}
								onChange={handleChange}
								type="password"
								placeholder="Nueva Contraseña"
							/>
							<Form.Control.Feedback type="invalid" className="d-block">
								{errors?.newPassword}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col
						className="my-4 d-flex align-items-center justify-content-end"
						xs={{ offset: 2, span: 8 }}
						sm={{ offset: 2, span: 8 }}
						md={{ offset: 3, span: 6 }}
					>
						<Button type="submit" className="d-block" disabled={isLoading}>
							Cambiar Contraseña
						</Button>
					</Col>
				</Row>
			</Form>
		</Container>
	);
}
