import {
	IAddAccionResponsable,
	IAddResponsable,
	IResponsable,
} from '../models/interfaces/condicionInsegura/IResponsable';
import http from '../utils/http';

export const getAllResponsablesService = async () => {
	try {
		const response = await http.get<IResponsable[]>('responsable');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addResponsableService = async (
	dataResponsable: IAddResponsable
) => {
	try {
		const response = await http.post(
			'Auditoria/AddResponableToAuditoria',
			dataResponsable
		);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteResponsableService = async (responsableId: number) => {
	try {
		const response = await http.delete(
			`Auditoria/DeleteResponsableAuditoria/${responsableId}`
		);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addAccionResponsableService = async (
	dataAccion: IAddAccionResponsable
) => {
	try {
		const response = await http.post(
			'Auditoria/AddAccionToResponableToAuditoria',
			dataAccion
		);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteAccionResponsableService = async (idAccion: number) => {
	try {
		const response = await http.delete(
			`Auditoria/DeleteAccionResponsableAuditoria/${idAccion}`
		);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
