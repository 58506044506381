import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';

import useDescargarGuia from '@app/hooks/useDescargarGuia';
import useRole from '@app/hooks/useRole';
import { ICargando } from '@app/models/ICargando';
import { POSICION } from '@app/models/enums/posicion.enum';
import LoadingComponent from '../../components/Loading';
import AgregarCatalogoModal from '../../components/Modals/AgregarCatalgoModal';
import DeleteModal from '../../components/Modals/DeleteModal';
import useData from '../../hooks/useData';
import { IDataFpsContext } from '../../models/IDataContext';
import { ICatalogo } from '../../models/interfaces/catalogos/ICatalogo';
import { IRamaCatalogo } from '../../models/interfaces/catalogos/IRamaCatalogo';
import { TablaCatalogosProps } from '../../models/types/tabla-catalogos.type';
import {
	deleteCatalogoService,
	editPosicionCatalogoService,
	getAllCatalogosService,
} from '../../services/catalogos.service';
import PreviewDocumentModal from '../Modals/PreviewDocumentModal';

import '../../styles/components/TablaCatalogo.component.scss';

export default function TablaCatalogo(props: TablaCatalogosProps) {
	const [cargando, setCargando] = useState<ICargando>({
		cargando: false,
		mensaje: '',
	});
	const { catalogos, asignarFilaAbierta, seleccionarFila, filasAbiertas, hayError } = props;
	const [estadoPreview, setEstadoPreview] = useState<{
		estado: boolean;
		idAspectoObservable: number;
		aspectoObservable: string;
	}>({ estado: false, idAspectoObservable: 0, aspectoObservable: '' });
	const [estadoModalEliminar, setEstadoModalEliminar] = useState<{
		estado: boolean;
		catalogo: ICatalogo;
	}>({
		estado: false,
		catalogo: {
			id: 0,
			catalogoId: 0,
			tipoAuditoriaId: 0,
			archivoGuia: '',
			tipoAuditoria: '',
			codigo: '',
			descripcion: '',
			severidad: '',
			severidadId: 0,
			orderAscendingBranch: 0,
			nivel: 0,
			catalogosChildren: [],
			usuarioId: '',
			foto: '',
			userName: '',
			nombre: '',
			email: '',
			puesto: '',
			rol: '',
			factorDeRiesgoFpsId: 0,
			factorDeRiesgoFps: '',
			precursorFpsId: 0,
			precursorFps: '',
		},
	});
	const [estadoModal, setEstadoModal] = useState<{
		estado: boolean;
		idCatalogo: number;
		nivel: number;
		catalogo: ICatalogo | undefined;
		tituloProvicional?: string;
	}>({
		estado: false,
		idCatalogo: 0,
		nivel: 0,
		catalogo: undefined,
		tituloProvicional: '',
	});
	const [filaCatalogoEncontrada, setFilaCatalogoEncontrada] = useState<number>(0);
	const [opcionFiltro, setOpcionFiltro] = useState<any>({});
	const { setDataFpsContext, dataFpsContext } = useData();
	const { mutate: descargarGuia, isLoading: descargandoGuia } = useDescargarGuia();
	const { esRole } = useRole();
	const esAdministrador = esRole('administrador');

	const expandirFila = (idCatalogo: number) => event => {
		event.stopPropagation();

		//i Verificamos si no está en la lista de "filas abiertas", si está dentro, se remueve y ya no estaría abierta la fila
		//i en caso contrario se agrega a la lista de "filas abiertas" y cuenta como fila abierta
		if (!!filasAbiertas.find((fl: number) => fl === +idCatalogo)) {
			asignarFilaAbierta((filas: number[]) => filas.filter((fil: number) => fil !== +idCatalogo));
		} else {
			asignarFilaAbierta((filas: number[]) => [...filas, +idCatalogo]);
		}
	};

	//i Obtenemos el catálogo buscado y lo seleccionamos en el árbol
	const seleccionarCatalogoBuscado = () => {
		//i Se cierran todas las filas
		asignarFilaAbierta([]);

		//i Abrimos las ramas padres
		asignarFilaAbierta(dataFpsContext.ramaCatalogo.map((rama: IRamaCatalogo) => rama.id));

		//i Seleccionamos la descripción buscada - ponemos delay para que se termine el render
		const idCatalogoSeleccionar =
			dataFpsContext.ramaCatalogo[dataFpsContext.ramaCatalogo.length - 1].id;
		setFilaCatalogoEncontrada(idCatalogoSeleccionar);
	};

	const seleccionarFilaCatalogo = (idFila: number) => () => seleccionarFila(idFila);

	const cambiarPosicion = async (catalogoId: number, posicion: POSICION) => {
		try {
			setCargando({
				cargando: true,
				mensaje: 'Cambiando posición',
			});
			await editPosicionCatalogoService(catalogoId, posicion);
			const catalogosActualizado = await getAllCatalogosService();
			setDataFpsContext((data: IDataFpsContext) => ({
				...data,
				catalogos: catalogosActualizado,
			}));
		} catch (ex) {
			ex;
		} finally {
			setCargando({
				cargando: false,
				mensaje: '',
			});
		}
	};

	const esPenultimoNivel = ({ catalogosChildren }: ICatalogo) => {
		if (catalogosChildren.length) {
			const hasSeveridad = catalogosChildren.some((cat: ICatalogo) => cat.severidad !== '');
			return hasSeveridad;
		}
		return false;
	};

	const buscarPenultimoNivel = ({ catalogosChildren }: ICatalogo) => {
		if (catalogosChildren.length) {
			const cantidadNietos: ICatalogo[] = catalogosChildren.filter(
				(cat: ICatalogo) => cat.catalogosChildren.length
			);
			//i Si no hay nietos entonces si es penultimo nivel
			if (!cantidadNietos.length) {
				return true;
			}
			return false;
		}
		return false;
	};

	const renderFiltrado = (cat: ICatalogo, opcion: number = 0) => {
		const { catalogosChildren: subCatalogos } = cat;
		const nuevosSubCatalogos =
			opcion === 0
				? subCatalogos
				: subCatalogos.filter(
						(sub: ICatalogo) =>
							sub.tipoAuditoriaId === opcion || sub.tipoAuditoriaId === cat.tipoAuditoriaId
				  );
		return nuevosSubCatalogos;
	};

	//i Renderizado del cuerpo de la tabla
	const cuerpoTablaRender = (listaCat: ICatalogo[], nivel: number = 0) => {
		return listaCat.map((cat: ICatalogo) => {
			//i Si se encuentra el id del catálogo dentro de la lista "filas abiertas" entonces se marca como tal
			const filaAbierta: boolean = !!(
				filasAbiertas.length && filasAbiertas.find((fil: number) => fil === +cat.id)
			);
			const hayMasCatalogos: boolean = !!cat.catalogosChildren.length;

			return (
				<Fragment key={cat.id}>
					<tr
						className={classNames({
							'fw-bold': nivel === 0,
							'tabla-catalogo__seleccionado': cat.id === filaCatalogoEncontrada,
						})}
						role="button"
						onClick={seleccionarFilaCatalogo(cat.id)}
					>
						<td>
							<div style={{ marginLeft: `${nivel}rem` }}>
								{hayMasCatalogos && (
									<IconButton aria-label="Expandir" onClick={expandirFila(cat.id)} size="small">
										<ArrowForwardIosIcon
											className={classNames('tabla-catalogo__arrow', {
												'tabla-catalogo__arrow--open': filaAbierta,
											})}
											id={`fila-${cat.id}`}
											fontSize="inherit"
										/>
									</IconButton>
								)}
								&nbsp;{cat.codigo}
							</div>
						</td>
						<td>{cat.descripcion}</td>
						<td>
							{esPenultimoNivel(cat) ? (
								<Form.Group>
									<Form.Select
										name="tipo"
										value={opcionFiltro[cat.id] || 0}
										onChange={({ target }) => {
											setOpcionFiltro(values => ({ ...values, [cat.id]: +target.value }));
										}}
									>
										<option value={0}>Todos los tipos</option>
										<option value={1}>Acto Inseguro</option>
										<option value={2}>Condición Insegura</option>
									</Form.Select>
								</Form.Group>
							) : null}
						</td>
						<td>{cat.tipoAuditoria}</td>
						<td>{cat.factorDeRiesgoFps}</td>
						<td>{cat.precursorFps}</td>
						<td>{cat.severidad}</td>
						<td>
							{esAdministrador && (
								<>
									{cat.nivel < 4 && !cat.tipoAuditoriaId && (
										<OverlayTrigger
											placement="top"
											overlay={<Tooltip id="overlay-example">Agrear Catálogo</Tooltip>}
										>
											<IconButton
												aria-label="Hijo"
												onClick={() =>
													setEstadoModal({
														estado: true,
														idCatalogo: cat.id,
														nivel: cat.nivel,
														catalogo: null,
														tituloProvicional: cat.descripcion,
													})
												}
												size="small"
											>
												<AccountTreeIcon fontSize="inherit" />
											</IconButton>
										</OverlayTrigger>
									)}
									<OverlayTrigger
										placement="top"
										overlay={<Tooltip id="overlay-example">Editar Catálogo</Tooltip>}
									>
										<IconButton
											aria-label="Editar"
											onClick={() =>
												setEstadoModal({
													estado: true,
													idCatalogo: cat.id,
													nivel: cat.nivel,
													catalogo: cat,
												})
											}
											size="small"
										>
											<EditIcon fontSize="inherit" />
										</IconButton>
									</OverlayTrigger>
									<OverlayTrigger
										placement="top"
										overlay={<Tooltip id="overlay-example">Cambiar abajo</Tooltip>}
									>
										<IconButton
											aria-label="Posición abajo"
											onClick={() => cambiarPosicion(cat.id, POSICION.ABAJO)}
											size="small"
										>
											<ArrowDownwardIcon fontSize="inherit" />
										</IconButton>
									</OverlayTrigger>
									<OverlayTrigger
										placement="top"
										overlay={<Tooltip id="overlay-example">Cambiar arriba</Tooltip>}
									>
										<IconButton
											aria-label="Posición arriba"
											onClick={() => cambiarPosicion(cat.id, POSICION.ARRIBA)}
											size="small"
										>
											<ArrowUpwardIcon fontSize="inherit" />
										</IconButton>
									</OverlayTrigger>
									{!!!cat.catalogosChildren.length && (
										<OverlayTrigger
											placement="top"
											overlay={<Tooltip id="overlay-example">Eliminar Catálogo</Tooltip>}
										>
											<IconButton
												aria-label="Eliminar"
												onClick={() => setEstadoModalEliminar({ estado: true, catalogo: cat })}
												size="small"
											>
												<DeleteIcon fontSize="inherit" />
											</IconButton>
										</OverlayTrigger>
									)}
									<>
										{buscarPenultimoNivel(cat) && (
											<OverlayTrigger
												placement="top"
												overlay={<Tooltip id="overlay-example">Cargar Guía</Tooltip>}
											>
												<IconButton
													aria-label="Posición arriba"
													onClick={() =>
														setEstadoPreview({
															estado: true,
															idAspectoObservable: cat.id,
															aspectoObservable: cat.descripcion,
														})
													}
													size="small"
												>
													<UploadFileIcon fontSize="inherit" />
												</IconButton>
											</OverlayTrigger>
										)}
									</>
								</>
							)}
							<>
								{buscarPenultimoNivel(cat) && cat.archivoGuia && (
									<OverlayTrigger
										placement="top"
										overlay={<Tooltip id="overlay-example">Descargar Guía</Tooltip>}
									>
										<IconButton
											aria-label="Hijo"
											onClick={() => descargarDocumentoGuia(cat.archivoGuia)}
											size="small"
										>
											<SimCardDownloadIcon fontSize="inherit" />
										</IconButton>
									</OverlayTrigger>
								)}
							</>
						</td>
					</tr>
					{/* Solo si se abre la fila */}
					{filaAbierta &&
						cuerpoTablaRender(
							renderFiltrado(cat, opcionFiltro[cat.id]) || cat.catalogosChildren,
							nivel + 1
						)}
				</Fragment>
			);
		});
	};

	const recuperarCatalogoEliminado = async (catalogoEliminado: ICatalogo) => {
		try {
			await deleteCatalogoService(catalogoEliminado.id);
			const catalogosActualizado = await getAllCatalogosService();
			setDataFpsContext((data: IDataFpsContext) => ({
				...data,
				catalogos: catalogosActualizado,
			}));
			setEstadoModalEliminar((cata: { estado: boolean; catalogo: ICatalogo }) => ({
				...cata,
				estado: false,
			}));
		} catch (ex) {
			throw ex;
		}
	};

	const descargarDocumentoGuia = async (nombreArchivo: string) => {
		descargarGuia(nombreArchivo, {
			onSuccess: response => {
				const enlaceTemporal = document.createElement('a');
				const binario = URL.createObjectURL(response);

				enlaceTemporal.href = binario;
				enlaceTemporal.download = nombreArchivo;
				enlaceTemporal.click();
				URL.revokeObjectURL(binario);
			},
			onError: error => {
				hayError(error);
			},
		});
	};

	useEffect(() => {
		if (!!dataFpsContext.ramaCatalogo.length) {
			seleccionarCatalogoBuscado();
		}
	}, [dataFpsContext.ramaCatalogo]);

	useEffect(() => {
		asignarFilaAbierta([]);
		setFilaCatalogoEncontrada(0);
		return () => {
			setEstadoModal({
				estado: false,
				idCatalogo: 0,
				nivel: 0,
				catalogo: undefined,
			});
			setEstadoModalEliminar({
				estado: false,
				catalogo: {
					id: 0,
					catalogoId: 0,
					tipoAuditoriaId: 0,
					tipoAuditoria: '',
					archivoGuia: '',
					codigo: '',
					descripcion: '',
					severidad: '',
					severidadId: 0,
					orderAscendingBranch: 0,
					nivel: 0,
					catalogosChildren: [],
					usuarioId: '',
					foto: '',
					userName: '',
					nombre: '',
					email: '',
					puesto: '',
					rol: '',
					factorDeRiesgoFpsId: 0,
					factorDeRiesgoFps: '',
					precursorFpsId: 0,
					precursorFps: '',
				},
			});
		};
	}, []);

	return (
		<>
			{(cargando.cargando || descargandoGuia) && (
				<LoadingComponent mensaje={cargando.mensaje || 'Descargando Guía'} />
			)}
			<Table className="tabla-catalogo" striped hover responsive>
				<thead>
					<tr>
						<th>Nivel</th>
						<th>Aspectos Evaluados</th>
						<th>Filtro</th>
						<th>Tipo Observación</th>
						<th>Factor de Riesgo</th>
						<th>Precursor</th>
						<th>Severidad</th>
						<th>Acciones</th>
					</tr>
				</thead>
				<tbody>{cuerpoTablaRender(catalogos)}</tbody>
			</Table>
			<AgregarCatalogoModal
				estadoModal={estadoModal.estado}
				cambiarEstadoModal={setEstadoModal}
				tituloModal={estadoModal.tituloProvicional || 'Nueva Descripción'}
				idCatalogo={estadoModal.idCatalogo}
				nivel={estadoModal.nivel}
				catalogo={estadoModal.catalogo}
			/>
			<PreviewDocumentModal estadoModal={estadoPreview} cambiarEstadoModal={setEstadoPreview} />
			<DeleteModal
				estadoModal={estadoModalEliminar.estado}
				cambiarEstadoModal={setEstadoModalEliminar}
				tituloModal="Eliminar Catálogo"
				textoCuerpo={`¿Está seguro que quiere eliminar el catálogo "${estadoModalEliminar.catalogo.descripcion}"?`}
				elemento={estadoModalEliminar.catalogo}
				recuperarData={recuperarCatalogoEliminado}
				tipoElemento="catalogo"
			/>
		</>
	);
}
