import { ICambiarContrasena } from '@app/models/interfaces/usuarios/ICambiarContrasena';
import {
	IEditarUsuario,
	IRegistroUsuario,
	IRol,
} from '../models/interfaces/usuarios/IRegistroUsuario';
import http from '../utils/http';

export const getUsuariosService = async (isAuditor: boolean, filter: string) => {
	try {
		const response = await http.get('AdminUser/GetUsuarios', {
			params: {
				auditor: isAuditor,
				filter,
			},
		});
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getUsuarioByIdService = async (idUser: string) => {
	const response = await http.get(`AdminUser/getUsuarioById/${idUser}`);
	return response?.data;
};

export const getUsuariosInternosService = async () => {
	try {
		const response = await http.get('AdminUser/GetUsuariosInternosAD');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getRolesService = async () => {
	const response = await http.get<IRol[]>('AdminUser/GetRoles');
	return response?.data;
};

export const addUsuarioGenericoService = async (requestUser: IRegistroUsuario) => {
	const response = await http.post('User/AgregarUsuarioApp', requestUser);
	return response?.data;
};

export const addUsuarioIternoService = async (dataRequest: IRegistroUsuario) => {
	try {
		const response = await http.post('AdminUser/registraUsuarioInterno', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addUsuarioExternoService = async (dataRequest: IRegistroUsuario) => {
	try {
		const response = await http.post('AdminUser/registraUsuarioExterno', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editUsuariosService = async (dataRequest: IEditarUsuario, idUser: string) => {
	try {
		const response = await http.put(`AdminUser/EditUser/${idUser}`, dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const resetPasswordUsuarioExternoService = async (idUsuario: string) => {
	try {
		const response = await http.put(`AdminUser/resetPasswordUsuarioExterno/${idUsuario}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const resetPasswordAuditorService = async (idUsuario: string) => {
	const response = await http.put(`User/resetPasswordUserAuditorExterno/${idUsuario}`);
	return response?.data;
};

export const activarUsuarioExternoService = async (idUsuario: string, estado: boolean) => {
	try {
		const response = await http.put(`AdminUser/activarUsuarioExterno/${idUsuario}`, {
			active: estado,
		});
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const cambiarContrasenaService = async (requestData: ICambiarContrasena) => {
	const response = await http.put('User/ChangePasswordUsuarioExterno', requestData);
	return response?.data;
};
