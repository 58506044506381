import { useState } from 'react'
import { Alert } from 'react-bootstrap'

function useError() {
  const [error, setErrorState] = useState<string>('')

  const onClose = () => setErrorState('')
  const ErrorAlert = () =>
    error && (
      <Alert className="mt-4" onClose={onClose} dismissible variant="danger">
        {error}
      </Alert>
    )
  const setError = (ex) =>
    setErrorState(ex?.response?.data?.message ?? ex?.message ?? '')

  return { ErrorAlert, setError }
}

export default useError
