import { IAreaZona } from '@app/models/interfaces/areasZonas/IAreaZona';
import { IRamaAreaZona } from '@app/models/interfaces/areasZonas/IRamaAreaZona';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import AgregarAreaZonaModal from '../../components/Modals/AgregarAreaZonaModal';
import DeleteModal from '../../components/Modals/DeleteModal';
import useData from '../../hooks/useData';
import { IDataFpsContext } from '../../models/IDataContext';
import { TablaAreasZonasProps } from '../../models/types/tabla-areazona.type';
import { deleteAreaZonaService, getAllAreasZonasService } from '../../services/areas-zonas.service';

import '../../styles/components/TablaAreasZonas.component.scss';

export default function TablaCatalogo(props: TablaAreasZonasProps) {
  const { areasZonas, asignarFilaAbierta, seleccionarFila, filasAbiertas } = props;
  const [estadoModalEliminar, setEstadoModalEliminar] = useState<{ estado: boolean; areaZona: IAreaZona }>({
    estado: false,
    areaZona: {
      id: 0,
      nivel: '',
      descripcion: '',
      codigo: '',
      areaZonaId: null,
      orderAscendingBranch: 0,
      areasZonasChildren: []
    },
  });
  const [estadoModal, setEstadoModal] = useState<{
    estado: boolean;
    idAreaZona: number;
    nivel: number;
    areaZona: IAreaZona | undefined;
    tituloProvicional?: string;
  }>({
    estado: false,
    idAreaZona: 0,
    nivel: 0,
    areaZona: undefined,
    tituloProvicional: ''
  });
  const [filaAreaZonaEncontrada, setFilaAreaZonaEncontrada] = useState<number>(0);
  const { setDataFpsContext, dataFpsContext } = useData();

  const expandirFila = (idAreaZona: number) => event => {
    event.stopPropagation();
    if (!!filasAbiertas.find((fl: number) => fl === +idAreaZona)) {
      asignarFilaAbierta((filas: number[]) => filas.filter((fil: number) => fil !== +idAreaZona));
    } else {
      asignarFilaAbierta((filas: number[]) => [...filas, +idAreaZona]);
    }
  };

  const seleccionarAreaZonaBuscada = () => {
    asignarFilaAbierta([]);
    asignarFilaAbierta(dataFpsContext.ramaAreaZona.map((rama: IRamaAreaZona) => rama.id));

    const idAreaZonaSeleccionar = dataFpsContext.ramaAreaZona[dataFpsContext.ramaAreaZona.length - 1].id;
    setFilaAreaZonaEncontrada(idAreaZonaSeleccionar);
  };

  const seleccionarFilaAreaZona = (idFila: number) => () => seleccionarFila(idFila);

  const cuerpoTablaRender = (listaCat: IAreaZona[], nivel: number = 0) => {
    return listaCat.map((areaZona: IAreaZona) => {
      const filaAbierta: boolean = !!(filasAbiertas.length && filasAbiertas.find((fil: number) => fil === +areaZona.id));
      const hayMasCatalogos: boolean = !!areaZona.areasZonasChildren.length;

      return (
        <Fragment key={areaZona.id}>
          <tr
            className={classNames({
              'fw-bold': nivel === 0,
              'tabla-area-zona__seleccionado': areaZona.id === filaAreaZonaEncontrada,
            })}
            role="button"
            onClick={seleccionarFilaAreaZona(areaZona.id)}
          >
            <td>
              <div style={{ marginLeft: `${nivel}rem` }}>
                {hayMasCatalogos && (
                  <IconButton aria-label="Expandir" onClick={expandirFila(areaZona.id)} size="small">
                    <ArrowForwardIosIcon
                      className={classNames('tabla-area-zona__arrow', {
                        'tabla-area-zona__arrow--open': filaAbierta,
                      })}
                      id={`fila-${areaZona.id}`}
                      fontSize="inherit"
                    />
                  </IconButton>
                )}
                &nbsp;{areaZona.codigo}
              </div>
            </td>
            <td>{areaZona.descripcion}</td>
            <td>
              {+areaZona.nivel < 4 && 
                <IconButton
                  aria-label="Hijo"
                  size="small"
                  onClick={() =>
                    setEstadoModal({ estado: true, idAreaZona: areaZona.id, nivel: +areaZona.nivel, areaZona: null, tituloProvicional: areaZona.descripcion })
                  }
                >
                  <AccountTreeIcon fontSize="inherit" />
                </IconButton>
              }
              <IconButton
                aria-label="Editar"
                size="small"
                onClick={() => setEstadoModal({ estado: true, idAreaZona: areaZona.id, nivel: +areaZona.nivel, areaZona: areaZona })}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              {!areaZona.areasZonasChildren.length &&               
                <IconButton
                  aria-label="Eliminar"
                  size="small"
                  onClick={() => setEstadoModalEliminar({ estado: true, areaZona })}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              }
            </td>
          </tr>
          {filaAbierta && cuerpoTablaRender(areaZona.areasZonasChildren, nivel + 1)}
        </Fragment>
      );
    });
  };

  const recuperarCatalogoEliminado = async (catalogoEliminado: IAreaZona) => {
    try {
      await deleteAreaZonaService(catalogoEliminado.id);
      const areasZonasActualizadas = await getAllAreasZonasService();
      setDataFpsContext((data: IDataFpsContext) => ({
        ...data,
        areasZonas: areasZonasActualizadas,
      }));
      setEstadoModalEliminar((areaZ: { estado: boolean; areaZona: IAreaZona }) => ({ ...areaZ, estado: false }));
    } catch (ex) {
      throw ex;
    }
  };

  useEffect(() => {
    if (!!dataFpsContext.ramaAreaZona.length) {
      seleccionarAreaZonaBuscada();
    }
  }, [dataFpsContext.ramaAreaZona]);

  useEffect(() => {
    asignarFilaAbierta([]);
    setFilaAreaZonaEncontrada(0);
    return () => {
      setEstadoModal({
        estado: false,
        idAreaZona: 0,
        nivel: 0,
        areaZona: undefined,
      });
      setEstadoModalEliminar({
        estado: false,
        areaZona: {
          id: 0,
          nivel: '',
          descripcion: '',
          codigo: '',
          areaZonaId: null,
          orderAscendingBranch: 0,
          areasZonasChildren: []
        },
      });
    };
  }, []);

  return (
    <>
      <Table className="tabla-area-zona" striped hover responsive>
        <thead>
          <tr>
            <th>Nivel</th>
            <th>Área o Zona</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>{cuerpoTablaRender(areasZonas)}</tbody>
      </Table>
      <AgregarAreaZonaModal
        estadoModal={estadoModal.estado}
        cambiarEstadoModal={setEstadoModal}
        tituloModal={estadoModal.tituloProvicional || 'Nueva Descripción'}
        idAreaZona={estadoModal.idAreaZona}
        nivel={estadoModal.nivel}
        areaZona={estadoModal.areaZona}
      />
      <DeleteModal
        estadoModal={estadoModalEliminar.estado}
        cambiarEstadoModal={setEstadoModalEliminar}
        tituloModal="Eliminar Área o Zona"
        textoCuerpo={`¿Está seguro que quiere eliminar el área o zona "${estadoModalEliminar.areaZona.descripcion}"?`}
        elemento={estadoModalEliminar.areaZona}
        recuperarData={recuperarCatalogoEliminado}
        tipoElemento='areaZona'
      />
    </>
  );
}
