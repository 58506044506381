import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import useRole from '@app/hooks/useRole';
import Logo from '../assets/images/logo.png';
import NavItemComponent from '../components/NavItem';
import { ROUTES } from '../constants/routes';
import useData from '../hooks/useData';
import { logoutService } from '../services/auth.service';

import '../styles/components/NavMenu.component.scss';

export default function NavMenu() {
	const { setDataUser, dataUser } = useData();
	const { esRole } = useRole();

	return (
		<Navbar
			className="navmenu px-3"
			bg="primary"
			expand="lg"
			variant="dark"
			sticky="top"
			collapseOnSelect
		>
			<Container fluid>
				<Navbar.Brand as={Link} to={ROUTES.ROOT}>
					<img src={Logo} alt="Logo Peña colorada" height="57" />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<div className="navmenu__nav-items">
						<Nav>
							{esRole('Administrador') && (
								<Nav.Link as={Link} to={ROUTES.ROOT}>
									Indicadores
								</Nav.Link>
							)}
							<NavDropdown title="Auditorías" id="collasible-nav-dropdown">
								<NavDropdown.Item as={Link} to={ROUTES.TABLA_AUDITORIAS}>
									Levantamiento de Auditoria
								</NavDropdown.Item>
								{esRole('Administrador') && (
									<NavDropdown.Item as={Link} to={ROUTES.AUDITORIAS_OTRO_TIPO}>
										Auditorías Fuera de Catálogo
									</NavDropdown.Item>
								)}
							</NavDropdown>
							<NavDropdown title="Catálogos" id="collasible-nav-dropdown">
								{esRole('Administrador') && (
									<NavDropdown.Item as={Link} to={ROUTES.AREAS_ZONAS}>
										Áreas y Zonas
									</NavDropdown.Item>
								)}
								<NavDropdown.Item as={Link} to={ROUTES.CATALOGOS}>
									Aspectos Evaluados
								</NavDropdown.Item>
								{esRole('Administrador') && (
									<>
										<NavDropdown.Item as={Link} to={ROUTES.REACCIONES}>
											Reacciones Personas
										</NavDropdown.Item>
										<NavDropdown.Item as={Link} to={ROUTES.JEFATURAS_GERENCIAS}>
											Estructura Organizativa
										</NavDropdown.Item>
										<NavDropdown.Item as={Link} to={ROUTES.FACTOR_RIESGO}>
											Factores de Riesgo
										</NavDropdown.Item>
										<NavDropdown.Item as={Link} to={ROUTES.PRECURSORES}>
											Precursores
										</NavDropdown.Item>
									</>
								)}
							</NavDropdown>
							{esRole('Administrador') && (
								<>
									<Nav.Link as={Link} to={ROUTES.METAS}>
										Metas
									</Nav.Link>
									<Nav.Link as={Link} to={ROUTES.USUARIOS}>
										Usuarios
									</Nav.Link>
									<Nav.Link as={Link} to={ROUTES.MOTS}>
										MOTS
									</Nav.Link>
								</>
							)}
						</Nav>
						<Nav>
							<NavDropdown
								title={
									<NavItemComponent
										usuario={dataUser.usuario}
										role={dataUser.roles.length > 1 ? dataUser.roles[1] : dataUser.roles[0]}
									/>
								}
								id="basic-nav-dropdown"
							>
								{esRole('UsuarioExterno') && (
									<>
										<NavDropdown.Item as={Link} to={ROUTES.CAMBIAR_CONTRASENA}>
											Cambiar Contraseña
										</NavDropdown.Item>
										<NavDropdown.Divider />
									</>
								)}
								<NavDropdown.Item onClick={() => logoutService(setDataUser)}>
									Cerrar Sesión
								</NavDropdown.Item>
							</NavDropdown>
						</Nav>
					</div>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
