import { useQuery } from '@tanstack/react-query';

import { getPrecursores } from '@app/services/catalogos.service';

export default function usePrecursor() {
	return useQuery({
		queryKey: ['precursor'],
		queryFn: getPrecursores,
		refetchOnWindowFocus: false,
		retry: 0,
		cacheTime: 0,
	});
}
