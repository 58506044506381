import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material';
import { differenceInCalendarWeeks } from 'date-fns';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';

import useAreasZonas from '@app/hooks/useAreasZonas';
import { IDataAuditoriaContext } from '@app/models/IDataContext';
import { TIPO_AUDITORIAS } from '@app/models/enums/tipoAuditorias.enum';
import { IPersonal } from '@app/models/interfaces/auditorias/IPersonal';
import { IJefaturaGerencia } from '@app/models/interfaces/jefaturasGerencias/IJefaturaGerencia';
import { DEFAULT_AUDITORIA_DATA_CONTEXT } from '../../../constants/defaultAuditoriaDataContext';
import useData from '../../../hooks/useData';
import useOrganigrama from '../../../hooks/useOrganigrama';
import { IAreaZona } from '../../../models/interfaces/areasZonas/IAreaZona';
import { IFiltros } from '../../../models/interfaces/condicionInsegura/IFiltros';
import { FormularioStepperProps } from '../../../models/types/formulario-stepper.type';
import LoadingComponent from '../../Loading';

export default function FormularioAreas(props: FormularioStepperProps) {
	const { avance, formik, termino, hayError } = props;
	const [ultimosNiveles, setUltimosNiveles] = useState<{ organigrama: boolean; areas: boolean }>({
		organigrama: true,
		areas: true,
	});
	const [zonas, setZonas] = useState<IAreaZona[][]>([]);
	const [gerencias, setGerencias] = useState<IJefaturaGerencia[][]>([]);
	const [trabajadorSeleccionado, setTrabajadorSeleccionado] = useState<IPersonal[]>([]);
	const [filtros, setFiltros] = useState<IFiltros>({
		area: '',
		zona: [],
	});
	const [filtrosJefaturaGerencia, setFiltrosJefaturaGerencia] = useState<{
		jefatura: string;
		gerencias: [];
	}>({
		jefatura: '',
		gerencias: [],
	});
	const { dataAuditoriaContext, setDataAuditoriaContext } = useData();
	const { data: jefaturasGerencias, isFetching: cargandoJefaturasGerencias } = useOrganigrama();
	const { data: areasZonas, isFetching: cargandoAreasZonas } = useAreasZonas();

	const obtenerRamaAreaZona = async (
		idAreaZona: number | null,
		listaAreas: IAreaZona[] = areasZonas || []
	) => {
		try {
			const ramaResponse: IAreaZona[] =
				listaAreas?.find((area: IAreaZona) => area.id === idAreaZona)?.areasZonasChildren || [];
			if (ramaResponse.length) {
				setZonas((zonas: IAreaZona[][]) => [...zonas, ramaResponse]);
			}
		} catch (ex) {
			throw ex;
		}
	};

	const obtenerRamaJefaturaGerencia = async (
		idJefaturaGerencia: number | null,
		listaGerencias: IJefaturaGerencia[] = jefaturasGerencias || []
	) => {
		try {
			const ramaResponse: IJefaturaGerencia[] =
				listaGerencias?.find((geren: IJefaturaGerencia) => geren.id === idJefaturaGerencia)
					?.organigramasChildren || [];
			if (ramaResponse.length) {
				setGerencias((gerencias: IJefaturaGerencia[][]) => [...gerencias, ramaResponse]);
			}
		} catch (ex) {
			throw ex;
		}
	};

	const removerSelect = (indiceZonaItem: number) => {
		setUltimosNiveles(niveles => ({ ...niveles, areas: true }));
		if (indiceZonaItem < 4) {
			const zonasActualizadas = zonas.filter((val, index) => {
				val;
				return index < indiceZonaItem;
			});
			setZonas(zonasActualizadas);
			formik.setFieldValue('idAreaZona', indiceZonaItem, false);

			//i Si ya no hay mas zonas entonces se resetea el select de área
			if (!zonasActualizadas.length) {
				setFiltros((filtros: IFiltros) => ({ ...filtros, area: '' }));
			}
			//i Reseteamos el valor vacio
			const selectZona: HTMLInputElement = document.querySelector(`#select-${indiceZonaItem}`);
			selectZona.value = '0';
		}
	};

	//i Renderizamos las zonas hijas de las áreas y zonas padres (selects anteriores)
	//i así se va formando el árbol de inputs tipo "select".
	const renderSubZonas = () => {
		return (
			<>
				{zonas.map((zonaItem: IAreaZona[], index: number) => (
					<div
						className="mt-4 d-flex flex-column position-relative"
						key={index}
						style={{ width: '95%' }}
					>
						{index < zonas.length - 1 && index > zonas.length - 3 ? (
							<div
								className="position-absolute"
								style={{
									right: '-35px',
									top: '5px',
								}}
							>
								<IconButton
									aria-label="Eliminar"
									size="small"
									onClick={() => removerSelect(index + 1)}
								>
									<RefreshIcon fontSize="inherit" />
								</IconButton>
							</div>
						) : null}
						<Form.Control
							id={`select-${index + 1}`}
							as="select"
							className="select-areazona"
							name="idAreaZona"
							value={formik.idAreaZona}
							onChange={({ target }) => {
								obtenerRamaAreaZona(+target.value, zonaItem);
								formik.setFieldValue('idAreaZona', +target.value);
								const hasChildren = !!zonaItem.find(item => item?.id === +target.value)
									?.areasZonasChildren?.length;
								setUltimosNiveles(niv => ({ ...niv, areas: hasChildren }));
							}}
							isInvalid={!!formik.errors.idAreaZona}
							disabled={index !== zonas.length - 1}
						>
							<option value={0}>{`Seleccione Nivel ${
								zonaItem.length ? zonaItem[0]?.nivel : ''
							}`}</option>
							{zonaItem.length && (
								<>
									{zonaItem.map((zon: IAreaZona) => (
										<option key={zon.id} value={zon.id}>
											{zon.descripcion}
										</option>
									))}
								</>
							)}
						</Form.Control>
						<Form.Control.Feedback className="d-block" type="invalid">
							{formik.errors.idAreaZona}
						</Form.Control.Feedback>
					</div>
				))}
			</>
		);
	};

	const removerSelectJefaturaGerencia = (indiceJefaturaGerenciaItem: number) => {
		setUltimosNiveles(niveles => ({ ...niveles, organigrama: true }));
		if (indiceJefaturaGerenciaItem < 4) {
			const gerenciasActualizadas = gerencias.filter((val, index) => {
				val;
				return index < indiceJefaturaGerenciaItem;
			});
			setGerencias(gerenciasActualizadas);
			formik.setFieldValue('idOrganigrama', indiceJefaturaGerenciaItem, false);

			//i Si ya no hay mas gerencias entonces se resetea el select de jefatura
			if (!gerenciasActualizadas.length) {
				setFiltrosJefaturaGerencia((filtros: { jefatura: string; gerencias: [] }) => ({
					...filtros,
					jefatura: '',
				}));
			}
			//i Reseteamos el valor vacio
			const selectJefaturaGerencia: HTMLInputElement = document.querySelector(
				`#select-jefatura-gerencia-${indiceJefaturaGerenciaItem}`
			);
			selectJefaturaGerencia.value = '0';
		}
	};

	const renderGerencias = () => {
		return (
			<>
				{gerencias.map((gerenciaItem: IJefaturaGerencia[], index: number) => (
					<div
						className="mt-4 d-flex flex-column position-relative"
						key={index}
						style={{ width: '95%' }}
					>
						{index < gerencias.length - 1 && index > gerencias.length - 3 ? (
							<div
								className="position-absolute"
								style={{
									right: '-35px',
									top: '5px',
								}}
							>
								<IconButton
									aria-label="Eliminar"
									size="small"
									onClick={() => removerSelectJefaturaGerencia(index + 1)}
								>
									<RefreshIcon fontSize="inherit" />
								</IconButton>
							</div>
						) : null}
						<Form.Control
							id={`select-jefatura-gerencia-${index + 1}`}
							as="select"
							className="select-jefatura-gerencia"
							name="idOrganigrama"
							value={formik.idOrganigrama}
							onChange={({ target }) => {
								obtenerRamaJefaturaGerencia(+target.value, gerenciaItem);
								formik.setFieldValue('idOrganigrama', +target.value);
								const hasChildren = !!gerenciaItem.find(item => item?.id === +target.value)
									?.organigramasChildren?.length;
								setUltimosNiveles(niv => ({ ...niv, organigrama: hasChildren }));
							}}
							isInvalid={!!formik.errors.idOrganigrama}
							disabled={index !== gerencias.length - 1}
						>
							<option value={0}>{`Seleccione Nivel ${
								gerenciaItem.length ? gerenciaItem[0]?.nivel : ''
							}`}</option>
							{gerenciaItem.length && (
								<>
									{gerenciaItem.map((geren: IJefaturaGerencia) => (
										<option key={geren.id} value={geren.id}>
											{geren.descripcion}
										</option>
									))}
								</>
							)}
						</Form.Control>
						<Form.Control.Feedback className="d-block" type="invalid">
							{formik.errors.idOrganigrama}
						</Form.Control.Feedback>
					</div>
				))}
			</>
		);
	};

	//i buscar la rama correspondiente y mostrar el área seleccionada
	const setearAreasZonas = () => {
		try {
			const { detalleAuditoria } = dataAuditoriaContext;

			if (detalleAuditoria.areaZonaRamaAsc?.length) {
				//i Se invierte el orden de las ramas de menor a mayor para llevar un orden
				const ramaArea = detalleAuditoria.areaZonaRamaAsc.reverse();
				let zonasCargadas: IAreaZona[][] = [];

				setTimeout(() => {
					//i Se busca el primer nivel (de zonas) y lo cargamos a las zonas
					const result = areasZonas?.find((ar: IAreaZona) => ar.id === ramaArea[0].id);
					zonasCargadas = [...zonasCargadas, result?.areasZonasChildren];
					ramaArea.forEach((item: { id: number; nivel: number }, index: number) => {
						if (item.nivel > 1) {
							if (zonasCargadas?.length === index) {
								const encontrado = zonasCargadas[index - 1]?.find(
									(areaZon: IAreaZona) => areaZon.id === item.id
								);
								if (encontrado) {
									zonasCargadas = [...zonasCargadas, encontrado?.areasZonasChildren];
								}
							}
						}
					});
					setZonas(zonasCargadas.filter((ite: any[]) => ite?.length));
				});

				//i Inyectamos los valores a los selects de áreas y zonas
				ramaArea.forEach((item: any, index: number) => {
					setTimeout(() => {
						const areaSelect: any = document.getElementById(`select-${index}`);
						if (areaSelect) {
							areaSelect.value = item.id;
						}
					});
				});
			}
		} catch (ex) {
			hayError(ex);
		}
	};

	const setearJefaturasGerencias = () => {
		try {
			const { detalleAuditoria } = dataAuditoriaContext;
			if (detalleAuditoria.gerenciaJefaturaRamaAsc?.length) {
				//i Se invierte el orden de las ramas de menor a mayor para llevar un orden
				const ramaJefaturaGerencia = detalleAuditoria.gerenciaJefaturaRamaAsc.reverse();
				let gerenciasCargadas: IJefaturaGerencia[][] = [];

				//i Se busca el primer nivel (de jefaturas) y lo cargamos a las gerencias
				setTimeout(() => {
					const result = jefaturasGerencias?.find(
						(jef: IJefaturaGerencia) => jef.id === ramaJefaturaGerencia[0].id
					);
					gerenciasCargadas = [...gerenciasCargadas, result?.organigramasChildren];
					ramaJefaturaGerencia.forEach((item: { id: number; nivel: number }, index: number) => {
						if (item.nivel > 1) {
							if (gerenciasCargadas?.length === index) {
								const encontrado = gerenciasCargadas[index - 1]?.find(
									(geren: IJefaturaGerencia) => geren.id === item.id
								);
								if (encontrado) {
									gerenciasCargadas = [...gerenciasCargadas, encontrado?.organigramasChildren];
								}
							}
						}
					});
					setGerencias(gerenciasCargadas.filter((ite: any[]) => ite?.length));
				});

				//i Inyectamos los valores a los selects de jefaturas y gerencias
				ramaJefaturaGerencia.forEach((item: any, index: number) => {
					setTimeout(() => {
						const gerenciaSelect: any = document.getElementById(
							`select-jefatura-gerencia-${index}`
						);
						if (gerenciaSelect) {
							gerenciaSelect.value = item.id;
						}
					});
				});

				//i Si se carga la data de direcciones y areas deben ser el último nivel siempre entonces estos deben tener
				//i el ultimo nivel como "true"
				setUltimosNiveles({ areas: false, organigrama: false });
			}
		} catch (ex) {
			hayError(ex);
		}
	};

	const trabajadorAuditadoSeleccionado = (trabajador: IPersonal[]) => {
		formik.setValues(vals => ({ ...vals, trabajador: '', empresa: '' }));
		setTrabajadorSeleccionado(trabajador);
		if (trabajador.length) {
			const [{ nombre, empresa, puesto }] = trabajador;
			formik.setValues(vals => ({ ...vals, trabajador: nombre, empresa: empresa }));
			setTrabajadorSeleccionado([{ nombre, empresa, puesto }]);
		}
	};

	const noAvanza = !!Object.keys(formik.errors).filter(
		error =>
			error === 'trabajador' ||
			error === 'empresa' ||
			error === 'fecha' ||
			error === 'idOrganigrama' ||
			error === 'idAreaZona'
	).length;

	useEffect(() => {
		if (!!formik.values.fecha) {
			const fechaFormulario = new Date(formik.values.fecha);
			const numeroSemanaActual = differenceInCalendarWeeks(
				new Date(),
				new Date(new Date().getFullYear(), 0, 1),
				{
					weekStartsOn: 1,
				}
			);

			const anioCorrecto =
				fechaFormulario.getFullYear() !== new Date().getFullYear()
					? fechaFormulario.getFullYear()
					: new Date().getFullYear();

			const numeroSemanaCambiado = differenceInCalendarWeeks(
				fechaFormulario,
				new Date(anioCorrecto, 0, 1),
				{
					weekStartsOn: 1,
				}
			);

			setDataAuditoriaContext((dataAudit: IDataAuditoriaContext) => ({
				...dataAudit,
				numeroSemanaVariante: numeroSemanaCambiado || 52, //i Si retorna semana 0 entonces corresponde a la semana 52
				semanaExtemporanea:
					numeroSemanaCambiado < numeroSemanaActual ||
					fechaFormulario.getFullYear() < new Date().getFullYear(),
			}));
		} else {
			const numeroSemanaActual = differenceInCalendarWeeks(
				new Date(),
				new Date(new Date().getFullYear(), 0, 1),
				{
					weekStartsOn: 1,
				}
			);
			setDataAuditoriaContext((dataAudit: IDataAuditoriaContext) => ({
				...dataAudit,
				numeroSemanaVariante: numeroSemanaActual,
			}));
		}
	}, [formik.values.fecha]);

	useEffect(() => {
		const cargaDatos = async () => {
			if (!!dataAuditoriaContext.detalleAuditoria.id) {
				const {
					fecha,
					descripcion,
					severidad,
					tipoLevantamiento,
					direccion,
					esRechadada,
					descripcionRechazoTarea,
					accionesTomadasRechazoTarea,
					catalogoId,
					areaZonaId,
					abierto,
					organigramaId,
					factorDeRiesgoFpsId,
					precursorFpsId,
					fotosEvidencias,
					esOtroTipoObservacion,
				} = dataAuditoriaContext.detalleAuditoria;

				//i Se establece el valor del trabajador (input autocomplete)
				const trabajadorEncontrado = dataAuditoriaContext.personal?.find(
					(per: IPersonal) =>
						per.nombre.toLowerCase() ===
							dataAuditoriaContext.detalleAuditoria?.trabajador?.toLowerCase() &&
						per.empresa.toLowerCase() ===
							dataAuditoriaContext.detalleAuditoria?.empresa?.toLowerCase()
				);
				setTrabajadorSeleccionado([
					trabajadorEncontrado?.nombre
						? trabajadorEncontrado
						: { nombre: '', empresa: '', puesto: '' },
				]);

				//i Se establecen los valores de formik
				setTimeout(async () => {
					formik.setValues(values => ({
						...values,
						trabajador: trabajadorEncontrado?.nombre || '',
						empresa: trabajadorEncontrado?.empresa || '',
						fecha,
						descripcion: '',
						severidad: severidad || 'Alta',
						tipoLevantamiento,
						direccion: direccion || '',
						esRechadada,
						descripcionRechazoTarea: descripcionRechazoTarea || '',
						accionesTomadasRechazoTarea: accionesTomadasRechazoTarea || '',
						idAreaZona: +areaZonaId,
						idCatalogo: +catalogoId,
						idOrganigrama: +organigramaId,
						abierto,
						factorDeRiesgoFpsId: factorDeRiesgoFpsId || 1,
						precursorFpsId: precursorFpsId || 1,
						fotosEvidencias: fotosEvidencias,
						descripcionEvidencia: descripcion || fotosEvidencias[0]?.descripcionEvidencia,
						esOtroTipoObservacion: esOtroTipoObservacion || false,
					}));
				});

				//i Renderizar los inputs de los árboles
				setearJefaturasGerencias();
				setearAreasZonas();
			}
		};
		if (dataAuditoriaContext.editarAuditoria && !!jefaturasGerencias && !!areasZonas) {
			cargaDatos();
		}
	}, [dataAuditoriaContext.editarAuditoria, jefaturasGerencias, areasZonas]);

	useEffect(() => {
		obtenerRamaJefaturaGerencia(
			filtrosJefaturaGerencia.jefatura ? +filtrosJefaturaGerencia.jefatura : null
		);
	}, [filtrosJefaturaGerencia.jefatura]);

	useEffect(() => {
		obtenerRamaAreaZona(filtros.area ? +filtros.area : null);
	}, [filtros.area]);

	useEffect(() => {
		formik.setFieldValue('esRechadada', false);
		formik.setFieldValue('descripcionRechazoTarea', '', false);
		formik.setFieldValue('accionesTomadasRechazoTarea', '', false);
	}, [formik.values.tipoLevantamiento]);

	useEffect(() => {
		return () => {
			setFiltros({
				area: '',
				zona: [],
			});
			setFiltrosJefaturaGerencia({
				jefatura: '',
				gerencias: [],
			});
			setZonas([]);
			setGerencias([]);
			setDataAuditoriaContext(audit => ({
				...audit,
				detalleAuditoria: DEFAULT_AUDITORIA_DATA_CONTEXT,
				editarAuditoria: false,
			}));
		};
	}, []);

	return (
		<>
			{(termino || cargandoJefaturasGerencias || cargandoAreasZonas) && (
				<LoadingComponent mensaje="Cargando catálogos" />
			)}
			<Row className="mt-3">
				<Col
					xs={{ offset: 1, span: 5 }}
					sm={{ offset: 1, span: 5 }}
					md={{ offset: 1, span: 5 }}
					lg={{ offset: 1, span: 5 }}
				>
					<Form.Group>
						<Form.Label>
							Trabajador Auditado<span className="auditoria__required">*</span>
						</Form.Label>
						<Typeahead
							id="single-typeahead"
							clearButton
							filterBy={['nombre']}
							labelKey="nombre"
							onChange={trabajadorAuditadoSeleccionado}
							options={dataAuditoriaContext.personal}
							selected={trabajadorSeleccionado}
							renderMenuItemChildren={(option: IPersonal, { text }) => (
								<div>
									<Highlighter
										highlightClassName="bg-transparent font-weight-bold p-0"
										search={text}
									>
										{`${option?.nombre}`}
									</Highlighter>
									<div>
										<div>
											<small>
												Empresa:{' '}
												<Highlighter
													highlightClassName="bg-transparent font-weight-bold p-0"
													search={text}
												>
													{option?.empresa}
												</Highlighter>
											</small>
										</div>
									</div>
								</div>
							)}
							isInvalid={!!formik.errors.trabajador}
							placeholder="Trabajador Auditado"
						/>
						<Form.Control.Feedback className="d-block" type="invalid">
							{formik.errors.trabajador}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col xs={5} sm={5} md={5} lg={5} style={{ margin: '0 10px' }}>
					<Form.Group>
						<Form.Label>Tipo Auditoria</Form.Label>
						<Form.Control
							name="tipoLevantamiento"
							value={formik.values.tipoLevantamiento}
							onChange={({ target }) => {
								setDataAuditoriaContext((dataAudit: IDataAuditoriaContext) => ({
									...dataAudit,
									prevTipoAuditoria: +formik.values.tipoLevantamiento,
								}));
								formik.setFieldValue('tipoLevantamiento', +target.value, false);
							}}
							as="select"
						>
							<option value={TIPO_AUDITORIAS.ACTO_INSEGURO}>Acto Inseguro</option>
							<option value={TIPO_AUDITORIAS.CONDICION_INSEGURA}>Condición Insegura</option>
							<option value={TIPO_AUDITORIAS.REFORZAMIENTO_POSITIVO}>Reforzamiento Positivo</option>
						</Form.Control>
					</Form.Group>
				</Col>
			</Row>
			{+formik.values.tipoLevantamiento === TIPO_AUDITORIAS.REFORZAMIENTO_POSITIVO && (
				<Row className="mt-3">
					<Col xs={{ offset: 6, span: 5 }} sm={{ offset: 6, span: 5 }} md={{ offset: 6, span: 5 }}>
						<Form.Group
							style={{
								backgroundColor: 'rgb(80, 116, 138)',
								color: 'white',
								padding: '15px',
								borderRadius: '7px',
								fontSize: '18px',
								fontWeight: 'bold',
								textAlign: 'center',
							}}
						>
							<Form.Label className="m-0">¿Rechazó la tarea?</Form.Label>&nbsp;&nbsp;
							<Form.Check
								inline
								className="mb-0"
								type="checkbox"
								name="rechazo"
								onClick={(event: any) => {
									formik.setFieldValue('esRechadada', event?.target?.checked, false);
									setDataAuditoriaContext((dataAuditoria: IDataAuditoriaContext) => ({
										...dataAuditoria,
										mostrarPasoTres: event?.target?.checked,
									}));
									if (!event?.target?.checked) {
										formik.setFieldValue('descripcionRechazoTarea', '', false);
										formik.setFieldValue('accionesTomadasRechazoTarea', '', false);
									}
								}}
								checked={formik.values.esRechadada}
								value={formik.values.esRechadada}
							/>
						</Form.Group>
					</Col>
				</Row>
			)}
			<Row className="mt-3">
				<Col
					xs={{ offset: 1, span: 5 }}
					sm={{ offset: 1, span: 5 }}
					md={{ offset: 1, span: 5 }}
					lg={{ offset: 1, span: 5 }}
				>
					<Form.Group>
						<Form.Label>
							Empresa<span className="auditoria__required">*</span>
						</Form.Label>
						<Form.Control
							name="empresa"
							value={formik.values.empresa}
							onChange={formik.handleChange}
							isInvalid={!!formik.errors.empresa}
							placeholder="Empresa"
							disabled
						/>
						<Form.Control.Feedback className="d-block" type="invalid">
							{formik.errors.empresa}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col xs={5} sm={5} md={5} lg={5} style={{ margin: '0 10px' }}>
					<Form.Group>
						<Form.Label>
							Fecha/Hora<span className="auditoria__required">*</span>
						</Form.Label>
						<Form.Control
							name="fecha"
							value={formik.values.fecha}
							onChange={formik.handleChange}
							isInvalid={!!formik.errors.fecha}
							max={new Date().toISOString().slice(0, 16)}
							type="datetime-local"
						/>
						<Form.Control.Feedback className="d-block" type="invalid">
							{formik.errors.fecha}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
			</Row>
			<Row className="mt-3">
				<Col
					xs={{ offset: 1, span: 5 }}
					sm={{ offset: 1, span: 5 }}
					md={{ offset: 1, span: 5 }}
					lg={{ offset: 1, span: 5 }}
					style={{ border: 'solid 2px #4e779e', borderRadius: '0.25rem', padding: '10px' }}
				>
					<Form.Group>
						<div className="d-flex align-items-center justify-content-between">
							<Form.Label htmlFor="select-jefatura-gerencia-0">
								Direcciones<span className="auditoria__required">*</span>
							</Form.Label>
							<IconButton
								aria-label="Eliminar"
								size="small"
								onClick={() => {
									setGerencias([]);
									setFiltrosJefaturaGerencia(filtros => ({ ...filtros, jefatura: '' }));
									const elemento: HTMLInputElement = document.querySelector(
										'#select-jefatura-gerencia-0'
									);
									elemento.value = '0';
									formik.setFieldValue('idOrganigrama', 0);
									setUltimosNiveles(niveles => ({ ...niveles, organigrama: true }));
								}}
							>
								<PlaylistRemoveIcon fontSize="inherit" />
							</IconButton>
						</div>
						<Form.Control
							as="select"
							className="select-jefatura-gerencia"
							id="select-jefatura-gerencia-0"
							name="idOrganigrama"
							value={formik.idOrganigrama}
							onChange={({ target }) => {
								setFiltrosJefaturaGerencia((filtros: { jefatura: string; gerencias: [] }) => ({
									...filtros,
									jefatura: target.value,
								}));
								formik.setFieldValue('idOrganigrama', target.value);
							}}
							isInvalid={!!formik.errors.idOrganigrama}
							disabled={!!gerencias.length}
						>
							<option value={0}>Seleccione una dirección</option>
							{jefaturasGerencias?.map((jefaGen: IJefaturaGerencia) => (
								<option key={jefaGen.id} value={jefaGen.id}>
									{jefaGen.descripcion}
								</option>
							))}
						</Form.Control>
						<Form.Control.Feedback className="d-block" type="invalid">
							{formik.errors.idOrganigrama}
						</Form.Control.Feedback>
					</Form.Group>
					{gerencias.length ? renderGerencias() : null}
				</Col>
				<Col
					xs={5}
					sm={5}
					md={5}
					lg={5}
					style={{
						border: 'solid 2px #4e779e',
						borderRadius: '0.25rem',
						margin: '0 10px',
						padding: '10px',
					}}
				>
					<Form.Group>
						<div className="d-flex align-items-center justify-content-between">
							<Form.Label htmlFor="select-0">
								Área<span className="auditoria__required">*</span>
							</Form.Label>
							<IconButton
								aria-label="Eliminar"
								size="small"
								onClick={() => {
									setZonas([]);
									setFiltros(filtros => ({ ...filtros, area: '' }));
									const elemento: HTMLInputElement = document.querySelector('#select-0');
									elemento.value = '0';
									formik.setFieldValue('idAreaZona', 0);
									setUltimosNiveles(niveles => ({ ...niveles, areas: true }));
								}}
							>
								<PlaylistRemoveIcon fontSize="inherit" />
							</IconButton>
						</div>
						<Form.Control
							as="select"
							className="select-areazona"
							id="select-0"
							name="idAreaZona"
							value={formik.idAreaZona}
							onChange={({ target }) => {
								setFiltros((filtros: IFiltros) => ({
									...filtros,
									area: target.value,
								}));
								formik.setFieldValue('idAreaZona', +target.value);
							}}
							isInvalid={!!formik.errors.idAreaZona}
							disabled={!!zonas.length}
						>
							<option value={0}>Seleccione un área</option>
							{areasZonas?.map((area: IAreaZona) => (
								<option key={area.id} value={area.id}>
									{area.descripcion}
								</option>
							))}
						</Form.Control>
						<Form.Control.Feedback className="d-block" type="invalid">
							{formik.errors.idAreaZona}
						</Form.Control.Feedback>
					</Form.Group>
					{zonas.length ? renderSubZonas() : null}
				</Col>
			</Row>
			<Row className="mt-5">
				<Col
					xs={{ offset: 1, span: 3 }}
					sm={{ offset: 1, span: 3 }}
					md={{ offset: 1, span: 3 }}
					lg={{ offset: 1, span: 3 }}
				>
					<p className="mx-0 fw-bold">
						Los campos con <span className="auditoria__required">*</span> son requeridos.
					</p>
				</Col>
				<Col className="d-flex align-items-end justify-content-end" lg={7} md={7} sm={7} xs={7}>
					<Button
						variant="secondary"
						disabled={noAvanza || ultimosNiveles.areas || ultimosNiveles.organigrama}
						onClick={() => {
							avance(1, 'siguiente');
						}}
					>
						Siguiente
					</Button>
				</Col>
			</Row>
		</>
	);
}
