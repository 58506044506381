import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';

import LoadingComponent from '@app/components/Loading';
import useAspectosObservables from '@app/hooks/useAspectosObservables';
import useFactorRiesgo from '@app/hooks/useFactorRiesgo';
import usePrecursor from '@app/hooks/usePrecursor';
import { TIPO_AUDITORIAS } from '@app/models/enums/tipoAuditorias.enum';
import { ICatalogoGenerico } from '@app/models/interfaces/catalogos/ICatalogoGenerico';
import { DEFAULT_AUDITORIA_DATA_CONTEXT } from '../../../constants/defaultAuditoriaDataContext';
import useData from '../../../hooks/useData';
import { ICatalogo } from '../../../models/interfaces/catalogos/ICatalogo';
import { ISeveridad } from '../../../models/interfaces/catalogos/ISeveridad';
import { IFiltrosCatalogos } from '../../../models/interfaces/condicionInsegura/IFiltros';
import { FormularioStepperProps } from '../../../models/types/formulario-stepper.type';
import { SeveridadMock } from '../../../utils/mocks/severidades.mock';

export default function FormularioCatalogos(props: FormularioStepperProps) {
	const { avance, formik } = props;
	const [subCatalogos, setSubCatalogos] = useState<ICatalogo[][]>([]);
	const [haySeveridad, setHaySeveridad] = useState<{
		idSeveridad: number;
		severidad: string;
		precursor: string;
		factorRiesgo: string;
	}>({
		idSeveridad: 0,
		severidad: '',
		precursor: '',
		factorRiesgo: '',
	});
	const [filtros, setFiltros] = useState<IFiltrosCatalogos>({
		catalogos: '',
	});
	const [noEsUltimoNivel, setNoEsUltimoNivel] = useState<boolean>(false);
	const [ultimoNivelAspectoObservado, setUltimoNivelAspectoObservado] = useState<boolean>(false);
	const { dataAuditoriaContext, setDataAuditoriaContext } = useData();
	const { data: catalogos, isFetching: cargandoAspectosObservables } = useAspectosObservables();
	const { data: factoresRiesgo } = useFactorRiesgo();
	const { data: precursores } = usePrecursor();
	const severidadRef = useRef();

	const noEsUltimoNivelAlert = (
		<Row>
			<Col md={{ offset: 1, span: 10 }}>
				<Alert variant="warning" onClose={() => setNoEsUltimoNivel(false)} dismissible>
					El catálogo seleccionado no tiene severidad y no puede continuar.
				</Alert>
			</Col>
		</Row>
	);

	const obtenerRamaCatalogo = async (
		idCatalogo: number | null,
		listaCatalogos: ICatalogo[] = catalogos
	) => {
		try {
			const catalogoEncontrado: ICatalogo = listaCatalogos?.find(
				(cat: ICatalogo) => cat.id === idCatalogo
			);
			const ramaResponse: ICatalogo[] =
				listaCatalogos?.find((cat: ICatalogo) => cat.id === idCatalogo)?.catalogosChildren || [];
			if (!!catalogoEncontrado?.severidad) {
				setHaySeveridad({
					idSeveridad: catalogoEncontrado.severidadId,
					severidad: catalogoEncontrado.severidad,
					precursor: catalogoEncontrado.precursorFps,
					factorRiesgo: catalogoEncontrado.factorDeRiesgoFps,
				});
			}

			if (ramaResponse.length) {
				setSubCatalogos((catas: ICatalogo[][]) => [...catas, ramaResponse]);
			}
		} catch (ex) {
			throw ex;
		}
	};

	const removerSelect = (indiceCatalogoItem: number) => {
		setNoEsUltimoNivel(false);
		setUltimoNivelAspectoObservado(false);
		if (indiceCatalogoItem < 4) {
			const catalogosActualizados = subCatalogos.filter((val, index) => {
				val;
				return index < indiceCatalogoItem;
			});
			setSubCatalogos(catalogosActualizados);
			formik.setValues(values => ({ ...values, idCatalogo: indiceCatalogoItem }));

			//i Si ya no hay mas zonas entonces se resetea el select de área
			if (!catalogosActualizados.length) {
				setFiltros((filtros: IFiltrosCatalogos) => ({
					...filtros,
					catalogos: '',
				}));
			}
			//i Reseteamos el valor vacio
			const selectCatalogo: HTMLInputElement = document.querySelector(
				`#aspecto-observado-${indiceCatalogoItem}`
			);
			selectCatalogo.value = '0';

			//i Quitamos la opción de otro tipo de observación
			formik.setValues(values => ({ ...values, esOtroTipoObservacion: false }));

			//i Vaceamos la severidad si hay
			if (!!haySeveridad.idSeveridad) {
				setHaySeveridad({ idSeveridad: 0, severidad: '', precursor: '', factorRiesgo: '' });
			}
		}
	};

	//i Renderizamos los campos select de los hijos del catálogo
	const renderSubCatalogos = () => {
		return (
			<>
				{subCatalogos.map((catalogoItem: ICatalogo[], index: number) => (
					<>
						<div
							className="mt-4 d-flex align-items-center justify-content-between"
							style={{ width: '95%' }}
							key={catalogoItem[index] ? catalogoItem[index]?.id : index}
						>
							<div className="d-flex flex-column w-100 position-relative">
								{index < subCatalogos.length - 1 && index > subCatalogos.length - 3 ? (
									<div
										className="position-absolute"
										style={{
											right: '-35px',
											top: '5px',
										}}
									>
										<IconButton
											aria-label="Eliminar"
											size="small"
											onClick={() => removerSelect(index + 1)}
										>
											<RefreshIcon fontSize="inherit" />
										</IconButton>
									</div>
								) : null}
								<Form.Control
									id={`aspecto-observado-${index + 1}`}
									as="select"
									name="idCatalogo"
									value={formik.idCatalogo}
									onChange={({ target }) => {
										setNoEsUltimoNivel(false);
										if (target.value === '-1') {
											formik.setValues(values => ({ ...values, esOtroTipoObservacion: true }));
											setHaySeveridad({
												severidad: '',
												precursor: '',
												factorRiesgo: '',
												idSeveridad: 0,
											});
										} else {
											formik.setFieldValue('idCatalogo', +target.value, false);
											formik.setFieldValue('esOtroTipoObservacion', false, false);
											obtenerRamaCatalogo(+target.value, catalogoItem);
											target.disabled = !!haySeveridad.severidad;
										}
										const itemFound = catalogoItem.find(item => item?.id === +target.value);
										if (!itemFound?.catalogosChildren?.length && !!itemFound?.severidad.trim()) {
											setUltimoNivelAspectoObservado(true);
										} else if (
											!itemFound?.catalogosChildren?.length &&
											!itemFound?.severidad.trim()
										) {
											setNoEsUltimoNivel(true);
										}
									}}
									isInvalid={!!formik.errors.idCatalogo}
									disabled={index !== subCatalogos.length - 1}
								>
									<option value={0}>{`Seleccione Nivel ${
										catalogoItem?.length ? catalogoItem[0]?.nivel : ''
									}`}</option>
									{catalogoItem?.length && (
										<>
											{catalogoItem.map((cata: ICatalogo) => (
												<option key={cata.id} value={cata.id}>
													{cata.descripcion}
												</option>
											))}
										</>
									)}
									<>{renderOpcionOtraObservacion(catalogoItem)}</>
								</Form.Control>
								<Form.Control.Feedback className="d-block" type="invalid">
									{formik.errors.idCatalogo}
								</Form.Control.Feedback>
							</div>
						</div>
					</>
				))}
			</>
		);
	};

	//i Se hace el render de opcion de otra observación si ningun item tiene hijos
	const renderOpcionOtraObservacion = (opciones: any[]) => {
		if (!!opciones?.length) {
			if (!opciones.filter(it => it.catalogosChildren.length).length) {
				return <option value={-1}>Otro tipo de observación</option>;
			}
		}
	};

	//i Se cambian la lista de subcatalogos por los correspondientes,
	//i depende del tipo de auditoria que seleccionón en la primera vista.
	const filtrarCatalogosTipoCondicion = () => {
		const filtradosActoInseguro = subCatalogos.map((cat: ICatalogo[]) => {
			return cat?.filter(
				(subcat: ICatalogo) =>
					subcat.tipoAuditoriaId === +formik.values.tipoLevantamiento || !subcat.tipoAuditoriaId
			);
		});

		//i Se filtran los catálogos por los que no tienen hijos
		if (filtradosActoInseguro.length) {
			filtradosActoInseguro?.filter(itemFilt => itemFilt?.length);
		}
	};

	//i buscar la rama correspondiente y mostrar el catálogo seleccionada
	const setearCatalogos = () => {
		try {
			const { detalleAuditoria } = dataAuditoriaContext;
			if (detalleAuditoria.catalogoRamaAsc?.length) {
				//i Se invierte el orden de las ramas de menor a mayor para llevar un orden
				const ramaCatalogo = detalleAuditoria.catalogoRamaAsc.reverse();
				let catalogosCargados: ICatalogo[][] = [];

				setTimeout(() => {
					//i Se busca el primer nivel (de catálogos) y lo cargamos a las catálogos
					const resultCata = catalogos?.find((ar: ICatalogo) => ar.id === ramaCatalogo[0].id);
					catalogosCargados = [...catalogosCargados, resultCata?.catalogosChildren];
					ramaCatalogo.forEach((item: { id: number; nivel: number }, index: number) => {
						if (item.nivel > 1) {
							if (catalogosCargados?.length === index) {
								const encontrado = catalogosCargados[index - 1]?.find(
									(areaZon: ICatalogo) => areaZon.id === item.id
								);
								if (encontrado) {
									catalogosCargados = [...catalogosCargados, encontrado?.catalogosChildren];
								}
							}
						}
					});
					setSubCatalogos(catalogosCargados.filter((ite: any[]) => ite?.length));
				});

				//i Inyectamos los valores a los selects de catálogos
				ramaCatalogo.forEach((item: any, index: number) => {
					setTimeout(() => {
						const catalogoSelect: any = document.getElementById(`aspecto-observado-${index}`);
						if (catalogoSelect) {
							catalogoSelect.value = item.id;
						}
					});
				});

				setUltimoNivelAspectoObservado(true);

				if (
					!!dataAuditoriaContext.detalleAuditoria.severidad &&
					!dataAuditoriaContext.detalleAuditoria.esOtroTipoObservacion
				) {
					const { severidad, severidadId, factorRiesgo, precursor } =
						dataAuditoriaContext.detalleAuditoria;
					setHaySeveridad({
						severidad,
						precursor,
						factorRiesgo,
						idSeveridad: severidadId,
					});
				}
			}
		} catch (ex) {
			ex;
		}
	};

	const noAvanza =
		!!Object.keys(formik.errors).filter(error => error === 'idCatalogo').length ||
		(formik.values.esOtroTipoObservacion && !formik.values.descripcion) ||
		(formik.values.esOtroTipoObservacion && !formik.values.severidad);

	useEffect(() => {
		if (!!dataAuditoriaContext.detalleAuditoria.id && !!catalogos) {
			//i Renderizado de árbol de aspectosObservables
			setearCatalogos();
		}
	}, [dataAuditoriaContext.editarAuditoria, catalogos]);

	useEffect(() => {
		obtenerRamaCatalogo(filtros.catalogos ? +filtros.catalogos : null);
	}, [filtros]);

	useEffect(() => {
		filtrarCatalogosTipoCondicion();
	}, [formik.values.idCatalogo]);

	useEffect(() => {
		//i Al editar si tiene otro tipo de observación entonces no se carga la severidad, factor de riesgo ni precursor de algún aspecto observado
		if (dataAuditoriaContext.detalleAuditoria.esOtroTipoObservacion) {
			setHaySeveridad({ idSeveridad: 0, severidad: '', precursor: '', factorRiesgo: '' });
			setTimeout(() => {
				const selects = document.querySelectorAll('select[name=idCatalogo]');
				if (selects) {
					const ultimoSelect: any = selects[selects.length - 1];
					if (ultimoSelect) {
						ultimoSelect.value = -1;
					}
				}
			});
		}
	}, [dataAuditoriaContext.detalleAuditoria.esOtroTipoObservacion]);

	useEffect(() => {
		if (+formik.values.tipoLevantamiento === TIPO_AUDITORIAS.REFORZAMIENTO_POSITIVO) {
			setSubCatalogos(subs => (subs.length ? [subs[0]] : []));
			setFiltros(filtros => ({ ...filtros, catalogos: '' }));
			setHaySeveridad({ idSeveridad: 0, severidad: '', precursor: '', factorRiesgo: '' });
			const elemento: HTMLInputElement = document.querySelector('#aspecto-observado-0');
			elemento.value = `${subCatalogos.length ? subCatalogos[0][0].catalogoId : 0}`;
			formik.setValues(values => ({
				...values,
				idCatalogo: subCatalogos.length
					? subCatalogos[0][0].catalogoId
					: dataAuditoriaContext.editarAuditoria
					? +dataAuditoriaContext.detalleAuditoria.catalogoId
					: 0,
				esOtroTipoObservacion: false,
			}));
		} else {
			if (formik.values.idCatalogo) {
				if (dataAuditoriaContext.prevTipoAuditoria === TIPO_AUDITORIAS.REFORZAMIENTO_POSITIVO) {
					setSubCatalogos([]);
					setUltimoNivelAspectoObservado(false);
				}
				obtenerRamaCatalogo(+formik.values.idCatalogo);
			}
		}
	}, [formik.values.tipoLevantamiento]);

	useEffect(() => {
		return () => {
			setFiltros({
				catalogos: '',
			});
			setSubCatalogos([]);
			setDataAuditoriaContext(audit => ({
				...audit,
				detalleAuditoria: DEFAULT_AUDITORIA_DATA_CONTEXT,
				editarAuditoria: false,
			}));
			setNoEsUltimoNivel(false);
		};
	}, []);

	return (
		<>
			{cargandoAspectosObservables && !!dataAuditoriaContext.detalleAuditoria.id && (
				<LoadingComponent mensaje="Cargando catálogos" />
			)}
			{noEsUltimoNivel && noEsUltimoNivelAlert}
			<Row className="mt-3">
				<Col
					xs={{ offset: 1, span: 10 }}
					sm={{ offset: 1, span: 5 }}
					md={{ offset: 1, span: 5 }}
					lg={{ offset: 1, span: 5 }}
				>
					<div
						className="d-flex align-items-center justify-content-between"
						style={{ width: '95%' }}
					>
						<Form.Label>Aspecto Observado</Form.Label>
						<IconButton
							aria-label="Eliminar"
							size="small"
							onClick={() => {
								setSubCatalogos([]);
								setFiltros(filtros => ({ ...filtros, catalogos: '' }));
								setHaySeveridad({ idSeveridad: 0, severidad: '', precursor: '', factorRiesgo: '' });
								const elemento: HTMLInputElement = document.querySelector('#aspecto-observado-0');
								elemento.value = '0';
								formik.setValues(values => ({
									...values,
									idCatalogo: 0,
									esOtroTipoObservacion: false,
								}));
								setUltimoNivelAspectoObservado(false);
								setNoEsUltimoNivel(false);
							}}
						>
							<PlaylistRemoveIcon fontSize="inherit" />
						</IconButton>
					</div>
					<Form.Group>
						<Form.Control
							id="aspecto-observado-0"
							as="select"
							name="idCatalogo"
							style={{ width: '95%' }}
							value={formik.idCatalogo}
							onChange={({ target }) => {
								setUltimoNivelAspectoObservado(false);
								setNoEsUltimoNivel(false);
								setFiltros((filtros: IFiltrosCatalogos) => ({
									...filtros,
									catalogos: target.value,
								}));
								formik.setValues(values => ({ ...values, idCatalogo: +target.value }));
								const itemFound = catalogos.find(item => item?.id === +target.value);
								if (!itemFound?.catalogosChildren?.length && !!itemFound?.severidad.trim()) {
									setUltimoNivelAspectoObservado(true);
								} else if (!itemFound?.catalogosChildren?.length && !itemFound?.severidad.trim()) {
									setNoEsUltimoNivel(true);
								}
							}}
							isInvalid={!!formik.errors.idCatalogo}
							disabled={
								!!subCatalogos.length &&
								+formik.values.tipoLevantamiento !== TIPO_AUDITORIAS.REFORZAMIENTO_POSITIVO
							}
						>
							<option value={0}>Seleccione Aspecto Observado</option>
							{catalogos?.map((cat: ICatalogo) => (
								<option key={cat.id} value={cat.id}>
									{cat.descripcion}
								</option>
							))}
						</Form.Control>
						<Form.Control.Feedback className="d-block" type="invalid">
							{formik.errors.idCatalogo}
						</Form.Control.Feedback>
					</Form.Group>
					<>
						{+formik.values.tipoLevantamiento !== TIPO_AUDITORIAS.REFORZAMIENTO_POSITIVO
							? renderSubCatalogos()
							: null}
					</>
					<>
						{/* Se hace el ultimo render del select */}
						{haySeveridad.idSeveridad ? (
							<Row className="mt-4 w-100">
								<Col xs={12} sm={12} md={12}>
									<h6>
										<span className="fw-bold">Severidad:</span>&nbsp;{haySeveridad.severidad}
									</h6>
									<h6>
										<span className="fw-bold">Factor de Riesgo:</span>&nbsp;
										{haySeveridad.factorRiesgo}
									</h6>
									<h6>
										<span className="fw-bold">Precursor:</span>&nbsp;{haySeveridad.precursor}
									</h6>
								</Col>
							</Row>
						) : null}
					</>
				</Col>
				{formik.values.esRechadada &&
					+formik.values.tipoLevantamiento === TIPO_AUDITORIAS.REFORZAMIENTO_POSITIVO && (
						<Col sm={5} md={5} lg={5}>
							<Form.Group>
								<Form.Label>Descripción Rechazo</Form.Label>
								<Form.Control
									name="descripcionRechazoTarea"
									onChange={formik.handleChange}
									value={formik.values.descripcionRechazoTarea}
									placeholder="Descripción del rechazo de la tarea"
								/>
							</Form.Group>
						</Col>
					)}
				<Col xs={{ span: 10 }} sm={{ span: 5 }} md={{ span: 5 }} lg={{ span: 5 }}>
					{!!formik.values.esOtroTipoObservacion && (
						<>
							<h4 className="text-center mb-3">Otro tipo de observación</h4>
							<Form.Group>
								<Form.Label>
									Descripción Breve Observación<span className="auditoria__required">*</span>
								</Form.Label>
								<Form.Control
									as="textarea"
									rows={6}
									name="descripcion"
									isInvalid={!!formik.errors.descripcion}
									value={formik.values.descripcion}
									onChange={formik.handleChange}
									placeholder="Descripción Breve Observación"
								/>
								<Form.Control.Feedback type="invalid" className="d-block">
									{formik?.errors?.descripcion}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="mt-4">
								<Form.Label>
									Severidad<span className="auditoria__required">*</span>
								</Form.Label>
								<Form.Control
									ref={severidadRef}
									as="select"
									name="severidad"
									isInvalid={!!formik.errors.severidad}
									value={formik.values.severidad}
									onChange={formik.handleChange}
								>
									<option value="">Seleccione una severidad</option>
									{SeveridadMock.map((sev: ISeveridad) => (
										<option key={sev.valor} value={sev.nombre}>
											{sev.nombre}
										</option>
									))}
								</Form.Control>
								<Form.Control.Feedback type="invalid" className="d-block">
									{formik?.errors?.severidad}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="mt-4">
								<Form.Label>Factor de Riesgo</Form.Label>
								<Form.Control
									as="select"
									name="factorDeRiesgoFpsId"
									isInvalid={!!formik.errors.factorDeRiesgoFpsId}
									value={formik.values.factorDeRiesgoFpsId}
									onChange={formik.handleChange}
								>
									<option value={0}>Seleccione un Factor de Riesgo</option>
									{factoresRiesgo?.map(({ id, descripcion }: ICatalogoGenerico) => (
										<option key={id} value={id}>
											{descripcion}
										</option>
									))}
								</Form.Control>
								<Form.Control.Feedback type="invalid" className="d-block">
									{formik?.errors?.factorDeRiesgoFpsId}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="mt-4">
								<Form.Label>Precursor</Form.Label>
								<Form.Control
									as="select"
									name="precursorFpsId"
									isInvalid={!!formik.errors.precursorFpsId}
									value={formik.values.precursorFpsId}
									onChange={formik.handleChange}
								>
									<option value={0}>Seleccione un Precursor</option>
									{precursores?.map(({ id, descripcion }: ICatalogoGenerico) => (
										<option key={id} value={id}>
											{descripcion}
										</option>
									))}
								</Form.Control>
								<Form.Control.Feedback type="invalid" className="d-block">
									{formik?.errors?.precursorFpsId}
								</Form.Control.Feedback>
							</Form.Group>
						</>
					)}
				</Col>
			</Row>
			{formik.values.esRechadada &&
				+formik.values.tipoLevantamiento === TIPO_AUDITORIAS.REFORZAMIENTO_POSITIVO && (
					<Row className="mt-5">
						<Col
							sm={{ offset: 6, span: 5 }}
							md={{ offset: 6, span: 5 }}
							lg={{ offset: 6, span: 5 }}
						>
							<Form.Group>
								<Form.Label>Acciones Tomadas</Form.Label>
								<Form.Control
									as="textarea"
									rows={5}
									name="accionesTomadasRechazoTarea"
									onChange={formik.handleChange}
									value={formik.values.accionesTomadasRechazoTarea}
									placeholder="Acciones Tomadas"
								/>
							</Form.Group>
						</Col>
					</Row>
				)}
			<Row className="mt-5">
				<Col
					xs={{ offset: 1, span: 3 }}
					sm={{ offset: 1, span: 3 }}
					md={{ offset: 1, span: 3 }}
					lg={{ offset: 1, span: 3 }}
				>
					<p className="mx-0 fw-bold">
						Los campos con <span className="auditoria__required">*</span> son requeridos.
					</p>
				</Col>
				<Col className="d-flex align-items-end justify-content-end" lg={7} md={7} sm={7} xs={7}>
					<Button variant="outline-secondary" onClick={() => avance(2, 'atras')}>
						Cancelar
					</Button>
					&nbsp;&nbsp;
					<Button
						variant="secondary"
						disabled={
							noAvanza ||
							(!ultimoNivelAspectoObservado &&
								+formik.values.tipoLevantamiento !== TIPO_AUDITORIAS.REFORZAMIENTO_POSITIVO &&
								!formik.values.esOtroTipoObservacion)
						}
						onClick={() => avance(dataAuditoriaContext.mostrarPasoTres ? 2 : 3, 'siguiente')}
					>
						Siguiente
					</Button>
				</Col>
			</Row>
		</>
	);
}
