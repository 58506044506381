import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';

import avatarPlaceholder from '../../../assets/images/avatar-placeholder.png';
import useData from '../../../hooks/useData';
import useError from '../../../hooks/useError';
import {
	IAccionResponsable,
	IResponsable,
} from '../../../models/interfaces/condicionInsegura/IResponsable';
import { FormularioStepperProps } from '../../../models/types/formulario-stepper.type';
import {
	addAccionResponsableService,
	addResponsableService,
	deleteAccionResponsableService,
	deleteResponsableService,
} from '../../../services/responsables.service';
import ReaccionPersonaObservada from '../../ReaccionPersonaObservada/ReaccionPersonaObservada';

import '../../../styles/components/FormularioEstado.scss';

export default function FormularioEstado(props: FormularioStepperProps) {
	const { avance, formik, responsables } = props;
	const [estado, setEstado] = useState<boolean>(true);
	const [seleccionado, setSeleccionado] = useState<IResponsable[]>([]);
	const [listadoResponsables, setListadoResponsables] = useState<IResponsable[]>([]);
	const [accionActual, setAccionActual] = useState<any>(null);
	const { setError, ErrorAlert } = useError();
	const { dataAuditoriaContext } = useData();
	const idAuditoria = dataAuditoriaContext.detalleAuditoria.id;

	//i Se agregan  responsables a la lista (local) o al backend si esta en modo editar
	const responsableSeleccionado = (respo: IResponsable[]) => {
		if (!!idAuditoria) {
			if (respo?.length) {
				const [unicoResponsable] = respo;
				agregarResponsableBackend(unicoResponsable);
			}
		} else {
			setSeleccionado([...respo]);
			if (!!respo?.length) {
				//i Buscamos para ver si no está en la lista
				//i si no se encuentra entonces se añade a la lista de responsables
				const existeEnLista = listadoResponsables.find(
					(resp: IResponsable) => resp.userName.toLowerCase() === respo[0].userName.toLowerCase()
				);
				if (!!existeEnLista) {
					setError(new Error('El responsable, ya está en la lista.'));
				} else {
					setListadoResponsables((res: IResponsable[]) => [...res, respo[0]]);
					formik.setFieldValue('responsables', [
						...formik.values.responsables,
						{ id: respo[0].id, acciones: respo[0].accionesResponsable || [] },
					]);
				}
			}
		}
	};

	//i Se agrega responsable cuando estamos en modo "editar auditoría"
	const agregarResponsableBackend = async (nuevoResponsable: IResponsable) => {
		try {
			//i Buscamos para ver si no está en la lista
			//i si no se encuentra entonces se añade a la lista de responsables
			setSeleccionado([nuevoResponsable]);
			const existeEnLista = listadoResponsables.find(
				(resp: IResponsable) =>
					resp.userName.toLowerCase() === nuevoResponsable.userName.toLowerCase()
			);

			if (!!existeEnLista) {
				setError(new Error('El responsable, ya está en la lista.'));
			} else {
				const response = await addResponsableService({
					idAuditoria: idAuditoria,
					idResponsable: nuevoResponsable.userName,
					accionesResponsable: [],
				});
				setListadoResponsables((res: IResponsable[]) => [...res, response]);
				formik.setFieldValue('responsables', [
					...formik.values.responsables,
					{ id: response.id, acciones: [] },
				]);
			}
		} catch (ex) {
			setError(ex);
		}
	};

	//i Se quitan los responsables de la lista (de manera local)
	const cerrarTarjetaResponsable = (usuarioResponable: string) => {
		if (!!idAuditoria) {
			eliminarResponsable(usuarioResponable);
		} else {
			const responsablesRestantes = listadoResponsables.filter(
				(rs: IResponsable) => rs.userName.toLowerCase() !== usuarioResponable.toLowerCase()
			);
			setListadoResponsables(responsablesRestantes);
			formik.setFieldValue(
				'responsables',
				responsablesRestantes.map((resp: IResponsable) => ({
					id: resp.id,
					acciones: resp.accionesResponsable,
				}))
			);
		}
	};

	//i Eliminamos el responsable cuando se edita la auditoria
	const eliminarResponsable = async idResponsable => {
		try {
			const responsableEncontrado: IResponsable = listadoResponsables.find(
				(resp: IResponsable) => resp.userName.toLowerCase() === idResponsable.toLowerCase()
			);
			if (!!responsableEncontrado) {
				await deleteResponsableService(+responsableEncontrado.id);
				const responsablesRestantes = listadoResponsables.filter(
					(rs: IResponsable) => rs.userName.toLowerCase() !== idResponsable.toLowerCase()
				);
				setListadoResponsables(responsablesRestantes);
				formik.setFieldValue(
					'responsables',
					responsablesRestantes.map((resp: IResponsable) => ({
						id: resp.id,
						acciones: resp.accionesResponsable,
					}))
				);
			} else {
				throw new Error('No se encuentra el responsable en la lista');
			}
		} catch (ex) {
			setError(ex);
		}
	};

	//i Cuando hacemo enter en el campo de la acción la añadimos al listado de acciones
	//i del responsable
	const agregarAccion = async (usuario: string, event: any) => {
		try {
			if (event?.keyCode === 13) {
				event.preventDefault();
				if (!!idAuditoria) {
					agregarAccionBackend(usuario, event.target.value);
				} else {
					const responsablesActualizados = listadoResponsables.map((resp: IResponsable) => {
						if (resp.userName.toLowerCase() === usuario.toLowerCase()) {
							return {
								...resp,
								accionesResponsable: [...(resp.accionesResponsable || []), event.target.value],
							};
						}
						return resp;
					});
					setListadoResponsables(responsablesActualizados);
					const elementoInputAccion: HTMLInputElement = document.getElementById(
						`accion-${usuario}`
					) as HTMLInputElement;
					elementoInputAccion.value = '';
					formik.setFieldValue(
						'responsables',
						responsablesActualizados.map((res: IResponsable) => ({
							id: res.id,
							acciones: res.accionesResponsable,
						}))
					);
				}
			}
		} catch (ex) {
			setError(ex);
		}
	};

	//i Se agrega acción con el botón en lugar de presionar "Enter"
	const agregarAccionBoton = (usuario: string, event: any) => {
		if (!!idAuditoria) {
			agregarAccionBackend(usuario, event.target.value);
		} else {
			const responsablesActualizados = listadoResponsables.map((resp: IResponsable) => {
				if (resp.userName.toLowerCase() === usuario.toLowerCase()) {
					return {
						...resp,
						accionesResponsable: [...(resp.accionesResponsable || []), event.target.value],
					};
				}
				return resp;
			});
			setListadoResponsables(responsablesActualizados);
			const elementoInputAccion: HTMLInputElement = document.getElementById(
				`accion-${usuario}`
			) as HTMLInputElement;
			elementoInputAccion.value = '';
			formik.setFieldValue(
				'responsables',
				responsablesActualizados.map((res: IResponsable) => ({
					id: res.id,
					acciones: res.accionesResponsable,
				}))
			);
		}
	};

	//i Agregar acción a backend cuando está en modo editar
	const agregarAccionBackend = async (usuario: string, valor: string) => {
		try {
			const responsableEncontrado: IResponsable = listadoResponsables.find(
				(resp: IResponsable) => resp.userName.toLowerCase() === usuario.toLowerCase()
			);
			if (!!responsableEncontrado) {
				const response = await addAccionResponsableService({
					idResponsableAuditoria: responsableEncontrado.id,
					accion: valor,
				});
				const responsablesActualizados = listadoResponsables.map((resp: IResponsable) => {
					if (resp.userName.toLowerCase() === usuario.toLowerCase()) {
						return {
							...resp,
							accionesResponsable: [...(resp.accionesResponsable || []), response.accion],
						};
					}
					return resp;
				});
				setListadoResponsables(responsablesActualizados);
				const elementoInputAccion: HTMLInputElement = document.getElementById(
					`accion-${usuario}`
				) as HTMLInputElement;
				elementoInputAccion.value = '';
				formik.setFieldValue(
					'responsables',
					responsablesActualizados.map((res: IResponsable) => ({
						id: res.id,
						acciones: res.accionesResponsable,
					}))
				);
			}
		} catch (ex) {
			setError(ex);
		}
	};

	//i Eliminamos la acción de la lista por medio del indice en el que
	//i se encuentra
	const eliminarAccion = (responsableAccion: string, indiceAccion: number) => {
		if (!!idAuditoria) {
			eliminarAccionBackend(responsableAccion, indiceAccion);
		} else {
			const listaAccionesAuxiliar = listadoResponsables.find(
				(resp: IResponsable) => resp.userName.toLowerCase() === responsableAccion.toLowerCase()
			)?.accionesResponsable;
			if (!!listaAccionesAuxiliar?.length) {
				listaAccionesAuxiliar.splice(indiceAccion, 1);
				const responsablesActualizados = listadoResponsables.map((resp: IResponsable) => {
					if (resp.userName.toLowerCase() === responsableAccion.toLowerCase()) {
						return { ...resp, acciones: listaAccionesAuxiliar };
					}
					return resp;
				});
				setListadoResponsables(responsablesActualizados);
				formik.setFieldValue(
					'responsables',
					responsablesActualizados.map((res: IResponsable) => ({
						id: +res.id,
						acciones: res.accionesResponsable,
					}))
				);
			}
		}
	};

	//i Eliminamos la acción de la lista para backend
	const eliminarAccionBackend = async (responsable: string, indiceAccion: number) => {
		try {
			const listaAcciones = listadoResponsables.find(
				(resp: IResponsable) => resp.userName.toLowerCase() === responsable.toLowerCase()
			)?.accionesResponsable;
			if (listaAcciones?.length) {
				const accionEncontrada: any = listaAcciones[indiceAccion];
				if (accionEncontrada) {
					await deleteAccionResponsableService(accionEncontrada?.id || 0);
					listaAcciones.splice(indiceAccion, 1);
					const responsablesActualizados = listadoResponsables.map((resp: IResponsable) => {
						if (resp.userName.toLowerCase() === responsable.toLowerCase()) {
							return { ...resp, acciones: listaAcciones };
						}
						return resp;
					});
					setListadoResponsables(responsablesActualizados);
					formik.setFieldValue(
						'responsables',
						responsablesActualizados.map((res: IResponsable) => ({
							id: +res.id,
							acciones: res.accionesResponsable,
						}))
					);
				} else {
					throw new Error(`No se encontró la acción`);
				}
			}
		} catch (ex) {
			setError(ex);
		}
	};

	useEffect(() => {
		if (!!dataAuditoriaContext.detalleAuditoria.id) {
			const { responsables } = dataAuditoriaContext.detalleAuditoria;
			if (responsables?.length) {
				setListadoResponsables(responsables);
			}
		}
	}, [dataAuditoriaContext]);

	useEffect(() => {
		//i Limpiamos el campo del "autocomplete" cuando seleccionamos un responsable
		//i así dejamos el campo abierto para escribir
		if (!!seleccionado?.length) {
			setSeleccionado([]);
		}
	}, [seleccionado]);

	useEffect(() => {
		//i Si se cierra cuando estan editando, se dejan los responsables actuales
		//i se no se deja agregar mas.
		if (!estado) {
			if (!!!idAuditoria) {
				formik.setFieldValue('responsables', []);
				setListadoResponsables([]);
				setSeleccionado([]);
			}
		}
	}, [estado]);

	return (
		<div className="formulario-estado">
			<Row>
				<Col
					xs={{ offset: 1, span: 4 }}
					sm={{ offset: 1, span: 4 }}
					md={{ offset: 1, span: 4 }}
					lg={{ offset: 1, span: 4 }}
				>
					<div className="d-flex align-items-center justify-content-between">
						<Form.Label className="m-0">Estado Auditoría</Form.Label>
						<Form.Check
							className="mb-0"
							inline
							type="radio"
							name="estado"
							label="Abierta"
							onChange={() => {
								setEstado((est: boolean) => {
									formik.setFieldValue('abierto', !est);
									return !est;
								});
							}}
							checked={estado}
							value={1}
						/>
						<Form.Check
							className="mb-0"
							inline
							type="radio"
							name="estado"
							label="Cerrada"
							onChange={() => {
								setEstado((est: boolean) => {
									formik.setFieldValue('abierto', !est);
									return !est;
								});
							}}
							checked={!estado}
							value={0}
						/>
					</div>
				</Col>
				<Col xs={6} sm={6} md={6} lg={6}>
					<Form.Group>
						<Typeahead
							id="single-typeahead"
							clearButton
							filterBy={['userName', 'nombre', 'email']}
							labelKey="nombre"
							onChange={responsableSeleccionado}
							options={responsables}
							selected={seleccionado}
							renderMenuItemChildren={(option: IResponsable, { text }) => (
								<div>
									<Highlighter
										highlightClassName="bg-transparent font-weight-bold p-0"
										search={text}
									>
										{option.nombre}
									</Highlighter>
									<div>
										<div>
											<small>
												Usuario:{' '}
												<Highlighter
													highlightClassName="bg-transparent font-weight-bold p-0"
													search={text}
												>
													{option.userName}
												</Highlighter>
											</small>
										</div>
										<div>
											<small>
												Email:{' '}
												<Highlighter
													highlightClassName="bg-transparent font-weight-bold p-0"
													search={text}
												>
													{option.email}
												</Highlighter>
											</small>
										</div>
									</div>
								</div>
							)}
							disabled={!estado}
							placeholder={`${
								formik.values.esRechadada ? 'Responsable' : 'Jefe Directo'
							} (Es requerido al menos uno)`}
						/>
					</Form.Group>
				</Col>
			</Row>
			<>
				{ErrorAlert && (
					<Row className="mb-2">
						<Col
							xs={{ offset: 1, span: 10 }}
							sm={{ offset: 1, span: 10 }}
							md={{ offset: 1, span: 10 }}
							lg={{ offset: 1, span: 10 }}
						>
							<ErrorAlert />
						</Col>
					</Row>
				)}
			</>
			<Row>
				<Col
					xs={{ offset: 1, span: 4 }}
					sm={{ offset: 1, span: 4 }}
					md={{ offset: 1, span: 4 }}
					lg={{ offset: 1, span: 4 }}
				>
					{+formik.values.tipoLevantamiento !== 2 && !formik.values.esRechadada && (
						<ReaccionPersonaObservada formik={formik} />
					)}
				</Col>
				{!!listadoResponsables?.length && estado ? (
					<Col xs={6} sm={6} md={6} lg={6}>
						<div className="mt-4">
							{listadoResponsables.map((res: IResponsable) => (
								<Card className="my-3" key={res.userName}>
									<Card.Body className="d-flex align-items-center justify-content-between">
										<div className="formulario-estado__responsable-detalle">
											<img
												className="formulario-estado__responsable-avatar"
												src={avatarPlaceholder}
												alt="Imágen Usuario"
											/>
											<p className="formulario-estado__responsable-nombre">{res.nombre}</p>
											<p className="formulario-estado__responsable-texto">{res.puesto}</p>
											<p className="formulario-estado__responsable-texto">{res.rol}</p>
										</div>
										<div className="formulario-estado__responsable-acciones">
											<div className="formulario-estado__responsable-titulo">
												<p className="fw-bold m-0">Acciones</p>
												{estado && (
													<IconButton
														aria-label="Eliminar"
														size="small"
														onClick={() => cerrarTarjetaResponsable(res.userName)}
													>
														<CloseIcon fontSize="inherit" />
													</IconButton>
												)}
											</div>
											<InputGroup className="mb-2">
												<Form.Control
													id={`accion-${res.userName}`}
													name="accion"
													onKeyDown={event => {
														setAccionActual(event);
														agregarAccion(res.userName, event);
													}}
													placeholder={`Escribe la acción`}
													disabled={!!idAuditoria && !estado}
												/>
												<Button
													onClick={() => agregarAccionBoton(res.userName, accionActual)}
													disabled={!accionActual || !accionActual.target.value}
												>
													<PostAddIcon />
												</Button>
											</InputGroup>
											{res.accionesResponsable?.length ? (
												<div className="formulario-estado__responsable-contenedor-accion">
													{res.accionesResponsable.map(
														(accion: string | IAccionResponsable, index: number) => (
															<div
																className="formulario-estado__responsable-accion"
																key={typeof accion !== 'string' ? accion.id : accion}
															>
																<p>{typeof accion !== 'string' ? accion.accion : accion}</p>
																{estado && (
																	<IconButton
																		aria-label="Eliminar"
																		size="small"
																		onClick={() => eliminarAccion(res.userName, index)}
																	>
																		<DeleteIcon fontSize="inherit" />
																	</IconButton>
																)}
															</div>
														)
													)}
												</div>
											) : null}
										</div>
									</Card.Body>
								</Card>
							))}
						</div>
					</Col>
				) : (
					<Col xs={6} sm={6} md={6} lg={6}>
						{estado && (
							<h5 className="text-center mt-5">
								Debe registrar al menos un{' '}
								{formik.values.esRechadada ? 'Responsable' : 'Jefe Directo'}.
							</h5>
						)}
					</Col>
				)}
			</Row>
			<Row className="mt-5">
				<Col
					xs={{ offset: 1, span: 3 }}
					sm={{ offset: 1, span: 3 }}
					md={{ offset: 1, span: 3 }}
					lg={{ offset: 1, span: 3 }}
				>
					<p className="mx-0 fw-bold">
						Los campos con <span className="auditoria__required">*</span> son requeridos.
					</p>
				</Col>
				<Col className="d-flex align-items-end justify-content-end" xs={7} sm={7} md={7} lg={7}>
					<Button variant="outline-secondary" onClick={() => avance(3, 'atras')}>
						Cancelar
					</Button>
					&nbsp;&nbsp;
					{dataAuditoriaContext.mostrarPasoCuatro ? (
						<Button
							variant="secondary"
							disabled={!listadoResponsables?.length && estado}
							onClick={() => avance(3, 'siguiente')}
						>
							Siguiente
						</Button>
					) : (
						<Button
							variant="secondary"
							disabled={!listadoResponsables?.length && estado}
							type="submit"
						>
							Guardar
						</Button>
					)}
				</Col>
			</Row>
		</div>
	);
}
