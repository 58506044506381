import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import '../styles/components/TablaAreasZonas.component.scss';

interface IAspectoObservableAuditoria {
	aspectoObservableId: number;
	aspectoObservable: string;
	aspectoObservableChildren: IAspectoObservableAuditoria[];
}

export default function TablaArbol(props: { elementos: IAspectoObservableAuditoria[] }) {
	const { elementos } = props;
	const [filasAbiertas, setFilasAbiertas] = useState<number[]>([]);

	const abrirFila = (aspectoObservableId: number) => event => {
		event.stopPropagation();
		if (!!filasAbiertas.find((fl: number) => fl === +aspectoObservableId)) {
			setFilasAbiertas((filas: number[]) =>
				filas.filter((fil: number) => fil !== +aspectoObservableId)
			);
		} else {
			setFilasAbiertas((filas: number[]) => [...filas, +aspectoObservableId]);
		}
	};

	const renderTablaAspectoObservable = (
		aspectosObservables: IAspectoObservableAuditoria[],
		nivel: number = 0
	) => {
		return aspectosObservables?.map((aspectoObservable: IAspectoObservableAuditoria) => {
			const tieneJefaturas = aspectoObservable?.aspectoObservableChildren?.length;
			const filaAbierta: boolean = !!(
				filasAbiertas.length &&
				filasAbiertas.find((fil: number) => fil === +aspectoObservable.aspectoObservableId)
			);
			return (
				<Fragment key={aspectoObservable.aspectoObservableId}>
					<tr
						className={classNames({
							'fw-bold': nivel === 0,
						})}
						role="button"
					>
						<td>
							<div style={{ marginLeft: `${nivel}rem` }}>
								{tieneJefaturas && (
									<IconButton
										aria-label="Expandir"
										size="small"
										onClick={abrirFila(aspectoObservable?.aspectoObservableId)}
									>
										<ArrowForwardIosIcon
											className={classNames('tabla-area-zona__arrow', {
												'tabla-area-zona__arrow--open': filaAbierta,
											})}
											id={`fila-${aspectoObservable.aspectoObservableId}`}
											fontSize="inherit"
										/>
									</IconButton>
								)}
							</div>
						</td>
						<td>{aspectoObservable.aspectoObservable}</td>
					</tr>
					{filaAbierta &&
						renderTablaAspectoObservable(aspectoObservable.aspectoObservableChildren, nivel + 1)}
				</Fragment>
			);
		});
	};

	useEffect(() => {
		return () => {
			setFilasAbiertas([]);
		};
	}, []);

	return (
		<Table striped>
			<thead>
				<tr>
					<th />
					<th>Aspecto Observable / Jefatura</th>
				</tr>
			</thead>
			<tbody>
				{elementos?.length ? (
					<>{renderTablaAspectoObservable(elementos)}</>
				) : (
					<tr>
						<td colSpan={2}>No hay aspectos observables/jefaturas</td>
					</tr>
				)}
			</tbody>
		</Table>
	);
}
