import { IAgregarAreaZona } from '../models/interfaces/areasZonas/IAgregarAreaZona';
import { IEditarAreaZona } from '../models/interfaces/areasZonas/IEditarAreaZona';
import http from '../utils/http';

export const getAllAreasZonasService = async () => {
	try {
		const response = await http.get('AreaZona');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getFiltroAreasZonasService = async (filter: string) => {
	try {
		const response = await http.get('AreaZona/GetFilterAreaZona', {
			params: {
				filter,
			},
		});
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addAreaZonaService = async (dataRequest: IAgregarAreaZona) => {
	try {
		const response = await http.post('AreaZona', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editAreaZonaService = async (
	dataRequest: IEditarAreaZona,
	idAreaZona: number
) => {
	try {
		const response = await http.put(`AreaZona/${idAreaZona}`, dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteAreaZonaService = async (idAreaZona: number) => {
	try {
		const response = await http.delete(`AreaZona/${idAreaZona}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getRamaAreaZonaService = async (areaZonaId: number) => {
	try {
		const response = await http.get('AreaZona/GetRamaAreaZonaAscById', {
			params: {
				areaZonaId,
			},
		});
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
