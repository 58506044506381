import { useQuery } from '@tanstack/react-query';

import { getUsuariosService } from '@app/services/usuarios.service';

export default function useUsuarios(isAuditor: boolean, filter: string = '') {
	return useQuery({
		queryKey: ['detalle-usuario', isAuditor, filter],
		queryFn: async () => await getUsuariosService(isAuditor, filter),
		refetchOnWindowFocus: false,
	});
}
