import { Button, Modal } from 'react-bootstrap';

import TablaArbol from '@app/components/TablaArbol';
import { IAspectoObservableAuditoriasDirigidas } from '@app/models/interfaces/metas/IMeta';

export default function AuditoriasModal(props: {
	estadoModal: boolean;
	cambiarEstadoModal: Function;
	auditoriasDirigidas: IAspectoObservableAuditoriasDirigidas[];
}) {
	const { estadoModal, cambiarEstadoModal, auditoriasDirigidas } = props;

	const formatAspectosObservables = auditoriasDirigidas.map(
		(audit: IAspectoObservableAuditoriasDirigidas) => ({
			aspectoObservableId: audit.catalogoId,
			aspectoObservable: audit.catalogoDescripcion,
			aspectoObservableChildren: auditoriasDirigidas
				.filter((aud: IAspectoObservableAuditoriasDirigidas) => aud.catalogoId === audit.catalogoId)
				.map((ad: IAspectoObservableAuditoriasDirigidas) => ({
					aspectoObservableId: ad.organigramaId,
					aspectoObservable: ad.organigramaDescripcion,
				})),
		})
	);

	const listaAspectosObservables: any[] = formatAspectosObservables.filter((asp, index) => {
		return (
			index ===
			formatAspectosObservables.findIndex(item => {
				return asp.aspectoObservableId === item.aspectoObservableId;
			})
		);
	});

	return (
		<Modal show={estadoModal} onHide={() => cambiarEstadoModal(false)} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>Auditorías Dirigidas</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<TablaArbol elementos={listaAspectosObservables} />
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => cambiarEstadoModal(false)}>
					Cancelar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
