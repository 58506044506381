import { useMutation } from '@tanstack/react-query';
import { Button, Col, Modal, Row } from 'react-bootstrap';

import useError from '@app/hooks/useError';
import { DeleteCatalogoGenericoProps } from '@app/models/types/catalogoGenerico.type';
import { deleteCatalogoGenerico } from '@app/services/catalogos.service';
import { useEffect } from 'react';

export default function DeleteCatalogoGenerico(props: DeleteCatalogoGenericoProps) {
	const {
		catalogo: { id, descripcion },
		cambiarEstadoModal,
		estadoModal,
		isFactorRiesgo,
		tipoCatalogo,
		refetch,
	} = props;
	const { setError, ErrorAlert } = useError();

	const { mutate, isLoading } = useMutation({
		mutationFn: async () => await deleteCatalogoGenerico(isFactorRiesgo, id),
		onSuccess: () => {
			refetch();
			cambiarEstadoModal(false);
		},
		onError: error => setError(error),
	});

	useEffect(() => {
		if (!estadoModal) {
			setError('');
		}
	}, [estadoModal]);

	return (
		<Modal show={estadoModal} onHide={() => cambiarEstadoModal(false)}>
			<Modal.Header closeButton>
				<Modal.Title>Eliminar {tipoCatalogo}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ErrorAlert />
				<Row>
					<Col xs={12} sm={12} md={12}>
						<h5>¿Está seguro que quiere eliminar {descripcion}?</h5>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => cambiarEstadoModal(false)}>
					Cancelar
				</Button>
				<Button variant="primary" disabled={isLoading} onClick={() => mutate()}>
					{isLoading ? 'Eliminando' : 'Eliminar'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
