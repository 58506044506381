export enum TitulosTabsCatalogosMots {
	tipoTrabajo = 'Tipo Trabajo',
	empresa = 'Empresa',
	area = 'Área',
	areaResponsable = 'Área Responsable',
	probabilidad = 'Factor Probabilidad',
	exposicion = 'Factor Exposición',
	severidad = 'Factor Mayor Severidad Calculada',
	magnitud = 'Factor Magnitud Riesgo',
	permisoAplicable = 'Permiso Aplicable',
	equipoProteccionPersonal = 'Equipo Protección Personal',
	vehiculoAutomotor = 'Vehículo Automotor',
	herramienta = 'Herramienta',
	accesorioSeguridad = 'Accesorio Seguridad',
	accionesMots = 'Acciones Mots',
	grupoSanguineo = 'Grupo Sanguineo',
	perfil = 'Perfil',
	observacionMedica = 'Observación Médica',
	norma = 'Norma',
	capacitacion = 'Capacitación',
}
