import classNames from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Step, Stepper } from 'react-form-stepper';
import { useNavigate } from 'react-router-dom';

import useAuditoria from '@app/hooks/auditorias/useAuditoria';
import { IDataAuditoriaContext } from '@app/models/IDataContext';
import { TIPO_AUDITORIAS } from '@app/models/enums/tipoAuditorias.enum';
import { numeroSemanaActual } from '@app/utils/fechas';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import FormularioAreasComponent from '../../components/Auditorias/AuditoriaLibre/FormularioAreas';
import FormularioCatalogosComponent from '../../components/Auditorias/AuditoriaLibre/FormularioCatalogos';
import FormularioEstadoComponent from '../../components/Auditorias/AuditoriaLibre/FormularioEstado';
import FormularioEvidenciasComponent from '../../components/Auditorias/AuditoriaLibre/FormularioEvidencias';
import LoadingComponent from '../../components/Loading';
import Title from '../../components/Title';
import { DEFAULT_AUDITORIA_DATA_CONTEXT } from '../../constants/defaultAuditoriaDataContext';
import { estiloLineaAvance, estiloStep } from '../../constants/estilosStepper';
import { ROUTES } from '../../constants/routes';
import useData from '../../hooks/useData';
import useError from '../../hooks/useError';
import useResponsables from '../../hooks/useResponsables';
import { INuevaAuditoria } from '../../models/interfaces/auditorias/INuevaAuditoria';
import { addAuditoriaService, editAuditoriaService } from '../../services/auditorias.service';
import { auditoriaValidationSchema } from '../../utils/validations/auditoria.validation';

import '../../styles/Auditoria.scss';

export default function AuditoriaLibre() {
	const [pasos, setPasos] = useState<number>(1);
	const [otroTipoObservacion, setOtroTipoObservacion] = useState<boolean>(false);
	const { setError, ErrorAlert } = useError();
	const navigate = useNavigate();
	const { data: responsables } = useResponsables();
	const initialValues: INuevaAuditoria = {
		fecha: '',
		idAreaZona: 0,
		idCatalogo: 0,
		idReaccion: 0,
		idOrganigrama: 0,
		abierto: true,
		JefeInmediatoEnterado: false,
		responsables: [],
		descripcionEvidencia: '',
		fotoEvidencia: null,
		esOtroTipoObservacion: false,
		descripcion: '',
		severidad: '',
		tipoLevantamiento: '1',
		direccion: '',
		trabajador: '',
		empresa: '',
		esRechadada: false,
		factorDeRiesgoFpsId: 0,
		precursorFpsId: 0,
		descripcionRechazoTarea: '',
		accionesTomadasRechazoTarea: '',
	};
	const { setDataAuditoriaContext, dataAuditoriaContext } = useData();
	const { id } = useParams();
	const formik = useFormik({
		initialValues,
		validationSchema: auditoriaValidationSchema(otroTipoObservacion),
		onSubmit: (values: INuevaAuditoria) => guardarEvidencia(values),
	});
	const { refetch, isError, error, isFetching } = useAuditoria(+id || 0);

	const { mutate: addAuditoriaLibre, isLoading: guardandoAuditoria } = useMutation({
		mutationFn: addAuditoriaService,
		onSuccess: () => {
			navigate(ROUTES.TABLA_AUDITORIAS);
		},
		onError: error => setError(error),
	});

	const { mutate: editAuditoriaLibre, isLoading: editandoAuditoria } = useMutation({
		mutationFn: editAuditoriaService,
		onSuccess: () => {
			navigate(ROUTES.TABLA_AUDITORIAS);
		},
		onError: error => setError(error),
	});

	const guardarEvidencia = async (formData: INuevaAuditoria) => {
		try {
			const requestAuditoria = { ...formData, idReaccion: formData.idReaccion || null };
			const formDataEvidencia = new FormData();
			Object.keys(requestAuditoria).forEach((key: string) => {
				if (key === 'responsables' && formData[key]?.length) {
					formData[key].forEach((resp: any, index: number) => {
						if (resp.acciones?.length) {
							resp.acciones.forEach((accion: string, indice: number) => {
								formDataEvidencia.append(`${key}[${index}][idResponsable]`, resp.id || 0);
								formDataEvidencia.append(
									`${key}[${index}][${`accionesResponsable[${indice}]`}][accion]`,
									accion
								);
							});
						} else {
							formDataEvidencia.append(`${key}[${index}][idResponsable]`, resp.id || 0);
						}
					});
				} else {
					formDataEvidencia.append(key, formData[key]);
				}
			});
			!!id
				? editAuditoriaLibre({
						id: +id,
						fecha: formData.fecha,
						idCatalogo: formData.idCatalogo,
						idAreaZona: formData.idAreaZona,
						idReaccion: formData.idReaccion,
						JefeInmediatoEnterado: formData.JefeInmediatoEnterado,
						abierto: formData.abierto,
						esOtroTipoObservacion: formData.esOtroTipoObservacion,
						descripcion: formData.descripcionEvidencia,
						severidad: formData.severidad,
						tipoLevantamiento: `${formData.tipoLevantamiento}`,
						direccion: formData.direccion,
						idOrganigrama: formData.idOrganigrama,
						trabajador: formData.trabajador,
						empresa: formData.empresa,
						factorDeRiesgoFpsId: formData.factorDeRiesgoFpsId,
						precursorFpsId: formData.precursorFpsId,
						jefeInmediatoEnterado: formData.JefeInmediatoEnterado,
						respuestaCierre: null,
						esRechadada: formData.esRechadada,
						descripcionRechazoTarea: formData.descripcionRechazoTarea,
						accionesTomadasRechazoTarea: formData.accionesTomadasRechazoTarea,
				  })
				: addAuditoriaLibre(formDataEvidencia);
		} catch (ex) {
			setError(ex);
		}
	};

	const avanceRetrocesoStepper = (paso: number, accion: string) => {
		accion.toLowerCase() === 'siguiente' ? paso++ : paso--;
		setPasos(paso);
	};

	const renderFormulario = () => {
		return (
			<>
				<ErrorAlert />
				{(guardandoAuditoria || editandoAuditoria) && (
					<LoadingComponent mensaje="Guardando Auditoría" />
				)}
				<div
					className={classNames('d-block', {
						'd-none': pasos !== 1,
					})}
				>
					<>
						<FormularioAreasComponent
							formik={formik}
							avance={avanceRetrocesoStepper}
							hayError={setError}
							termino={isFetching}
						/>
					</>
				</div>
				<div
					className={classNames('d-block', {
						'd-none': pasos !== 2,
					})}
				>
					<FormularioCatalogosComponent formik={formik} avance={avanceRetrocesoStepper} />
				</div>
				<div
					className={classNames('d-block', {
						'd-none': pasos !== 3,
					})}
				>
					<FormularioEstadoComponent
						formik={formik}
						avance={avanceRetrocesoStepper}
						hayError={setError}
						responsables={responsables ?? []}
					/>
				</div>
				<div
					className={classNames('d-block', {
						'd-none': pasos !== 4,
					})}
				>
					<FormularioEvidenciasComponent formik={formik} avance={avanceRetrocesoStepper} />
				</div>
			</>
		);
	};

	useEffect(() => {
		renderFormulario();
	}, [pasos]);

	useEffect(() => {
		setDataAuditoriaContext((dataAuditoria: IDataAuditoriaContext) => ({
			...dataAuditoria,
			mostrarPasoCuatro: !!(
				+formik.values.tipoLevantamiento === TIPO_AUDITORIAS.CONDICION_INSEGURA ||
				+formik.values.tipoLevantamiento === TIPO_AUDITORIAS.REFORZAMIENTO_POSITIVO
			),
			mostrarPasoTres:
				(!!(+formik.values.tipoLevantamiento === TIPO_AUDITORIAS.REFORZAMIENTO_POSITIVO) &&
					dataAuditoriaContext?.detalleAuditoria?.esRechadada) ||
				(!!(+formik.values.tipoLevantamiento !== TIPO_AUDITORIAS.REFORZAMIENTO_POSITIVO) &&
					!dataAuditoriaContext?.detalleAuditoria?.esRechadada),
		}));
	}, [formik.values.tipoLevantamiento]);

	useEffect(() => {
		setOtroTipoObservacion(formik.values.esOtroTipoObservacion);
		if (!formik.values.esOtroTipoObservacion) {
			formik.setValues(values => ({ ...values, descripcion: '', severidad: '' }));
		}
	}, [formik.values.esOtroTipoObservacion]);

	useEffect(() => {
		if (id) {
			refetch().then(({ data }) => {
				setDataAuditoriaContext(audit => ({
					...audit,
					detalleAuditoria: data,
					editarAuditoria: true,
					mostrarPasoTres:
						(data?.tipoLevantamiento === TIPO_AUDITORIAS.REFORZAMIENTO_POSITIVO &&
							data?.esRechadada) ||
						(!!(data?.tipoLevantamiento !== TIPO_AUDITORIAS.REFORZAMIENTO_POSITIVO) &&
							!data.esRechadada),
				}));
			});
		} else if (isError) {
			setError(error);
		}
	}, [id]);

	useEffect(() => {
		if (!id) {
			setTimeout(() => {
				formik.setErrors({});
			});
		}
		return () => {
			setDataAuditoriaContext(audit => ({
				...audit,
				detalleAuditoria: DEFAULT_AUDITORIA_DATA_CONTEXT,
				editarAuditoria: !!id,
				numeroSemanaViarante: numeroSemanaActual,
				semanaExtemporanea: false,
				mostrarPasoTres: false,
				mostrarPasoCuatro: false,
			}));
		};
	}, []);

	return (
		<Container className="d-flex flex-column">
			<Title
				titulo={!!id ? 'Editar Auditoría' : 'Nueva Auditoría'}
				ruta={ROUTES.TABLA_AUDITORIAS}
			/>
			<h2 className="text-center my-0">SEMANA {dataAuditoriaContext.numeroSemanaVariante}</h2>
			{dataAuditoriaContext.semanaExtemporanea && (
				<h3 className="text-center my-0" style={{ color: '#c6361b' }}>
					Semana Extemporánea
				</h3>
			)}
			<Row className="mt-0 mb-4">
				<Col xs={12} sm={12} md={12} lg={12}>
					<Stepper
						activeStep={pasos}
						connectorStateColors
						styleConfig={estiloStep}
						connectorStyleConfig={estiloLineaAvance}
					>
						<Step index={0} label="Información General" />
						<Step index={1} label="Observación" />
						{dataAuditoriaContext.mostrarPasoTres && (
							<Step index={2} label="Acciones de Seguimiento" />
						)}
						{dataAuditoriaContext.mostrarPasoCuatro && <Step index={3} label="Evidencias" />}
					</Stepper>
				</Col>
			</Row>
			<form onSubmit={formik.handleSubmit}>{renderFormulario()}</form>
		</Container>
	);
}
