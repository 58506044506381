import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import useAspectosObservables from '@app/hooks/useAspectosObservables';
import TablaCatalogosComponent from '../components/Catalogos/TablaCatalogo';
import LoadingComponent from '../components/Loading';
import Title from '../components/Title';
import useData from '../hooks/useData';
import useError from '../hooks/useError';
import { IDataFpsContext } from '../models/IDataContext';

export default function Catalogos() {
	const [filasAbiertas, setFilasAbiertas] = useState<number[]>([]);
	const [filaSeleccionada, setFilaSeleccionada] = useState<number>(0);
	const { dataFpsContext, setDataFpsContext } = useData();
	const { setError, ErrorAlert } = useError();
	const {
		data: response,
		isFetching: cargandoAspectosObservables,
		isFetched,
		isSuccess,
		isError,
		error,
		refetch,
	} = useAspectosObservables();

	useEffect(() => {
		if (!!dataFpsContext.nombreGuia) {
			refetch().then(response => {
				setDataFpsContext((data: IDataFpsContext) => ({ ...data, catalogos: response.data }));
			});
		}
	}, [dataFpsContext.nombreGuia]);

	useEffect(() => {
		if (isSuccess) {
			setDataFpsContext((data: IDataFpsContext) => ({
				...data,
				catalogos: response,
			}));
		}
		if (isError) {
			setError(error);
		}
	}, [isFetched]);

	useEffect(() => {
		return () => {
			setDataFpsContext((data: IDataFpsContext) => ({ ...data, nombreGuia: '' }));
		};
	}, []);

	return (
		<div className="d-flex flex-column px-4">
			{cargandoAspectosObservables && (
				<LoadingComponent mensaje="Obteniendo aspectos observables" />
			)}
			<Title titulo="Aspectos Evaluados" />
			<div>
				<ErrorAlert />
			</div>
			<>
				{dataFpsContext.catalogos.length ? (
					<Row className="mt-5">
						<Col xs={12} sm={12} md={12} lg={12}>
							<TablaCatalogosComponent
								filaSeleccionada={filaSeleccionada}
								seleccionarFila={setFilaSeleccionada}
								asignarFilaAbierta={setFilasAbiertas}
								filasAbiertas={filasAbiertas}
								catalogos={dataFpsContext.catalogos}
								hayError={setError}
							/>
						</Col>
					</Row>
				) : (
					<Row className="mt-5">
						<Col
							className="d-flex align-items-center justify-content-center flex-column"
							xs={12}
							sm={12}
							md={12}
							lg={12}
						>
							<h4 className="text-center">Crea la Estructura de Catálogos de Forma Jerárquica.</h4>
							<h5 className="text-center my-3">ESTRUCTURA</h5>
							<ul className="w-50 mt-3">
								<li>
									Genera la descripción principal al dar clic en el botón superor Agrega
									Descripción.
								</li>
								<li>
									En el siguiente modal ingresa el “Código” esto definira el orden de la nueva
									descripción, seguido de la descripcion correspondiente.
								</li>
								<li>Continúa el proceso segun el nivel de necesario.</li>
							</ul>
						</Col>
					</Row>
				)}
			</>
		</div>
	);
}
