import { getAuditoriaByFiltersService } from '@app/services/auditorias.service';
import { useInfiniteQuery } from '@tanstack/react-query';

import { IFullFiltrosAuditoria } from '@app/models/interfaces/auditorias/IFiltroAuditoria';

export default function useAuditoriasFiltros(filtros: IFullFiltrosAuditoria) {
	return useInfiniteQuery(
		['auditorias-filtros', filtros],
		async ({ pageParam = 1 }) => await getAuditoriaByFiltersService(pageParam, 20, filtros),
		{
			getNextPageParam: (lastPage, allPages) => {
				return lastPage.length ? allPages.length + 1 : undefined;
			},
			retry: 1,
			keepPreviousData: false,
			enabled: true,
			cacheTime: 0,
			staleTime: 3000,
		}
	);
}
