import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import { TitulosTabsCatalogosMots } from '../../models/enums/titulosTabsCatalogosMots.enum';
import { TabCatalogoMotsProps } from '../../models/types/tabs-catalogo-mots.type';
import TablaCatalogos from './TablaCatalogosMots';

export default function TabsGenerales(props: TabCatalogoMotsProps) {
	const { tipo, catalogos, cambiarCatalogos, recuperarTipoCatalogo } = props;
	const [tabActiva, setTabActiva] = useState<string>('permisoAplicable');

	useEffect(() => {
		recuperarTipoCatalogo('tipoTrabajo');
	}, []);

	return (
		<Tabs
			className="mb-3"
			activeKey={tabActiva}
			id="controlled-tab-example"
			onSelect={(activeTab: string) => {
				setTabActiva(activeTab);
				recuperarTipoCatalogo(activeTab);
			}}
		>
			{/* <Tab
				className="px-3"
				eventKey="tipoTrabajo"
				title={TitulosTabsCatalogosMots['tipoTrabajo']}
			>
				<TablaCatalogos listaCatalogos={catalogos} tipoCatalogo={tipo} />
			</Tab> */}
			<Tab
				className="px-3"
				eventKey="permisoAplicable"
				title={TitulosTabsCatalogosMots['permisoAplicable']}
			>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab
				className="px-3"
				eventKey="equipoProteccionPersonal"
				title={TitulosTabsCatalogosMots['equipoProteccionPersonal']}
			>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab
				className="px-3"
				eventKey="vehiculoAutomotor"
				title={TitulosTabsCatalogosMots['vehiculoAutomotor']}
			>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab className="px-3" eventKey="herramienta" title={TitulosTabsCatalogosMots['herramienta']}>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab className="px-3" eventKey="tipoTrabajo" title={TitulosTabsCatalogosMots['tipoTrabajo']}>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab className="px-3" eventKey="empresa" title={TitulosTabsCatalogosMots['empresa']}>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab
				className="px-3"
				eventKey="observacionMedica"
				title={TitulosTabsCatalogosMots['observacionMedica']}
			>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab className="px-3" eventKey="norma" title={TitulosTabsCatalogosMots['norma']}>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab
				className="px-3"
				eventKey="capacitacion"
				title={TitulosTabsCatalogosMots['capacitacion']}
			>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab
				className="px-3"
				eventKey="grupoSanguineo"
				title={TitulosTabsCatalogosMots['grupoSanguineo']}
			>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab className="px-3" eventKey="area" title={TitulosTabsCatalogosMots['area']}>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab
				className="px-3"
				eventKey="areaResponsable"
				title={TitulosTabsCatalogosMots['areaResponsable']}
			>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab
				className="px-3"
				eventKey="accesorioSeguridad"
				title={TitulosTabsCatalogosMots['accesorioSeguridad']}
			>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
		</Tabs>
	);
}
