import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';

import LogotipoPenaColorada from '../assets/images/logo-full.svg';
import useData from '../hooks/useData';
import useError from '../hooks/useError';
import { IAuth } from '../models/interfaces/auth/IAuth';
import { authService } from '../services/auth.service';
import { authValidationSchema } from '../utils/validations/auth.validation';

import '../styles/Login.scss';

export default function Home() {
  const [cargando, setCargando] = useState<boolean>(false);
  const initialValues: IAuth = {
    userName: '',
    password: '',
  };
  const formik = useFormik({
    initialValues,
    validationSchema: authValidationSchema,
    onSubmit: values => iniciarSesion(values),
  });
  const { setDataUser } = useData();
  const { setError, ErrorAlert } = useError();

  const iniciarSesion = async requestData => {
    try {
      setCargando(true);
      await authService(requestData, setDataUser);
    } catch (ex) {
      setError(ex);
    } finally {
      setCargando(false);
    }
  };

  useEffect(() => {
    return () => {
      setCargando(false);
    };
  }, []);

  return (
    <div className="container mt-5 login">
      <Row className="w-100 justify-content-center">
        <Col xs={12} sm={12} md={7} lg={5} xl={4}>
          <img className="mb-5" src={LogotipoPenaColorada} alt="Peña Colorada" />
          <Card>
            <Card.Body className="px-4 login__contenedor-formulario">
              <ErrorAlert />
              <form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col className="my-3" sm={12} md={12} lg={12}>
                    <Form.Group>
                      <Form.Label>Usuario</Form.Label>
                      <Form.Control
                        isInvalid={!!formik.errors.userName}
                        name="userName"
                        value={formik.values.userName}
                        onChange={formik.handleChange}
                        placeholder="Usuario"
                      />
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {formik.errors.userName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="my-3" sm={12} md={12} lg={12}>
                    <Form.Group>
                      <Form.Label>Contraseña</Form.Label>
                      <Form.Control
                        isInvalid={!!formik.errors.password}
                        type="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        placeholder="Contraseña"
                      />
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {formik.errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col className="my-0 mx-auto d-grid gap-2" sm={12} md={12} lg={12}>
                    <Button
                      className="d-block btn-block text-white"
                      type="submit"
                      variant="primary"
                      disabled={cargando}
                    >
                      {cargando ? (
                        <>
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                          &nbsp;&nbsp;{'Iniciando Sesión...'}
                        </>
                      ) : (
                        'Iniciar Sesión'
                      )}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="my-2 mx-auto text-center" sm={12} md={12} lg={12}>
                    <p className="text-center login__mensaje">
                      Para recuperar su contraseña contacte al administrador.
                    </p>
                  </Col>
                </Row>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
