import { useEffect } from 'react';
import { Card, ProgressBar } from 'react-bootstrap';

import useMetaUsuario from '@app/hooks/useMetaUsuario';
import useRole from '@app/hooks/useRole';

import '../../styles/components/IndicadoresMetas.scss';

export default function IndicadoresMetas() {
	const { esRole } = useRole();
	const { data: metas, refetch } = useMetaUsuario(!esRole('administrador'));

	useEffect(() => {
		refetch();
	}, []);

	return (
		<div className="indicadores-metas">
			{esRole('auditor') && (
				<Card className="indicadores-metas__contenedor">
					<Card.Body>
						<div className="mb-3">
							<label
								htmlFor="actos"
								className="w-100 d-flex align-items-center justify-content-between"
							>
								<span>Actos Inseguros</span>{' '}
								<span>
									{metas?.actosInseguros}/{metas?.metaActosInseguros}
								</span>
							</label>
							<ProgressBar now={metas?.actosInseguros} max={metas?.metaActosInseguros} />
						</div>
						<div className="mb-3">
							<label
								htmlFor="condiciones"
								className="w-100 d-flex align-items-center justify-content-between"
							>
								<span>Condiciones Inseguras</span>{' '}
								<span>
									{metas?.condicionesInseguras}/{metas?.metaCondicionesInseguras}
								</span>
							</label>
							<ProgressBar
								now={metas?.condicionesInseguras}
								max={metas?.metaCondicionesInseguras}
							/>
						</div>
						<div className="mb-3">
							<label
								htmlFor="reforzamiento"
								className="w-100 d-flex align-items-center justify-content-between"
							>
								<span>Reforzamientos Positivos</span>{' '}
								<span>
									{metas?.reforzamientosPositivos}/{metas?.metaReforzamientosPositivos}
								</span>
							</label>
							<ProgressBar
								now={metas?.reforzamientosPositivos}
								max={metas?.metaReforzamientosPositivos}
							/>
						</div>
					</Card.Body>
				</Card>
			)}
		</div>
	);
}
