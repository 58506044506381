import { useQuery } from '@tanstack/react-query';

import { getAllPersonalAuditorService } from '@app/services/personal.service';

export default function usePersonalAuditor() {
	return useQuery({
		queryKey: ['personal-auditor'],
		queryFn: getAllPersonalAuditorService,
		refetchOnWindowFocus: false,
		retry: false,
	});
}
