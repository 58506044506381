import {
	useFactorExposicionQuery,
	useFactorProbabilidadQuery,
	useFactorSeveridadQuery,
} from '@app/hooks/mots';
import { Grid } from '@mui/material';
import { FC } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { Control, FieldValues } from 'react-hook-form';

type DeterminarMRFormProps = {
	control?: Control<FieldValues, unknown>;
};

export const DeterminarMRForm: FC<DeterminarMRFormProps> = () => {
	const { data: factorExposicion = [] } = useFactorExposicionQuery();
	const { data: factorProbabilidad = [] } = useFactorProbabilidadQuery();
	const { data: factorSeveridad = [] } = useFactorSeveridadQuery();

	return (
		<>
			<Grid className="mb-3" item>
				<Form.Label htmlFor="nombre">Nombre del MOTS</Form.Label>
				<Form.Control type="text" id="nombre" name="nombre" placeholder="Nombre del MOTS" />
			</Grid>

			<Grid className="mb-3" spacing={6} container>
				<Grid xs={6} item>
					<Form.Label htmlFor="empresa">Empresa</Form.Label>
					<Form.Control type="text" id="empresa" name="empresa" placeholder="Empresa" />
				</Grid>

				<Grid xs={6} item>
					<Form.Label htmlFor="area">Área</Form.Label>
					<Form.Select id="area" name="area">
						<option value="">Seleccione el área</option>
					</Form.Select>
				</Grid>
			</Grid>

			<Grid className="mb-3" spacing={6} container>
				<Grid xs={6} item>
					<Form.Label htmlFor="zona">Zona</Form.Label>
					<Form.Control type="text" id="zona" name="zona" placeholder="Zona" />
				</Grid>

				<Grid xs={6} item>
					<Form.Label htmlFor="gerencia">Gerencia/Jefatura</Form.Label>
					<Form.Select id="gerencia" name="gerencia">
						<option value="">Seleccione la Gerencia/Jefatura</option>
					</Form.Select>
				</Grid>
			</Grid>

			<Stack>
				<p className="text-uppercase fw-bolder mb-5">Determinación de la Magnitud de Riesgo</p>

				<Grid alignItems="center" justifyContent="space-between" container>
					<p className="text-uppercase fw-bolder me-5">Magnitud de riesgo</p>
					<Grid
						className="border border-muted rounded-4"
						alignItems="center"
						justifyContent="center"
						style={{ height: '75px', width: '267px' }}
						container
					>
						<p className="fw-bolder text-muted text-uppercase my-0">MR Calculado</p>
					</Grid>
					<Grid
						className="border border-muted rounded-4"
						alignItems="center"
						justifyContent="center"
						style={{ height: '75px', width: '437px' }}
						container
					>
						<p className="fw-bolder text-muted text-uppercase my-0">MR severidad calculado</p>
					</Grid>
				</Grid>
			</Stack>

			<Grid className="mt-5" columnSpacing={4} container>
				<Grid xs={2} item></Grid>
				<Grid xs={5} item>
					Definición
				</Grid>
				<Grid xs={3} item>
					Clasificación
				</Grid>
				<Grid xs={2} item>
					Ponderación
				</Grid>
			</Grid>
			<Grid className="mt-4" columnSpacing={4} container>
				<Grid xs={2} item>
					Probabilidad
				</Grid>
				<Grid xs={5} item>
					<Form.Select>
						<option value="">Seleccione la definición</option>
						{factorProbabilidad.map(item => (
							<option key={item.id} value={item.id}>
								{item.descripcion}
							</option>
						))}
					</Form.Select>
				</Grid>
				<Grid xs={3} item>
					<Form.Control type="text" placeholder="Clasificación" disabled />
				</Grid>
				<Grid xs={2} item>
					<Form.Control type="text" placeholder="Ponderación" disabled />
				</Grid>
			</Grid>
			<Grid className="mt-4" columnSpacing={4} container>
				<Grid xs={2} item>
					Exposición
				</Grid>
				<Grid xs={5} item>
					<Form.Select>
						<option value="">Seleccione la exposición</option>
						{factorExposicion.map(item => (
							<option key={item.id} value={item.id}>
								{item.descripcion}
							</option>
						))}
					</Form.Select>
				</Grid>
				<Grid xs={3} item>
					<Form.Control type="text" placeholder="Clasificación" disabled />
				</Grid>
				<Grid xs={2} item>
					<Form.Control type="text" placeholder="Ponderación" disabled />
				</Grid>
			</Grid>
			<Grid className="mt-4" columnSpacing={4} container>
				<Grid xs={2} item>
					Severidad Mayor calculada
				</Grid>
				<Grid xs={5} item>
					<Form.Select>
						<option value="">Seleccione la severidad</option>
						{factorSeveridad.map(item => (
							<option key={item.id} value={item.id}>
								{item.descripcion}
							</option>
						))}
					</Form.Select>
				</Grid>
				<Grid xs={3} item>
					<Form.Control type="text" placeholder="Clasificación" disabled />
				</Grid>
				<Grid xs={2} item>
					<Form.Control type="text" placeholder="Ponderación" disabled />
				</Grid>
			</Grid>
		</>
	);
};
