import { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';

import { ModalEliminarProps } from '../../models/types/modal.type';

export default function DeleteModal(props: ModalEliminarProps) {
  const { tituloModal, estadoModal, cambiarEstadoModal, textoCuerpo, recuperarData, elemento, tipoElemento } = props;
  const [eliminando, setEliminando] = useState<boolean>(false);

  useEffect(() => {
    if (!estadoModal) {
      setEliminando(false);
    }
  }, [estadoModal]);

  return (
    <Modal show={estadoModal} onHide={() => cambiarEstadoModal({ estado: false, [tipoElemento]: elemento })}>
      <Modal.Header closeButton>
        <Modal.Title>{tituloModal}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{textoCuerpo}</h5>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => cambiarEstadoModal({ estado: false, [tipoElemento]: elemento })}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setEliminando(true);
            recuperarData(elemento);
          }}
          disabled={eliminando}
        >
          {eliminando ? (
            <>
              {' '}
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              &nbsp;&nbsp;{'Eliminando'}
            </>
          ) : (
            'Eliminar'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
