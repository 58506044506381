import * as Yup from 'yup';
import { ErrorMessages } from '../../constants/errorMessages';

export const registroUsuarioValidationSchema = (isUsersForm: boolean) =>
	Yup.object().shape({
		userName: Yup.string().required(ErrorMessages.Required),
		email: Yup.string().email(ErrorMessages.InvalidEmail),
		nombre: Yup.string().required(ErrorMessages.Required),
		empresa: Yup.string().required(ErrorMessages.Required),
		tipoUsuario: isUsersForm ? Yup.number().min(1, ErrorMessages.Required) : Yup.number(),
		roles: Yup.array(),
	});
