import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import { ICatalogo } from '@app/models/interfaces/catalogos/ICatalogo';
import { IJefaturaGerencia } from '@app/models/interfaces/jefaturasGerencias/IJefaturaGerencia';
import { IRamaJefaturaGerencia } from '@app/models/interfaces/jefaturasGerencias/IRamaJefaturaGerencia';
import { IAuditoriaRegistradaMeta } from '@app/models/interfaces/metas/IAgregarMeta';
import { TablaAuditoriasMetaProps } from '@app/models/types/tabla-auditorias-meta';
import {
	getJefaturaGerenciaById,
	getRamaJefaturasService,
} from '@app/services/organigrama.service';
import Delete from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import ListaGerenciasModal from '../Modals/MetasModals/ListaGerenciasModal';

export default function TablaAuditorias(props: TablaAuditoriasMetaProps) {
	const { ramasAuditorias, formik, auditoriasRegistradas, setAuditoriasRegistradas, isEditar } =
		props;
	const [estadoModal, setEstadoModal] = useState<boolean>(false);
	const [gerenciasAsignadas, setGerenciasAsignadas] = useState<IRamaJefaturaGerencia[]>([]);
	const [aspectoObservadoAsignado, setAspectoObservadoAsignado] = useState(null);

	const mostrarAuditoriaTabla = () => {
		const { ramaAspectoObservado, ramaPuesto } = ramasAuditorias;
		if (ramaAspectoObservado && ramaAspectoObservado.length) {
			const idNivelPuestoMasAlto = ramaPuesto[ramaPuesto.length - 1].id;

			//i Se busca si hay un registro igual
			const descripcionNivelUno =
				ramaAspectoObservado.find((ram: ICatalogo) => ram.nivel === 1)?.descripcion || '----------';
			const descripcionNivelDos =
				ramaAspectoObservado.find((ram: ICatalogo) => ram.nivel === 2)?.descripcion || '----------';
			const descripcionNivelTres =
				ramaAspectoObservado.find((ram: ICatalogo) => ram.nivel === 3)?.descripcion || '----------';
			const auditoriaEncontrada = auditoriasRegistradas.find(
				(audit: IAuditoriaRegistradaMeta) =>
					audit.aspectoObservado === descripcionNivelUno &&
					audit.nivelDos === descripcionNivelDos &&
					audit.nivelTres === descripcionNivelTres
			);

			//i Se agrega un item a la tabla
			if (!auditoriaEncontrada) {
				setAuditoriasRegistradas((audit: IAuditoriaRegistradaMeta[]) => [
					...audit,
					{
						idAspectoObservado: formik.values.aspectoObservado,
						aspectoObservado:
							ramaAspectoObservado.find((ram: ICatalogo) => ram.nivel === 1)?.descripcion ||
							'----------',
						nivelDos:
							ramaAspectoObservado.find((ram: ICatalogo) => ram.nivel === 2)?.descripcion ||
							'----------',
						nivelTres:
							ramaAspectoObservado.find((ram: ICatalogo) => ram.nivel === 3)?.descripcion ||
							'----------',
						gerenciasJefaturasAsignadas: [idNivelPuestoMasAlto],
					},
				]);
			} else {
				//i Acutalizamos el item existente
				setAuditoriasRegistradas((audit: IAuditoriaRegistradaMeta[]) =>
					audit.map((ad: IAuditoriaRegistradaMeta) => {
						return ad.aspectoObservado === auditoriaEncontrada.aspectoObservado &&
							ad.nivelDos === auditoriaEncontrada.nivelDos &&
							ad.nivelTres === auditoriaEncontrada.nivelTres
							? {
									...ad,
									gerenciasJefaturasAsignadas: [
										...auditoriaEncontrada.gerenciasJefaturasAsignadas,
										idNivelPuestoMasAlto,
									],
							  }
							: ad;
					})
				);
			}
		}
	};

	const eliminarAspectoObservado = async (aspecto: IAuditoriaRegistradaMeta) => {
		setAuditoriasRegistradas((audits: IAuditoriaRegistradaMeta[]) =>
			audits.filter(
				(audit: IAuditoriaRegistradaMeta) => audit.idAspectoObservado !== aspecto.idAspectoObservado
			)
		);
	};

	const mostrarListaJefaturasGerencias = async (
		aspectoObservado: IAuditoriaRegistradaMeta,
		gerenciasAsignadas: number[]
	) => {
		try {
			setEstadoModal(true);
			const resultado = await Promise.all(
				gerenciasAsignadas.map(async (puesto: number) => {
					return await getRamaJefaturasService(puesto);
				})
			);

			resultado.map(async (ram: any[]) => {
				const finalResult = await Promise.all(
					ram.map(async (pt: any) => {
						const response = await getJefaturaGerenciaById(pt.id);
						return response;
					})
				);

				setGerenciasAsignadas((gerens: any[]) => [...gerens, finalResult]);
				setAspectoObservadoAsignado(aspectoObservado);
			});
		} catch (ex) {
			throw ex;
		}
	};

	const puestoJefaturaGerenciaEliminado = (
		puesto: IJefaturaGerencia[],
		aspectoObservadoPadre: IAuditoriaRegistradaMeta
	) => {
		const { idAspectoObservado } = aspectoObservadoPadre;
		const gerenciasHijas = auditoriasRegistradas.find(
			(audit: IAuditoriaRegistradaMeta) => audit.idAspectoObservado === idAspectoObservado
		);

		//i Se obtiene el último elemento de los puestos
		const [itemGerenciaEliminado] = puesto.reverse();

		//i Eliminamos la gerencia del aspecto observado
		const gerenciaAspectoObservadoEliminado = gerenciasHijas.gerenciasJefaturasAsignadas.filter(
			(fil: number) => fil !== itemGerenciaEliminado.id
		);

		//i Reasignamos las gerencias al aspectoObservado
		setAuditoriasRegistradas((audits: IAuditoriaRegistradaMeta[]) =>
			audits.map(adt => {
				if (adt.idAspectoObservado === idAspectoObservado) {
					return { ...adt, gerenciasJefaturasAsignadas: gerenciaAspectoObservadoEliminado };
				} else {
					return adt;
				}
			})
		);
	};

	const puestoJefaturaGerenciaAgregado = (
		puesto: IJefaturaGerencia[],
		aspectoObservadoPadre: IAuditoriaRegistradaMeta
	) => {
		const { idAspectoObservado } = aspectoObservadoPadre;

		//i Se obtiene el último elemento de los puestos
		const [itemSeleccionado] = puesto.reverse();
		puesto.reverse();

		//i Agregamos la gerencia al aspectoObservado
		setAuditoriasRegistradas((audits: IAuditoriaRegistradaMeta[]) => {
			const resultAudit = audits.map(adt => {
				if (adt.idAspectoObservado === idAspectoObservado) {
					return {
						...adt,
						gerenciasJefaturasAsignadas: [...adt.gerenciasJefaturasAsignadas, itemSeleccionado.id],
					};
				} else {
					return adt;
				}
			});
			return resultAudit;
		});
	};

	useEffect(() => {
		mostrarAuditoriaTabla();
	}, [ramasAuditorias]);

	useEffect(() => {
		if (!estadoModal) {
			setGerenciasAsignadas([]);
		}
	}, [estadoModal]);

	useEffect(() => {
		return () => {
			setAuditoriasRegistradas([]);
		};
	}, []);

	return (
		<>
			<Table striped hover bordered>
				<thead>
					<tr>
						<th>Aspecto Observado</th>
						<th>Nivel 2</th>
						<th>Nivel 3</th>
						<th>Gerencia/Jefatura Asignada</th>
						<th>Acciones</th>
					</tr>
				</thead>
				<tbody>
					{auditoriasRegistradas.length ? (
						<>
							{auditoriasRegistradas.map((audit: IAuditoriaRegistradaMeta, index: number) => (
								<tr key={index}>
									<td>{audit.aspectoObservado}</td>
									<td>{audit.nivelDos}</td>
									<td>{audit.nivelTres}</td>
									<td>
										<div className="w-100 d-flex align-items-center justify-content-center">
											<div
												className="meta__gerencia-jefatura-asignada"
												onClick={() =>
													mostrarListaJefaturasGerencias(audit, audit.gerenciasJefaturasAsignadas)
												}
											>
												<span>{audit.gerenciasJefaturasAsignadas?.length}</span>
											</div>
										</div>
									</td>
									<td>
										<IconButton onClick={() => eliminarAspectoObservado(audit)}>
											<Delete fontSize="inherit" />
										</IconButton>
									</td>
								</tr>
							))}
						</>
					) : (
						<tr>
							<td colSpan={5}>No hay auditorías registradas.</td>
						</tr>
					)}
				</tbody>
			</Table>
			<ListaGerenciasModal
				estadoModal={estadoModal}
				cambiarEstadoModal={setEstadoModal}
				tituloModal="Gerencias/Jefaturas Asignadas"
				elementos={gerenciasAsignadas}
				cambiarElementos={setGerenciasAsignadas}
				aspectoObservado={aspectoObservadoAsignado}
				modificarElementos={setAuditoriasRegistradas}
				recuperarData={puestoJefaturaGerenciaEliminado}
				puestoAgregado={puestoJefaturaGerenciaAgregado}
				isEditar={isEditar}
				formik={formik}
			/>
		</>
	);
}
