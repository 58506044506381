import {
	IAgregarCatalogoMots,
	IEditarCatalogoMots,
} from '../../models/interfaces/mots/IAgregarCatalogoMots';
import http from '../../utils/http';

export const getAllEquipoAutomotorService = async () => {
	try {
		const response = await http.get('VehiculoAutomotor');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addEquipoAutomotorService = async (
	dataRequest: IAgregarCatalogoMots
) => {
	try {
		const response = await http.post('VehiculoAutomotor', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editEquipoAutomotorService = async (
	dataRequest: IEditarCatalogoMots
) => {
	try {
		const response = await http.put(
			`VehiculoAutomotor/${dataRequest.id}`,
			dataRequest
		);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteEquipoAutomotorService = async (id: number) => {
	try {
		const response = await http.delete(`VehiculoAutomotor/${id}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
