import { ChartOtroTipoObservacionProps } from '@app/models/types/chartOtroTipoObservacion.type';
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	ChartOptions,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export const optionsSev: ChartOptions<any> = {
	indexAxis: 'x' as const,
	responsive: true,
	ChartDataLabels,
	plugins: {
		legend: {
			display: true,
		},
		title: {
			display: true,
			text: 'Auditorías Otro Tipo Observación',
		},
		datalabels: {
			display: function (context) {
				return context.dataset.data[context.dataIndex] > 0;
			},
			color: 'white',
			labels: {
				title: {
					font: {
						weight: 'bold',
						size: '13px',
					},
				},
			},
		},
	},
};

const ChartOtroTipoObservacion = ({
	auditoriasOtroTipoObservacion,
}: ChartOtroTipoObservacionProps) => {
	const labels = ['Auditorías Otro Tipo Observación'];

	const data = {
		labels,
		datasets: [
			{
				label: 'Auditorías Otro Tipo Observación',
				data: [auditoriasOtroTipoObservacion],
				borderColor: '#C22A2A',
				backgroundColor: '#C22A2A',
			},
		],
	};

	return <Bar options={optionsSev} data={data} />;
};

export default ChartOtroTipoObservacion;
