import { ICatalogoMots } from '@app/models/interfaces/mots/ICatalogoMots';
import { getAllNormasService } from '@app/services/mots/norma.service';
import { useQuery } from '@tanstack/react-query';

export function useNormasQuery() {
	return useQuery<ICatalogoMots[]>({
		queryKey: ['mots', 'normas'],
		queryFn: getAllNormasService,
	});
}
