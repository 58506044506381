import { IUsuario } from '@app/models/interfaces/usuarios/IUsuario';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import { Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@app/constants/routes';
import '../../styles/components/modals/MostrarContrasenaModal.scss';

export default function MostrarContrasenaModal(props: {
	estadoModal: boolean;
	cambiarEstadoModal: Function;
	credenciales: IUsuario;
	isRegistroUsuario?: boolean;
}) {
	const { estadoModal, cambiarEstadoModal, credenciales, isRegistroUsuario } = props;
	const { password, nombre } = credenciales;
	const navigate = useNavigate();

	const cerrarModalContrasena = () => {
		if (isRegistroUsuario) {
			navigate(ROUTES.USUARIOS);
		}
		cambiarEstadoModal(false);
	};

	return (
		<Modal
			className="mostrar-contrasena-modal"
			show={estadoModal}
			onHide={() => cerrarModalContrasena()}
			backdrop="static"
		>
			<Modal.Header closeButton>
				<Modal.Title>Contraseña</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col xs={12} sm={12} md={12}>
						<h5 className="mb-3">{nombre}</h5>
						<Form.Group>
							<Form.Label>Contraseña Auditor</Form.Label>
							<div className="mostrar-contrasena-modal__campo-telefono">
								<Form.Control name="telefono" defaultValue={password} disabled />
								<div className="mostrar-contrasena-modal__icono-copiar">
									<OverlayTrigger placement="top" overlay={<Tooltip>Copiar</Tooltip>}>
										<IconButton
											onClick={() => {
												navigator.clipboard.writeText('').then(() => {
													navigator.clipboard.writeText(password);
												});
											}}
										>
											<ContentCopyIcon sx={{ fontSize: 16 }} />
										</IconButton>
									</OverlayTrigger>
								</div>
							</div>
						</Form.Group>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-secondary" onClick={() => cerrarModalContrasena()}>
					Cancelar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
