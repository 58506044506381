import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import '../styles/components/Title.component.scss';

export type TitleProps = {
	titulo: string;
	/**
	 * Si este prop está definido, agrega un botón de "Regresar" al título,
	 * que llevará a la ruta establecida.
	 */
	ruta?: string | undefined;
};

export default function Title(props: TitleProps) {
	const { ruta, titulo } = props;
	return (
		<>
			{!!ruta ? (
				<Row className="mt-5 title">
					<Col
						className="d-flex aling-items-center"
						xs={2}
						sm={2}
						md={2}
						lg={2}
					>
						<Link className="title__regresar" to={ruta}>
							<ArrowBackIcon />
						</Link>
					</Col>
					<Col xs={8} sm={8} md={8} lg={8}>
						<h1 className="text-center">{titulo}</h1>
					</Col>
					<Col xs={2} sm={2} md={2} lg={2} />
				</Row>
			) : (
				<h1 className="text-center mt-5">{titulo}</h1>
			)}
		</>
	);
}
