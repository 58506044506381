import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import { TitulosTabsCatalogosMots } from '../../models/enums/titulosTabsCatalogosMots.enum';
import { TabCatalogoMotsProps } from '../../models/types/tabs-catalogo-mots.type';
import TablaCatalogos from './TablaCatalogosMots';

export default function TabEmpleado(props: TabCatalogoMotsProps) {
	const { tipo, catalogos, cambiarCatalogos, recuperarTipoCatalogo } = props;
	const [tabActiva, setTabActiva] = useState<string>('tipoSangre');

	useEffect(() => {
		recuperarTipoCatalogo('tipoSangre');
	}, []);

	return (
		<Tabs
			className="mb-3"
			activeKey={tabActiva}
			id="controlled-tab-example"
			onSelect={(activeTab: string) => {
				setTabActiva(activeTab);
				recuperarTipoCatalogo(activeTab);
			}}
		>
			<Tab
				className="px-3"
				eventKey="tipoSangre"
				title={TitulosTabsCatalogosMots['tipoSangre']}
			>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					nombreCampo="tipoSangre"
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab
				className="px-3"
				eventKey="perfil"
				title={TitulosTabsCatalogosMots['perfil']}
			>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					nombreCampo="perfil"
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab
				className="px-3"
				eventKey="observacionMedica"
				title={TitulosTabsCatalogosMots['observacionMedica']}
			>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					nombreCampo="observacionMedica"
					tabActiva={tabActiva}
				/>
			</Tab>
		</Tabs>
	);
}
