import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';

import useFactorRiesgo from '@app/hooks/useFactorRiesgo';
import usePrecursor from '@app/hooks/usePrecursor';
import { ICatalogoGenerico } from '@app/models/interfaces/catalogos/ICatalogoGenerico';
import useData from '../../hooks/useData';
import useError from '../../hooks/useError';
import useResponsables from '../../hooks/useResponsables';
import { ICargando } from '../../models/ICargando';
import { IDataFpsContext } from '../../models/IDataContext';
import { IAgregarCatalogo } from '../../models/interfaces/catalogos/IAgregarCatalogo';
import { ISeveridad } from '../../models/interfaces/catalogos/ISeveridad';
import { IResponsable } from '../../models/interfaces/condicionInsegura/IResponsable';
import { ModalProps } from '../../models/types/modal.type';
import {
	addCatalogoService,
	editCatalogoService,
	getAllCatalogosService,
	getTipoAuditoriaService,
} from '../../services/catalogos.service';
import { SeveridadMock } from '../../utils/mocks/severidades.mock';
import { agregarCatalogoValidationSchema } from '../../utils/validations/modals/agregar-catalogo-moda.validation';

import '../../styles/components/modals/AgregarCatalogoModal.scss';

export default function AgregarCatalgoModal(props: ModalProps) {
	const { tituloModal, estadoModal, cambiarEstadoModal, idCatalogo, nivel, catalogo } = props;
	const { setDataFpsContext } = useData();
	const initialValues: IAgregarCatalogo = {
		catalogoId: null,
		tipoAuditoriaId: 0,
		codigo: '',
		descripcion: '',
		severidadId: 0,
		usuarioId: '',
		factorDeRiesgoFpsId: 0,
		precursorFpsId: 0,
	};
	const formik = useFormik({
		initialValues,
		validationSchema: agregarCatalogoValidationSchema(nivel, !!catalogo),
		onSubmit: (values: IAgregarCatalogo) => guardarDescripcion(values),
	});
	const [tiposAuditoria, setTiposAuditoria] = useState<{ id: number; descripcion: string }[]>([]);
	const [guardando, setGuardando] = useState<ICargando>({
		cargando: false,
		mensaje: '',
	});
	const [mostrarSelect, setMostrarSelect] = useState<boolean>(false);
	const [seleccionado, setSeleccionado] = useState<IResponsable[]>([]);
	const { data: responsables } = useResponsables();
	const { data: factoresRiesgo } = useFactorRiesgo();
	const { data: precursores } = usePrecursor();
	const { setError, ErrorAlert } = useError();

	//i Se llama al endpoint y la respuesta que obtenega se agrega a la dataContext
	//i en el apartado de catálogos
	const guardarDescripcion = async (requestData: IAgregarCatalogo) => {
		try {
			setGuardando({
				cargando: true,
				mensaje: 'Guardando',
			});
			if (!!catalogo) {
				await editCatalogoService(
					{
						codigo: requestData.codigo,
						descripcion: requestData.descripcion,
						tipoAuditoriaId: !requestData.tipoAuditoriaId ? null : requestData.tipoAuditoriaId,
						severidadId:
							!requestData.severidadId || !requestData.tipoAuditoriaId
								? null
								: requestData.severidadId,
						usuarioId: seleccionado.length ? seleccionado[0].id : '',
						factorDeRiesgoFpsId: requestData.factorDeRiesgoFpsId,
						precursorFpsId: requestData.precursorFpsId,
					},
					catalogo.id
				);
			} else {
				await addCatalogoService({
					...requestData,
					catalogoId: idCatalogo,
					tipoAuditoriaId: !requestData.tipoAuditoriaId ? null : requestData.tipoAuditoriaId,
					severidadId:
						!requestData.severidadId || !requestData.tipoAuditoriaId
							? null
							: requestData.severidadId,
					usuarioId: seleccionado.length ? seleccionado[0].id : '',
				});
			}
			const catalogosActualizados = await getAllCatalogosService();
			setDataFpsContext((data: IDataFpsContext) => ({
				...data,
				catalogos: catalogosActualizados,
			}));

			//i Si el idCatalogo viene nulo, entonces un un catálogo de nivel 1 y se maneja un state tipo "boolean"
			//i en caso contrario se maneja un state "{estado, idCatalogo}" -> (esto porque se ocupa mandar idCatalogo al que se va a agregar)
			//i el nuevo, esto cuando se abre la modal.
			cambiarEstadoModal(!!idCatalogo ? { estado: false, idCatalogo: 0, nivel: 0 } : false);
		} catch (ex) {
			setError(ex);
		} finally {
			setGuardando({ cargando: false, mensaje: '' });
		}
	};

	const obtenerTiposAuditoria = async () => {
		try {
			const response = await getTipoAuditoriaService();
			setTiposAuditoria(response);
		} catch (ex) {
			setError(ex);
		}
	};

	const responsableSeleccionado = (respo: IResponsable[]) => {
		setSeleccionado([...respo]);
		if (respo.length) {
			formik.setFieldValue('usuarioId', respo[0].id, false);
		}
	};

	useEffect(() => {
		if (!estadoModal) {
			formik.resetForm();
			setError('');
		} else {
			setMostrarSelect(!!nivel);
			if (!!catalogo) {
				formik.setFieldValue('codigo', catalogo.codigo, false);
				formik.setFieldValue('descripcion', catalogo.descripcion, false);
				formik.setFieldValue('tipoAuditoriaId', catalogo.tipoAuditoriaId, false);
				formik.setFieldValue(
					'severidadId',
					SeveridadMock.find(
						(sev: ISeveridad) => sev.nombre.toLowerCase() === catalogo.severidad.toLowerCase()
					)?.valor || 0,
					false
				);
				formik.setFieldValue('factorDeRiesgoFpsId', catalogo.factorDeRiesgoFpsId, false);
				formik.setFieldValue('precursorFpsId', catalogo.precursorFpsId, false);
				const responsableEncontrado = responsables?.find(
					(resp: IResponsable) => resp.id === catalogo.usuarioId
				);
				if (responsableEncontrado) {
					responsableSeleccionado([responsableEncontrado]);
				}
			}
		}
	}, [estadoModal]);

	useEffect(() => {
		obtenerTiposAuditoria();
		return () => {
			formik.resetForm();
			setGuardando({
				cargando: false,
				mensaje: '',
			});
			setError('');
		};
	}, []);

	return (
		<Modal
			className="moda-agregar-catalogo"
			show={estadoModal}
			onHide={() =>
				cambiarEstadoModal(!!idCatalogo ? { estado: false, idCatalogo: 0, nivel: 0 } : false)
			}
		>
			<Modal.Header closeButton>
				<Modal.Title>{catalogo?.descripcion ? `${catalogo.descripcion}` : tituloModal}</Modal.Title>
			</Modal.Header>
			<form onSubmit={formik.handleSubmit}>
				<Modal.Body>
					<ErrorAlert />
					<Row className="my-3">
						<Col xs={12} sm={12} md={12} lg={12}>
							<Form.Group>
								<Form.Label>
									Descripción&nbsp;
									<span className="modal-agregar-catalogo__requerido">*</span>
								</Form.Label>
								<Form.Control
									isInvalid={!!formik.errors.descripcion}
									name="descripcion"
									value={formik.values.descripcion}
									onChange={formik.handleChange}
									placeholder="Descripción"
								/>
								<Form.Control.Feedback className="d-block" type="invalid">
									{formik.errors.descripcion}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					{mostrarSelect && !!!catalogo?.catalogosChildren.length && (
						<Row className="my-3">
							<Col xs={12} sm={12} md={12} lg={12}>
								<Form.Group>
									<Form.Label>Tipo Observación</Form.Label>
									<Form.Control
										as="select"
										isInvalid={!!formik.errors.tipoAuditoriaId}
										name="tipoAuditoriaId"
										onChange={event => {
											if(!event.target.value){
												formik.setFieldValue('severidadId', 0, false);
												formik.setFieldValue('factorDeRiesgoFpsId', 0, false);
												formik.setFieldValue('precursorFpsId', 0, false);
											}
											formik.handleChange(event);
										}}
										value={formik.values.tipoAuditoriaId || 0}
									>
										<option value="">Seleccione Tipo Observación</option>
										{tiposAuditoria
											.filter(tipo => tipo.id !== 3)
											.map((tipoAudit: { id: number; descripcion: string }) => (
												<option key={tipoAudit.id} value={tipoAudit.id}>
													{tipoAudit.descripcion}
												</option>
											))}
									</Form.Control>
									<Form.Control.Feedback className="d-block" type="invalid">
										{formik.errors.tipoAuditoriaId}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
					)}
					{!!formik.values.tipoAuditoriaId && formik.values.tipoAuditoriaId < 3 && (
						<>
							<Row className="my-3">
								<Col xs={12} sm={12} md={12} lg={12}>
									<Form.Group className="d-flex flex-column">
										<Form.Label>Severidad</Form.Label>
										<div>
											{SeveridadMock.map((sev: ISeveridad) => (
												<Form.Check
													key={sev.nombre}
													inline
													type="radio"
													id={`${sev.valor}`}
													label={sev.nombre}
													isInvalid={!!formik.errors.severidadId}
													name="severidadId"
													onChange={formik.handleChange}
													value={`${sev.valor}`}
													checked={+formik.values.severidadId === sev.valor}
												/>
											))}
										</div>
										<Form.Control.Feedback className="d-block" type="invalid">
											{formik.errors.severidadId}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col xs={12} sm={12} md={12}>
									<Form.Group>
										<Form.Label>Factor de Riesgo</Form.Label>
										<Form.Select
											name="factorDeRiesgoFpsId"
											value={formik.values.factorDeRiesgoFpsId}
											onChange={formik.handleChange}
										>
											<option value={0}>Seleccione factor de riesgo</option>
											{factoresRiesgo
												?.sort((primero: ICatalogoGenerico, segundo: ICatalogoGenerico) =>
													primero.descripcion > segundo.descripcion ? 1 : -1
												)
												?.map(({ id, descripcion }: ICatalogoGenerico) => (
													<option key={id} value={id}>
														{descripcion}
													</option>
												))}
										</Form.Select>
									</Form.Group>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col xs={12} sm={12} md={12}>
									<Form.Group>
										<Form.Label>Precursor</Form.Label>
										<Form.Select
											name="precursorFpsId"
											value={formik.values.precursorFpsId}
											onChange={formik.handleChange}
										>
											<option value={0}>Seleccione precursor</option>
											{precursores
												?.sort((primero: ICatalogoGenerico, segundo: ICatalogoGenerico) =>
													primero.descripcion > segundo.descripcion ? 1 : -1
												)
												?.map(({ id, descripcion }: ICatalogoGenerico) => (
													<option key={id} value={id}>
														{descripcion}
													</option>
												))}
										</Form.Select>
									</Form.Group>
								</Col>
							</Row>
						</>
					)}
					<Row className="mt-3">
						<p className="modal-agregar-catalogo__leyenda">
							Todos los campos <span className="modal-agregar-catalogo__requerido">*</span> son
							obligatorios.
						</p>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-primary"
						onClick={() =>
							cambiarEstadoModal(!!idCatalogo ? { estado: false, idCatalogo: 0, nivel: 0 } : false)
						}
					>
						Cancelar
					</Button>
					<Button variant="secondary" disabled={guardando.cargando} type="submit">
						{guardando.cargando ? (
							<>
								<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
								&nbsp;&nbsp;
								{`${guardando.mensaje}`}
							</>
						) : (
							'Guardar'
						)}
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}
