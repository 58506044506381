import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import useData from '../../hooks/useData';
import { IDataFpsContext } from '../../models/IDataContext';
import { IRamaReaccion } from '../../models/interfaces/reacciones/IRamaReaccion';
import { IReaccion } from '../../models/interfaces/reacciones/IReaccion';
import { TablaReaccionesProps } from '../../models/types/tabla-reacciones.type';
import {
	deleteReaccionService,
	getAllReaccionesService,
} from '../../services/reacciones.service';
import AgregarReaccionModal from '../Modals/AgregarReaccionModal';
import DeleteModal from '../Modals/DeleteModal';

import '../../styles/components/TablaAreasZonas.component.scss';

export default function TablaReacciones(props: TablaReaccionesProps) {
	const { reacciones, asignarFilaAbierta, seleccionarFila, filasAbiertas } =
		props;
	const [estadoModalEliminar, setEstadoModalEliminar] = useState<{
		estado: boolean;
		reaccion: IReaccion;
	}>({
		estado: false,
		reaccion: {
			id: 0,
			nivel: '',
			descripcion: '',
			codigo: '',
			catalogoReaccionId: null,
			orderAscendingBranch: 0,
			catalogoReaccionesChildren: [],
		},
	});
	const [estadoModal, setEstadoModal] = useState<{
		estado: boolean;
		idReaccion: null | string | number;
		nivel: number;
		reaccion: IReaccion | undefined;
		tituloProvicional?: string;
	}>({
		estado: false,
		idReaccion: 0,
		nivel: 0,
		reaccion: undefined,
		tituloProvicional: '',
	});
	const [filaReaccionEncontrada, setFilaReaccionEncontrada] =
		useState<number>(0);
	const { dataFpsContext, setDataFpsContext } = useData();

	const expandirFila = (idReaccion: number) => event => {
		event.stopPropagation();
		if (!!filasAbiertas.find((fl: number) => fl === +idReaccion)) {
			asignarFilaAbierta((filas: number[]) =>
				filas.filter((fil: number) => fil !== +idReaccion)
			);
		} else {
			asignarFilaAbierta((filas: number[]) => [...filas, +idReaccion]);
		}
	};

	const seleccionarReaccionBuscada = () => {
		asignarFilaAbierta([]);
		asignarFilaAbierta(
			dataFpsContext.ramaReaccion.map((rama: IRamaReaccion) => rama.id)
		);

		const idReaccionSeleccionar =
			dataFpsContext.ramaReaccion[dataFpsContext.ramaReaccion.length - 1].id;
		setFilaReaccionEncontrada(idReaccionSeleccionar);
	};

	const seleccionarFilaReaccion = (idFila: number) => () =>
		seleccionarFila(idFila);

	const cuerpoTablaRender = (listaCat: IReaccion[], nivel: number = 0) => {
		return listaCat.map((reaccion: IReaccion) => {
			const filaAbierta: boolean = !!(
				filasAbiertas.length &&
				filasAbiertas.find((fil: number) => fil === +reaccion.id)
			);
			const hayMasCatalogos: boolean =
				!!reaccion.catalogoReaccionesChildren.length;

			return (
				<Fragment key={reaccion.id}>
					<tr
						className={classNames({
							'fw-bold': nivel === 0,
							'tabla-area-zona__seleccionado':
								reaccion.id === filaReaccionEncontrada,
						})}
						role="button"
						onClick={seleccionarFilaReaccion(reaccion.id)}
					>
						<td>
							<div style={{ marginLeft: `${nivel}rem` }}>
								{hayMasCatalogos && (
									<IconButton
										aria-label="Expandir"
										onClick={expandirFila(reaccion.id)}
										size="small"
									>
										<ArrowForwardIosIcon
											className={classNames('tabla-area-zona__arrow', {
												'tabla-area-zona__arrow--open': filaAbierta,
											})}
											id={`fila-${reaccion.id}`}
											fontSize="inherit"
										/>
									</IconButton>
								)}
								&nbsp;{reaccion.codigo}
							</div>
						</td>
						<td>{reaccion.descripcion}</td>
						<td>
							{+reaccion.nivel < 4 && (
								<IconButton
									aria-label="Hijo"
									size="small"
									onClick={() =>
										setEstadoModal({
											estado: true,
											idReaccion: reaccion.id,
											nivel: +reaccion.nivel,
											reaccion: undefined,
											tituloProvicional: reaccion.descripcion,
										})
									}
								>
									<AccountTreeIcon fontSize="inherit" />
								</IconButton>
							)}
							<IconButton
								aria-label="Editar"
								size="small"
								onClick={() =>
									setEstadoModal({
										estado: true,
										idReaccion: reaccion.id,
										nivel: +reaccion.nivel,
										reaccion: reaccion,
									})
								}
							>
								<EditIcon fontSize="inherit" />
							</IconButton>
							{!reaccion.catalogoReaccionesChildren.length && (
								<IconButton
									aria-label="Eliminar"
									size="small"
									onClick={() =>
										setEstadoModalEliminar({ estado: true, reaccion })
									}
								>
									<DeleteIcon fontSize="inherit" />
								</IconButton>
							)}
						</td>
					</tr>
					{filaAbierta &&
						cuerpoTablaRender(reaccion.catalogoReaccionesChildren, nivel + 1)}
				</Fragment>
			);
		});
	};

	const recuperarCatalogoEliminado = async (catalogoEliminado: IReaccion) => {
		try {
			await deleteReaccionService(catalogoEliminado.id);
			const reaccionesActualizadas = await getAllReaccionesService();
			setDataFpsContext((data: IDataFpsContext) => ({
				...data,
				reacciones: reaccionesActualizadas,
			}));
			setEstadoModalEliminar(
				(reac: { estado: boolean; reaccion: IReaccion }) => ({
					...reac,
					estado: false,
				})
			);
		} catch (ex) {
			throw ex;
		}
	};

	useEffect(() => {
		if (!!dataFpsContext.ramaReaccion.length) {
			seleccionarReaccionBuscada();
		}
	}, [dataFpsContext.ramaReaccion]);

	useEffect(() => {
		asignarFilaAbierta([]);
		setFilaReaccionEncontrada(0);
		return () => {
			setEstadoModal({
				estado: false,
				idReaccion: 0,
				nivel: 0,
				reaccion: undefined,
			});
			setEstadoModalEliminar({
				estado: false,
				reaccion: {
					id: 0,
					nivel: '',
					descripcion: '',
					codigo: '',
					catalogoReaccionId: null,
					orderAscendingBranch: 0,
					catalogoReaccionesChildren: [],
				},
			});
		};
	}, []);

	return (
		<>
			<Table className="tabla-area-zona" striped hover responsive>
				<thead>
					<tr>
						<th>Nivel</th>
						<th>Reacción de Persona</th>
						<th>Acciones</th>
					</tr>
				</thead>
				<tbody>{cuerpoTablaRender(reacciones)}</tbody>
			</Table>
			<AgregarReaccionModal
				estadoModal={estadoModal.estado}
				cambiarEstadoModal={setEstadoModal}
				tituloModal={estadoModal.tituloProvicional || 'Nueva Descripción'}
				idReaccion={estadoModal.idReaccion}
				nivel={estadoModal.nivel}
				reaccion={estadoModal.reaccion}
			/>
			<DeleteModal
				estadoModal={estadoModalEliminar.estado}
				cambiarEstadoModal={setEstadoModalEliminar}
				tituloModal="Eliminar Reacción"
				textoCuerpo={`¿Está seguro que quiere eliminar la reacción "${estadoModalEliminar.reaccion?.descripcion}"?`}
				elemento={estadoModalEliminar.reaccion}
				recuperarData={recuperarCatalogoEliminado}
				tipoElemento="reaccion"
			/>
		</>
	);
}
