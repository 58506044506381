import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import { TitulosTabsCatalogosMots } from '../../models/enums/titulosTabsCatalogosMots.enum';
import { TabCatalogoMotsProps } from '../../models/types/tabs-catalogo-mots.type';
import TablaCatalogos from './TablaCatalogosMots';

export default function TabsFactores(props: TabCatalogoMotsProps) {
	const { tipo, catalogos, cambiarCatalogos, recuperarTipoCatalogo } = props;
	const [tabActiva, setTabActiva] = useState<string>('probabilidad');

	useEffect(() => {
		recuperarTipoCatalogo('probabilidad');
	}, []);

	return (
		<Tabs
			className="mb-3"
			activeKey={tabActiva}
			id="controlled-tab-example"
			onSelect={(activeTab: string) => {
				setTabActiva(activeTab);
				recuperarTipoCatalogo(activeTab);
			}}
		>
			<Tab
				className="px-3"
				eventKey="probabilidad"
				title={TitulosTabsCatalogosMots['probabilidad']}
			>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab
				className="px-3"
				eventKey="exposicion"
				title={TitulosTabsCatalogosMots['exposicion']}
			>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
			<Tab
				className="px-3"
				eventKey="severidad"
				title={TitulosTabsCatalogosMots['severidad']}
			>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab>
			{/* <Tab
				className="px-3"
				eventKey="magnitud"
				title={TitulosTabsCatalogosMots['magnitud']}
			>
				<TablaCatalogos
					listaCatalogos={catalogos}
					cambiarListaCatalogos={cambiarCatalogos}
					tipoCatalogo={tipo}
					tabActiva={tabActiva}
				/>
			</Tab> */}
		</Tabs>
	);
}
