import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { format } from 'date-fns';
import { Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Loading from '@app/components/Loading';
import Title from '@app/components/Title';
import { ROUTES } from '@app/constants/routes';
import { TipoLevantamiento } from '@app/constants/tipoLevantamiento';
import useAuditoriaOtroTipo from '@app/hooks/auditorias/useAuditoriaOtroTipo';
import { IAuditoria } from '@app/models/interfaces/auditorias/IAuditoria';
import useData from '../../hooks/useData';

export default function AuditoriaOtroTipo() {
	const { data: auditoriasOtroTipo, isFetching: cargando } = useAuditoriaOtroTipo();
	const {
		dataFpsContext: { idioma },
	} = useData();
	const navigate = useNavigate();

	return (
		<div className="d-flex flex-column mx-5">
			{cargando && <Loading mensaje="Cargando auditorías" />}
			<Title titulo="Auditorías con Otro Tipo de Observación" />
			<Row className="mt-5">
				<Col xs={12} sm={12} md={12}>
					<Table striped hover>
						<thead>
							<tr>
								<th>Fecha y Hora</th>
								<th>Otro tipo de observación</th>
								<th>Tipo Auditoría</th>
								<th>Severidad</th>
								<th>Factor de Riesgo</th>
								<th>Precursor</th>
								<th>Estado</th>
								<th>Revisar Auditoría</th>
							</tr>
						</thead>
						<tbody>
							{auditoriasOtroTipo?.length ? (
								<>
									{auditoriasOtroTipo.map((audit: IAuditoria) => (
										<tr key={audit.id}>
											<td>
												{format(
													new Date(audit.fecha),
													idioma === 'es' ? 'dd/MM/yyyy hh:mm aa' : 'MM/dd/yy hh:mm aa'
												)}
											</td>
											<td>{audit.descripcion}</td>
											<td>{TipoLevantamiento[audit.tipoAuditoria]}</td>
											<td>{audit.severidad}</td>
											<td>{audit.factorRiesgo}</td>
											<td>{audit.precursor}</td>
											<td>{audit.estado}</td>
											<td>
												{audit.abierto && (
													<IconButton
														onClick={() => navigate(`${ROUTES.AUDITORIA_LIBRE}/${audit.id}`)}
													>
														<EditIcon fontSize="small" />
													</IconButton>
												)}
											</td>
										</tr>
									))}
								</>
							) : (
								<tr>
									<td colSpan={8}>No hay auditorías registradas.</td>
								</tr>
							)}
						</tbody>
					</Table>
				</Col>
			</Row>
		</div>
	);
}
