import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';

import { IAgregarJefaturaGerencia } from '@app/models/interfaces/jefaturasGerencias/INuevaJefaturaGerencia';
import {
	addJefaturaGerenciaService,
	editJefaturaGerenciaService,
	getAllArbolJefaturasGerenciasService,
} from '@app/services/organigrama.service';
import useData from '../../hooks/useData';
import useError from '../../hooks/useError';
import { ICargando } from '../../models/ICargando';
import { IDataFpsContext } from '../../models/IDataContext';
import { ModalJefaturaGerenciaProps } from '../../models/types/modal.type';

import { agregarJefaturaGerenciaValidationSchema } from '@app/utils/validations/modals/agregar-jefatura-gerencia.modal.validation';
import '../../styles/components/modals/AgregarCatalogoModal.scss';

export default function AgregarJefaturaGerenciaModal(props: ModalJefaturaGerenciaProps) {
	const { tituloModal, estadoModal, cambiarEstadoModal, idJefaturaGerencia, jefaturaGerencia } =
		props;
	const { setDataFpsContext } = useData();
	const initialValues: IAgregarJefaturaGerencia = {
		organigramaId: null,
		descripcion: '',
	};
	const formik = useFormik({
		initialValues,
		validationSchema: agregarJefaturaGerenciaValidationSchema,
		onSubmit: (values: IAgregarJefaturaGerencia) => guardarDescripcion(values),
	});
	const [guardando, setGuardando] = useState<ICargando>({
		cargando: false,
		mensaje: '',
	});
	const { setError, ErrorAlert } = useError();

	//i Se llama al endpoint y la respuesta que obtenega se agrega a la dataContext
	//i en el apartado de catálogos
	const guardarDescripcion = async (requestData: IAgregarJefaturaGerencia) => {
		try {
			setGuardando({
				cargando: true,
				mensaje: 'Guardando',
			});
			if (!!jefaturaGerencia) {
				await editJefaturaGerenciaService(
					{
						descripcion: requestData.descripcion,
					},
					jefaturaGerencia.id
				);
			} else {
				await addJefaturaGerenciaService({
					organigramaId: idJefaturaGerencia ? +idJefaturaGerencia : null,
					descripcion: requestData.descripcion,
				});
			}
			const catalogosActualizados = await getAllArbolJefaturasGerenciasService();
			setDataFpsContext((data: IDataFpsContext) => ({
				...data,
				jefaturaGerencia: catalogosActualizados,
			}));

			//i Si el idCatalogo viene nulo, entonces un un catálogo de nivel 1 y se maneja un state tipo "boolean"
			//i en caso contrario se maneja un state "{estado, idCatalogo}" -> (esto porque se ocupa mandar idCatalogo al que se va a agregar)
			//i el nuevo, esto cuando se abre la modal.
			cambiarEstadoModal(
				!!idJefaturaGerencia ? { estado: false, organigramaId: 0, nivel: 0 } : false
			);
		} catch (ex) {
			setError(ex);
		} finally {
			setGuardando({ cargando: false, mensaje: '' });
		}
	};

	useEffect(() => {
		if (!estadoModal) {
			formik.resetForm();
			setError('');
		} else {
			if (!!jefaturaGerencia) {
				formik.setFieldValue('codigo', jefaturaGerencia.codigo, false);
				formik.setFieldValue('descripcion', jefaturaGerencia.descripcion, false);
			}
		}
	}, [estadoModal]);

	useEffect(() => {
		return () => {
			formik.resetForm();
			setGuardando({
				cargando: false,
				mensaje: '',
			});
			setError('');
		};
	}, []);

	return (
		<Modal
			className="moda-agregar-catalogo"
			show={estadoModal}
			onHide={() =>
				cambiarEstadoModal(
					!!idJefaturaGerencia ? { estado: false, organigramaId: 0, nivel: 0 } : false
				)
			}
		>
			<Modal.Header closeButton>
				<Modal.Title>{tituloModal}</Modal.Title>
			</Modal.Header>
			<form onSubmit={formik.handleSubmit}>
				<Modal.Body>
					<ErrorAlert />
					<Row className="my-3">
						<Col xs={12} sm={12} md={12} lg={12}>
							<Form.Group>
								<Form.Label>
									Descripción&nbsp;
									<span className="modal-agregar-catalogo__requerido">*</span>
								</Form.Label>
								<Form.Control
									isInvalid={!!formik.errors.descripcion}
									name="descripcion"
									value={formik.values.descripcion}
									onChange={formik.handleChange}
									placeholder="Descripción"
								/>
								<Form.Control.Feedback className="d-block" type="invalid">
									{formik.errors.descripcion}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row className="mt-3">
						<p className="modal-agregar-catalogo__leyenda">
							Todos los campos <span className="modal-agregar-catalogo__requerido">*</span> son
							obligatorios.
						</p>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-primary"
						onClick={() =>
							cambiarEstadoModal(
								!!idJefaturaGerencia ? { estado: false, organigramaId: 0, nivel: 0 } : false
							)
						}
					>
						Cancelar
					</Button>
					<Button variant="secondary" disabled={guardando.cargando} type="submit">
						{guardando.cargando ? (
							<>
								<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
								&nbsp;&nbsp;
								{`${guardando.mensaje}`}
							</>
						) : (
							'Guardar'
						)}
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}
