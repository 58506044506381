import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import { IDataFpsContext } from '@app/models/IDataContext';
import { IJefaturaGerencia } from '@app/models/interfaces/jefaturasGerencias/IJefaturaGerencia';
import { IRamaJefaturaGerencia } from '@app/models/interfaces/jefaturasGerencias/IRamaJefaturaGerencia';
import { TablaJefaturasGerenciasProps } from '@app/models/types/tabla-jefaturas-gerencias';
import {
	deleteJefaturaGerenciaService,
	getAllArbolJefaturasGerenciasService,
} from '@app/services/organigrama.service';
import useData from '../../hooks/useData';
import AgregarJefaturaGerenciaModal from '../Modals/AgregarJefaturaGerenciaModal';
import DeleteModal from '../Modals/DeleteModal';

export default function TablaJefaturasGerencias(props: TablaJefaturasGerenciasProps) {
	const { jefaturasGerencias, filasAbiertas, seleccionarFila, asignarFilaAbierta } = props;
	const [filaJefaturaEncontrada, setFilaJefaturaEncontrada] = useState<number>(0);
	const [estadoModal, setEstadoModal] = useState<{
		estado: boolean;
		idJefaturaGerencia: null | string | number;
		nivel: number;
		jefaturaGerencia: IJefaturaGerencia | undefined;
		tituloProvicional?: string;
	}>({
		estado: false,
		idJefaturaGerencia: 0,
		nivel: 0,
		jefaturaGerencia: undefined,
		tituloProvicional: '',
	});
	const [estadoModalEliminar, setEstadoModalEliminar] = useState<{
		estado: boolean;
		jefaturaGerencia: IJefaturaGerencia;
	}>({
		estado: false,
		jefaturaGerencia: {
			id: 0,
			nivel: 0,
			descripcion: '',
			codigo: '',
			organigramaId: null,
			orderAscendingBranch: 0,
			organigramasChildren: [],
		},
	});
	const { dataFpsContext, setDataFpsContext } = useData();

	const seleccionarFilaJefatura = (idFila: number) => () => seleccionarFila(idFila);

	const seleccionarJefaturaGerenciaBuscada = () => {
		asignarFilaAbierta([]);
		asignarFilaAbierta(
			dataFpsContext.ramaJefaturaGerencia.map((rama: IRamaJefaturaGerencia) => rama.id)
		);

		const idJefaturaSeleccionar =
			dataFpsContext.ramaJefaturaGerencia[dataFpsContext.ramaJefaturaGerencia.length - 1].id;
		setFilaJefaturaEncontrada(idJefaturaSeleccionar);
	};

	const expandirFila = (idJefatura: number) => event => {
		event.stopPropagation();
		if (!!filasAbiertas.find((fl: number) => fl === +idJefatura)) {
			asignarFilaAbierta((filas: number[]) => filas.filter((fil: number) => fil !== +idJefatura));
		} else {
			asignarFilaAbierta((filas: number[]) => [...filas, +idJefatura]);
		}
	};

	const cuerpoTablaRender = (jefaturasGerencias: IJefaturaGerencia[], nivel: number = 0) => {
		return jefaturasGerencias.map((jefaturaGeren: IJefaturaGerencia) => {
			const filaAbierta: boolean = !!(
				filasAbiertas.length && filasAbiertas.find((fil: number) => fil === +jefaturaGeren.id)
			);
			const hayMasCatalogos: boolean = !!jefaturaGeren.organigramasChildren.length;

			return (
				<Fragment key={jefaturaGeren.id}>
					<tr
						className={classNames({
							'fw-bold': nivel === 0,
							'tabla-area-zona__seleccionado': jefaturaGeren.id === filaJefaturaEncontrada,
						})}
						role="button"
						onClick={seleccionarFilaJefatura(jefaturaGeren.id)}
					>
						<td>
							<div style={{ marginLeft: `${nivel}rem` }}>
								{hayMasCatalogos && (
									<IconButton
										aria-label="Expandir"
										onClick={expandirFila(jefaturaGeren.id)}
										size="small"
									>
										<ArrowForwardIosIcon
											className={classNames('tabla-area-zona__arrow', {
												'tabla-area-zona__arrow--open': filaAbierta,
											})}
											id={`fila-${jefaturaGeren.id}`}
											fontSize="inherit"
										/>
									</IconButton>
								)}
								&nbsp;{jefaturaGeren.codigo}
							</div>
						</td>
						<td>{jefaturaGeren.descripcion}</td>
						<td>
							<IconButton
								aria-label="Hijo"
								size="small"
								onClick={() =>
									setEstadoModal({
										estado: true,
										idJefaturaGerencia: jefaturaGeren.id,
										nivel: +jefaturaGeren.nivel,
										jefaturaGerencia: undefined,
										tituloProvicional: jefaturaGeren.descripcion,
									})
								}
							>
								<AccountTreeIcon fontSize="inherit" />
							</IconButton>
							<IconButton
								aria-label="Editar"
								size="small"
								onClick={() =>
									setEstadoModal({
										estado: true,
										idJefaturaGerencia: jefaturaGeren.id,
										nivel: +jefaturaGeren.nivel,
										jefaturaGerencia: jefaturaGeren,
										tituloProvicional: 'Editar ' + jefaturaGeren.descripcion,
									})
								}
							>
								<EditIcon fontSize="inherit" />
							</IconButton>
							{!jefaturaGeren.organigramasChildren.length && (
								<IconButton
									aria-label="Eliminar"
									size="small"
									onClick={() =>
										setEstadoModalEliminar({ estado: true, jefaturaGerencia: jefaturaGeren })
									}
								>
									<DeleteIcon fontSize="inherit" />
								</IconButton>
							)}
						</td>
					</tr>
					{filaAbierta && cuerpoTablaRender(jefaturaGeren.organigramasChildren, nivel + 1)}
				</Fragment>
			);
		});
	};

	const recuperarCatalogoEliminado = async (catalogoEliminado: IJefaturaGerencia) => {
		try {
			await deleteJefaturaGerenciaService(catalogoEliminado.id);
			const organigramaActualizado = await getAllArbolJefaturasGerenciasService();
			setDataFpsContext((data: IDataFpsContext) => ({
				...data,
				jefaturaGerencia: organigramaActualizado,
			}));
			setEstadoModalEliminar((reac: { estado: boolean; jefaturaGerencia: IJefaturaGerencia }) => ({
				...reac,
				estado: false,
			}));
		} catch (ex) {
			throw ex;
		}
	};

	useEffect(() => {
		if (!!dataFpsContext.ramaJefaturaGerencia.length) {
			seleccionarJefaturaGerenciaBuscada();
		}
	}, [dataFpsContext.ramaJefaturaGerencia]);

	useEffect(() => {
		return () => {
			setEstadoModalEliminar({
				estado: false,
				jefaturaGerencia: {
					id: 0,
					nivel: 0,
					descripcion: '',
					codigo: '',
					organigramaId: null,
					orderAscendingBranch: 0,
					organigramasChildren: [],
				},
			});
		};
	}, []);

	return (
		<>
			<Table className="tabla-area-zona" striped hover responsive>
				<thead>
					<tr>
						<th>Nivel</th>
						<th>Estructura</th>
						<th>Acciones</th>
					</tr>
				</thead>
				<tbody>{cuerpoTablaRender(jefaturasGerencias)}</tbody>
			</Table>
			<AgregarJefaturaGerenciaModal
				estadoModal={estadoModal.estado}
				cambiarEstadoModal={setEstadoModal}
				tituloModal={estadoModal.tituloProvicional || 'Nueva Estructura'}
				idJefaturaGerencia={estadoModal.idJefaturaGerencia}
				nivel={estadoModal.nivel}
				jefaturaGerencia={estadoModal.jefaturaGerencia}
			/>
			<DeleteModal
				estadoModal={estadoModalEliminar.estado}
				cambiarEstadoModal={setEstadoModalEliminar}
				tituloModal="Eliminar Jefatura/Gerencia"
				textoCuerpo={`¿Está seguro que quiere eliminar la Jefatura/Gerencia "${estadoModalEliminar.jefaturaGerencia?.descripcion}"?`}
				elemento={estadoModalEliminar.jefaturaGerencia}
				recuperarData={recuperarCatalogoEliminado}
				tipoElemento="jefaturaGerencia"
			/>
		</>
	);
}
