import Delete from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';

// import { IAuditoriaRegistradaMeta } from '@app/models/interfaces/metas/IAgregarMeta';
import DireccionFormulario from '@app/components/Metas/Fomularios/Direccion';
import { IRamaJefaturaGerencia } from '@app/models/interfaces/jefaturasGerencias/IRamaJefaturaGerencia';
import { ModalListaGerenciasProps } from '@app/models/types/modal.type';
import {
	getJefaturaGerenciaById,
	getRamaJefaturasService,
} from '@app/services/organigrama.service';

export default function ListaGerenciasModal(props: ModalListaGerenciasProps) {
	const {
		tituloModal,
		estadoModal,
		cambiarEstadoModal,
		elementos,
		cambiarElementos,
		recuperarData,
		aspectoObservado,
		puestoAgregado,
		formik,
	} = props;

	const eliminarPuesto = (elemento: IRamaJefaturaGerencia[], indice: number) => {
		if (elemento.length && elementos.length) {
			const puestosRestantes = elementos.filter((el, index) => {
				el;
				return index !== indice;
			});
			if (puestosRestantes) {
				cambiarElementos(puestosRestantes);
				recuperarData(elemento, aspectoObservado);
			}
		}
	};

	const agregarDireccion = async () => {
		try {
			const ramaPuesto = await getRamaJefaturasService(+formik.values.direccion);
			const formatPuestos = ramaPuesto.map(async puesto => {
				const detallePuesto = await getJefaturaGerenciaById(puesto.id);
				return detallePuesto;
			});
			Promise.all(formatPuestos).then(result => {
				cambiarElementos(elements => {
					return [...elements, result];
				});
				puestoAgregado(result, aspectoObservado);
			});
		} catch (ex) {
			ex;
		}
	};

	return (
		<Modal show={estadoModal} onHide={() => cambiarEstadoModal(false)} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{tituloModal}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="mb-3">
					<Col xs={12} sm={12} md={6}>
						<DireccionFormulario formik={formik} />
					</Col>
					<Col className="mx-3" xs={12} sm={12} md={12}>
						<Button onClick={() => agregarDireccion()}>Agregar Dirección</Button>
					</Col>
				</Row>
				<Row style={{ height: '350px', overflowY: 'scroll' }}>
					<Col xs={12} sm={12} md={12}>
						<Table striped hover>
							<thead>
								<tr>
									<th>Dirección</th>
									<th>Gerencia/Jefatura</th>
									<th>Jefatura</th>
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{elementos.length ? (
									<>
										{elementos.map((item: IRamaJefaturaGerencia[], key) => (
											<tr key={key}>
												<td>{item[0]?.descripcion || '------'}</td>
												<td>{item[1]?.descripcion || '------'}</td>
												<td>{item[2]?.descripcion || '------'}</td>
												<td>
													<IconButton onClick={() => eliminarPuesto(item, key)}>
														<Delete fontSize="inherit" />
													</IconButton>
												</td>
											</tr>
										))}
									</>
								) : (
									<tr>
										<td colSpan={4}>No hay puestos registrados</td>
									</tr>
								)}
							</tbody>
						</Table>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-secondary" onClick={() => cambiarEstadoModal(false)}>
					Cerrar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
