import { ROUTES } from '@app/constants/routes';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { Button, Container, Form, Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import TitleComponent from '../components/Title';
import { CardMots } from './components/CardMots';

export default function Mots() {
	const [activeTab, setActiveTab] = useState('genericos');
	const navigate = useNavigate();

	function goToCrearMots() {
		navigate(ROUTES.CREAR_MOTS);
	}

	return (
		<Container className="d-flex flex-column">
			<TitleComponent titulo="Método Operativo De Trabajo Seguro" />

			<Grid className="my-4" justifyContent="space-between" container>
				<Form.Group>
					<Form.Control type="search" placeholder="Buscar MOTS" />
				</Form.Group>

				<Button onClick={goToCrearMots} type="button" variant="secondary">
					Nuevo MOTS
				</Button>
			</Grid>

			<Tabs
				className="mb-3"
				activeKey={activeTab}
				onSelect={tab => setActiveTab(tab)}
			>
				<Tab className="px-3" eventKey="genericos" title="MOTS Genéricos">
					<CardMots mots={null} />
				</Tab>

				<Tab
					className="px-3"
					eventKey="empresa"
					title="MOTS de mi Empresa"
				></Tab>
			</Tabs>
		</Container>
	);
}
