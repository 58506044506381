import { ICatalogoMots } from '@app/models/interfaces/mots/ICatalogoMots';
import { getAllFactorProbabilidadService } from '@app/services/mots/factor-probabilidad.service';
import { useQuery } from '@tanstack/react-query';

export function useFactorProbabilidadQuery() {
	return useQuery<ICatalogoMots[]>({
		queryKey: ['mots', 'factor', 'probabilidad'],
		queryFn: getAllFactorProbabilidadService,
	});
}
