import { ROUTES } from '@app/constants/routes';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Grid } from '@mui/material';
import { FC, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DeterminarMRForm } from './components/DeterminarMRForm';
import { PermisosNormasForm } from './components/PermisosNormasForm';

const motsSteps = {
	1: {
		title: 'Determinar MR',
		component: <DeterminarMRForm />,
	},
	2: {
		title: 'Permisos y Normas',
		component: <PermisosNormasForm />,
	},
	3: {
		title: 'EPP y Accesorios',
		component: null,
	},
	4: {
		title: 'Análisis de la Tarea',
		component: null,
	},
} as const;

export const CrearMotsPage: FC = () => {
	const [currentStep, setCurrentStep] = useState(1);
	const navigate = useNavigate();
	const { formState, getValues } = useForm();

	function goToMotsPage() {
		navigate(ROUTES.MOTS);
	}

	console.log({ formState, formValues: getValues() });

	return (
		<Container className="d-flex flex-column">
			<h1 className="text-center mt-3 fs-4">Método Operativo de Trabajo Seguro</h1>

			<Grid
				alignItems="center"
				justifyContent="center"
				className="position-relative mt-4 mb-3"
				container
			>
				<Button
					onClick={goToMotsPage}
					type="button"
					variant=""
					className="position-absolute start-0 d-inline-flex align-center"
				>
					<ArrowBack className="me-1" />
					Regresar
				</Button>
				<h3 className="fs-4">{motsSteps[currentStep]?.title}</h3>
			</Grid>

			<div className="mx-5">
				{motsSteps[currentStep]?.component}

				<Grid justifyContent="flex-end" className="mt-4" container>
					<div>
						{currentStep - 1 > 0 && (
							<Button
								onClick={() => setCurrentStep(prev => prev - 1)}
								type="button"
								variant="secondary"
								className="me-5 px-5"
							>
								Regresar: {motsSteps[currentStep - 1]?.title}
							</Button>
						)}
						<Button
							onClick={() => setCurrentStep(prev => prev + 1)}
							type="button"
							variant="secondary"
							className="px-5"
						>
							Siguiente: {motsSteps[currentStep + 1]?.title}
						</Button>
						<p className="text-end">Paso {currentStep} de 17</p>
					</div>
				</Grid>
			</div>
		</Container>
	);
};
