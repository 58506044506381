import { IJefaturaGerencia } from '@app/models/interfaces/jefaturasGerencias/IJefaturaGerencia';
import {
	IAgregarJefaturaGerencia,
	IEditarJefaturaGerencia,
} from '@app/models/interfaces/jefaturasGerencias/INuevaJefaturaGerencia';
import http from '../utils/http';

export const getAllDireccionesService = async () => {
	try {
		const response = await http.get('Organigrama/GetDirecciones');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getAllJefaturasGerenciasService = async () => {
	try {
		const response = await http.get('Organigrama/GetGerenciasJefaturas');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getAllArbolJefaturasGerenciasService = async (): Promise<IJefaturaGerencia[]> => {
	try {
		const { data } = await http.get<IJefaturaGerencia[]>('Organigrama');
		return data;
	} catch (ex) {
		throw ex;
	}
};

export const getJefaturaGerenciaById = async (idOrganigrama: number) => {
	try {
		const { data } = await http.get(`Organigrama/${idOrganigrama}`);
		return data;
	} catch (ex) {
		throw ex;
	}
};

export const getRamaJefaturasService = async (jefaturaGerenciaId: number) => {
	try {
		const response = await http.get('Organigrama/GetRamaOrganigramaAscById', {
			params: {
				OrganogramaId: jefaturaGerenciaId,
			},
		});
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getFiltroJefaturasService = async (filter: string) => {
	try {
		const response = await http.get('Organigrama/GetFilterOrganigrama', {
			params: {
				filter,
			},
		});
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addJefaturaGerenciaService = async (dataRequest: IAgregarJefaturaGerencia) => {
	try {
		const response = await http.post('Organigrama', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editJefaturaGerenciaService = async (
	dataRequest: IEditarJefaturaGerencia,
	idJefaturaGerencia: number
) => {
	try {
		const response = await http.put(`Organigrama/${idJefaturaGerencia}`, dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteJefaturaGerenciaService = async (idJefaturaGerencia: number) => {
	try {
		const response = await http.delete(`Organigrama/${idJefaturaGerencia}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addJefaturaGerenciaAspectoObservableService = async (aspectoObservable: {
	metaId: number;
	catalogoId: number;
	organigramaIds: number[];
}) => {
	try {
		const response = await http.post('AspectoObservable', aspectoObservable);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteJefaturaGerenciaAspectoObservableService = async (
	idJefaturaGerencia: number
) => {
	try {
		const response = await http.delete(`AspectoObservable/${idJefaturaGerencia}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
