import { ChartEstAuditProps } from '@app/models/types/chartEstAudit.type';
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	ChartOptions,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export const options: ChartOptions<any> = {
	indexAxis: 'y' as const,
	responsive: true,
	plugins: {
		ChartDataLabels,
		legend: {
			display: false,
			position: 'top' as const,
		},
		title: {
			display: true,
			text: 'Estatus de Audítorias',
		},
		datalabels: {
			display: function (context) {
				return context.dataset.data[context.dataIndex] > 0;
			},
			color: 'white',
			labels: {
				title: {
					font: {
						size: '13px',
					},
				},
			},
		},
	},
};

const ChartEstAudit = ({ statusAuditoria }: ChartEstAuditProps) => {
	const labels = ['Extemporaneas Abiertas', 'Abiertas', 'Cerradas'];
	const data = {
		labels,
		datasets: [
			{
				label: '',
				data: [
					statusAuditoria?.extemporaneasAbiertas,
					statusAuditoria?.abiertas,
					statusAuditoria?.cerradas,
				],
				backgroundColor: ['#3E5878', '#C22A2A', '#6E727A'],
			},
		],
	};

	return <Bar options={options} data={data} />;
};

export default ChartEstAudit;
