import { getAspectoObservableUsuarioService } from '@app/services/catalogos.service';
import { useQuery } from '@tanstack/react-query';

export default function useAspectoObservableUsuario(noEsAdmin: boolean, semana: number = null) {
	return useQuery({
		queryKey: ['aspectoObservable-usuario', noEsAdmin, semana],
		queryFn: async () => await getAspectoObservableUsuarioService(semana),
		refetchOnWindowFocus: false,
		enabled: noEsAdmin,
		retry: false,
	});
}
