import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';
import { useNavigate, useParams } from 'react-router-dom';

import MostrarContrasenaModal from '@app/components/Modals/MostrarContrasenaModal';
import useDetalleUsuario from '@app/hooks/useDetalleUsuario';
import usePersonalAuditor from '@app/hooks/usePersonalAuditor';
import useRoles from '@app/hooks/useRoles';
import { IPersonalAuditor } from '@app/models/interfaces/personal/IPersonalAuditor';
import { capitalize } from '@app/utils/capitalize';
import Loading from '../components/Loading';
import Title from '../components/Title';
import { ROUTES } from '../constants/routes';
import useError from '../hooks/useError';
import { IRegistroUsuario } from '../models/interfaces/usuarios/IRegistroUsuario';
import { IRol, IUsuario } from '../models/interfaces/usuarios/IUsuario';
import { addUsuarioGenericoService, editUsuariosService } from '../services/usuarios.service';
import { registroUsuarioValidationSchema } from '../utils/validations/registro-usuario.validation';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../styles/RegistroUsuario.scss';

export default function RegistroUsuario() {
	const { id, tipo } = useParams();
	const [seleccionado, setSeleccionado] = useState<IPersonalAuditor[]>([]);
	const [estadoMostrarContrasena, setEstadoMostrarContrasena] = useState<boolean>(false);
	const [credenciales, setCredenciales] = useState<IUsuario>({
		id: '',
		userName: '',
		fullName: '',
		phoneNumber: '',
		email: '',
		tipo: '',
		active: true,
		nombre: '',
		empresa: '',
		password: '',
		roles: [],
	});
	const { data: personal, isLoading: cargandoPersonal } = usePersonalAuditor();
	const { isFetched: cargado, isFetching: cargandoUsuario, refetch } = useDetalleUsuario(id || '');
	const { data: roles } = useRoles();
	const navigate = useNavigate();
	const { ErrorAlert, setError } = useError();

	const initialValues: IRegistroUsuario = {
		userName: '',
		email: '',
		nombre: '',
		empresa: '',
		roles: tipo === 'auditor' ? [tipo] : [],
	};

	const { values, errors, handleSubmit, handleChange, setFieldValue } = useFormik({
		initialValues,
		validationSchema: registroUsuarioValidationSchema(tipo === 'usuario'),
		onSubmit: values =>
			registrarUsuario({
				...values,
				roles: values.roles.map(role => ({ name: role, selected: true })),
			}),
	});

	const { mutate: addUsuario, isLoading: agregandoUsuario } = useMutation({
		mutationFn: addUsuarioGenericoService,
		onSuccess: (usuario: IUsuario) => {
			setCredenciales(usuario);
			if (usuario.roles.some((role: IRol) => role.name.toLowerCase() === 'usuariointerno')) {
				navigate(ROUTES.USUARIOS);
			} else {
				setEstadoMostrarContrasena(true);
			}
		},
		onError: error => setError(error),
	});

	const { mutate: editUsuario, isLoading: editandoUsuario } = useMutation({
		mutationFn: async (dataUsuario: IRegistroUsuario) => await editUsuariosService(dataUsuario, id),
		onSuccess: () => navigate(ROUTES.USUARIOS),
		onError: error => setError(error),
	});

	const registrarUsuario = async (dataRequest: IRegistroUsuario) => {
		if (!!id) {
			editUsuario(dataRequest);
		} else {
			addUsuario(dataRequest);
		}
	};

	const establecerDatosUsuario = async (usuario: IUsuario) => {
		try {
			if (cargado) {
				const { userName, email, nombre, empresa, roles, tipo } = usuario;

				//i Se quitan los roles externos e internos de la lista
				const rolesNuevos = [...roles]
					.filter((role: IRol) => role.name !== 'UsuarioInterno' && role.name !== 'UsuarioExterno')
					.map(item => item.name);

				setSeleccionado([{ userName, nombre, empresa, tipo, puesto: '' }]);
				setFieldValue('userName', userName, false);
				setFieldValue('email', email, false);
				setFieldValue('nombre', nombre || '', false);
				setFieldValue('empresa', empresa, false);
				setFieldValue('roles', [...rolesNuevos], false);
			}
		} catch (ex) {
			setError(ex);
		}
	};

	const personalSeleccionado = (personal: IPersonalAuditor[]) => {
		setSeleccionado([...personal]);
		if (!!personal.length) {
			const [{ userName, nombre, empresa }] = personal;
			setFieldValue('userName', userName, false);
			setFieldValue('nombre', nombre, false);
			setFieldValue('empresa', empresa, false);
			setFieldValue('email', '', false);
		} else {
			setFieldValue('userName', '', false);
			setFieldValue('nombre', '', false);
			setFieldValue('empresa', '', false);
			setFieldValue('email', '', false);
		}
	};

	useEffect(() => {
		if (!!id) {
			refetch().then(({ data }) => {
				establecerDatosUsuario(data);
			});
		}
	}, [id]);

	useEffect(() => {
		return () => {
			setSeleccionado([]);
			setEstadoMostrarContrasena(false);
			setCredenciales({
				id: '',
				userName: '',
				fullName: '',
				phoneNumber: '',
				email: '',
				tipo: '',
				active: true,
				nombre: '',
				empresa: '',
				password: '',
				roles: [],
			});
		};
	}, []);

	return (
		<Container className="registro-usuario d-flex flex-column">
			{(agregandoUsuario || editandoUsuario || cargandoPersonal || cargandoUsuario) && (
				<Loading
					mensaje={
						agregandoUsuario
							? `Agregando ${capitalize(tipo)}`
							: editandoUsuario
							? `Editando ${capitalize(tipo)}`
							: cargandoUsuario
							? 'Cargando Usuario'
							: 'Cargando Personal'
					}
				/>
			)}
			<Title
				titulo={!!id ? `Editar ${capitalize(tipo)}` : `Registro de ${capitalize(tipo)}`}
				ruta={ROUTES.USUARIOS}
			/>
			<ErrorAlert />
			<form className="mt-5" onSubmit={handleSubmit}>
				<Row className="my-4">
					<Col xs={12} sm={12} md={{ offset: 1, span: 5 }} lg={{ offset: 1, span: 5 }}>
						<Form.Group>
							<Form.Label htmlFor="single-typeahead">Buscar Personal</Form.Label>
							<Typeahead
								id="single-typeahead"
								clearButton
								filterBy={['userName', 'nombre', 'empresa']}
								labelKey="nombre"
								onChange={personalSeleccionado}
								options={personal || []}
								selected={seleccionado}
								paginate
								paginationText="Mostra más personal..."
								disabled={!!id}
								renderMenuItemChildren={(option: IPersonalAuditor, { text }) => (
									<div>
										<Highlighter
											highlightClassName="bg-transparent font-weight-bold p-0"
											search={text}
										>
											{option.nombre}
										</Highlighter>
										<div>
											<div>
												<small>
													Usuario:{' '}
													<Highlighter
														highlightClassName="bg-transparent font-weight-bold p-0"
														search={text}
													>
														{option.userName}
													</Highlighter>
												</small>
											</div>
											<div>
												<small>
													Empresa:{' '}
													<Highlighter
														highlightClassName="bg-transparent font-weight-bold p-0"
														search={text}
													>
														{option.empresa}
													</Highlighter>
												</small>
											</div>
										</div>
									</div>
								)}
								placeholder={`Personal ${capitalize(tipo)}`}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row className="my-4">
					<Col xs={12} sm={12} md={{ offset: 1, span: 5 }} lg={{ offset: 1, span: 5 }}>
						<Form.Group>
							<Form.Label>
								Nombre<span className="registro-usuario__required">*</span>
							</Form.Label>
							<Form.Control
								name="nombre"
								isInvalid={!!errors.nombre}
								placeholder="Nombre"
								value={values.nombre}
								onChange={handleChange}
								disabled
							/>
							<Form.Control.Feedback className="d-block" type="invalid">
								{errors.nombre}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col xs={12} sm={12} md={5} lg={5}>
						<Form.Group>
							<Form.Label>
								Empresa<span className="registro-usuario__required">*</span>
							</Form.Label>
							<Form.Control
								name="empresa"
								isInvalid={!!errors.empresa}
								placeholder="Empresa"
								value={values.empresa}
								onChange={handleChange}
								disabled
							/>
							<Form.Control.Feedback className="d-block" type="invalid">
								{errors.empresa}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>
				<Row className="my-4 d-flex align-items-end">
					<Col xs={12} sm={12} md={{ offset: 1, span: 5 }} lg={{ offset: 1, span: 5 }}>
						<Form.Group>
							<Form.Label>
								Usuario<span className="registro-usuario__required">*</span>
							</Form.Label>
							<Form.Control
								name="userName"
								isInvalid={!!errors.userName}
								value={values.userName}
								onChange={handleChange}
								placeholder="Usuario"
								disabled
							/>
							<Form.Control.Feedback className="d-block" type="invalid">
								{errors.userName}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col xs={12} sm={12} md={5} lg={5}>
						<Form.Group>
							<Form.Label>Correo Electronico</Form.Label>
							<Form.Control
								name="email"
								isInvalid={!!errors.email}
								value={values.email}
								onChange={handleChange}
								placeholder="Correo Electronico"
							/>
							<Form.Control.Feedback className="d-block" type="invalid">
								{errors.email}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>
				<Row className="my-4">
					<Col xs={12} sm={12} md={{ offset: 1, span: 5 }} lg={{ offset: 1, span: 5 }}>
						{roles?.length ? (
							<Form.Group>
								<Form.Label className="m-0">
									Roles<span className="registro-usuario__required">*</span>
								</Form.Label>
								<div className="d-flex flex-wrap">
									{roles
										?.filter((rol: IRol) =>
											tipo === 'usuario'
												? rol.name.toLowerCase() !== 'auditor'
												: rol.name.toLowerCase() === 'auditor'
										)
										?.map((role: IRol) => (
											<>
												<Form.Check
													key={role.id}
													className="my-3"
													type="checkbox"
													id={role.name?.toLowerCase()}
													label={role.name}
													name="roles"
													isInvalid={!!errors.roles}
													checked={values.roles.some(
														item => item?.toLowerCase() === role.name?.toLowerCase()
													)}
													value={role.name}
													onChange={handleChange}
												/>
												&nbsp;&nbsp;&nbsp;
											</>
										))}
								</div>
								<Form.Control.Feedback className="d-block" type="invalid">
									{errors.roles}
								</Form.Control.Feedback>
							</Form.Group>
						) : null}
						<p className="mt-4">
							Los campos con <span className="registro-usuario__required">*</span> son obligatorios.
						</p>
					</Col>
					<Col
						className="my-0 d-flex align-items-center justify-content-end"
						xs={12}
						sm={12}
						md={5}
						lg={5}
					>
						<Button className="w-50 text-white" type="submit" variant="secondary">
							Guardar {capitalize(tipo)}
						</Button>
					</Col>
				</Row>
			</form>
			<MostrarContrasenaModal
				estadoModal={estadoMostrarContrasena}
				cambiarEstadoModal={setEstadoMostrarContrasena}
				credenciales={credenciales}
				isRegistroUsuario
			/>
		</Container>
	);
}
