import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import { getAllAccesorioSeguridadService } from '@app/services/mots/accesorio-seguridad.service';
import { getAllAreaResponsableService } from '@app/services/mots/area-responsable.service';
import { getAllAreaService } from '@app/services/mots/area.service';
import AgregarCatalogoMots from '../../components/Modals/MotsModals/AgregarCatalogoMots';
import TabsEmpleado from '../../components/Mots/TabEmpleado';
import TabsFactores from '../../components/Mots/TabsFactores';
import TabsGenerales from '../../components/Mots/TabsGenerales';
import TitleComponent from '../../components/Title';
import { TitulosTabsCatalogosMots } from '../../models/enums/titulosTabsCatalogosMots.enum';
import { ICatalogoGeneralMots } from '../../models/interfaces/mots/ICatalogoMots';
import { getAllCapacitacionesService } from '../../services/mots/capacitacion.service';
import { getAllEmpresaService } from '../../services/mots/empresa.service';
import { getAllEquipoAutomotorService } from '../../services/mots/equipo-automotor.service';
import { getAllEquipoProteccionPersonalService } from '../../services/mots/equipo-proteccion-personal.service';
import { getAllFactorExposicionService } from '../../services/mots/factor-exposicion.service';
import { getAllFactorProbabilidadService } from '../../services/mots/factor-probabilidad.service';
import { getAllFactorSeveridadService } from '../../services/mots/factor-severidad.service';
import { getAllGrupoSanguineoService } from '../../services/mots/grupo-sanguineo.service';
import { getAllHerramientaService } from '../../services/mots/herramienta.service';
import { getAllNormasService } from '../../services/mots/norma.service';
import { getAllObservacionMedicaService } from '../../services/mots/observacion-medica.service';
import { getAllPermisoAplicableService } from '../../services/mots/permiso-aplicable.service';
import { getAllTipoTrabajoService } from '../../services/mots/tipo-trabajo.service';

export default function CatalogoMots() {
	const [estadoModalAgregar, setEstadoModalAgregar] = useState<boolean>(false);
	const [tabActiva, setTabActiva] = useState('permisoAplicable');
	const [opcionCatalogo, setOpcionCatalogo] = useState<string>('general');
	const [listaCatalogos, setListaCatalogos] = useState<ICatalogoGeneralMots[]>([]);

	const obtenerTipoCatalogo = (tipoCatalogo: string) => setTabActiva(tipoCatalogo);

	//i Si se agrega correctamente un catálogo se agrega a la lista actual
	//i de catálogos renderizada.
	const obtenerResponseCatalogoMots = (response: ICatalogoGeneralMots) => {
		if (response) {
			setListaCatalogos((cats: ICatalogoGeneralMots[]) => [...cats, response]);
		}
	};

	//i Dependiendo el tab seleccionado es la lista de catálogos
	//i que se obtinen y se muestran en la tabla.
	const obtenerListCatalogos = async () => {
		try {
			const opcionTab = tabActiva.toLowerCase();
			let responseCatalogo = [];
			if (opcionTab.includes('proteccionpersonal')) {
				responseCatalogo = await getAllEquipoProteccionPersonalService();
			} else if (opcionTab.includes('permisoaplicable')) {
				responseCatalogo = await getAllPermisoAplicableService();
			} else if (opcionTab.includes('herramienta')) {
				responseCatalogo = await getAllHerramientaService();
			} else if (opcionTab.includes('vehiculoautomotor')) {
				responseCatalogo = await getAllEquipoAutomotorService();
			} else if (opcionTab.includes('probabilidad')) {
				responseCatalogo = await getAllFactorProbabilidadService();
			} else if (opcionTab.includes('exposicion')) {
				responseCatalogo = await getAllFactorExposicionService();
			} else if (opcionTab.includes('severidad')) {
				responseCatalogo = await getAllFactorSeveridadService();
			} else if (opcionTab.includes('trabajo')) {
				responseCatalogo = await getAllTipoTrabajoService();
			} else if (opcionTab.includes('empresa')) {
				responseCatalogo = await getAllEmpresaService();
			} else if (opcionTab.includes('observacion')) {
				responseCatalogo = await getAllObservacionMedicaService();
			} else if (opcionTab.includes('sanguineo')) {
				responseCatalogo = await getAllGrupoSanguineoService();
			} else if (opcionTab.includes('norma')) {
				responseCatalogo = await getAllNormasService();
			} else if (opcionTab.includes('capacitacion')) {
				responseCatalogo = await getAllCapacitacionesService();
			} else if (opcionTab === 'area') {
				responseCatalogo = await getAllAreaService();
			} else if (opcionTab.includes('arearesponsable')) {
				responseCatalogo = await getAllAreaResponsableService();
			} else if (opcionTab.includes('accesorioseguridad')) {
				responseCatalogo = await getAllAccesorioSeguridadService();
			}
			setListaCatalogos(responseCatalogo);
		} catch (ex) {
			throw ex;
		}
	};

	useEffect(() => {
		obtenerListCatalogos();
	}, [tabActiva]);

	//i Si se cambia el select, se inyecta la primera opcion en tabActiva
	useEffect(() => {
		if (opcionCatalogo.toLowerCase() === 'general') {
			setTabActiva('permisoAplicable');
		} else if (opcionCatalogo.toLowerCase() === 'factor') {
			setTabActiva('probabilidad');
		} else {
			setTabActiva('tipoSangre');
		}
	}, [opcionCatalogo]);

	return (
		<Container className="d-flex flex-column">
			<TitleComponent titulo="Catálogos MOTS" />
			<Row className="mt-5 mb-2">
				<Col xs={12} sm={12} md={6} lg={6}>
					<Form.Group className="w-75">
						<Form.Label>Tipo Catálogos</Form.Label>
						<Form.Control
							as="select"
							value={opcionCatalogo}
							onChange={({ target }) => setOpcionCatalogo(target.value)}
						>
							<option value="general">Catálogos Generales</option>
							<option value="factor">Catálogos Factores</option>
						</Form.Control>
					</Form.Group>
				</Col>
				<Col xs={12} sm={12} md={6} lg={6} className="d-flex align-items-end justify-content-end">
					<Button
						variant="primary"
						onClick={() => setEstadoModalAgregar(true)}
						disabled={tabActiva.toLowerCase().includes('sanguineo')}
					>
						Agregar Catálogo
					</Button>
				</Col>
			</Row>
			<Row className="mt-5">
				<Col xs={12} sm={12} md={12} lg={12}>
					{opcionCatalogo === 'general' ? (
						<TabsGenerales
							tipo={opcionCatalogo}
							catalogos={listaCatalogos}
							cambiarCatalogos={setListaCatalogos}
							recuperarTipoCatalogo={obtenerTipoCatalogo}
						/>
					) : opcionCatalogo === 'factor' ? (
						<TabsFactores
							tipo={opcionCatalogo}
							catalogos={listaCatalogos}
							cambiarCatalogos={setListaCatalogos}
							recuperarTipoCatalogo={obtenerTipoCatalogo}
						/>
					) : (
						<TabsEmpleado
							tipo={opcionCatalogo}
							catalogos={listaCatalogos}
							cambiarCatalogos={setListaCatalogos}
							recuperarTipoCatalogo={obtenerTipoCatalogo}
						/>
					)}
				</Col>
			</Row>
			<AgregarCatalogoMots
				nombreCatalogo={TitulosTabsCatalogosMots[tabActiva]}
				recuperarData={obtenerResponseCatalogoMots}
				estadoModal={estadoModalAgregar}
				cambiarEstadoModal={setEstadoModalAgregar}
				tipoCatalogo={opcionCatalogo}
				pestanaActiva={tabActiva}
			/>
		</Container>
	);
}
