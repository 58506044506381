import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Step, Stepper } from 'react-form-stepper';

import LoadingComponent from '@app/components/Loading';
import FormularioAuditoriaComponent from '@app/components/Metas/FormularioAuditoria';
import FormularioAuditoriaDirigidaComponent from '@app/components/Metas/FormularioAuditoriaDirigida';
import Title from '@app/components/Title';
import { ROUTES } from '@app/constants/routes';
import useError from '@app/hooks/useError';
import {
	IAgregarMeta,
	IAuditoriaRegistradaMeta,
	IEditarMeta,
} from '@app/models/interfaces/metas/IAgregarMeta';
import { addMetaService, editMetaService, getMetaById } from '@app/services/meta.service';
import { numeroSemanaActual, rangosSemanaActual } from '@app/utils/fechas';
import { metaValidationSchema } from '@app/utils/validations/meta.validation';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { estiloLineaAvance, estiloStep } from '../constants/estilosStepper';

// import { IDetalleMeta } from '@app/models/interfaces/metas/IMeta';
import '../styles/Meta.scss';

export default function NuevaMeta() {
	const [pasos, setPasos] = useState<number>(1);
	const [guardandoMeta, setGuardandoMeta] = useState<boolean>(false);
	const [metasDirigidasId, setMetasDirigidasId] = useState<IAuditoriaRegistradaMeta[]>([]);
	const [isEditar, setIsEditar] = useState<boolean>(false);
	const [detalleMeta, setDetalleMeta] = useState<any>({
		id: 0,
		anio: 0,
		libres: 0,
		reforzamiento: 0,
		semana: 0,
		aspectosObservableAuditoriasDirigidas: [],
	});
	const { id } = useParams();
	const { ErrorAlert, setError } = useError();
	const { inicio, fin } = rangosSemanaActual;
	const initialValues: IAgregarMeta = {
		semana: 1,
		semanaInicio: 1,
		semanaFin: 1,
		libres: 0,
		reforzamiento: 0,
		aspectoObservado: 0,
		anio: `${new Date().getFullYear()}`,
		repetirMeta: false,
		aspectosObservablesAuditoriaDirigidas: [],
	};
	const formik = useFormik({
		initialValues,
		validationSchema: metaValidationSchema(!!id),
		onSubmit: (values: IAgregarMeta) => guardarMeta(values),
	});
	const navigate = useNavigate();

	const guardarMeta = async (nuevaMeta: IAgregarMeta) => {
		try {
			setGuardandoMeta(true);
			if (!isEditar) {
				const metaModel: IAgregarMeta = {
					...nuevaMeta,
					aspectosObservablesAuditoriaDirigidas: metasDirigidasId.map(
						(mt: IAuditoriaRegistradaMeta) => ({
							catalogoId: mt.idAspectoObservado,
							organigramaIds: mt.gerenciasJefaturasAsignadas,
						})
					),
				};
				await addMetaService(metaModel);
			} else {
				const metaUpdateModel: IEditarMeta = {
					libres: nuevaMeta.libres,
					reforzamiento: nuevaMeta.reforzamiento,
					aspectosObservablesAuditoriaDirigidas: metasDirigidasId.map(
						(mt: IAuditoriaRegistradaMeta) => ({
							catalogoId: mt.idAspectoObservado,
							organigramaIds: mt.gerenciasJefaturasAsignadas,
						})
					),
				};
				await editMetaService(metaUpdateModel, +id);
			}
			navigate(ROUTES.METAS);
		} catch (ex) {
			setError(ex);
		} finally {
			setGuardandoMeta(false);
		}
	};

	const renderFormulario = () => {
		return (
			<>
				{guardandoMeta && (
					<LoadingComponent mensaje={isEditar ? 'Editando meta' : 'Guardando meta'} />
				)}
				<div
					className={classNames('d-block', {
						'd-none': pasos !== 1,
					})}
				>
					<>
						<FormularioAuditoriaComponent
							formik={formik}
							avance={avanceRetrocesoStepper}
							isEditar={isEditar}
						/>
					</>
				</div>
				<div
					className={classNames('d-block', {
						'd-none': pasos !== 2,
					})}
				>
					<FormularioAuditoriaDirigidaComponent
						formik={formik}
						avance={avanceRetrocesoStepper}
						recuperarMetasDirigidas={setMetasDirigidasId}
						isEditar={isEditar}
						detalleMeta={detalleMeta}
						hayError={setError}
					/>
				</div>
			</>
		);
	};

	const avanceRetrocesoStepper = (paso: number, accion: string) => {
		accion.toLowerCase() === 'siguiente' ? paso++ : paso--;
		setPasos(paso);
	};

	const obtenerDetalleMeta = async () => {
		try {
			const meta = await getMetaById(+id);
			formik.setValues(vals => ({
				...vals,
				anio: meta.anio,
				semana: meta.semana,
				libres: meta.libres,
				reforzamiento: meta.reforzamiento,
			}));
			setDetalleMeta(meta);
		} catch (ex) {
			setError(ex);
		}
	};

	useEffect(() => {
		setIsEditar(!!id);
		if (id) {
			obtenerDetalleMeta();
		}
	}, [id]);

	return (
		<Container className="d-flex flex-column">
			<Title
				titulo={!isEditar ? `Nueva Meta Auditoría` : `Editar Meta Auditoría`}
				ruta={ROUTES.METAS}
			/>
			<h2 className="text-center">Semana {numeroSemanaActual}</h2>
			<h3 className="text-center">
				{inicio} al {fin}
			</h3>
			<Row className="mt-0 mb-2">
				<Col xs={12} sm={12} md={12} lg={12}>
					<Stepper
						activeStep={pasos}
						connectorStateColors
						styleConfig={estiloStep}
						connectorStyleConfig={estiloLineaAvance}
					>
						<Step index={0} label="Observaciones Libres" />
						<Step index={1} label="Asignación de Auditorías Dirigidas" />
					</Stepper>
				</Col>
			</Row>
			<Row>
				<Col
					xs={{ offset: 2, span: 8 }}
					sm={{ offset: 2, span: 8 }}
					md={{ offset: 2, span: 8 }}
					lg={{ offset: 2, span: 8 }}
				>
					<ErrorAlert />
				</Col>
			</Row>
			<form onSubmit={formik.handleSubmit}>{renderFormulario()}</form>
		</Container>
	);
}
