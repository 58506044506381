import { ChartFactRiesgoProps } from '@app/models/types/chartFactRiesgo.type';
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	ChartOptions,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const optionsFact: ChartOptions<any> = {
	indexAxis: 'x' as const,
	responsive: true,
	plugins: {
		legend: {
			display: false,
			position: 'top' as const,
		},
		title: {
			display: true,
			text: 'Factores de Riesgo',
		},
		datalabels: {
			display: true,
			color: 'white',
			formatter: Math.round,
			labels: {
				title: {
					font: {
						weight: 'bold',
						size: '13px',
					},
				},
			},
		},
	},
};

const ChartFactRiesgo = ({ factoresRiesgo }: ChartFactRiesgoProps) => {
	const labels = factoresRiesgo?.map(factorRiesgo => factorRiesgo.descripcion) ?? [];
	const data = {
		labels,
		datasets: [
			{
				label: 'Factores de Riesgo',
				data: factoresRiesgo?.map(factorRiesgo => factorRiesgo.cantidad) ?? [],
				borderColor: '#929395',
				backgroundColor: '#929395',
			},
		],
	};

	return <Bar options={optionsFact} data={data} />;
};

export default ChartFactRiesgo;
