import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { IJefaturaGerencia } from '@app/models/interfaces/jefaturasGerencias/IJefaturaGerencia';
import { IPuesto } from '@app/models/interfaces/metas/IPuesto';
import { DireccionProps } from '@app/models/types/direccion.type';
import { getAllArbolJefaturasGerenciasService } from '@app/services/organigrama.service';

export default function Direccion(props: DireccionProps) {
	const { formik } = props;
	const [puestoNivel, setPuestoNivel] = useState({
		nivUno: 0,
		nivDos: 0,
		nivTres: 0,
	});
	const [puestos, setPuestos] = useState<IPuesto>({
		direcciones: [],
		gerencias: [],
		jefaturas: [],
	});

	const obtenerDirecciones = async () => {
		try {
			const response = await getAllArbolJefaturasGerenciasService();
			setPuestos((puestos: IPuesto) => ({ ...puestos, direcciones: response }));
		} catch (ex) {
			throw ex;
		}
	};

	//i Se inyectan los valores hijos dependiendo el nivel en el que se haya seleccionado el padre
	//i Si se selecciona una dirección se buscan sus gerencias y se inyectan en el select de gerencias.
	const obtenerSubNiveles = (subNivel: IJefaturaGerencia, nivel: number) => {
		if (!!subNivel) {
			const { nivel, organigramasChildren } = subNivel;
			const nombrePuesto = nivel === 1 ? 'gerencias' : nivel === 2 && 'jefaturas';
			setPuestos((puesto: IPuesto) => ({ ...puesto, [nombrePuesto]: organigramasChildren }));
		} else {
			//i Si no hay un subnivel entonces se selecciono la opción vacia
			formik.setFieldValue(
				'direccion',
				nivel === 3 ? puestoNivel.nivDos : nivel === 2 && puestoNivel.nivUno
			);
			setPuestoNivel(niveles => ({
				...niveles,
				[nivel === 3 ? 'nivTres' : nivel === 2 ? 'nivDos' : 'nivUno']: 0,
			}));

			//i Limpiamos los campos hijos
			if (nivel === 1) {
				setPuestos((puestos: IPuesto) => ({ ...puestos, gerencias: [], jefaturas: [] }));
			} else if (nivel == 2) {
				setPuestos((puestos: IPuesto) => ({ ...puestos, jefaturas: [] }));
			}
		}
	};

	useEffect(() => {
		obtenerDirecciones();
	}, []);

	return (
		<>
			<Form.Group className="mx-3 meta__campo-direccion">
				<Form.Label>Dirección</Form.Label>
				<Form.Control
					as="select"
					value={formik.direccion}
					onChange={({ target }) => {
						formik.setFieldValue('direccion', +target.value);
						setPuestoNivel(puestosNiv => ({ ...puestosNiv, nivUno: +target.value }));
						obtenerSubNiveles(
							puestos.direcciones.find((pt: IJefaturaGerencia) => pt.id === +target.value),
							1
						);
					}}
					isInvalid={!!formik.errors.direccion}
					name="direccion"
				>
					<option value={0}>Seleccione Dirección</option>
					{puestos.direcciones.map((direccion: IJefaturaGerencia) => (
						<option key={direccion.id} value={direccion.id}>
							{direccion.descripcion}
						</option>
					))}
				</Form.Control>
				<Form.Control.Feedback className="d-block" type="invalid">
					{formik.errors.direccion}
				</Form.Control.Feedback>
			</Form.Group>
			<Form.Group className="mx-3 meta__campo-direccion--gerencia">
				<Form.Control
					as="select"
					value={formik.direccion}
					onChange={({ target }) => {
						formik.setFieldValue('direccion', +target.value);
						setPuestoNivel(puestosNiv => ({ ...puestosNiv, nivDos: +target.value }));
						obtenerSubNiveles(
							puestos.gerencias.find((pt: IJefaturaGerencia) => pt.id === +target.value),
							2
						);
					}}
					isInvalid={!!formik.errors.direccion}
					name="direccion"
				>
					<option value={0}>Seleccione Gerencia/Jefatura</option>
					{puestos.gerencias.map((direccion: IJefaturaGerencia) => (
						<option key={direccion.id} value={direccion.id}>
							{direccion.descripcion}
						</option>
					))}
				</Form.Control>
				<Form.Control.Feedback className="d-block" type="invalid">
					{formik.errors.direccion}
				</Form.Control.Feedback>
			</Form.Group>
			<Form.Group className="mx-3 meta__campo-direccion">
				<Form.Control
					as="select"
					value={formik.direccion}
					onChange={({ target }) => {
						setPuestoNivel(puestosNiv => ({ ...puestosNiv, nivTres: +target.value }));
						formik.setFieldValue('direccion', +target.value);
						obtenerSubNiveles(
							puestos.jefaturas.find((pt: IJefaturaGerencia) => pt.id === +target.value),
							3
						);
					}}
					isInvalid={!!formik.errors.direccion}
					name="direccion"
				>
					<option value={0}>Seleccione Jefatura</option>
					{puestos.jefaturas.map((direccion: IJefaturaGerencia) => (
						<option key={direccion.id} value={direccion.id}>
							{direccion.descripcion}
						</option>
					))}
				</Form.Control>
				<Form.Control.Feedback className="d-block" type="invalid">
					{formik.errors.direccion}
				</Form.Control.Feedback>
			</Form.Group>
		</>
	);
}
