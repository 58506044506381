import { useQuery } from '@tanstack/react-query';

import { getAllAreasZonasService } from '../services/areas-zonas.service';

export default function useAreasZonas() {
	return useQuery({
		queryKey: ['allAreasZonas'],
		queryFn: getAllAreasZonasService,
		refetchOnWindowFocus: false,
	});
}
