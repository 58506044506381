import http from '../../utils/http';

export const getAllGrupoSanguineoService = async () => {
	try {
		const response = await http.get('GrupoSanguineo');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
