import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { ICatalogo } from '@app/models/interfaces/catalogos/ICatalogo';
import { IFiltrosCatalogos } from '@app/models/interfaces/condicionInsegura/IFiltros';
import { AspectoObservadoProps } from '@app/models/types/aspecto-observado.type';
import { getAllCatalogosService } from '@app/services/catalogos.service';

export default function AspectoObservado(props: AspectoObservadoProps) {
	const [aspectosObservados, setAspectosObservados] = useState<ICatalogo[]>([]);
	const [subAspectosObservados, setSubAspectosObservados] = useState<ICatalogo[][]>([]);
	const [filtros, setFiltros] = useState<IFiltrosCatalogos>({
		catalogos: '',
	});
	const { formik, ramaDataAspectoObservado, setRamaDataAspectoObservado } = props;

	const obtenerAspectosObservados = async () => {
		try {
			setAspectosObservados(await getAllCatalogosService());
		} catch (ex) {
			throw ex;
		}
	};

	const obtenerRamaAspectoObservado = async (
		idAspectoObservado: number | null,
		listaAspectosObservados: ICatalogo[] = aspectosObservados
	) => {
		try {
			const ramaResponse: ICatalogo[] =
				listaAspectosObservados.find((cat: ICatalogo) => cat.id === idAspectoObservado)
					?.catalogosChildren || [];
			if (ramaResponse.length) {
				setSubAspectosObservados((catas: ICatalogo[][]) => [...catas, ramaResponse]);
			}
		} catch (ex) {
			throw ex;
		}
	};

	const removerSelectAspectoObservado = (
		indiceAspectoObservadoItem: number,
		indiceOption: number
	) => {
		if (indiceAspectoObservadoItem < 4) {
			const catalogosActualizados = subAspectosObservados.filter((val, index) => {
				val;
				return index < indiceAspectoObservadoItem;
			});
			setSubAspectosObservados(catalogosActualizados);
			formik.setFieldValue('aspectoObservado', indiceOption, false);

			//i Si ya no hay mas zonas entonces se resetea el select de área
			if (!catalogosActualizados.length) {
				setFiltros((filtros: IFiltrosCatalogos) => ({
					...filtros,
					catalogos: '',
				}));
			}
			//i Reseteamos el valor vacio
			const selectCatalogo: HTMLInputElement = document.querySelector(
				`#aspecto-observado-${indiceAspectoObservadoItem}`
			);
			selectCatalogo.value = '0';

			//i Quitamos el elemento de la rama de aspectos observados
			const ultimoAspectoSeleccionado = ramaDataAspectoObservado.findIndex(
				(ap: ICatalogo) => ap.id === indiceOption
			);
			const copiaRamaAspectosObservados = [...ramaDataAspectoObservado];
			const fragmentoRamaAspectosObservados = copiaRamaAspectosObservados.splice(
				0,
				ultimoAspectoSeleccionado === 0 ? ultimoAspectoSeleccionado + 1 : ultimoAspectoSeleccionado
			);
			setRamaDataAspectoObservado(fragmentoRamaAspectosObservados);
		}
	};

	const renderSubAspectoObservado = () => {
		return (
			<>
				{subAspectosObservados
					.map((item: ICatalogo[]) => item.filter((cat: ICatalogo) => !!!+cat.severidadId))
					.filter((aspe: ICatalogo[]) => !!aspe.length)
					.map((aspectoObservadoItem: ICatalogo[], index: number) => (
						<div
							className="mt-4 d-flex align-items-center justify-content-between"
							style={{ width: '100%' }}
							key={aspectoObservadoItem[index] ? aspectoObservadoItem[index].id : index}
						>
							<div className="d-flex flex-column w-100 position-relative">
								{index < subAspectosObservados.length - 1 &&
								index > subAspectosObservados.length - 3 ? (
									<div
										className="position-absolute"
										style={{
											right: '-35px',
											top: '5px',
										}}
									>
										<IconButton
											aria-label="Eliminar"
											size="small"
											onClick={() => {
												const selectValue: HTMLInputElement = document.querySelector(
													`#aspecto-observado-${index + 1}`
												);
												removerSelectAspectoObservado(index + 1, +selectValue.value);
											}}
										>
											<RefreshIcon fontSize="inherit" />
										</IconButton>
									</div>
								) : null}
								<Form.Control
									id={`aspecto-observado-${index + 1}`}
									as="select"
									name="aspectoObservado"
									value={formik.aspectoObservado}
									onChange={({ target }) => {
										if (+target.value > 0) {
											obtenerRamaAspectoObservado(+target.value, aspectoObservadoItem);
											formik.setFieldValue('aspectoObservado', +target.value);
											setRamaDataAspectoObservado((ramas: ICatalogo[]) => [
												...ramas,
												aspectoObservadoItem.find((asp: ICatalogo) => asp.id === +target.value),
											]);
										}
										//i Si selecciona la opción default se busca la última opción seleccionada
										//i y cambiamos el valor de formik
										if (target.value === '0') {
											const selectCatalogo: HTMLInputElement = document.querySelector(
												`#aspecto-observado-${index}`
											);
											if (selectCatalogo) {
												formik.setFieldValue('aspectoObservado', +selectCatalogo.value);
												const ramaSinUltimoItem = ramaDataAspectoObservado.filter((item, index) => {
													item;
													return index < ramaDataAspectoObservado.length - 1;
												});
												setRamaDataAspectoObservado(ramaSinUltimoItem);
											}
										}
									}}
									isInvalid={!!formik.errors.aspectoObservado}
									disabled={index !== subAspectosObservados.length - 1}
								>
									<option value={0}>{`Seleccione Nivel ${
										aspectoObservadoItem.length ? aspectoObservadoItem[0]?.nivel : ''
									}`}</option>
									{aspectoObservadoItem.length && (
										<>
											{aspectoObservadoItem.map((cata: ICatalogo) => (
												<option key={cata.id} value={cata.id}>
													{cata.descripcion}
												</option>
											))}
										</>
									)}
								</Form.Control>
								<Form.Control.Feedback className="d-block" type="invalid">
									{formik.errors.aspectoObservado}
								</Form.Control.Feedback>
							</div>
						</div>
					))}
			</>
		);
	};

	useEffect(() => {
		obtenerRamaAspectoObservado(filtros.catalogos ? +filtros.catalogos : null);
	}, [filtros]);

	useEffect(() => {
		obtenerAspectosObservados();
		return () => {
			setFiltros({
				catalogos: '',
			});
			setSubAspectosObservados([]);
		};
	}, []);

	return (
		<>
			<Form.Group>
				<div
					className="d-flex align-items-center justify-content-between"
					style={{ width: '100%' }}
				>
					<Form.Label>
						Aspecto Observado<span className="meta__required">*</span>
					</Form.Label>
					<IconButton
						aria-label="Eliminar"
						size="small"
						onClick={() => {
							setSubAspectosObservados([]);
							setFiltros(filtros => ({ ...filtros, catalogos: '' }));
							const elemento: HTMLInputElement = document.querySelector('#aspecto-observado-0');
							elemento.value = '0';
							formik.setFieldValue('aspectoObservado', 0);
							setRamaDataAspectoObservado([]);
						}}
					>
						<PlaylistRemoveIcon fontSize="inherit" />
					</IconButton>
				</div>
				<Form.Control
					id="aspecto-observado-0"
					as="select"
					value={formik.aspectoObservado}
					onChange={({ target }) => {
						setFiltros((filtros: IFiltrosCatalogos) => ({
							...filtros,
							catalogos: target.value,
						}));
						formik.setFieldValue('aspectoObservado', +target.value);
						setRamaDataAspectoObservado((ramas: ICatalogo[]) => [
							...ramas,
							aspectosObservados.find((asp: ICatalogo) => asp.id === +target.value),
						]);
					}}
					isInvalid={!!formik.errors.aspectoObservado}
					disabled={!!subAspectosObservados.length}
					name="aspectoObservado"
				>
					<option value={0}>Seleccione Aspecto Observado</option>
					{aspectosObservados.map((cat: ICatalogo) => (
						<option key={cat.id} value={cat.id}>
							{cat.descripcion}
						</option>
					))}
				</Form.Control>
				<Form.Control.Feedback className="d-block" type="invalid">
					{formik.errors.aspectoObservado}
				</Form.Control.Feedback>
			</Form.Group>
			<>{renderSubAspectoObservado()}</>
		</>
	);
}
