import AddIcon from '@mui/icons-material/Add';
import DescriptionIcon from '@mui/icons-material/Description';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom';

import useRole from '@app/hooks/useRole';
import { IJefaturaGerencia } from '@app/models/interfaces/jefaturasGerencias/IJefaturaGerencia';
import {
	getFiltroJefaturasService,
	getRamaJefaturasService,
} from '@app/services/organigrama.service';
import AgregarAreaZonaModal from '../../components/Modals/AgregarAreaZonaModal';
import AgregarCatalogoModal from '../../components/Modals/AgregarCatalgoModal';
import AgregarReaccionModal from '../../components/Modals/AgregarReaccionModal';
import { ROUTES } from '../../constants/routes';
import useData from '../../hooks/useData';
import { IDataContext } from '../../models/IDataContext';
import { RUTAS_CATALOGOS } from '../../models/enums/rutasCatalogos';
import { IAreaZona } from '../../models/interfaces/areasZonas/IAreaZona';
import { ICatalogo } from '../../models/interfaces/catalogos/ICatalogo';
import { IReaccion } from '../../models/interfaces/reacciones/IReaccion';
import { ItemNavCatalogoProps } from '../../models/types/item-nav-catalogo.type';
import {
	getFiltroAreasZonasService,
	getRamaAreaZonaService,
} from '../../services/areas-zonas.service';
import {
	getFiltroCatalogosService,
	getRamaCatalogoService,
} from '../../services/catalogos.service';
import {
	getFiltroReaccionesService,
	getRamaReaccionesService,
} from '../../services/reacciones.service';
import AgregarJefaturaGerenciaModal from '../Modals/AgregarJefaturaGerenciaModal';

export default function ItemNavCatalogo(props: ItemNavCatalogoProps) {
	const { tipoCatalogo } = props;

	const [estadoModal, setEstadoModal] = useState<boolean>(false);
	const [areaZonEstadoModal, setAreaZonaEstadoModal] = useState<boolean>(false);
	const [jefaturaEstadoModal, setJefaturaEstadoModal] = useState<boolean>(false);
	const [reaccionEstadoModal, setReaccionEstadoModal] = useState<boolean>(false);
	const [catalogosFiltrados, setCatalogosFiltrados] = useState<
		ICatalogo[] | IAreaZona[] | IReaccion[] | IJefaturaGerencia[]
	>([]);
	const [catalogoSeleccionado, setCatalogoSeleccionado] = useState<
		ICatalogo[] | IAreaZona[] | IReaccion[] | IJefaturaGerencia[]
	>([]);
	const { setDataFpsContext } = useData();
	const { esRole } = useRole();
	const esAdministrador = esRole('administrador');
	const formatTipoCatalogo = tipoCatalogo.toLowerCase();

	const obtenerFiltrosCatalogos = async (filtro: string = '') => {
		try {
			if (!!filtro) {
				let response = null;
				if (formatTipoCatalogo === RUTAS_CATALOGOS.AREAS_ZONAS) {
					response = await getFiltroAreasZonasService(filtro);
				} else if (formatTipoCatalogo === RUTAS_CATALOGOS.CATALOGOS) {
					response = await getFiltroCatalogosService(filtro);
				} else if (formatTipoCatalogo === RUTAS_CATALOGOS.REACCIONES) {
					response = await getFiltroReaccionesService(filtro);
				} else if (formatTipoCatalogo === RUTAS_CATALOGOS.JEFATURAS_GERENCIAS) {
					response = await getFiltroJefaturasService(filtro);
				}
				setCatalogosFiltrados(response);
			}
		} catch (ex) {
			throw ex;
		}
	};

	const obtenerRamaBuscada = async (idCatalogo: number) => {
		try {
			let response = null;
			let propiedadBuscada = '';
			if (formatTipoCatalogo === RUTAS_CATALOGOS.AREAS_ZONAS) {
				response = await getRamaAreaZonaService(idCatalogo);
				propiedadBuscada = 'ramaAreaZona';
			} else if (formatTipoCatalogo === RUTAS_CATALOGOS.CATALOGOS) {
				response = await getRamaCatalogoService(idCatalogo);
				propiedadBuscada = 'ramaCatalogo';
			} else if (formatTipoCatalogo === RUTAS_CATALOGOS.REACCIONES) {
				response = await getRamaReaccionesService(idCatalogo);
				propiedadBuscada = 'ramaReaccion';
			} else if (tipoCatalogo.toLowerCase() === RUTAS_CATALOGOS.JEFATURAS_GERENCIAS) {
				//i Llamamos el endpoint de jefaturas/gerencias
				response = await getRamaJefaturasService(idCatalogo);
				propiedadBuscada = 'ramaJefaturaGerencia';
			}
			setDataFpsContext((dataFps: IDataContext) => ({
				...dataFps,
				[propiedadBuscada]: response,
			}));
		} catch (ex) {
			throw ex;
		}
	};

	const labelSubNav = () => {
		if (formatTipoCatalogo === RUTAS_CATALOGOS.AREAS_ZONAS) {
			return { label: 'Área o Zona', modal: setAreaZonaEstadoModal };
		} else if (formatTipoCatalogo === RUTAS_CATALOGOS.CATALOGOS) {
			return { label: 'Descripción', modal: setEstadoModal };
		} else if (formatTipoCatalogo === RUTAS_CATALOGOS.JEFATURAS_GERENCIAS) {
			return { label: 'Estructura', modal: setJefaturaEstadoModal };
		} else if (formatTipoCatalogo === RUTAS_CATALOGOS.REACCIONES) {
			return { label: 'Reacción', modal: setReaccionEstadoModal };
		}
	};

	const descripcionCatalogoSeleccionado = (cat: any[] = []) => {
		setCatalogoSeleccionado([...cat]);
		const [catalogoObtenido] = cat;
		if (catalogoObtenido) {
			obtenerRamaBuscada(catalogoObtenido.id);
		}
	};

	useEffect(() => {
		setCatalogosFiltrados([]);
		setCatalogoSeleccionado([]);
		obtenerFiltrosCatalogos();
	}, [tipoCatalogo]);

	useEffect(() => {
		return () => {
			setEstadoModal(false);
			setAreaZonaEstadoModal(false);
		};
	}, []);

	return (
		<>
			<Row className="w-75">
				{tipoCatalogo.toLowerCase() === RUTAS_CATALOGOS.MOTS ? (
					<div className="d-flex align-items-center justify-content-end">
						<Link to={ROUTES.MOTS}>
							<Button variant="default">
								<DescriptionIcon fontSize="inherit" />
								&nbsp;&nbsp;MOTS
							</Button>
						</Link>
						&nbsp;&nbsp;
						<Link to={ROUTES.CATALOGOS_MOTS}>
							<Button variant="default">
								<MenuBookIcon fontSize="inherit" />
								&nbsp;&nbsp;Catálogos MOTS
							</Button>
						</Link>
					</div>
				) : (
					<>
						<Col
							xs={{ span: 5, offset: 2 }}
							sm={{ span: 5, offset: 2 }}
							md={{ span: 5, offset: 2 }}
							lg={{ span: 5, offset: 2 }}
						>
							<InputGroup>
								<Typeahead
									id="single-typeahead"
									clearButton
									filterBy={['descripcion']}
									labelKey="descripcion"
									onInputChange={(param: string) => obtenerFiltrosCatalogos(param)}
									onChange={descripcionCatalogoSeleccionado}
									options={catalogosFiltrados}
									selected={catalogoSeleccionado}
									renderMenuItemChildren={(
										option: ICatalogo | IAreaZona | IJefaturaGerencia,
										{ text }
									) => (
										<div>
											<Highlighter
												highlightClassName="bg-transparent font-weight-bold p-0"
												search={text}
											>
												{option.descripcion}
											</Highlighter>
											<div>
												<div>
													<small>
														Nivel:{' '}
														<Highlighter
															highlightClassName="bg-transparent font-weight-bold p-0"
															search={text}
														>
															{option.codigo}
														</Highlighter>
													</small>
												</div>
											</div>
										</div>
									)}
									placeholder={`Buscar ${labelSubNav().label}`}
								/>
								<InputGroup.Text id="basic-addon1">
									<SearchIcon />
								</InputGroup.Text>
							</InputGroup>
						</Col>
						{esAdministrador && (
							<Col
								className="d-flex align-items-center"
								xs={{ span: 5 }}
								sm={{ span: 5 }}
								md={{ span: 5 }}
								lg={{ span: 5 }}
							>
								<Button variant="default" onClick={() => labelSubNav().modal(true)}>
									<AddIcon />
									&nbsp; Agregar {labelSubNav().label}
								</Button>
							</Col>
						)}
					</>
				)}
			</Row>
			<AgregarCatalogoModal
				estadoModal={estadoModal}
				cambiarEstadoModal={setEstadoModal}
				tituloModal="Nueva Descripción"
				idCatalogo={null}
				nivel={null}
			/>
			<AgregarAreaZonaModal
				estadoModal={areaZonEstadoModal}
				cambiarEstadoModal={setAreaZonaEstadoModal}
				tituloModal="Nueva Área o Zona"
				idAreaZona={null}
				nivel={null}
			/>
			<AgregarReaccionModal
				estadoModal={reaccionEstadoModal}
				cambiarEstadoModal={setReaccionEstadoModal}
				tituloModal="Nueva Reacción"
				idReaccion={null}
				nivel={null}
			/>
			<AgregarJefaturaGerenciaModal
				estadoModal={jefaturaEstadoModal}
				cambiarEstadoModal={setJefaturaEstadoModal}
				tituloModal="Nueva Estructura"
				idJefaturaGerencia={null}
				nivel={null}
			/>
		</>
	);
}
