import { useQuery } from '@tanstack/react-query';

import { getAllReaccionesService } from '@app/services/reacciones.service';

export default function useReacciones() {
	return useQuery({
		queryKey: ['reacciones'],
		queryFn: getAllReaccionesService,
		refetchOnWindowFocus: false,
	});
}
