import { getAuditoriaByIdService } from '@app/services/auditorias.service';
import { useQuery } from '@tanstack/react-query';

export default function useAuditoria(idAuditoria: number = 0) {
	return useQuery({
		queryKey: ['detalle-auditoria'],
		queryFn: async () => await getAuditoriaByIdService(idAuditoria),
		refetchOnWindowFocus: false,
		enabled: !!idAuditoria,
	});
}
