import { useState } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';

import { TablaUsuarios } from '@app/components/Usuarios';
import Title from '../components/Title';
import useError from '../hooks/useError';

import '../styles/Usuarios.scss';

enum KEYS {
	USUARIOS = 'Usuarios',
	AUDITORES = 'Auditores',
}

export default function Usuarios() {
	const [activeKey, setActiveKey] = useState<string>(KEYS.AUDITORES);
	const { ErrorAlert, setError } = useError();

	return (
		<Container className="usuarios d-flex flex-column">
			<ErrorAlert />
			<Title titulo="Administración de Usuarios" />
			<Tabs
				className="mt-5"
				activeKey={activeKey}
				onSelect={(key: string) => setActiveKey(key)}
				id="usuarios-keys"
			>
				<Tab eventKey={KEYS.AUDITORES} title={KEYS.AUDITORES}>
					<TablaUsuarios tipoUsuario={activeKey} setError={setError} />
				</Tab>
				{/* <Tab eventKey={KEYS.USUARIOS} title={KEYS.USUARIOS}>
					<TablaUsuarios tipoUsuario={activeKey} setError={setError} />
				</Tab> */}
			</Tabs>
		</Container>
	);
}
