import {
	IAgregarCatalogoMots,
	IEditarCatalogoMots,
} from '../../models/interfaces/mots/IAgregarCatalogoMots';
import http from '../../utils/http';

export const getAllAccesorioSeguridadService = async () => {
	try {
		const response = await http.get('AccesorioSeguridad');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addAccesorioSeguridadService = async (dataRequest: IAgregarCatalogoMots) => {
	try {
		const response = await http.post('AccesorioSeguridad', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editAccesorioSeguridadService = async (dataRequest: IEditarCatalogoMots) => {
	try {
		const response = await http.put(`AccesorioSeguridad/${dataRequest.id}`, dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteAccesorioSeguridadService = async (id: number) => {
	try {
		const response = await http.delete(`AccesorioSeguridad/${id}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
