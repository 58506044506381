import { ErrorMessages } from '@app/constants/errorMessages';
import * as Yup from 'yup';

export const metaValidationSchema = (isEditar: boolean = false) =>
	Yup.object().shape({
		semana: Yup.number()
			.max(52, ErrorMessages.MaxLengthNumber(52))
			.min(1, ErrorMessages.MinLengthNumber(1)),
		semanaInicio: Yup.number()
			.max(52, ErrorMessages.MaxLengthNumber(52))
			.min(1, ErrorMessages.MinLengthNumber(1)),
	    semanaFin: Yup.number()
			.max(52, ErrorMessages.MaxLengthNumber(52))
			.min(1, ErrorMessages.MinLengthNumber(1)),
		anio: Yup.number()
			.required(ErrorMessages.Required)
			.max(2099, ErrorMessages.MaxLengthNumber(2099))
			.min(1900, ErrorMessages.MinLengthNumber(1900)),
		libres: Yup.number(),
		reforzamiento: Yup.number(),
		repetirMeta: Yup.boolean(),
		aspectoObservado: isEditar ? Yup.number() : Yup.number().min(1, ErrorMessages.Required),
		metasDirigidas: Yup.array(),
	});
