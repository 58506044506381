import { IGrafica } from '@app/models/interfaces/graficas/IGraficas';
import http from '../utils/http';

export const getGraficaDashboardService = async (mesAnioInicio: string, mesAnioTermino: string, direccionJefatura: number) => {
	try {
		const response = await http.get<IGrafica>(`EstadoAuditoria`, {
			params: {
				mesAnioInicio,
				mesAnioTermino,
				direccionJefatura
			}
		});
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};