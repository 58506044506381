import {
	IAgregarCatalogoNormaMots,
	IEditarCatalogoNormaMots,
} from '../../models/interfaces/mots/IAgregarCatalogoMots';
import http from '../../utils/http';

export const getAllNormasService = async () => {
	try {
		const response = await http.get('Norma');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addNormaService = async (dataRequest: IAgregarCatalogoNormaMots) => {
	try {
		const response = await http.post('Norma', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editNormaService = async (dataRequest: IEditarCatalogoNormaMots) => {
	try {
		const response = await http.put(`Norma/${dataRequest.id}`, dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteNormaService = async (id: number) => {
	try {
		const response = await http.delete(`Norma/${id}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
