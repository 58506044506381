//i Propiedades de estilo para el stepper
export const estiloStep = {
	activeBgColor: '#D9D9D9',
	activeTextColor: '#999999',
	completedBgColor: '#50748A',
	completedTextColor: '#FFFFFF',
	inactiveBgColor: '#D9D9D9',
	inactiveTextColor: '#999999',
	size: '40px',
	circleFontSize: '24px',
	labelFontSize: '15px',
	borderRadius: '50%',
	fontWeight: '500',
};

//i propiedades de estilo para la linea de avance
export const estiloLineaAvance = {
	disabledColor: '#000000',
	activeColor: '#000000',
	completedColor: '#000000',
	size: '1px',
	stepSize: '2em',
	style: 'solid',
};
