import { getRolesService } from '@app/services/usuarios.service';
import { useQuery } from '@tanstack/react-query';

export default function useRoles() {
	return useQuery({
		queryKey: ['roles'],
		queryFn: getRolesService,
		refetchOnWindowFocus: false,
	});
}
