import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactElement, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import FondoLogin from './assets/images/fondo.jpg';
import FooterComponent from './components/Footer';
import NavMenuComponent from './components/NavMenu';
import ItemNavCatalogoComponent from './components/SubNavMenu/ItemNavCatalogo';
import SubNavMenuComponent from './components/SubNavMenu/SubNavMenu';
import { DEFAULT_AUDITORIA_DATA_CONTEXT } from './constants/defaultAuditoriaDataContext';
import { DEFAULT_FPS_DATA_CONTEXT } from './constants/defaultFpsDataContext';
import { queryClient } from './constants/queryClient';
import { ROUTES } from './constants/routes';
import SesionContext from './context/DataContext';
import useNavegacion from './hooks/useNavegacion';
import {
	IDataAuditoriaContext,
	IDataContext,
	IDataFpsContext,
	IUsuarioSesionContext,
} from './models/IDataContext';
import { RUTAS_CATALOGOS } from './models/enums/rutasCatalogos';
import AmbientalPage from './pages/Ambiental';
import AreasZonasPage from './pages/AreasZonas';
import CambiarContrasenaPage from './pages/CambiarContrasena';
import AspectosObservablesPage from './pages/Catalogos';
import { CrearMotsPage } from './pages/CrearMots/CrearMotsPage';
import LoginPage from './pages/Home';
import HomePage from './pages/Inicio';
import JefaturasGerenciasPage from './pages/JefaturasGerencias';
import MetasPage from './pages/Metas';
import MotsPage from './pages/Mots';
import CatalogoMotsPage from './pages/Mots/CatalogoMots';
import NoEncontradoPage from './pages/NoEncontrado';
import RegistroMetaPage from './pages/NuevaMeta';
import ReaccionesPage from './pages/Reacciones';
import RegistroUsuarioPage from './pages/RegistroUsuario';
import ReportesPage from './pages/Reportes';
import UsuariosPage from './pages/Usuarios';
import AuditoriaLibrePage from './pages/auditorias/AuditoriaLibre';
import AuditoriaOtroTipo from './pages/auditorias/AuditoriaOtroTipo';
import TablaAuditorias from './pages/auditorias/TablaAuditorias';
import CatalogoGenericoPage from './pages/catalogos/CatalogoGenerico';
import { getAllPersonalService } from './services/personal.service';

function App() {
	const [dataUser, setDataUser] = useState<IUsuarioSesionContext>({
		usuario: JSON.parse(localStorage.getItem('credenciales'))?.usuario,
		roles: JSON.parse(localStorage.getItem('credenciales'))?.roles,
	});
	const [dataFps, setDataFps] = useState<IDataFpsContext>(DEFAULT_FPS_DATA_CONTEXT);
	const [dataAuditoria, setDataAuditoria] = useState<IDataAuditoriaContext>(
		DEFAULT_AUDITORIA_DATA_CONTEXT
	);

	const [elementoSubNav, setElementoSubNav] = useState<ReactElement | null>(null);
	const contextData: IDataContext = {
		dataUser,
		setDataUser,
		dataFpsContext: dataFps,
		dataAuditoriaContext: dataAuditoria,
		setDataFpsContext: setDataFps,
		setDataAuditoriaContext: setDataAuditoria,
	};
	const location = useLocation();

	const fondoLogin = (user: string) => {
		if (!!!user && location.pathname === ROUTES.ROOT) {
			return {
				minHeight: '100vh',
				backgroundImage: `url('${FondoLogin}')`,
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
			};
		}
		return { minHeight: '100vh' };
	};

	const tipoCatalogoRender = (ruta: string) => {
		switch (ruta.toLowerCase()) {
			case ROUTES.CATALOGOS:
				return RUTAS_CATALOGOS.CATALOGOS;
			case ROUTES.AREAS_ZONAS:
				return RUTAS_CATALOGOS.AREAS_ZONAS;
			case ROUTES.REACCIONES:
				return RUTAS_CATALOGOS.REACCIONES;
			case ROUTES.JEFATURAS_GERENCIAS:
				return RUTAS_CATALOGOS.JEFATURAS_GERENCIAS;
			default:
				return RUTAS_CATALOGOS.MOTS;
		}
	};

	const mostrarSubMenu = (ruta: string) => {
		if (
			ruta === ROUTES.CATALOGOS ||
			ruta === ROUTES.AREAS_ZONAS ||
			ruta === ROUTES.REACCIONES ||
			ruta === ROUTES.JEFATURAS_GERENCIAS ||
			ruta.includes(ROUTES.MOTS)
		) {
			setElementoSubNav(
				<SubNavMenuComponent>
					<ItemNavCatalogoComponent tipoCatalogo={tipoCatalogoRender(ruta)} />
				</SubNavMenuComponent>
			);
		} else {
			return setElementoSubNav(null);
		}
	};

	const obtenerPersonalPeCo = async () => {
		try {
			const response = await getAllPersonalService();
			setDataAuditoria(audit => ({ ...audit, personal: response }));
		} catch (ex) {
			throw ex;
		}
	};

	useEffect(() => {
		mostrarSubMenu(location.pathname);
	}, [location]);

	useEffect(() => {
		obtenerPersonalPeCo();
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<SesionContext.Provider value={contextData}>
				<main
					className="d-flex flex-column justify-content-between"
					style={fondoLogin(dataUser.usuario)}
				>
					{!!dataUser.usuario && (
						<>
							<NavMenuComponent />
							{elementoSubNav}
						</>
					)}
					<div className="flex-grow-1">
						<Routes>
							<Route
								path={ROUTES.ROOT}
								element={
									!!dataUser.usuario ? (
										<Navigate
											to={
												dataUser.roles.includes('auditor') ? ROUTES.TABLA_AUDITORIAS : ROUTES.HOME
											}
										/>
									) : (
										<LoginPage />
									)
								}
							/>
							<Route path={ROUTES.HOME} element={useNavegacion(<HomePage />)} />
							<Route path={ROUTES.USUARIOS} element={useNavegacion(<UsuariosPage />)} />
							<Route
								path={`${ROUTES.REGISTRAR_USUARIO}/:tipo`}
								element={useNavegacion(<RegistroUsuarioPage />)}
							/>
							<Route
								path={`${ROUTES.REGISTRAR_USUARIO}/:tipo/:id`}
								element={useNavegacion(<RegistroUsuarioPage />)}
							/>
							<Route
								path={`${ROUTES.TABLA_AUDITORIAS}`}
								element={useNavegacion(<TablaAuditorias />)}
							/>
							<Route
								path={ROUTES.AUDITORIA_LIBRE}
								element={useNavegacion(<AuditoriaLibrePage />)}
							/>
							<Route
								path={`${ROUTES.AUDITORIA_LIBRE}/:id`}
								element={useNavegacion(<AuditoriaLibrePage />)}
							/>
							<Route
								path={ROUTES.AUDITORIAS_OTRO_TIPO}
								element={useNavegacion(<AuditoriaOtroTipo />)}
							/>
							<Route path={ROUTES.AMBIENTAL} element={useNavegacion(<AmbientalPage />)} />
							<Route path={ROUTES.REPORTES} element={useNavegacion(<ReportesPage />)} />
							<Route path={ROUTES.CATALOGOS} element={useNavegacion(<AspectosObservablesPage />)} />
							<Route
								path={ROUTES.JEFATURAS_GERENCIAS}
								element={useNavegacion(<JefaturasGerenciasPage />)}
							/>
							<Route path={ROUTES.AREAS_ZONAS} element={useNavegacion(<AreasZonasPage />)} />
							<Route path={ROUTES.REACCIONES} element={useNavegacion(<ReaccionesPage />)} />
							<Route path={ROUTES.METAS} element={useNavegacion(<MetasPage />)} />
							<Route path={ROUTES.REGISTRAR_META} element={useNavegacion(<RegistroMetaPage />)} />
							<Route
								path={`${ROUTES.EDITAR_META}/:id`}
								element={useNavegacion(<RegistroMetaPage />)}
							/>
							<Route
								path={ROUTES.FACTOR_RIESGO}
								element={useNavegacion(<CatalogoGenericoPage />)}
							/>
							<Route path={ROUTES.PRECURSORES} element={useNavegacion(<CatalogoGenericoPage />)} />
							<Route path={ROUTES.MOTS} element={useNavegacion(<MotsPage />)} />
							<Route path={ROUTES.CATALOGOS_MOTS} element={useNavegacion(<CatalogoMotsPage />)} />
							<Route path={ROUTES.CREAR_MOTS} element={useNavegacion(<CrearMotsPage />)} />
							<Route
								path={ROUTES.CAMBIAR_CONTRASENA}
								element={useNavegacion(<CambiarContrasenaPage />)}
							/>
							<Route path={ROUTES.NO_ENCONTRADO} element={<NoEncontradoPage />} />
						</Routes>
					</div>
					<ReactQueryDevtools />
					<FooterComponent />
				</main>
			</SesionContext.Provider>
		</QueryClientProvider>
	);
}

export default App;
