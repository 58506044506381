import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import EditarCatalogoMots from '../../components/Modals/MotsModals/AgregarCatalogoMots';
import EliminarCatalogoMots from '../../components/Modals/MotsModals/EliminarCatalogoMots';
import { TitulosTabsCatalogosMots } from '../../models/enums/titulosTabsCatalogosMots.enum';
import { IEditarCatalogoNormaMots } from '../../models/interfaces/mots/IAgregarCatalogoMots';
import { ICatalogoGeneralMots } from '../../models/interfaces/mots/ICatalogoMots';
import { TablaCatalogoMotsProps } from '../../models/types/tabla-catalogo-mots.type';
import { deleteEquipoAutomotorService } from '../../services/mots/equipo-automotor.service';
import { deleteEquipoProteccionPersonalService } from '../../services/mots/equipo-proteccion-personal.service';
import { deleteHerramientaService } from '../../services/mots/herramienta.service';
import { getAllNormasService } from '../../services/mots/norma.service';
import { deletePermisoAplicableService } from '../../services/mots/permiso-aplicable.service';

export default function TablaCatalogosMots(props: TablaCatalogoMotsProps) {
	const [listaNormas, setListaNormas] = useState<IEditarCatalogoNormaMots[]>([]);
	const [estadoModalEliminar, setEstadoModalEliminar] = useState<boolean>(false);
	const [estadoModalEditar, setEstadoModalEditar] = useState<boolean>(false);
	const [catalogoSeleccionado, setCatalogoSeleccionado] = useState<ICatalogoGeneralMots | null>(
		null
	);

	const { listaCatalogos, cambiarListaCatalogos, tipoCatalogo, tabActiva } = props;
	const campoTipoEmpleado = props?.nombreCampo || '';

	const obtenerNormas = async () => {
		try {
			const response = await getAllNormasService();
			setListaNormas(response);
		} catch (ex) {
			throw ex;
		}
	};

	//i Se obtiene solo el id de la norma entonces la buscamos para obtener
	//i el nombre de la misma.
	const obtenerNombreNorma = (normaId: number) => {
		if (listaNormas.length) {
			if (!!normaId) {
				const normaEncontrada: IEditarCatalogoNormaMots = listaNormas.find(
					(norm: IEditarCatalogoNormaMots) => norm.id === normaId
				);
				return normaEncontrada.clave;
			}
			return '';
		}
		return '';
	};

	const recuperarCatalgoEliminado = async (elemento: ICatalogoGeneralMots) => {
		try {
			llamadaEnpointEliminar(elemento);
		} catch (ex) {
			throw ex;
		}
	};

	const recuperarCatalogoEditado = async (catalogo: ICatalogoGeneralMots) => {
		try {
			const listaCatalogosActualizados: ICatalogoGeneralMots[] = listaCatalogos.map(
				(cata: ICatalogoGeneralMots) => (cata.id === catalogo.id ? catalogo : cata)
			);
			cambiarListaCatalogos(listaCatalogosActualizados);
		} catch (ex) {
			throw ex;
		}
	};

	//i Dependiendo del nombre del catálogo es el endpoint que se llama para eliminarlo
	const llamadaEnpointEliminar = async (requestData: ICatalogoGeneralMots) => {
		const nombreCat = tabActiva.toLowerCase();
		if (nombreCat.includes('protección')) {
			return await deleteEquipoProteccionPersonalService(+requestData.id);
		} else if (nombreCat.includes('aplicable')) {
			return await deletePermisoAplicableService(+requestData.id);
		} else if (nombreCat.includes('herramienta')) {
			return await deleteHerramientaService(+requestData.id);
		} else if (nombreCat.includes('vehiculo')) {
			return await deleteEquipoAutomotorService(+requestData.id);
		}
		const listaCatalogosActualizados: ICatalogoGeneralMots[] = listaCatalogos.filter(
			(cata: ICatalogoGeneralMots) => cata.id !== catalogoSeleccionado.id
		);
		cambiarListaCatalogos(listaCatalogosActualizados);
		setEstadoModalEliminar(false);
	};

	//i Cuando el tab activo sea el de capacitación se buscan las normas
	useEffect(() => {
		if (tabActiva.toLowerCase().includes('capacitacion')) {
			obtenerNormas();
		}
	}, [tabActiva]);

	useEffect(() => {
		return () => {
			setEstadoModalEliminar(false);
			setCatalogoSeleccionado(null);
		};
	}, []);

	return (
		<>
			<Table striped bordered>
				<thead>
					<tr>
						{tipoCatalogo !== 'empleado' && (
							<>
								<th>Descripción</th>
								<th>Activo</th>
							</>
						)}
						{tipoCatalogo === 'factor' && (
							<>
								<th>Clasificación</th>
								<th>Ponderación</th>
							</>
						)}
						{tabActiva.toLowerCase().includes('norma') && <th>Clave</th>}
						{tabActiva.toLowerCase().includes('capacitacion') && (
							<>
								<th>Detalles</th>
								<th>Norma</th>
							</>
						)}
						{tipoCatalogo === 'empleado' && (
							<>
								{campoTipoEmpleado === 'perfil' && <th>Perfil</th>}
								{campoTipoEmpleado === 'observacionMedica' && <th>Observación Médica</th>}
							</>
						)}
						{!tabActiva.toLowerCase().includes('sanguineo') && (
							<>
								<th>Acciones</th>
							</>
						)}
					</tr>
				</thead>
				<tbody>
					{listaCatalogos.length ? (
						<>
							{listaCatalogos.map((cata: ICatalogoGeneralMots) => (
								<tr key={cata.id}>
									{tipoCatalogo !== 'empleado' && (
										<>
											<td>{cata.descripcion}</td>
											<td>{cata.activo ? 'Activo' : 'Inactivo'}</td>
										</>
									)}
									{tabActiva.toLowerCase().includes('norma') && <td>{cata?.clave}</td>}
									{tabActiva.toLowerCase().includes('capacitacion') && (
										<>
											<td>{cata?.detalles}</td>
											<td>{obtenerNombreNorma(cata?.normaId)}</td>
										</>
									)}
									{tipoCatalogo === 'factor' && (
										<>
											<td>{cata?.clasificacion}</td>
											<td>{cata?.ponderacion}</td>
										</>
									)}
									{tipoCatalogo === 'empleado' && (
										<>
											{campoTipoEmpleado === 'perfil' && <td>{cata?.perfil}</td>}
											{campoTipoEmpleado === 'observacionMedica' && (
												<td>{cata?.tipoObservacion}</td>
											)}
										</>
									)}
									{!tabActiva.toLowerCase().includes('sanguineo') && (
										<td>
											<IconButton
												aria-label="Editar"
												onClick={() => {
													setCatalogoSeleccionado(cata);
													setEstadoModalEditar(true);
												}}
												size="small"
											>
												<EditIcon fontSize="inherit" />
											</IconButton>
											{/* &nbsp;
											<IconButton
												aria-label="Eliminar"
												onClick={() => {
													setCatalogoSeleccionado(cata);
													setEstadoModalEliminar(true);
												}}
												size="small"
											>
												<DeleteIcon fontSize="inherit" />
											</IconButton> */}
										</td>
									)}
								</tr>
							))}
						</>
					) : (
						<tr>
							<td colSpan={5}>No hay catálogos registrados.</td>
						</tr>
					)}
				</tbody>
			</Table>
			<EditarCatalogoMots
				nombreCatalogo={TitulosTabsCatalogosMots[tabActiva]}
				estadoModal={estadoModalEditar}
				cambiarEstadoModal={setEstadoModalEditar}
				recuperarData={recuperarCatalogoEditado}
				tipoCatalogo={tipoCatalogo}
				editar={true}
				pestanaActiva={tabActiva}
				dataEditar={catalogoSeleccionado}
			/>
			<EliminarCatalogoMots
				tituloModal={TitulosTabsCatalogosMots[tabActiva]}
				estadoModal={estadoModalEliminar}
				cambiarEstadoModal={setEstadoModalEliminar}
				textoCuerpo={`¿Está seguro que quiere eliminar ${catalogoSeleccionado?.descripcion || ''}?`}
				recuperarData={recuperarCatalgoEliminado}
				elemento={catalogoSeleccionado}
			/>
		</>
	);
}
