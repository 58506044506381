import { useQuery } from '@tanstack/react-query';

import { getGraficaDashboardService } from '@app/services/grafica.service';

export default function useGraficaDashboard({ mesAnioInicio, mesAnioTermino, direccionJefatura }) {
	const response = useQuery({
		queryKey: ['grafica-dashboard', mesAnioInicio, mesAnioTermino, direccionJefatura],
		queryFn: () => getGraficaDashboardService(mesAnioInicio, mesAnioTermino, direccionJefatura),
		staleTime: 0,
	});

	return response;
}
