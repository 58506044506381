import { IDataAuditoriaContext } from '../models/IDataContext';

export const DEFAULT_AUDITORIA_DATA_CONTEXT: IDataAuditoriaContext = {
	detalleAuditoria: {
		id: 0,
		fecha: '',
		catalogoId: 0,
		catalogo: '',
		catalogoRaiz: '',
		areaZonaId: 0,
		areaZona: '',
		abierto: false,
		jefeInmediatoEnterado: false,
		reaccionId: 0,
		estado: '',
		tipoAuditoriaId: 0,
		tipoAuditoria: '',
		esOtroTipoObservacion: false,
		descripcion: '',
		tipoLevantamiento: '',
		levantamientoExtemporaneo: false,
		severidadId: 0,
		severidad: '',
		fotosEvidencias: [],
		responsables: [],
		catalogoRamaAsc: [],
		areaZonaRamaAsc: [],
		direccion: '',
		organigramaId: 0,
		trabajador: '',
		empresa: '',
		esRechadada: false,
		descripcionRechazoTarea: '',
		accionesTomadasRechazoTarea: '',
		factorDeRiesgoFpsId: 0,
		factorRiesgo: '',
		precursorFpsId: 0,
		precursor: '',
		usuarioName: '',
	},
	editarAuditoria: false,
	numeroSemanaVariante: 0,
	semanaExtemporanea: false,
	mostrarPasoCuatro: false,
	mostrarPasoTres: false,
	prevTipoAuditoria: 1,
	personal: [],
};
