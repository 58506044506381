export const ROUTES = {
	ROOT: '/',
	HOME: '/dashboard',
	USUARIOS: '/usuarios',
	REGISTRAR_USUARIO: '/registrar-usuario',
	EDITAR_USUARIO: '/registrar-usuario/usuario',
	CATALOGOS: '/catalogos',
	AREAS_ZONAS: '/areas-zonas',
	JEFATURAS_GERENCIAS: '/jefaturas-gerencias',
	REACCIONES: '/reacciones',
	AUDITORIA_DIRIGIDA: '/auditoria-dirigida',
	AUDITORIA_LIBRE: '/auditoria-libre',
	AUDITORIAS_OTRO_TIPO: '/auditoria-otro-tipo',
	TABLA_AUDITORIAS: '/tabla-auditorias',
	ACTO_INSEGURO: '/acto-inseguro',
	REFORZAMIENTO_POSITIVO: '/reforzamiento-positivo',
	METAS: '/metas',
	REGISTRAR_META: '/nueva-meta',
	EDITAR_META: '/meta',
	AMBIENTAL: '/ambiental',
	REPORTES: '/reportes',
	FACTOR_RIESGO: '/factor-riesgo',
	PRECURSORES: '/precursores',
	MOTS: '/mots',
	CATALOGOS_MOTS: '/mots/catalogos',
	CREAR_MOTS: '/crear-mots',
	CAMBIAR_CONTRASENA: '/cambiar-contrasena',
	NO_ENCONTRADO: '*',
};
