import {
	IAgregarReaccion,
	IEditarReaccion,
} from '../models/interfaces/reacciones/IAgregarReaccion';
import http from '../utils/http';

export const getAllReaccionesService = async () => {
	try {
		const response = await http.get('CatalogoReaccion');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getRamaReaccionesService = async (reaccionId: number) => {
	try {
		const response = await http.get(
			'CatalogoReaccion/GetRamaCatalogoReaccionAscById',
			{
				params: {
					catalogoReaccionId: reaccionId,
				},
			}
		);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getFiltroReaccionesService = async (filter: string) => {
	try {
		const response = await http.get(
			'CatalogoReaccion/GetFilterCatalogoReaccion',
			{
				params: {
					filter,
				},
			}
		);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addReaccionService = async (dataRequest: IAgregarReaccion) => {
	try {
		const response = await http.post('CatalogoReaccion', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editReaccionService = async (
	dataRequest: IEditarReaccion,
	idReaccion: number
) => {
	try {
		const response = await http.put(
			`CatalogoReaccion/${idReaccion}`,
			dataRequest
		);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteReaccionService = async (idReaccion: number) => {
	try {
		const response = await http.delete(`CatalogoReaccion/${idReaccion}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
