import { POSICION } from '@app/models/enums/posicion.enum';
import { ICatalogo } from '@app/models/interfaces/catalogos/ICatalogo';
import { ICatalogoGenerico } from '@app/models/interfaces/catalogos/ICatalogoGenerico';
import { IAgregarCatalogo } from '../models/interfaces/catalogos/IAgregarCatalogo';
import { IEditarCatalogo } from '../models/interfaces/catalogos/IEditarCatalogo';
import http from '../utils/http';

export const getAllCatalogosService = async () => {
	try {
		const response = await http.get<ICatalogo[]>('Catalogo');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getCatalogoByIdService = async (idCatalogo: number) => {
	try {
		const response = await http.get<ICatalogo>(`Catalogo/${idCatalogo}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addCatalogoService = async (dataRequest: IAgregarCatalogo) => {
	try {
		const response = await http.post('Catalogo', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editCatalogoService = async (dataRequest: IEditarCatalogo, idCatalogo: number) => {
	try {
		const response = await http.put(`Catalogo/${idCatalogo}`, dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editPosicionCatalogoService = async (idCatalogo: number, posicion: POSICION) => {
	try {
		await http.put(`Catalogo/PutPosicion/${idCatalogo}/${posicion}`);
	} catch (ex) {
		throw ex;
	}
};

export const deleteCatalogoService = async (idCatalogo: number) => {
	try {
		const response = await http.delete(`Catalogo/${idCatalogo}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getFiltroCatalogosService = async (filter: string) => {
	try {
		const response = await http.get('Catalogo/GetFilterCatalogo', {
			params: {
				filter,
			},
		});
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getRamaCatalogoService = async (catalogId: number) => {
	try {
		const response = await http.get('Catalogo/GetRamaCatalogoAscById', {
			params: {
				catalogId,
			},
		});
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getTipoAuditoriaService = async () => {
	try {
		const response = await http.get('Catalogo/GetTiposAuditoria');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getCatalogoGenerico = async (isFactorRiesgo: boolean) => {
	const response = await http.get<ICatalogoGenerico[]>(
		isFactorRiesgo ? 'CatalogoFactorDeRiesgo' : 'CatalogoPrecursor'
	);
	return response.data;
};

export const addCatalogoGenerico = async (
	isFactorRiesgo: boolean,
	{ descripcion }: ICatalogoGenerico
) => {
	const response = await http.post(
		isFactorRiesgo ? 'CatalogoFactorDeRiesgo' : 'CatalogoPrecursor',
		{
			descripcion,
		}
	);
	return response.data;
};

export const updateCatalogoGenerico = async (
	isFactorRiesgo: boolean,
	catalogo: ICatalogoGenerico
) => {
	const response = await http.put(
		isFactorRiesgo
			? `CatalogoFactorDeRiesgo/ActulizaFactorDeRiesgo/${catalogo.id}`
			: `CatalogoPrecursor/ActulizaPrecursor/${catalogo.id}`,
		catalogo
	);
	return response.data;
};

export const deleteCatalogoGenerico = async (isFactorRiesgo: boolean, catalogoId: number) => {
	const response = await http.delete(
		isFactorRiesgo
			? `CatalogoFactorDeRiesgo/EliminaFactorDeRiesgo/${catalogoId}`
			: `CatalogoPrecursor/EliminaPrecursor/${catalogoId}`
	);
	return response.data;
};

export const cargarGuiaCatalogo = async (requestGuia: FormData) => {
	const response = await http.put('Catalogo/PutGuideToCatalogo', requestGuia, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response?.data;
};

export const descargarGuiaCatalogo = async (nombreGuia: string) => {
	const response = await http.get(`Catalogo/DownloadGuiaCatalogo/${nombreGuia}`, {
		responseType: 'blob',
	});
	return response?.data;
};

export const getAspectoObservableUsuarioService = async (semana: number = null) => {
	const response = await http.get<ICatalogo>('Catalogo/GetAspectoObservadoMetaDirigidaByUser', {
		params: {
			semana,
		},
	});
	return response?.data;
};

// Obtención de catálogos de manera individual
export const getFactorRiesgo = async () => {
	const response = await http.get<ICatalogoGenerico[]>('CatalogoFactorDeRiesgo');
	return response.data;
};

export const getPrecursores = async () => {
	const response = await http.get<ICatalogoGenerico[]>('CatalogoPrecursor');
	return response.data;
};
