import { ChartPrecursoresProps } from '@app/models/types/chartPrecursores.type';
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	ChartOptions,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const optionsPrec: ChartOptions<any> = {
	indexAxis: 'x' as const,
	elements: {
		bar: {
			borderWidth: 1,
		},
	},
	responsive: true,
	plugins: {
		legend: {
			display: false,
			position: 'top' as const,
		},
		title: {
			display: true,
			text: 'Precursores',
		},
		datalabels: {
			display: true,
			color: 'white',
			formatter: Math.round,
			labels: {
				title: {
					font: {
						weight: 'bold',
						size: '13px',
					},
				},
			},
		},
	},
};

const ChartPrecursores = ({ precursores }: ChartPrecursoresProps) => {
	const labels = precursores?.map(precursor => precursor.descripcion) ?? [];
	const data = {
		labels,
		datasets: [
			{
				label: 'Precursores',
				data: precursores?.map(precursor => precursor.cantidad) ?? [],
				borderColor: '#385B7D',
				backgroundColor: '#385B7D',
			},
		],
	};
	return <Bar options={optionsPrec} data={data} />;
};

export default ChartPrecursores;
