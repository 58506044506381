import {
	IAgregarCatalogoFactor,
	IEditarCatalogoFactor,
} from '../../models/interfaces/mots/IAgregarCatalogoMots';
import http from '../../utils/http';

export const getAllFactorSeveridadService = async () => {
	try {
		const response = await http.get('FactorSeveridad');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addFactorSeveridadService = async (
	dataRequest: IAgregarCatalogoFactor
) => {
	try {
		const response = await http.post('FactorSeveridad', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editFactorSeveridadService = async (
	dataRequest: IEditarCatalogoFactor
) => {
	try {
		const response = await http.put(
			`FactorSeveridad/${dataRequest.id}`,
			dataRequest
		);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteFactorSeveridadService = async (id: number) => {
	try {
		const response = await http.delete(`FactorSeveridad/${id}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
