import {
	IAgregarMeta,
	IEditarMeta,
	IEditarMetaDirigida,
	IRegistrarMeta,
} from '@app/models/interfaces/metas/IAgregarMeta';
import { IDetalleMeta } from '@app/models/interfaces/metas/IMeta';
import http from '../utils/http';

export const getAllMetasService = async () => {
	try {
		const response = await http.get<IDetalleMeta[]>('Meta');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getAllMetasDirigidas = async () => {
	const response = await http.get<IDetalleMeta[]>('Meta/GetMetasDirigidas');
	return response?.data;
};

export const getMetaById = async (idMeta: number) => {
	try {
		const response = await http.get(`Meta/${idMeta}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addMetaService = async (dataRequest: IAgregarMeta) => {
	try {
		const response = await http.post('Meta', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addMetaDirigidaService = async (requestData: IRegistrarMeta) =>
	await http.post('Meta/PostMetaDirigida', requestData);

export const editMetaService = async (dataRequest: IEditarMeta, idMeta: number) => {
	try {
		const response = await http.put(`Meta/${idMeta}`, dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editMetaDirigidaService = async (meta: IEditarMetaDirigida) => {
	const response = await http.put(`Meta/UpdateMeta/${meta.id}`, meta);
	return response?.data;
};

export const deleteMetaService = async (idMeta: number) => {
	try {
		const response = await http.delete(`Meta/${idMeta}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
