import { useQuery } from '@tanstack/react-query';

import { getAllAuditoriasOtroTipoObservacion } from '@app/services/auditorias.service';

export default function useAuditoriaOtroTipo() {
	const client = useQuery({
		queryKey: ['auditoria-otro'],
		queryFn: getAllAuditoriasOtroTipoObservacion,
		refetchOnWindowFocus: false,
		retry: 0,
		cacheTime: 0,
	});

	return client;
}
