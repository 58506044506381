import { useNormasQuery, usePermisosAplicablesQuery } from '@app/hooks/mots';
import { ICatalogoMots } from '@app/models/interfaces/mots/ICatalogoMots';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, Stack } from '@mui/material';
import classNames from 'classnames';
import { FC, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import style from './PermisosNormasForm.module.scss';

type PermisosNormasFormProps = {
	onChange?: (data: { permisos: ICatalogoMots[]; normas: ICatalogoMots[] }) => void;
};

export const PermisosNormasForm: FC<PermisosNormasFormProps> = () => {
	const { data: catalogoPermisos = [], isFetching: fetchingPermisos } =
		usePermisosAplicablesQuery();
	const [selectedPermiso, setSelectedPermiso] = useState<ICatalogoMots[]>([]);
	const [addedPermisos, setAddedPermisos] = useState<ICatalogoMots[]>([]);

	const { data: catalogoNormas = [], isFetching: fetchingNormas } = useNormasQuery();
	const [selectedNorma, setSelectedNorma] = useState<ICatalogoMots[]>([]);
	const [addedNormas, setAddedNormas] = useState<ICatalogoMots[]>([]);

	function addPermission() {
		const existingItem = addedPermisos.find(x => x.id === selectedPermiso[0]?.id);
		if (!existingItem) {
			setAddedPermisos(prev => [...prev, ...selectedPermiso]);
		}
		setSelectedPermiso([]);
	}

	function removePermission(id: string | number) {
		setAddedPermisos(prev => prev.filter(item => item.id !== id));
	}

	function addNorma() {
		const existingItem = addedNormas.find(x => x.id === selectedNorma[0]?.id);
		if (!existingItem) {
			setAddedNormas(prev => [...prev, ...selectedNorma]);
		}
		setSelectedNorma([]);
	}

	function removeNorma(id: string | number) {
		setAddedNormas(prev => prev.filter(item => item.id !== id));
	}

	return (
		<Stack>
			<p className="text-uppercase fw-bolder mb-3">Permisos Aplicables</p>
			<Grid className="mb-3" container>
				<Typeahead
					id="permisos"
					options={catalogoPermisos}
					filterBy={['descripcion']}
					labelKey="descripcion"
					placeholder="Permisos Aplicables al MOTS"
					className="flex-shrink-1 flex-grow-1 me-5"
					onChange={option => setSelectedPermiso(option as ICatalogoMots[])}
					selected={selectedPermiso}
					style={{
						flexBasis: 0,
					}}
				>
					{fetchingPermisos && (
						<div className="rbt-aux">
							<Spinner animation="grow" size="sm" />
						</div>
					)}
				</Typeahead>
				<Button onClick={addPermission} type="button" variant="secondary">
					Agregar
				</Button>
			</Grid>

			<Grid className={classNames('mb-4', style.lista)} direction="column" wrap="nowrap" container>
				{!addedPermisos.length && (
					<p className="text-muted">No se encontraron permisos aplicables registrados</p>
				)}
				{addedPermisos.map(item => (
					<Grid
						key={item.id}
						justifyContent="space-between"
						alignItems="center"
						wrap="nowrap"
						container
					>
						<span>{item.descripcion}</span>
						<Button
							onClick={() => removePermission(item.id)}
							type="button"
							variant="link"
							className="text-black"
						>
							<DeleteIcon />
						</Button>
					</Grid>
				))}
			</Grid>

			<p className="text-uppercase fw-bolder mb-3">Normatividad y/o Referencias Aplicables</p>
			<Grid className="mb-3" container>
				<Typeahead
					id="normas"
					options={catalogoNormas}
					labelKey="descripcion"
					placeholder="Normas o referencias aplicables"
					className="flex-shrink-1 flex-grow-1 me-5"
					onChange={option => setSelectedNorma(option as ICatalogoMots[])}
					selected={selectedNorma}
					style={{
						flexBasis: 0,
					}}
				>
					{fetchingNormas && (
						<div className="rbt-aux">
							<Spinner animation="grow" size="sm" />
						</div>
					)}
				</Typeahead>
				<Button onClick={addNorma} type="button" variant="secondary">
					Agregar
				</Button>
			</Grid>

			<Grid className={style.lista} direction="column" wrap="nowrap" container>
				{!addedNormas.length && (
					<p className="text-muted">No se encontraron normas o referencias registradas</p>
				)}
				{addedNormas.map(item => (
					<Grid
						key={item.id}
						justifyContent="space-between"
						alignItems="center"
						wrap="nowrap"
						container
					>
						<span>{item.descripcion}</span>
						<Button
							onClick={() => removeNorma(item.id)}
							type="button"
							variant="link"
							className="text-black"
						>
							<DeleteIcon />
						</Button>
					</Grid>
				))}
			</Grid>
		</Stack>
	);
};
