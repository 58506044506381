import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Form,
	InputGroup,
	OverlayTrigger,
	Row,
	Table,
	Tooltip,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@app/constants/routes';
import useUsuarios from '@app/hooks/useUsuarios';
import { IMensaje } from '@app/models/IMensaje';
import { IUsuario } from '@app/models/interfaces/usuarios/IUsuario';
import { TablaUsuaiosProps } from '@app/models/types/tabla-usuarios.type';
import {
	activarUsuarioExternoService,
	resetPasswordAuditorService,
} from '@app/services/usuarios.service';
import Loading from '../Loading';
import MensajeComponent from '../Mensaje';
import MostrarContrasenaModal from '../Modals/MostrarContrasenaModal';

export default function TablaUsuarios(props: TablaUsuaiosProps) {
	const { tipoUsuario, setError } = props;
	const [itemBusqueda, setItemBusqueda] = useState<string>('');
	const [vistaMontada, setVistaMontada] = useState<boolean>(false);
	const [estadoModalContrasena, setEstadoModalContrasena] = useState<boolean>(false);
	const [credenciales, setCredenciales] = useState<IUsuario>({
		id: '',
		userName: '',
		fullName: '',
		phoneNumber: '',
		email: '',
		tipo: '',
		active: true,
		nombre: '',
		empresa: '',
		password: '',
		roles: [],
	});
	const [mensaje, setMensaje] = useState<IMensaje>({
		abierto: false,
		mensaje: '',
	});
	const navigate = useNavigate();
	const {
		data: usuarios,
		refetch,
		isFetching: cargandoUsuarios,
	} = useUsuarios(tipoUsuario === 'Auditores', itemBusqueda);

	const { mutate: resetPasswordUsuarioExterno, isLoading: reseteandoUsuario } = useMutation({
		mutationFn: async (idUsuario: string) => await resetPasswordAuditorService(idUsuario),
		onSuccess: (response: IUsuario) => {
			setCredenciales(response);
			setEstadoModalContrasena(true);
			setMensaje({
				abierto: true,
				mensaje: 'Se reseteo la contraseña correctamente.',
			});
		},
		onError: error => setError(error),
	});

	const { mutate: habilitarDeshabilitarUsuarioExterno, isLoading: habilitandoUsuario } =
		useMutation({
			mutationFn: async ({
				idUsuario,
				estadoActual,
			}: {
				idUsuario: string;
				estadoActual: boolean;
			}) => await activarUsuarioExternoService(idUsuario, estadoActual),
			onSuccess: ({ active }, { idUsuario }) => {
				refetch();
				setMensaje({
					abierto: true,
					mensaje: `Se ${active ? 'habilitó' : 'deshabilitó'} ${
						usuarios.find(usuario => usuario.id === idUsuario)?.userName
					}.`,
				});
			},
			onError: error => setError(error),
		});

	useEffect(() => {
		refetch();
	}, [itemBusqueda]);

	useEffect(() => {
		setItemBusqueda('');
		refetch();
	}, [tipoUsuario]);

	useEffect(() => {
		if (!cargandoUsuarios) {
			setVistaMontada(cargandoUsuarios);
		}
	}, [cargandoUsuarios]);

	useEffect(() => {
		setVistaMontada(cargandoUsuarios);
		return () => {
			setItemBusqueda('');
			setMensaje({
				abierto: false,
				mensaje: '',
			});
			setEstadoModalContrasena(false);
			setCredenciales({
				id: '',
				userName: '',
				fullName: '',
				phoneNumber: '',
				email: '',
				tipo: '',
				active: true,
				nombre: '',
				empresa: '',
				password: '',
				roles: [],
			});
			setVistaMontada(false);
		};
	}, []);

	return (
		<>
			{(vistaMontada || habilitandoUsuario || reseteandoUsuario) && (
				<Loading
					mensaje={
						habilitandoUsuario
							? `Habilitando o Deshabilitando ${
									tipoUsuario === 'Auditores' ? 'Auditor' : 'Usuario'
							  }`
							: reseteandoUsuario
							? `Reseteando ${tipoUsuario === 'Auditores' ? 'Auditor' : 'Usuario'}`
							: `Cargando ${tipoUsuario}`
					}
				/>
			)}
			{mensaje.abierto && (
				<MensajeComponent
					mensaje={mensaje.mensaje}
					abierto={mensaje.abierto}
					estadoMensaje={setMensaje}
				/>
			)}
			<Row className="mt-5">
				<Col
					className="my-3 d-flex align-items-end justify-content-between mb-0"
					xs={5}
					sm={5}
					md={5}
					lg={5}
				>
					<InputGroup>
						<Form.Control
							name="buscar"
							value={itemBusqueda}
							onChange={({ target }) => setItemBusqueda(target.value)}
							placeholder={`Buscar ${tipoUsuario}`}
						/>
						<InputGroup.Text id="search">
							<SearchIcon />
						</InputGroup.Text>
					</InputGroup>
				</Col>
				<Col
					className="my-3 d-flex align-items-end justify-content-end mb-0"
					xs={{ span: 4, offset: 3 }}
					sm={{ span: 4, offset: 3 }}
					md={{ span: 4, offset: 3 }}
					lg={{ span: 4, offset: 3 }}
				>
					<Button
						className="text-white"
						variant="secondary"
						onClick={() =>
							navigate(
								`${ROUTES.REGISTRAR_USUARIO}/${tipoUsuario === 'Usuarios' ? 'usuario' : 'auditor'}`
							)
						}
					>
						Nuevo {tipoUsuario === 'Usuarios' ? 'Usuario' : 'Auditor'}
					</Button>
				</Col>
			</Row>
			<Row className="my-5">
				<Col xs={12} sm={12} md={12} lg={12}>
					<Table responsive striped hover>
						<thead>
							<tr>
								<th>Tipo</th>
								<th>Usuario</th>
								<th>Nombre</th>
								<th>Empresa</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{!!usuarios?.length ? (
								<>
									{usuarios?.map((usr: IUsuario) => (
										<tr key={usr.id}>
											<td>{usr.tipo}</td>
											<td>{usr.userName}</td>
											<td>{usr.nombre}</td>
											<td>{usr.empresa}</td>
											<td>
												{tipoUsuario !== 'Auditores' && (
													<OverlayTrigger
														placement="top"
														overlay={<Tooltip id="overlay-example">Editar Usuario</Tooltip>}
													>
														<IconButton
															size="small"
															onClick={() =>
																navigate(`${ROUTES.REGISTRAR_USUARIO}/usuario/${usr.id}`)
															}
														>
															<EditIcon fontSize="small" className="usuarios__accion" />
														</IconButton>
													</OverlayTrigger>
												)}
												{usr.tipo.toLowerCase() === 'externo' && (
													<>
														<OverlayTrigger
															placement="top"
															overlay={<Tooltip id="overlay-example">Resetear Usuario</Tooltip>}
														>
															<IconButton size="small">
																<RefreshIcon
																	fontSize="small"
																	className="usuarios__accion"
																	onClick={() => {
																		setMensaje({ abierto: false, mensaje: '' });
																		resetPasswordUsuarioExterno(usr.id);
																	}}
																/>
															</IconButton>
														</OverlayTrigger>
														{usr.active ? (
															<OverlayTrigger
																placement="top"
																overlay={<Tooltip id="overlay-example">Bloquear Usuario</Tooltip>}
															>
																<IconButton size="small">
																	<LockOpenIcon
																		fontSize="small"
																		className="usuarios__accion"
																		onClick={() => {
																			setMensaje({ abierto: false, mensaje: '' });
																			habilitarDeshabilitarUsuarioExterno({
																				idUsuario: usr.id,
																				estadoActual: !usr.active,
																			});
																		}}
																	/>
																</IconButton>
															</OverlayTrigger>
														) : (
															<OverlayTrigger
																placement="top"
																overlay={
																	<Tooltip id="overlay-example">Desbloquear Usuario</Tooltip>
																}
															>
																<IconButton size="small">
																	<LockIcon
																		fontSize="small"
																		className="usuarios__accion"
																		onClick={() => {
																			setMensaje({ abierto: false, mensaje: '' });
																			habilitarDeshabilitarUsuarioExterno({
																				idUsuario: usr.id,
																				estadoActual: !usr.active,
																			});
																		}}
																	/>
																</IconButton>
															</OverlayTrigger>
														)}
													</>
												)}
											</td>
										</tr>
									))}
								</>
							) : (
								<>
									<tr>
										<td colSpan={5}>No hay {tipoUsuario.toLowerCase()} registrados.</td>
									</tr>
								</>
							)}
						</tbody>
					</Table>
				</Col>
			</Row>
			<MostrarContrasenaModal
				estadoModal={estadoModalContrasena}
				cambiarEstadoModal={setEstadoModalContrasena}
				credenciales={credenciales}
			/>
		</>
	);
}
