import * as Yup from 'yup';
import { ErrorMessages } from '../../../constants/errorMessages';

export const agregarCatalogoValidationSchema = (
	nivelCatalogo: number | undefined,
	esEditar: boolean
) =>
	Yup.object().shape({
		catalogoId: Yup.number().nullable(),
		tipoAuditoriaId:
			(!!!esEditar && nivelCatalogo === 3) ||
			(!!esEditar && nivelCatalogo === 4)
				? Yup.number().min(1, ErrorMessages.Required)
				: Yup.number().nullable(),
		codigo: Yup.string(),
		descripcion: Yup.string().required(ErrorMessages.Required),
		severidad: Yup.number(),
	});
