import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import useReacciones from '@app/hooks/useReacciones';
import LoadingComponent from '../../components/Loading';
import useData from '../../hooks/useData';
import { IFiltros } from '../../models/interfaces/condicionInsegura/IFiltros';
import { IReaccion } from '../../models/interfaces/reacciones/IReaccion';
import { ReaccionObservadaProps } from '../../models/types/reaccion-observada.type';

export default function ReaccionPersonaObservada(props: ReaccionObservadaProps) {
	const { formik } = props;
	const [jefeEnterado, setJefeEnterado] = useState<boolean>(false);
	const [matrizReacciones, setMatrizReacciones] = useState<IReaccion[][]>([]);
	const [cargandoReacciones, setCargandoReacciones] = useState<boolean>(false);
	const [filtros, setFiltros] = useState<IFiltros>({
		area: '',
		zona: [],
	});
	const { dataAuditoriaContext } = useData();
	const { data: reacciones } = useReacciones();

	const obtenerRamaReaccion = async (
		idReaccion: number | null,
		listaReacciones: IReaccion[] = reacciones
	) => {
		try {
			const ramaResponse: IReaccion[] =
				listaReacciones?.find((reac: IReaccion) => reac.id === idReaccion)
					?.catalogoReaccionesChildren || [];
			if (ramaResponse.length) {
				setMatrizReacciones((reacts: IReaccion[][]) => [...reacts, ramaResponse]);
			}
		} catch (ex) {
			throw ex;
		}
	};

	const removerSelect = (indiceReaccionItem: number) => {
		if (indiceReaccionItem < 4) {
			const reaccionesActualizadas = matrizReacciones.filter((val, index) => {
				val;
				return index < indiceReaccionItem;
			});
			setMatrizReacciones(reaccionesActualizadas);
			formik.setFieldValue('idReaccion', indiceReaccionItem, false);

			//i Si ya no hay mas zonas entonces se resetea el select de área
			if (!reaccionesActualizadas.length) {
				setFiltros((filtros: IFiltros) => ({ ...filtros, area: '' }));
			}
			//i Reseteamos el valor vacio
			const selectReaccion: HTMLInputElement = document.querySelector(
				`#select-reaccion-${indiceReaccionItem}`
			);
			selectReaccion.value = '0';
		}
	};

	const renderSubReacciones = () => {
		return (
			<>
				{matrizReacciones.map((reaccionItem: IReaccion[], index: number) => (
					<Row className="mt-4" key={index}>
						<Col
							className="d-flex align-items-center justify-content-between"
							xs={12}
							sm={12}
							md={12}
							lg={12}
						>
							<div className="d-flex flex-column w-100 position-relative">
								{index < matrizReacciones.length - 1 && index > matrizReacciones.length - 3 ? (
									<div
										className="position-absolute"
										style={{
											right: '-35px',
											top: '5px',
										}}
									>
										<IconButton
											aria-label="Eliminar"
											size="small"
											onClick={() => removerSelect(index + 1)}
										>
											<RefreshIcon fontSize="inherit" />
										</IconButton>
									</div>
								) : null}
								<Form.Control
									id={`select-reaccion-${index + 1}`}
									as="select"
									className="select-reaccion"
									name="idReaccion"
									value={formik.idReaccion}
									onChange={({ target }) => {
										obtenerRamaReaccion(+target.value, reaccionItem);
										formik.setFieldValue('idReaccion', +target.value);
									}}
									isInvalid={!!formik.errors.idReaccion}
									disabled={index !== matrizReacciones.length - 1}
								>
									<option value={0}>{`Seleccione Nivel ${
										reaccionItem.length ? reaccionItem[0]?.nivel : ''
									}`}</option>
									{reaccionItem.length && (
										<>
											{reaccionItem.map((react: IReaccion) => (
												<option key={react.id} value={react.id}>
													{react.descripcion}
												</option>
											))}
										</>
									)}
								</Form.Control>
								<Form.Control.Feedback className="d-block" type="invalid">
									{formik.errors.idReaccion}
								</Form.Control.Feedback>
							</div>
						</Col>
					</Row>
				))}
			</>
		);
	};

	//i Se cargan los valores de las reacciones guardadas
	const setearDatosReacciones = () => {
		try {
			const { detalleAuditoria } = dataAuditoriaContext;

			//i Seteamos el valor de enterado
			formik.setFieldValue('JefeInmediatoEnterado', detalleAuditoria.jefeInmediatoEnterado);
			setJefeEnterado(detalleAuditoria.jefeInmediatoEnterado);

			if (detalleAuditoria.catalogoRamaAsc.length) {
				formik.setFieldValue('idReaccion', detalleAuditoria.reaccionId, false);

				//i Se invierte el orden de las ramas de menor a mayor para llevar un orden
				const ramaReaccion = detalleAuditoria.catalgoReaccionRamaAsc.reverse();
				let reaccionesCargadas: IReaccion[][] = [];

				//i Se busca el primer nivel y lo cargamos a las reacciones
				const result = reacciones?.find((rec: IReaccion) => rec.id === ramaReaccion[0].id);
				reaccionesCargadas = [...reaccionesCargadas, result?.catalogoReaccionesChildren];
				ramaReaccion.forEach((item: { id: number; nivel: number }, index: number) => {
					if (item.nivel > 1) {
						if (reaccionesCargadas?.length === index) {
							const encontrado = reaccionesCargadas[index - 1]?.find(
								(reac: IReaccion) => reac.id === item.id
							);
							if (encontrado) {
								reaccionesCargadas = [
									...reaccionesCargadas,
									encontrado?.catalogoReaccionesChildren,
								];
							}
						}
					}
				});
				setMatrizReacciones(reaccionesCargadas.filter((ite: any[]) => ite.length));

				//i Inyectamos los valores a los selects de reacciones
				ramaReaccion.forEach((item: any, index: number) => {
					setTimeout(() => {
						const reaccionSelect: any = document.getElementById(`select-reaccion-${index}`);
						if (reaccionSelect) {
							reaccionSelect.value = item.id;
						}
						setCargandoReacciones(false);
					});
				});
			}
		} catch {
			setCargandoReacciones(false);
		}
	};

	useEffect(() => {
		obtenerRamaReaccion(filtros.area ? +filtros.area : null);
	}, [filtros.area]);

	useEffect(() => {
		if (!!dataAuditoriaContext.editarAuditoria) {
			setCargandoReacciones(true);
		}

		if (!!dataAuditoriaContext.detalleAuditoria.id && !!reacciones) {
			setearDatosReacciones();
		}
	}, [dataAuditoriaContext.editarAuditoria, reacciones]);

	useEffect(() => {
		return () => {
			setFiltros({
				area: '',
				zona: [],
			});
			setMatrizReacciones([]);
			setJefeEnterado(false);
		};
	}, []);

	return (
		<>
			{cargandoReacciones && <LoadingComponent mensaje="Cargando reacciones" />}
			<Row className="mt-3 mb-4">
				<Col xs={12} sm={12} md={12} lg={12}>
					<div className="d-flex align-items-center justify-content-between">
						<Form.Label className="m-0">¿El jefe inmediato está enterado?</Form.Label>
						<Form.Check
							className="mb-0"
							inline
							type="radio"
							name="JefeInmediatoEnterado"
							label="Si"
							onChange={() => {
								setJefeEnterado((est: boolean) => {
									formik.setFieldValue('JefeInmediatoEnterado', !est);
									return !est;
								});
							}}
							checked={jefeEnterado}
							value={1}
						/>
						<Form.Check
							className="mb-0"
							inline
							type="radio"
							name="JefeInmediatoEnterado"
							label="No"
							onChange={() => {
								setJefeEnterado((est: boolean) => {
									formik.setFieldValue('JefeInmediatoEnterado', !est);
									return !est;
								});
							}}
							checked={!jefeEnterado}
							value={0}
						/>
					</div>
				</Col>
			</Row>
			<Row className="mt-3">
				<Col xs={12} sm={12} md={12} lg={12}>
					<div className="d-flex align-items-center justify-content-between">
						<Form.Label>Reacción de Persona</Form.Label>
						<IconButton
							aria-label="Eliminar"
							size="small"
							onClick={() => {
								setMatrizReacciones([]);
								setFiltros(filtros => ({ ...filtros, area: '' }));
								const elemento: HTMLInputElement = document.querySelector('#select-reaccion-0');
								elemento.value = '0';
								formik.setFieldValue('idReaccion', 0);
							}}
						>
							<PlaylistRemoveIcon fontSize="inherit" />
						</IconButton>
					</div>
					<Form.Control
						as="select"
						className="select-reaccion"
						id="select-reaccion-0"
						name="idReaccion"
						value={formik.idReaccion}
						onChange={({ target }) => {
							setFiltros((filtros: IFiltros) => ({
								...filtros,
								area: target.value,
							}));
							formik.setFieldValue('idReaccion', +target.value);
						}}
						isInvalid={!!formik.errors.idReaccion}
						disabled={!!matrizReacciones.length}
					>
						<option value={0}>Seleccione una reacción</option>
						{reacciones?.map((reac: IReaccion) => (
							<option key={reac.id} value={reac.id}>
								{reac.descripcion}
							</option>
						))}
					</Form.Control>
					<Form.Control.Feedback className="d-block" type="invalid">
						{formik.errors.idReaccion}
					</Form.Control.Feedback>
				</Col>
			</Row>
			{matrizReacciones.length ? renderSubReacciones() : null}
		</>
	);
}
