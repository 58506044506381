import useData from '@app/hooks/useData';

export default function useRole() {
	const { dataUser } = useData();

	const esRole = (role: string) =>
		dataUser.roles.map((rol: string) => rol.toLowerCase()).includes(role.toLowerCase());

	return { esRole };
}
