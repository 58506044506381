import useData from '../hooks/useData';

import '../styles/components/Footer.component.scss';

export default function Footer() {
  const { dataUser } = useData();

  return (
    <footer className="footer">
      <p className={!!!dataUser.usuario ? 'footer__texto--login' : 'footer__texto'}>
        &copy; {new Date().getFullYear()} Consorcio Minero Benito Juárez Peña Colorada, S.A. de C.V.
      </p>
    </footer>
  );
}
