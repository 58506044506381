import {
	IAgregarCatalogoFactor,
	IEditarCatalogoFactor,
} from '../../models/interfaces/mots/IAgregarCatalogoMots';
import http from '../../utils/http';

export const getAllFactorExposicionService = async () => {
	try {
		const response = await http.get('FactorExposicion');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addFactorExposicionService = async (
	dataRequest: IAgregarCatalogoFactor
) => {
	try {
		const response = await http.post('FactorExposicion', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editFactorExposicionService = async (
	dataRequest: IEditarCatalogoFactor
) => {
	try {
		const response = await http.put(
			`FactorExposicion/${dataRequest.id}`,
			dataRequest
		);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteFactorExposicionService = async (id: number) => {
	try {
		const response = await http.delete(`FactorExposicion/${id}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
