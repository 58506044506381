import {
	IAgregarCatalogoMots,
	IEditarCatalogoMots,
} from '../../models/interfaces/mots/IAgregarCatalogoMots';
import http from '../../utils/http';

export const getAllAreaResponsableService = async () => {
	try {
		const response = await http.get('AreaResponsable');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addAreaResponsableService = async (dataRequest: IAgregarCatalogoMots) => {
	try {
		const response = await http.post('AreaResponsable', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editAreaResponsableService = async (dataRequest: IEditarCatalogoMots) => {
	try {
		const response = await http.put(`AreaResponsable/${dataRequest.id}`, dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteAreaResponsableService = async (id: number) => {
	try {
		const response = await http.delete(`AreaResponsable/${id}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
