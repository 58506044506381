import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';

import {
	addAccesorioSeguridadService,
	editAccesorioSeguridadService,
} from '@app/services/mots/accesorio-seguridad.service';
import {
	addAreaResponsableService,
	editAreaResponsableService,
} from '@app/services/mots/area-responsable.service';
import { addAreaService, editAreaService } from '@app/services/mots/area.service';
import useError from '../../../hooks/useError';
import {
	IAgregarCatalogoGeneralMots,
	IEditarCatalogoNormaMots,
} from '../../../models/interfaces/mots/IAgregarCatalogoMots';
import { AgregarCatalogoMotsProps } from '../../../models/types/agregar-catalogo-mots.type';
import {
	addCapacitacionService,
	editCapacitacionService,
} from '../../../services/mots/capacitacion.service';
import { addEmpresaService, editEmpresaService } from '../../../services/mots/empresa.service';
import {
	addEquipoAutomotorService,
	editEquipoAutomotorService,
} from '../../../services/mots/equipo-automotor.service';
import {
	addEquipoProteccionPersonalService,
	editEquipoProteccionPersonalService,
} from '../../../services/mots/equipo-proteccion-personal.service';
import {
	addFactorExposicionService,
	editFactorExposicionService,
} from '../../../services/mots/factor-exposicion.service';
import {
	addFactorProbabilidadService,
	editFactorProbabilidadService,
} from '../../../services/mots/factor-probabilidad.service';
import {
	addFactorSeveridadService,
	editFactorSeveridadService,
} from '../../../services/mots/factor-severidad.service';
import {
	addHerramientaService,
	editHerramientaService,
} from '../../../services/mots/herramienta.service';
import {
	addNormaService,
	editNormaService,
	getAllNormasService,
} from '../../../services/mots/norma.service';
import {
	addObservacionMedicaService,
	editObservacionMedicaService,
} from '../../../services/mots/observacion-medica.service';
import {
	addPermisoAplicableService,
	editPermisoAplicableService,
} from '../../../services/mots/permiso-aplicable.service';
import {
	addTipoTrabajoService,
	editTipoTrabajoService,
} from '../../../services/mots/tipo-trabajo.service';
import { catalogoMotsValidationSchema } from '../../../utils/validations/catalogo-mots.validation';

export default function AgregarCatalogoMots(props: AgregarCatalogoMotsProps) {
	const { estadoModal, cambiarEstadoModal, recuperarData, nombreCatalogo, tipoCatalogo } = props;
	const pestanaActivaTipoEmpleado = props?.pestanaActiva || '';
	const editarCatalogo = props?.editar || false;
	const dataEditar = props?.dataEditar || null;
	const [guardando, setGuardando] = useState<boolean>(false);
	const [estadoCatalogo, setEstadoCatalogo] = useState<boolean>(true);
	const [listaNormas, setListaNormas] = useState<IEditarCatalogoNormaMots[]>([]);
	const { setError, ErrorAlert } = useError();
	const initialValues: IAgregarCatalogoGeneralMots = {
		descripcion: '',
		clasificacion: '',
		ponderacion: 0,
		tipoSangre: '',
		clave: '',
		perfil: '',
		tipoObservacion: '',
		detalles: '',
		normaId: 1,
		activo: true,
	};
	const formik = useFormik({
		initialValues,
		validationSchema: catalogoMotsValidationSchema(tipoCatalogo, pestanaActivaTipoEmpleado),
		onSubmit: (values: IAgregarCatalogoGeneralMots) => agrgearCatalogo(values),
	});

	const agrgearCatalogo = async (response: IAgregarCatalogoGeneralMots) => {
		try {
			setGuardando(true);
			const respuesta = await llamadaEnpoint(response);
			recuperarData(respuesta);
			cambiarEstadoModal(false);
		} catch (ex) {
			setError(ex);
		} finally {
			setGuardando(false);
		}
	};

	const obtenerNormas = async () => {
		try {
			const response = await getAllNormasService();
			setListaNormas(response);
		} catch (ex) {
			throw ex;
		}
	};

	//i Dependiendo del nombre del catálogo es el endpoint que se llama para agregarlo
	//i a los catálogos.
	const llamadaEnpoint = async (requestData: IAgregarCatalogoGeneralMots) => {
		const nombreCat = nombreCatalogo.toLowerCase();
		if (nombreCat.includes('protección')) {
			if (editarCatalogo) {
				return await editEquipoProteccionPersonalService({
					id: +dataEditar.id,
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			} else {
				return await addEquipoProteccionPersonalService({
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			}
		} else if (nombreCat.includes('aplicable')) {
			if (editarCatalogo) {
				return await editPermisoAplicableService({
					id: +dataEditar.id,
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			} else {
				return await addPermisoAplicableService({
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			}
		} else if (nombreCat.includes('herramienta')) {
			if (editarCatalogo) {
				return await editHerramientaService({
					id: +dataEditar.id,
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			} else {
				return await addHerramientaService({
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			}
		} else if (nombreCat.includes('vehiculo')) {
			if (editarCatalogo) {
				return await editEquipoAutomotorService({
					id: +dataEditar.id,
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			} else {
				return await addEquipoAutomotorService({
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			}
		} else if (nombreCat.includes('trabajo')) {
			if (editarCatalogo) {
				return await editTipoTrabajoService({
					id: +dataEditar.id,
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			} else {
				return await addTipoTrabajoService({
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			}
		} else if (nombreCat.includes('empresa')) {
			if (editarCatalogo) {
				return await editEmpresaService({
					id: +dataEditar.id,
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			} else {
				return await addEmpresaService({
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			}
		} else if (nombreCat.includes('observación')) {
			if (editarCatalogo) {
				return await editObservacionMedicaService({
					id: +dataEditar.id,
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			} else {
				return await addObservacionMedicaService({
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			}
		} else if (nombreCat.includes('norma')) {
			if (editarCatalogo) {
				return await editNormaService({
					id: +dataEditar.id,
					descripcion: requestData.descripcion,
					clave: requestData.clave,
					activo: requestData.activo,
				});
			} else {
				return await addNormaService({
					descripcion: requestData.descripcion,
					clave: requestData.clave,
					activo: requestData.activo,
				});
			}
		} else if (nombreCat.includes('capacitación')) {
			if (editarCatalogo) {
				return await editCapacitacionService({
					id: +dataEditar.id,
					descripcion: requestData.descripcion,
					detalles: requestData.detalles,
					normaId: requestData.normaId,
					activo: requestData.activo,
				});
			} else {
				return await addCapacitacionService({
					descripcion: requestData.descripcion,
					detalles: requestData.detalles,
					normaId: requestData.normaId,
					activo: requestData.activo,
				});
			}
		} else if (nombreCat.includes('probabilidad')) {
			if (editarCatalogo) {
				return await editFactorProbabilidadService({
					id: +dataEditar.id,
					descripcion: requestData.descripcion,
					clasificacion: requestData.clasificacion,
					ponderacion: requestData.ponderacion,
					activo: false,
				});
			} else {
				return await addFactorProbabilidadService({
					descripcion: requestData.descripcion,
					clasificacion: requestData.clasificacion,
					ponderacion: requestData.ponderacion,
					activo: false,
				});
			}
		} else if (nombreCat.includes('exposición')) {
			if (editarCatalogo) {
				return await editFactorExposicionService({
					id: +dataEditar.id,
					descripcion: requestData.descripcion,
					clasificacion: requestData.clasificacion,
					ponderacion: requestData.ponderacion,
					activo: false,
				});
			} else {
				return await addFactorExposicionService({
					descripcion: requestData.descripcion,
					clasificacion: requestData.clasificacion,
					ponderacion: requestData.ponderacion,
					activo: false,
				});
			}
		} else if (nombreCat.includes('severidad')) {
			if (editarCatalogo) {
				return await editFactorSeveridadService({
					id: +dataEditar.id,
					descripcion: requestData.descripcion,
					clasificacion: requestData.clasificacion,
					ponderacion: requestData.ponderacion,
					activo: false,
				});
			} else {
				return await addFactorSeveridadService({
					descripcion: requestData.descripcion,
					clasificacion: requestData.clasificacion,
					ponderacion: requestData.ponderacion,
					activo: false,
				});
			}
		} else if (nombreCat === 'área') {
			if (editarCatalogo) {
				return await editAreaService({
					id: +dataEditar.id,
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			} else {
				return await addAreaService({
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			}
		} else if (nombreCat.includes('responsable')) {
			if (editarCatalogo) {
				return await editAreaResponsableService({
					id: +dataEditar.id,
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			} else {
				return await addAreaResponsableService({
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			}
		} else if (nombreCat.includes('accesorio')) {
			if (editarCatalogo) {
				return await editAccesorioSeguridadService({
					id: +dataEditar.id,
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			} else {
				return await addAccesorioSeguridadService({
					descripcion: requestData.descripcion,
					activo: requestData.activo,
				});
			}
		}
	};

	useEffect(() => {
		if (!estadoModal) {
			setError('');
			formik.resetForm();
		} else {
			//i Se obtienen las normas cuando el tab activo sea capacitación
			if (nombreCatalogo.toLowerCase().includes('capacitación')) {
				obtenerNormas();
			}
			//i Cuando la modal este abierta verificamos si se edita la data
			if (editarCatalogo && !!dataEditar) {
				const tipoCat = tipoCatalogo.toLowerCase();
				//i Dependiendo el tipo de catálogo son los campos que se llenan en el formulario
				if (tipoCat === 'general') {
					formik.setFieldValue('descripcion', dataEditar.descripcion, false);
					formik.setFieldValue('activo', dataEditar.activo, false);
					if (nombreCatalogo.toLowerCase().includes('norma')) {
						formik.setFieldValue('clave', dataEditar.clave, false);
					}
					if (nombreCatalogo.toLowerCase().includes('capacitación')) {
						formik.setFieldValue('detalles', dataEditar.detalles, false);
						formik.setFieldValue('normaId', dataEditar.normaId, false);
					}
					setEstadoCatalogo(dataEditar.activo);
				} else if (tipoCat === 'factor') {
					formik.setFieldValue('descripcion', dataEditar.descripcion, false);
					formik.setFieldValue('clasificacion', dataEditar.clasificacion, false);
					formik.setFieldValue('ponderacion', dataEditar.ponderacion, false);
				}
			}
		}
	}, [estadoModal]);

	return (
		<Modal show={estadoModal} onHide={() => cambiarEstadoModal(false)}>
			<Modal.Header closeButton>
				<Modal.Title>
					{editarCatalogo ? `Editar ${nombreCatalogo}` : `Agregar ${nombreCatalogo}`}
				</Modal.Title>
			</Modal.Header>
			<Form onSubmit={formik.handleSubmit}>
				<Modal.Body>
					<ErrorAlert />
					{tipoCatalogo !== 'empleado' && (
						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<Form.Group>
									<Form.Label>Descripción</Form.Label>
									<Form.Control
										name="descripcion"
										isInvalid={!!formik.errors.descripcion}
										value={formik.values.descripcion}
										onChange={formik.handleChange}
										placeholder="Descripción"
									/>
									<Form.Control.Feedback className="d-block" type="invalid">
										{formik.errors.descripcion}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
					)}
					<>
						{tipoCatalogo === 'factor' && (
							<>
								<Row>
									<Col xs={12} sm={12} md={12} lg={12}>
										<Form.Group>
											<Form.Label>Clasificación</Form.Label>
											<Form.Control
												name="clasificacion"
												isInvalid={!!formik.errors.clasificacion}
												value={formik.values.clasificacion}
												onChange={formik.handleChange}
												placeholder="Clasificación"
											/>
											<Form.Control.Feedback className="d-block" type="invalid">
												{formik.errors.clasificacion}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col xs={12} sm={12} md={12} lg={12}>
										<Form.Group>
											<Form.Label>Ponderación</Form.Label>
											<Form.Control
												name="ponderacion"
												isInvalid={!!formik.errors.ponderacion}
												value={formik.values.ponderacion}
												onChange={formik.handleChange}
												type="number"
											/>
											<Form.Control.Feedback className="d-block" type="invalid">
												{formik.errors.ponderacion}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>
							</>
						)}
						{pestanaActivaTipoEmpleado === 'norma' && (
							<Row className="mt-2">
								<Col xs={12} sm={12} md={12} lg={12}>
									<Form.Group>
										<Form.Label>Clave</Form.Label>
										<Form.Control
											name="clave"
											isInvalid={!!formik.errors.clave}
											value={formik.values.clave}
											onChange={formik.handleChange}
											placeholder="Clave"
										/>
										<Form.Control.Feedback className="d-block" type="invalid">
											{formik.errors.clave}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
						)}
						{pestanaActivaTipoEmpleado === 'capacitacion' && (
							<>
								<Row className="mt-2">
									<Col xs={12} sm={12} md={12} lg={12}>
										<Form.Group>
											<Form.Label>Norma</Form.Label>
											<Form.Control
												as="select"
												name="normaId"
												isInvalid={!!formik.errors.normaId}
												value={formik.values.normaId}
												onChange={formik.handleChange}
												placeholder="Norma"
											>
												{listaNormas.map((norm: IEditarCatalogoNormaMots) => (
													<option key={norm.id} value={norm.id}>
														{norm.clave}
													</option>
												))}
											</Form.Control>
											<Form.Control.Feedback className="d-block" type="invalid">
												{formik.errors.normaId}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>
								<Row className="mt-2">
									<Col xs={12} sm={12} md={12} lg={12}>
										<Form.Group>
											<Form.Label>Detalles</Form.Label>
											<Form.Control
												name="detalles"
												isInvalid={!!formik.errors.detalles}
												value={formik.values.detalles}
												onChange={formik.handleChange}
												placeholder="Detalles"
											/>
											<Form.Control.Feedback className="d-block" type="invalid">
												{formik.errors.detalles}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>
							</>
						)}
						{tipoCatalogo === 'empleado' && (
							<>
								{pestanaActivaTipoEmpleado === 'perfil' && (
									<>
										<Row>
											<Col xs={12} sm={12} md={12} lg={12}>
												<Form.Group>
													<Form.Label>Perfil</Form.Label>
													<Form.Control
														name="perfil"
														isInvalid={!!formik.errors.perfil}
														value={formik.values.perfil}
														onChange={formik.handleChange}
														placeholder="Perfil"
													/>
													<Form.Control.Feedback className="d-block" type="invalid">
														{formik.errors.perfil}
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>
									</>
								)}
							</>
						)}
						{tipoCatalogo === 'general' && (
							<Row className="my-3">
								<Col xs={12} sm={12} md={12} lg={12}>
									<Form.Group>
										<Form.Check
											inline
											className="mb-0"
											type="checkbox"
											name="activo"
											label={`${nombreCatalogo} Activo`}
											onChange={({ target }) => {
												formik.setFieldValue('activo', target.checked);
												setEstadoCatalogo((est: boolean) => !est);
											}}
											checked={estadoCatalogo}
											value="x"
										/>
										<Form.Control.Feedback className="d-block" type="invalid">
											{formik.errors.activo}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
						)}
					</>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={() => cambiarEstadoModal(false)}>
						Cancelar
					</Button>
					<Button variant="primary" type="submit" disabled={guardando}>
						{guardando ? (
							<>
								<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
								&nbsp;&nbsp;{editarCatalogo ? 'Editando' : 'Guardando'}
							</>
						) : (
							'Guardar'
						)}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}
