import * as Yup from 'yup';
import { ErrorMessages } from '../../constants/errorMessages';

export const catalogoMotsValidationSchema = (tipoCatalogo: string, nombrePestana: string = '') => {
	const esGeneral = tipoCatalogo !== 'factor';
	return Yup.object().shape({
		descripcion: Yup.string().required(ErrorMessages.Required),
		activo: Yup.boolean(),
		clasificacion: esGeneral
			? Yup.string()
			: Yup.string()
					.required(ErrorMessages.Required)
					.min(3, ErrorMessages.MinLength(3))
					.max(40, ErrorMessages.MaxLength(40)),
		ponderacion: esGeneral ? Yup.number() : Yup.number().min(1, ErrorMessages.Required),
		tipoSangre:
			tipoCatalogo === 'empleado' && nombrePestana === 'tipoSangre'
				? Yup.string().required(ErrorMessages.Required)
				: Yup.string(),
		clave: nombrePestana === 'norma' ? Yup.string().required(ErrorMessages.Required) : Yup.string(),
		detalles:
			nombrePestana === 'capacitacion'
				? Yup.string().required(ErrorMessages.Required)
				: Yup.string(),
		normaId: Yup.number(),
		perfil:
			tipoCatalogo === 'empleado' && nombrePestana === 'perfil'
				? Yup.string().required(ErrorMessages.Required)
				: Yup.string(),
		tipoObservacion:
			tipoCatalogo === 'empleado' && nombrePestana === 'observacionMedica'
				? Yup.string().required(ErrorMessages.Required)
				: Yup.string(),
	});
};
