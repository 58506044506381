import { IDataFpsContext } from '../models/IDataContext';

export const DEFAULT_FPS_DATA_CONTEXT: IDataFpsContext = {
	catalogos: [],
	ramaCatalogo: [],
	areasZonas: [],
	ramaAreaZona: [],
	reacciones: [],
	ramaReaccion: [],
	jefaturaGerencia: [],
	ramaJefaturaGerencia: [],
	idioma: navigator.language || 'en',
	nombreGuia: '',
};
