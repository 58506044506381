import {
	IAgregarCatalogoMots,
	IEditarCatalogoMots,
} from '../../models/interfaces/mots/IAgregarCatalogoMots';
import http from '../../utils/http';

export const getAllEmpresaService = async () => {
	try {
		const response = await http.get('Empresa');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addEmpresaService = async (dataRequest: IAgregarCatalogoMots) => {
	try {
		const response = await http.post('Empresa', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editEmpresaService = async (dataRequest: IEditarCatalogoMots) => {
	try {
		const response = await http.put(`Empresa/${dataRequest.id}`, dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteEmpresaService = async (id: number) => {
	try {
		const response = await http.delete(`Empresa/${id}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
