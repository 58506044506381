import {
	IAgregarCatalogoFactor,
	IEditarCatalogoFactor,
} from '../../models/interfaces/mots/IAgregarCatalogoMots';
import http from '../../utils/http';

export const getAllFactorProbabilidadService = async () => {
	try {
		const response = await http.get('FactorProbabilidad');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addFactorProbabilidadService = async (
	dataRequest: IAgregarCatalogoFactor
) => {
	try {
		const response = await http.post('FactorProbabilidad', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editFactorProbabilidadService = async (
	dataRequest: IEditarCatalogoFactor
) => {
	try {
		const response = await http.put(
			`FactorProbabilidad/${dataRequest.id}`,
			dataRequest
		);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteFactorProbabilidadService = async (id: number) => {
	try {
		const response = await http.delete(`FactorProbabilidad/${id}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
