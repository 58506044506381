import {
	IAgregarCatalogoMots,
	IEditarCatalogoMots,
} from '../../models/interfaces/mots/IAgregarCatalogoMots';
import http from '../../utils/http';

export const getAllHerramientaService = async () => {
	try {
		const response = await http.get('Herramienta');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addHerramientaService = async (
	dataRequest: IAgregarCatalogoMots
) => {
	try {
		const response = await http.post('Herramienta', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editHerramientaService = async (
	dataRequest: IEditarCatalogoMots
) => {
	try {
		const response = await http.put(
			`Herramienta/${dataRequest.id}`,
			dataRequest
		);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteHerramientaService = async (id: number) => {
	try {
		const response = await http.delete(`Herramienta/${id}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
