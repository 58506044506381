import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import TablaJefaturasGerencias from '@app/components/JefaturasGerencias/TablaJefaturasGerencias';
import LoadingComponent from '@app/components/Loading';
import useData from '@app/hooks/useData';
import useError from '@app/hooks/useError';
import { ICargando } from '@app/models/ICargando';
import { IDataFpsContext } from '@app/models/IDataContext';
import { IJefaturaGerencia } from '@app/models/interfaces/jefaturasGerencias/IJefaturaGerencia';
import { getAllArbolJefaturasGerenciasService } from '@app/services/organigrama.service';
import TitleComponent from '../components/Title';

export default function JefaturasGerencias() {
	const [cargando, setCargando] = useState<ICargando>({
		cargando: false,
		mensaje: '',
	});
	const [filasAbiertas, setFilasAbiertas] = useState<number[]>([]);
	const [filaSeleccionada, setFilaSeleccionada] = useState<number>(0);
	const { dataFpsContext, setDataFpsContext } = useData();
	const { setError, ErrorAlert } = useError();

	const obtenerJefaturasGerencias = async () => {
		try {
			setCargando({
				cargando: true,
				mensaje: 'Obteniendo Organigrama',
			});
			const response: IJefaturaGerencia[] = await getAllArbolJefaturasGerenciasService();
			setDataFpsContext((data: IDataFpsContext) => ({
				...data,
				jefaturaGerencia: response,
			}));
		} catch (ex) {
			setError(ex);
		} finally {
			setCargando({
				cargando: false,
				mensaje: '',
			});
		}
	};

	useEffect(() => {
		obtenerJefaturasGerencias();
		return () => {
			setCargando({
				cargando: false,
				mensaje: '',
			});
		};
	}, []);

	return (
		<Container className="d-flex flex-column">
			<ErrorAlert />
			{cargando.cargando && <LoadingComponent mensaje={cargando.mensaje} />}
			<TitleComponent titulo="Estructura Organizativa" />
			<Row className="mt-5">
				<Col xs={12} sm={12} md={12} lg={12}>
					{dataFpsContext.jefaturaGerencia.length ? (
						<Row className="mt-5">
							<Col xs={12} sm={12} md={12} lg={12}>
								<TablaJefaturasGerencias
									filaSeleccionada={filaSeleccionada}
									seleccionarFila={setFilaSeleccionada}
									asignarFilaAbierta={setFilasAbiertas}
									filasAbiertas={filasAbiertas}
									jefaturasGerencias={dataFpsContext.jefaturaGerencia}
								/>
							</Col>
						</Row>
					) : (
						<h4 className="text-center">No se encontraron jefaturas o gerencias</h4>
					)}
				</Col>
			</Row>
		</Container>
	);
}
