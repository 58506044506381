import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';

import imagenPlaceholder from '../../../assets/images/placeholder-image.png';
import useData from '../../../hooks/useData';
import useError from '../../../hooks/useError';
import { FormularioStepperProps } from '../../../models/types/formulario-stepper.type';

import '../../../styles/components/FormularioEvidencias.scss';

const formatosImagenes = ['png', 'jpeg', 'jpg', 'bmp'];

export default function FormularioEvidencias(props: FormularioStepperProps) {
	const { avance, formik } = props;
	const [cargandoEvidencia, setCargandoEvidencia] = useState<boolean>(false);
	const [textoDescripcion, setTextoDescripcion] = useState<string>('');
	const [formatoInvalido, setFormatoInvalido] = useState<boolean>(false);
	const refCargaImagen = useRef(null);
	const refImagenEvidencia = useRef(null);
	const { dataAuditoriaContext } = useData();
	const { setError, ErrorAlert } = useError();
	const idAuditoria = dataAuditoriaContext.detalleAuditoria.id;

	//i Abrimos el explorador de archivos para cargar evidencia
	const cambiarImagenEvidencia = () => {
		if (refCargaImagen) {
			const { current } = refCargaImagen;
			current.click();
		}
	};

	//i Se obtiene la imagen cargada y se muestra en el preview
	const imagenEvidenciaCargada = event => {
		try {
			setFormatoInvalido(false);
			setCargandoEvidencia(true);
			if (event) {
				const { files } = event.target;
				if (files.length) {
					const [imagenCargada] = files;
					const formato = imagenCargada.type.split('/')[1];
					const formatoValido = formatosImagenes.find(
						(fmt: string) => fmt === formato.toLowerCase()
					);
					const urlImagen = URL.createObjectURL(files[0]);
					if (refImagenEvidencia) {
						refImagenEvidencia.current.src = urlImagen;
						if (formatoValido) {
							formik?.setFieldValue('fotoEvidencia', files[0]);
						} else {
							setFormatoInvalido(true);
						}
					}
				}
			}
		} catch (ex) {
			setError(ex);
		} finally {
			setCargandoEvidencia(false);
		}
	};

	useEffect(() => {
		if (!!dataAuditoriaContext.detalleAuditoria.id) {
			const { fotosEvidencias, descripcion } = dataAuditoriaContext.detalleAuditoria;
			if (fotosEvidencias.length) {
				const [unicaEvidencia] = fotosEvidencias;
				const textoEvidencia = descripcion || unicaEvidencia.descripcionEvidencia;
				formik.setFieldValue('descripcionEvidencia', textoEvidencia, false);
				setTextoDescripcion(textoEvidencia);
				refImagenEvidencia.current.src = `data:image/*;base64, ${unicaEvidencia.urlImagen}`;
			}
		}
	}, [dataAuditoriaContext]);

	useEffect(() => {
		return () => {
			setCargandoEvidencia(false);
			setTextoDescripcion('');
		};
	}, []);

	return (
		<div className="formulario-evidencias">
			<>
				{ErrorAlert && (
					<Row className="mb-2">
						<Col
							xs={{ offset: 1, span: 10 }}
							sm={{ offset: 1, span: 10 }}
							md={{ offset: 1, span: 10 }}
							lg={{ offset: 1, span: 10 }}
						>
							<ErrorAlert />
						</Col>
					</Row>
				)}
			</>
			<>
				{formatoInvalido && (
					<Row>
						<Col md={{ offset: 1, span: 10 }}>
							<Alert variant="warning">
								El formato de la evidencia debe ser <span className="fw-bold">jpeg</span>,{' '}
								<span className="fw-bold">jpg</span>, <span className="fw-bold">png</span> o{' '}
								<span className="fw-bold">bmp</span>. La imagén cargada no tiene un formato válido.
							</Alert>
						</Col>
					</Row>
				)}
			</>
			<Row>
				<Col
					className="formulario-evidencias__carga-evidencias"
					xs={{ offset: 1, span: 5 }}
					sm={{ offset: 1, span: 5 }}
					md={{ offset: 1, span: 4 }}
					lg={{ offset: 1, span: 4 }}
				>
					<div className="formulario-evidencias__contenedor-foto">
						<img ref={refImagenEvidencia} src={imagenPlaceholder} alt="Evidencia" />
					</div>
					<input
						className="formulario-evidencias__carga-imagen"
						ref={refCargaImagen}
						type="file"
						accept=".jpg, .jpeg, .png, .bmp"
						onChange={event => imagenEvidenciaCargada(event)}
						name="fotoEvidencia"
					/>
					{!!!idAuditoria && (
						<Button
							variant="secondary"
							onClick={() => cambiarImagenEvidencia()}
							disabled={cargandoEvidencia}
						>
							{cargandoEvidencia ? (
								<>
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									&nbsp;&nbsp;Cargando Foto...
								</>
							) : (
								'Seleccionar Foto'
							)}
						</Button>
					)}
				</Col>
				<Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 5 }} lg={{ span: 5 }}>
					<Form.Group>
						<Form.Label>Comentarios Adicionales</Form.Label>
						<Form.Control
							as="textarea"
							name="descripcionEvidencia"
							isInvalid={!!formik.errors.descripcionEvidencia}
							value={textoDescripcion}
							onChange={({ target }) => setTextoDescripcion(target.value)}
							rows={5}
							placeholder="Comentarios Adicionales"
						/>
						<Form.Control.Feedback className="d-block" type="invalid">
							{formik.errors.descripcionEvidencia}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
			</Row>
			<Row className="mt-5">
				<Col
					className="d-flex align-items-end justify-content-end"
					xs={{ offset: 1, span: 10 }}
					sm={{ offset: 1, span: 10 }}
					md={{ offset: 1, span: 10 }}
					lg={{ offset: 1, span: 10 }}
				>
					<Button
						variant="outline-secondary"
						onClick={() => avance(dataAuditoriaContext.mostrarPasoTres ? 4 : 3, 'atras')}
					>
						Cancelar
					</Button>
					&nbsp;&nbsp;
					<Button
						disabled={formatoInvalido}
						variant="secondary"
						onClick={() => formik.setFieldValue('descripcionEvidencia', textoDescripcion)}
						type="submit"
					>
						Guardar
					</Button>
				</Col>
			</Row>
		</div>
	);
}
