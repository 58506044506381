import { getAllMetasDirigidas } from '@app/services/meta.service';
import { useQuery } from '@tanstack/react-query';

export default function useMetaDirigida() {
	return useQuery({
		queryKey: ['meta-dirigida'],
		queryFn: getAllMetasDirigidas,
		refetchOnWindowFocus: false,
		retry: 2,
	});
}
