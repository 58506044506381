import {
	IAgregarCatalogoMots,
	IEditarCatalogoMots,
} from '../../models/interfaces/mots/IAgregarCatalogoMots';
import http from '../../utils/http';

export const getAllTipoTrabajoService = async () => {
	try {
		const response = await http.get('TipoTrabajo');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addTipoTrabajoService = async (
	dataRequest: IAgregarCatalogoMots
) => {
	try {
		const response = await http.post('TipoTrabajo', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editTipoTrabajoService = async (
	dataRequest: IEditarCatalogoMots
) => {
	try {
		const response = await http.put(
			`TipoTrabajo/${dataRequest.id}`,
			dataRequest
		);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteTipoTrabajoService = async (id: number) => {
	try {
		const response = await http.delete(`TipoTrabajo/${id}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
