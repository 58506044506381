import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { getISOWeek } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';

import useAspectoObservableId from '@app/hooks/useAspectoObservableId';
import useAspectosObservables from '@app/hooks/useAspectosObservables';
import useError from '@app/hooks/useError';
import useFechasSemana from '@app/hooks/useFechasSemana';
import useUsuarios from '@app/hooks/useUsuarios';
import { ICatalogo } from '@app/models/interfaces/catalogos/ICatalogo';
import { IFiltrosCatalogos } from '@app/models/interfaces/condicionInsegura/IFiltros';
import { IRegistrarMeta } from '@app/models/interfaces/metas/IAgregarMeta';
import { IUsuario } from '@app/models/interfaces/usuarios/IUsuario';
import { ModalRegistrarEditarMetaProps } from '@app/models/types/modal.type';
import { addMetaDirigidaService, editMetaDirigidaService } from '@app/services/meta.service';
import { agregarMetaValidationSchema } from '@app/utils/validations/modals/agregar-meta.modal.validation';

import '../../../styles/components/RegistrarMeta.scss';

interface Semanas {
	semanaInicio: number;
	semanaFin: number;
}

export default function RegistrarMetaModal(props: ModalRegistrarEditarMetaProps) {
	const {
		estadoModal: { estado, meta },
		cambiarEstadoModal,
		tituloModal,
		recargar,
	} = props;
	const semanaActual: number = getISOWeek(new Date());
	const [idAspectoObservable, setIdAspectoObservable] = useState<number>(0);
	const [seleccionado, setSeleccionado] = useState<IUsuario[]>([]);
	const [subCatalogos, setSubCatalogos] = useState<ICatalogo[][]>([]);
	const [noSemanaSeleccionada, setNoSemanaSeleccionada] = useState<Semanas>({
		semanaInicio: getISOWeek(new Date()),
		semanaFin: getISOWeek(new Date()),
	});
	const [filtros, setFiltros] = useState<IFiltrosCatalogos>({
		catalogos: '',
	});
	const refInputAspectoObservado = useRef(null);
	const {
		data: usuarios,
		isFetching: cargandoUsuarios,
		isFetched: usuariosCargados,
	} = useUsuarios(true);
	const { data: aspectosEvaluados } = useAspectosObservables();
	const { fechaInicio } = useFechasSemana(noSemanaSeleccionada.semanaInicio);
	const { fechaTermino } = useFechasSemana(noSemanaSeleccionada.semanaFin);
	const { refetch } = useAspectoObservableId(idAspectoObservable);
	const { setError, ErrorAlert } = useError();
	const initialValues: IRegistrarMeta = {
		applicationUserId: '',
		aspectoObservableId: 0,
		semana: getISOWeek(new Date()),
		semanaFin: getISOWeek(new Date()),
		fechaInicio: fechaInicio,
		fechaTermino: fechaTermino,
		actosInseguros: 0,
		codicionesInseguras: 0,
		reforzamientosPositivos: 0,
	};
	const {
		handleSubmit,
		values,
		handleChange,
		errors,
		resetForm,
		setFieldValue,
		setFieldError,
		setValues,
		setErrors,
	} = useFormik({
		initialValues,
		validationSchema: agregarMetaValidationSchema(!!meta),
		onSubmit: (values: IRegistrarMeta) => {
			if (!meta) {
				mutate({ ...values, aspectoObservableId: +values.aspectoObservableId || null });
			} else {
				editarMeta({
					id: meta.id,
					applicationUserId: values.applicationUserId,
					semana: values.semana,
					semanaFin: values.semanaFin,
					fechaInicio: values.fechaInicio,
					fechaTermino: values.fechaTermino,
				});
			}
		},
	});

	const { mutate, isLoading: registrandoMeta } = useMutation({
		mutationFn: addMetaDirigidaService,
		onSuccess: () => {
			cambiarEstadoModal({ estado: false, meta: null });
			recargar();
		},
		onError: error => setError(error),
	});

	const { mutate: editarMeta } = useMutation({
		mutationFn: editMetaDirigidaService,
		onSuccess: () => {
			cambiarEstadoModal({ estado: false, meta: null });
			recargar();
		},
		onError: error => setError(error),
	});

	const usuarioSeleccionado = (usuario: IUsuario[]) => {
		if (!!usuario.length) {
			const [{ id }] = usuario;
			setFieldValue('applicationUserId', id, false);
			setFieldError('applicationUserId', '');
		} else {
			setFieldValue('applicationUserId', '');
		}
		setSeleccionado([...usuario]);
	};

	const calcularFechas = (noSemana: Semanas = noSemanaSeleccionada) => {
		setFieldValue(
			'fechaInicio',
			noSemana.semanaInicio > 0 && noSemana.semanaInicio <= 52 ? fechaInicio : '',
			false
		);
		setFieldValue(
			'fechaTermino',
			noSemana.semanaFin > 0 && noSemana.semanaFin <= 52 ? fechaTermino : '',
			false
		);
		setFieldValue('semana', noSemana.semanaInicio, false);
		setFieldValue('semanaFin', noSemana.semanaFin, false);
	};

	const obtenerRamaCatalogo = async (
		idCatalogo: number | null,
		listaCatalogos: ICatalogo[] = aspectosEvaluados || []
	) => {
		try {
			const ramaResponse: ICatalogo[] =
				listaCatalogos.find((cat: ICatalogo) => cat.id === idCatalogo)?.catalogosChildren || [];

			if (ramaResponse.length) {
				setSubCatalogos((catas: ICatalogo[][]) =>
					[...catas, ramaResponse.filter((cat: ICatalogo) => cat.severidadId === null)].filter(
						(val: ICatalogo[]) => val.length
					)
				);
			}
		} catch (ex) {
			throw ex;
		}
	};

	const removerSelect = (indiceCatalogoItem: number) => {
		if (indiceCatalogoItem < 4) {
			const catalogosActualizados = subCatalogos.filter((val, index) => {
				val;
				return index < indiceCatalogoItem;
			});
			setSubCatalogos(catalogosActualizados);
			setValues(values => ({ ...values, aspectoObservableId: indiceCatalogoItem }));

			//i Si ya no hay mas aspectos observables entonces se resetea el select de área
			if (!catalogosActualizados.length) {
				setFiltros((filtros: IFiltrosCatalogos) => ({
					...filtros,
					catalogos: '',
				}));
			}

			//i Reseteamos el valor vacio
			const selectCatalogo: HTMLInputElement = document.querySelector(
				`#aspecto-observado-${indiceCatalogoItem}`
			);
			selectCatalogo.value = '0';
		}
	};

	const renderAspectoObservable = () => {
		return (
			<>
				{subCatalogos.map((catalogoItem: ICatalogo[], index: number) => (
					<div
						className="mt-4 d-flex align-items-center justify-content-between"
						style={{ width: '95%' }}
						key={catalogoItem[index] ? catalogoItem[index].id : index}
					>
						<div className="d-flex flex-column w-100 position-relative">
							{index < subCatalogos.length - 1 && index > subCatalogos.length - 3 ? (
								<div
									className="position-absolute"
									style={{
										right: '-35px',
										top: '5px',
									}}
								>
									<IconButton
										aria-label="Eliminar"
										size="small"
										onClick={() => removerSelect(index + 1)}
									>
										<RefreshIcon fontSize="inherit" />
									</IconButton>
								</div>
							) : null}
							<Form.Control
								id={`aspecto-observado-${index + 1}`}
								as="select"
								name="aspectoObservableId"
								onChange={({ target }) => {
									setValues(values => ({
										...values,
										aspectoObservableId: +target.value,
									}));
									obtenerRamaCatalogo(+target.value, catalogoItem);
								}}
								isInvalid={!!errors.aspectoObservableId}
								disabled={index !== subCatalogos.length - 1 || !!meta}
							>
								<option value={0}>{`Seleccione Nivel ${
									catalogoItem.length ? catalogoItem[0]?.nivel : ''
								}`}</option>
								{catalogoItem.length && (
									<>
										{catalogoItem.map((cata: ICatalogo) => (
											<option key={cata.id} value={cata.id}>
												{cata.descripcion}
											</option>
										))}
									</>
								)}
							</Form.Control>
							<Form.Control.Feedback className="d-block" type="invalid">
								{errors.aspectoObservableId}
							</Form.Control.Feedback>
						</div>
					</div>
				))}
			</>
		);
	};

	const limpiarArbolAspectoObservable = () => {
		setSubCatalogos([]);
		setFiltros(filtros => ({ ...filtros, catalogos: '' }));
		const elemento: HTMLInputElement = document.querySelector('#aspecto-observado-0');
		if (elemento) {
			elemento.value = '0';
		}
		setValues(values => ({
			...values,
			aspectoObservableId: 0,
		}));
	};

	const setearAspectoObservable = () => {
		refetch().then(success => {
			if (refInputAspectoObservado?.current && success?.data) {
				refInputAspectoObservado.current.value = success.data.descripcion;
			}
		});
	};

	useEffect(() => {
		obtenerRamaCatalogo(filtros.catalogos ? +filtros.catalogos : null);
	}, [filtros]);

	useEffect(() => {
		calcularFechas();
	}, [noSemanaSeleccionada]);

	useEffect(() => {
		if (!estado) {
			setSeleccionado([]);
			setNoSemanaSeleccionada({
				semanaInicio: getISOWeek(new Date()),
				semanaFin: getISOWeek(new Date()),
			});
			limpiarArbolAspectoObservable();
		} else {
			resetForm();
			setErrors({});
		}
	}, [estado]);

	useEffect(() => {
		if (usuariosCargados && meta) {
			const {
				reforzamientosPositivos,
				actosInseguros,
				codicionesInseguras,
				semanaFin,
				semana,
				aspectoObservableId,
				applicationUserId,
			} = meta;

			setValues({
				applicationUserId,
				aspectoObservableId,
				semana,
				semanaFin,
				fechaInicio,
				fechaTermino,
				actosInseguros,
				codicionesInseguras,
				reforzamientosPositivos,
			});

			setNoSemanaSeleccionada({ semanaInicio: semana, semanaFin });
			setIdAspectoObservable(aspectoObservableId);

			if (usuarios?.length) {
				const encontrado = usuarios.find((usr: IUsuario) => usr.id === applicationUserId);
				setSeleccionado([encontrado]);
			}
		}
	}, [usuariosCargados, meta]);

	useEffect(() => {
		setearAspectoObservable();
	}, [idAspectoObservable]);

	useEffect(() => {
		return () => {
			setIdAspectoObservable(0);
			setSubCatalogos([]);
			setSeleccionado([]);
			setFiltros({
				catalogos: '',
			});
			setNoSemanaSeleccionada({
				semanaInicio: getISOWeek(new Date()),
				semanaFin: getISOWeek(new Date()),
			});
		};
	}, []);

	return (
		<Modal
			className="modal-agregar-meta"
			show={estado}
			onHide={() => cambiarEstadoModal({ estado: false, meta: null })}
			size="lg"
		>
			<Modal.Header closeButton>
				<Modal.Title>{meta ? `Editar Meta de ${meta.persona}` : tituloModal}</Modal.Title>
			</Modal.Header>
			<Form onSubmit={handleSubmit}>
				<Modal.Body>
					<ErrorAlert />
					<Row>
						<Col xs={12} sm={12} md={6}>
							<Form.Group>
								<Form.Label htmlFor="single-typeahead">
									Auditor&nbsp;<span className="modal-agregar-meta__requerido">*</span>
								</Form.Label>
								<Typeahead
									id="single-typeahead"
									clearButton
									filterBy={['userName', 'nombre', 'empresa']}
									labelKey="nombre"
									onChange={usuarioSeleccionado}
									options={usuarios || []}
									selected={seleccionado}
									paginate
									isLoading={cargandoUsuarios}
									disabled={cargandoUsuarios || !!meta}
									paginationText="Mostra más usuarios..."
									isInvalid={!!errors.applicationUserId}
									renderMenuItemChildren={(option: IUsuario, { text }) => (
										<div>
											<Highlighter
												highlightClassName="bg-transparent font-weight-bold p-0"
												search={text}
											>
												{option.nombre}
											</Highlighter>
											<div>
												<div>
													<small>
														Usuario:{' '}
														<Highlighter
															highlightClassName="bg-transparent font-weight-bold p-0"
															search={text}
														>
															{option.userName}
														</Highlighter>
													</small>
												</div>
												<div>
													<small>
														Empresa:{' '}
														<Highlighter
															highlightClassName="bg-transparent font-weight-bold p-0"
															search={text}
														>
															{option.empresa}
														</Highlighter>
													</small>
												</div>
											</div>
										</div>
									)}
									placeholder="Seleccionar Auditor"
								/>
								<Form.Control.Feedback type="invalid" className="d-block">
									{errors?.applicationUserId}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col xs={12} sm={12} md={6}>
							<Form.Group>
								<div className="d-flex align-items-end justify-content-between">
									<div>
										<Form.Label>Aspecto Observable</Form.Label>
										&nbsp;<span className="modal-agregar-meta__requerido">*</span>
									</div>
									{!!!meta && (
										<IconButton
											aria-label="Eliminar"
											size="small"
											onClick={() => limpiarArbolAspectoObservable()}
											disabled={!!meta}
										>
											<PlaylistRemoveIcon fontSize="inherit" />
										</IconButton>
									)}
								</div>
								{!!meta ? (
									<Form.Control
										name="aspectoObservableId"
										ref={refInputAspectoObservado}
										isInvalid={!!errors.aspectoObservableId}
										disabled
									/>
								) : (
									<Form.Control
										as="select"
										name="aspectoObservableId"
										id="aspecto-observado-0"
										isInvalid={!!errors.aspectoObservableId}
										onChange={({ target }) => {
											setFiltros((filtros: IFiltrosCatalogos) => ({
												...filtros,
												catalogos: target.value,
											}));
											setValues(values => ({ ...values, aspectoObservableId: +target.value }));
										}}
										disabled={!!subCatalogos.length || !!meta}
									>
										<option value={0}>Seleccione Aspecto Observable</option>
										{aspectosEvaluados?.map(({ id, descripcion }) => (
											<option key={id} value={id}>
												{descripcion}
											</option>
										))}
									</Form.Control>
								)}
								<Form.Control.Feedback type="invalid" className="d-block">
									{errors?.aspectoObservableId}
								</Form.Control.Feedback>
							</Form.Group>
							{renderAspectoObservable()}
						</Col>
					</Row>
					<Row className="my-4">
						<Col xs={6} sm={6} md={6}>
							<Form.Group>
								<Form.Label>
									Semana Inicio&nbsp;<span className="modal-agregar-meta__requerido">*</span>
								</Form.Label>
								<Form.Control
									type="number"
									isInvalid={!!errors.semana}
									name="semana"
									min={1}
									max={52}
									value={values.semana}
									onChange={({ target }) => {
										setNoSemanaSeleccionada((semanas: Semanas) => ({
											...semanas,
											semanaInicio: +target.value,
										}));
									}}
									disabled={!!meta && meta.semana <= semanaActual}
								/>
								<Form.Control.Feedback type="invalid" className="d-block">
									{errors?.semana}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col xs={6} sm={6} md={6}>
							<Form.Group>
								<Form.Label>
									Semana Termino&nbsp;<span className="modal-agregar-meta__requerido">*</span>
								</Form.Label>
								<Form.Control
									type="number"
									isInvalid={!!errors.semanaFin}
									name="semanaFin"
									min={1}
									max={52}
									value={values.semanaFin}
									onChange={({ target }) => {
										setNoSemanaSeleccionada((semanas: Semanas) => ({
											...semanas,
											semanaFin: +target.value,
										}));
									}}
									disabled={!!meta && meta.semanaFin <= semanaActual}
								/>
								<Form.Control.Feedback type="invalid" className="d-block">
									{errors?.semanaFin}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row className="my-4">
						<Col xs={6} sm={6} md={6}>
							<Form.Group>
								<Form.Label>
									Fecha Inicio&nbsp;<span className="modal-agregar-meta__requerido">*</span>
								</Form.Label>
								<Form.Control
									type="date"
									name="fechaInicio"
									value={values.fechaInicio}
									onChange={handleChange}
									disabled
								/>
							</Form.Group>
						</Col>
						<Col xs={6} sm={6} md={6}>
							<Form.Group>
								<Form.Label>
									Fecha Termino&nbsp;<span className="modal-agregar-meta__requerido">*</span>
								</Form.Label>
								<Form.Control
									type="date"
									name="fechaTermino"
									value={values.fechaTermino}
									onChange={handleChange}
									disabled
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row className="my-4">
						<Col xs={6} sm={6} md={4}>
							<Form.Group>
								<Form.Label>
									Actos Inseguros&nbsp;<span className="modal-agregar-meta__requerido">*</span>
								</Form.Label>
								<Form.Control
									name="actosInseguros"
									isInvalid={!!errors.actosInseguros}
									value={values.actosInseguros}
									onChange={handleChange}
									disabled={!!meta}
									type="number"
								/>
								<Form.Control.Feedback type="invalid" className="d-block">
									{errors?.actosInseguros}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col xs={6} sm={6} md={4}>
							<Form.Group>
								<Form.Label>
									Condiciones Inseguras&nbsp;
									<span className="modal-agregar-meta__requerido">*</span>
								</Form.Label>
								<Form.Control
									name="codicionesInseguras"
									isInvalid={!!errors.codicionesInseguras}
									value={values.codicionesInseguras}
									onChange={handleChange}
									disabled={!!meta}
									type="number"
								/>
								<Form.Control.Feedback type="invalid" className="d-block">
									{errors?.codicionesInseguras}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col xs={6} sm={6} md={4}>
							<Form.Group>
								<Form.Label>
									Reforzamiento Positivo&nbsp;
									<span className="modal-agregar-meta__requerido">*</span>
								</Form.Label>
								<Form.Control
									name="reforzamientosPositivos"
									isInvalid={!!errors.reforzamientosPositivos}
									value={values.reforzamientosPositivos}
									onChange={handleChange}
									disabled={!!meta}
									type="number"
								/>
								<Form.Control.Feedback type="invalid" className="d-block">
									{errors?.reforzamientosPositivos}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row className="mt-3">
						<p className="modal-agregar-meta__leyenda">
							Todos los campos <span className="modal-agregar-meta__requerido">*</span> son
							obligatorios.
						</p>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() => cambiarEstadoModal({ estado: false, meta: null })}
					>
						Cancelar
					</Button>
					<Button
						variant="primary"
						disabled={
							registrandoMeta ||
							cargandoUsuarios ||
							(!!meta && meta.semana <= semanaActual && meta.semanaFin <= semanaActual)
						}
						type="submit"
					>
						{registrandoMeta ? (
							<>
								<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
								&nbsp;&nbsp;
								<span>Guardando Meta...</span>
							</>
						) : (
							<>
								<span>Guardar Meta</span>
							</>
						)}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}
