import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Row, Spinner, Table, Tooltip } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import DescargarGuia from '@app/components/Catalogos/DescargarGuia';
import LoadingComponent from '@app/components/Loading';
import IndicadoresMetas from '@app/components/Metas/IndicadoresMetas';
import CerrarAuditoriaModal from '@app/components/Modals/CerrarAuditoriaModal';
import { TipoLevantamiento } from '@app/constants/tipoLevantamiento';
import useAspectoObservableUsuario from '@app/hooks/useAspectoObservableUsuario';
import useAuditoriasFiltros from '@app/hooks/useAuditoriasFiltros';
import useData from '@app/hooks/useData';
import useDescargarGuia from '@app/hooks/useDescargarGuia';
import useRole from '@app/hooks/useRole';
import { numeroSemanaActual, numeroSemanaByFecha } from '@app/utils/fechas';
import TitleComponent from '../../components/Title';
import { ROUTES } from '../../constants/routes';
import useError from '../../hooks/useError';
import { IAuditoria } from '../../models/interfaces/auditorias/IAuditoria';
import { IFullFiltrosAuditoria } from '../../models/interfaces/auditorias/IFiltroAuditoria';
import {
	cerrarAuditoriaService,
	downloadReporteAuditoriaService,
} from '../../services/auditorias.service';

import '../../styles/TablaAuditorias.scss';

export default function TablaAuditorias() {
	const [auditoriaCerrada, setAuditoriaCerrada] = useState<{
		estado: boolean;
		auditoria: IAuditoria;
	}>({
		estado: false,
		auditoria: null,
	});
	const [filtros, setFiltros] = useState<IFullFiltrosAuditoria>({
		filtro: '',
		fechaInicial: '',
		fechaFinal: '',
	});
	const { esRole } = useRole();
	const esAdministrador = esRole('administrador');
	const { data: aspectoObservable } = useAspectoObservableUsuario(!esAdministrador);
	const { mutate: descargarGuia, isLoading: descargandoArchivo } = useDescargarGuia();
	const {
		dataFpsContext: { idioma },
	} = useData();
	const {
		data: auditorias,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
		isLoading,
		refetch,
	} = useAuditoriasFiltros(filtros);
	const navigate = useNavigate();
	const { setError, ErrorAlert } = useError();

	const { mutate: descargarReporte, isLoading: descargandoReporte } = useMutation({
		mutationFn: downloadReporteAuditoriaService,
		onSuccess: (response: Blob) => {
			const enlaceTemporal = document.createElement('a');
			const binario = URL.createObjectURL(response);

			enlaceTemporal.href = binario;
			enlaceTemporal.download = 'auditorias';
			enlaceTemporal.click();
			URL.revokeObjectURL(binario);
		},
		onError: error => setError(error),
	});

	const { mutate: cerrarAuditorias } = useMutation({
		mutationFn: cerrarAuditoriaService,
		onSuccess: () => {
			refetch();
			setAuditoriaCerrada({ estado: false, auditoria: null });
		},
		onError: error => {
			setError(error);
			setAuditoriaCerrada({ estado: false, auditoria: null });
		},
	});

	const cerrarAuditoria = async ({ id, abierto }: IAuditoria, respuestaCierre: string) => {
		cerrarAuditorias({ status: !abierto, respuestaCierre, auditoriaId: id });
	};

	const descargarGuiaSemana = () => {
		descargarGuia(aspectoObservable.archivoGuia || '', {
			onSuccess: response => {
				const enlaceTemporal = document.createElement('a');
				const binario = URL.createObjectURL(response);

				enlaceTemporal.href = binario;
				enlaceTemporal.download = aspectoObservable.archivoGuia;
				enlaceTemporal.click();
				URL.revokeObjectURL(binario);
			},
			onError: error => setError(error),
		});
	};

	useEffect(() => {
		return () => {
			setFiltros({
				filtro: '',
				fechaInicial: '',
				fechaFinal: '',
			});
			setError('');
		};
	}, []);

	const dataLength = auditorias?.pages?.reduce((prev, curr) => prev + curr?.length, 0) || 0;

	return (
		<>
			<div
				className={classNames('tabla-auditorias d-flex flex-column mx-5', {
					'mt-5': !esAdministrador,
				})}
				style={{ position: 'relative' }}
			>
				{descargandoArchivo && (
					<LoadingComponent
						mensaje={descargandoArchivo ? 'Descargando guía' : 'Cargando Auditorias'}
					/>
				)}
				<TitleComponent titulo="Tabla Auditorías" />
				<h2 className="text-center my-3">SEMANA {numeroSemanaActual}</h2>
				<DescargarGuia
					descargarGuiaSemana={descargarGuiaSemana}
					descargando={descargandoArchivo}
					aspectoObservable={aspectoObservable}
				/>
				<IndicadoresMetas />
				<Row className="my-2">
					<Col xs={12} sm={12} md={12}>
						<ErrorAlert />
					</Col>
				</Row>
				<Row style={{ paddingTop: esAdministrador ? '10px' : '70px' }}>
					<Col xs={12} sm={12} md={3} lg={3}>
						<Form.Group>
							<Form.Label>Filtro Busqueda</Form.Label>
							<Form.Control
								name="filtro"
								value={filtros.filtro}
								type="search"
								onChange={({ target }) =>
									setFiltros((filtros: IFullFiltrosAuditoria) => ({
										...filtros,
										filtro: target.value,
										pagina: 1,
									}))
								}
								placeholder="Buscar Auditoria"
							/>
						</Form.Group>
					</Col>
					<Col xs={12} sm={12} md={3} lg={3}>
						<Form.Label>Fecha Inicial</Form.Label>
						<Form.Control
							type="datetime-local"
							name="fechaIncial"
							value={filtros.fechaInicial}
							onChange={({ target }) =>
								setFiltros((filtros: IFullFiltrosAuditoria) => ({
									...filtros,
									fechaInicial: target.value,
									pagina: 1,
								}))
							}
						/>
					</Col>
					<Col xs={12} sm={12} md={3} lg={3}>
						<Form.Label>Fecha Termino</Form.Label>
						<Form.Control
							type="datetime-local"
							name="fechaFinal"
							value={filtros.fechaFinal}
							onChange={({ target }) =>
								setFiltros((filtros: IFullFiltrosAuditoria) => ({
									...filtros,
									fechaFinal: target.value,
									pagina: 1,
								}))
							}
						/>
					</Col>
					<Col className="d-flex align-items-end justify-content-end" xs={12} sm={12} md={3} lg={3}>
						{esAdministrador && (
							<>
								<Button onClick={() => descargarReporte()} disabled={descargandoReporte}>
									{descargandoReporte ? (
										<>
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
											/>
											&nbsp;&nbsp;Descargando Reporte
										</>
									) : (
										'Descargar Reporte'
									)}
								</Button>
								&nbsp;&nbsp;
							</>
						)}
						<Button onClick={() => navigate(ROUTES.AUDITORIA_LIBRE)}>Agregar Auditoria</Button>
					</Col>
				</Row>
				<Row className="mt-5">
					<Col xs={12} sm={12} md={12} lg={12}>
						<InfiniteScroll
							dataLength={dataLength}
							next={() => fetchNextPage()}
							height={500}
							loader={
								isFetchingNextPage && (
									<div className="text-center">
										<Spinner animation="border" variant="primary" role="status" />
										&nbsp;&nbsp;<p>Cargando Auditorias...</p>
									</div>
								)
							}
							hasMore={hasNextPage}
						>
							<Table striped hover bordered>
								<thead>
									<tr>
										<th>Fecha y Hora</th>
										<th>No. Semana</th>
										<th>Aspecto Observado</th>
										<th>Área o Zona</th>
										<th>Tipo Auditoria</th>
										<th>Severidad</th>
										{esAdministrador && <th>Auditor</th>}
										<th>Auditado</th>
										<th>Extemporánea</th>
										<th>Estado</th>
										<th>Acciones</th>
									</tr>
								</thead>
								<tbody>
									{isLoading && (
										<tr>
											<td colSpan={esAdministrador ? 11 : 10} className="text-center">
												<div className="my-2">
													<Spinner animation="border" variant="primary" role="status" />
													&nbsp;&nbsp;<p>Cargando Auditorias...</p>
												</div>
											</td>
										</tr>
									)}
									{auditorias?.pages?.flat().length ? (
										<>
											{auditorias?.pages?.flat().map((audit: IAuditoria) => (
												<tr key={audit.id}>
													<td>
														{format(
															new Date(audit.fecha),
															idioma === 'es' ? 'dd/MM/yyyy hh:mm aa' : 'MM/dd/yy hh:mm aa'
														)}
													</td>
													<td>{numeroSemanaByFecha(audit.fecha)}</td>
													<td>{audit.catalogoRaiz}</td>
													<td>{audit.areaZona}</td>
													<td>{TipoLevantamiento[audit.tipoAuditoria]}</td>
													<td>{audit.severidad}</td>
													{esAdministrador && <td>{audit.usuarioName}</td>}
													<td>{audit.trabajador}</td>
													<td>{audit.levantamientoExtemporaneo ? 'Si' : 'No'}</td>
													<td>{audit.estado}</td>
													<td>
														{audit.abierto && (
															<OverlayTrigger
																placement="top"
																overlay={<Tooltip id="overlay-example">Editar Auditoría</Tooltip>}
															>
																<IconButton
																	aria-label="Editar"
																	onClick={() => navigate(`${ROUTES.AUDITORIA_LIBRE}/${audit.id}`)}
																	size="small"
																>
																	<EditIcon
																		className="tabla-auditorias__accion"
																		fontSize="inherit"
																	/>
																</IconButton>
															</OverlayTrigger>
														)}
														{audit.abierto && (
															<>
																&nbsp;&nbsp;
																<OverlayTrigger
																	placement="top"
																	overlay={<Tooltip id="overlay-example">Cerrar Auditoría</Tooltip>}
																>
																	<IconButton
																		aria-label="Cerrar"
																		onClick={() =>
																			setAuditoriaCerrada({
																				estado: true,
																				auditoria: audit,
																			})
																		}
																		size="small"
																	>
																		<ExitToAppIcon
																			className="tabla-auditorias__accion"
																			fontSize="inherit"
																		/>
																	</IconButton>
																</OverlayTrigger>
															</>
														)}
													</td>
												</tr>
											))}
										</>
									) : (
										<>
											{!isLoading && (
												<tr>
													<td colSpan={esAdministrador ? 11 : 10}>
														No hay auditorias registradas.
													</td>
												</tr>
											)}
										</>
									)}
								</tbody>
							</Table>
						</InfiniteScroll>
					</Col>
				</Row>
			</div>
			<CerrarAuditoriaModal
				estadoModal={auditoriaCerrada}
				cambiarEstadoModal={setAuditoriaCerrada}
				recuperarData={cerrarAuditoria}
			/>
		</>
	);
}
