import { getAllResponsablesService } from '@app/services/responsables.service';
import { useQuery } from '@tanstack/react-query';

export default function useResponsables() {
	const response = useQuery({
		queryKey: ['responsables'],
		queryFn: getAllResponsablesService,
        refetchOnWindowFocus: false,
	});
	return response;
}