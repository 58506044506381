import { IAuditoria } from '@app/models/interfaces/auditorias/IAuditoria';
import { IFullFiltrosAuditoria } from '@app/models/interfaces/auditorias/IFiltroAuditoria';
import { IIndicadoresMetas } from '@app/models/interfaces/metas/IMeta';
import { IEditarAuditoria } from '../models/interfaces/auditorias/IEditarAuditoria';
import http from '../utils/http';

export const getAllAuditoriasService = async () => {
	try {
		const response = await http.get('auditoria/GetUltimasVeinteByUser');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getAllAuditoriasOtroTipoObservacion = async () => {
	const response = await http.get<IAuditoria[]>('AuditoriaObservacion');
	return response.data;
};

export const getAuditoriaByIdService = async (idAuditoria: number) => {
	try {
		const response = await http.get(`auditoria/${idAuditoria}`, {
			params: {
				idAuditoria,
			},
		});
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getAuditoriaByFilterService = async (filter: string) => {
	try {
		const response = await http.get('auditoria/getFilterAuditoriaByUser', {
			params: {
				filter,
			},
		});
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getAuditoriasByFilterService = async (fechaInicial: string, fechaFinal: string) => {
	try {
		const response = await http.get('auditoria/GetDateRangeAuditoriasByUser', {
			params: {
				fechaInicial,
				fechaFinal,
			},
		});
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getAuditoriaByFiltersService = async (
	pagina: number,
	numeroPagina: number,
	{ filtro, fechaInicial, fechaFinal }: IFullFiltrosAuditoria
) => {
	const response = await http.get<IAuditoria[]>(
		`Auditoria/GetAuditoriaConFiltros/${pagina}/${numeroPagina}`,
		{
			params: {
				filtro,
				fechaInicial,
				fechaFinal,
			},
		}
	);
	return response?.data;
};

export const addAuditoriaService = async (auditoriaData: FormData) => {
	try {
		const response = await http.post('auditoria', auditoriaData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editAuditoriaService = async (auditoriaData: IEditarAuditoria) => {
	try {
		const response = await http.put(`auditoria/${auditoriaData.id}`, auditoriaData);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const cerrarAuditoriaService = async (requestData: {
	status: boolean;
	respuestaCierre: string;
	auditoriaId: number;
}) => {
	try {
		const response = await http.put(`auditoria/SetEstadoAuditoria/${requestData.auditoriaId}`, {
			status: requestData.status,
			respuestaCierre: requestData.respuestaCierre,
		});
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getMetaByUserAuditoria = async () => {
	const response = await http.get<IIndicadoresMetas>(
		'Auditoria/GetIndicadoresMetaSemanaActualByUser'
	);
	return response?.data;
};

export const downloadReporteAuditoriaService = async () => {
	const response = await http.get('ExportacionesExcel/ExportAuditoria', {
		responseType: 'blob',
	});
	return response?.data;
};
