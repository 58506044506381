import { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';

import AspectoObservadoFormulario from '@app/components/Metas/Fomularios/AspectoObservado';
import DireccionFormulario from '@app/components/Metas/Fomularios/Direccion';
import { ICargando } from '@app/models/ICargando';
import { ICatalogo } from '@app/models/interfaces/catalogos/ICatalogo';
import { IAuditoriaRegistradaMeta } from '@app/models/interfaces/metas/IAgregarMeta';
import { IAspectoObservableAuditoriasDirigidas } from '@app/models/interfaces/metas/IMeta';
import { IRamasAuditoria } from '@app/models/interfaces/metas/IRamasAuditoria';
import { FormularioStepperProps } from '@app/models/types/formulario-stepper.type';
import { getCatalogoByIdService } from '@app/services/catalogos.service';
import { getRamaJefaturasService } from '@app/services/organigrama.service';
import TablaAuditorias from './TablaAuditorias';

export default function FormularioAuditoriaDirigida(props: FormularioStepperProps) {
	const { avance, formik, recuperarMetasDirigidas, isEditar, detalleMeta, hayError } = props;
	const [auditoriasRegistradas, setAuditoriasRegistradas] = useState<IAuditoriaRegistradaMeta[]>(
		[]
	);
	const [cargando, setCargando] = useState<ICargando>({
		cargando: false,
		mensaje: '',
	});
	const [ramas, setRamas] = useState<IRamasAuditoria>({
		ramaAspectoObservado: null,
		ramaPuesto: null,
	});
	const [ramaDataAspectoObservado, setRamaDataAspectoObservado] = useState<ICatalogo[]>([]);

	const agregarAuditoria = async () => {
		try {
			setCargando({ cargando: true, mensaje: '' });
			const ramaPuesto = await getRamaJefaturasService(+formik.values.direccion);
			setRamas({
				ramaAspectoObservado: ramaDataAspectoObservado,
				ramaPuesto,
			});
		} catch (ex) {
			hayError(ex);
		} finally {
			setCargando({
				cargando: false,
				mensaje: '',
			});
		}
	};

	const detalleAspectoObservable = async (idAspectoObservable: number) => {
		try {
			return await getCatalogoByIdService(idAspectoObservable);
		} catch (ex) {
			ex;
		}
	};

	const cargarTablaAspectosObservados = () => {
		const listaAspectosObservados: IAuditoriaRegistradaMeta[] =
			detalleMeta.aspectosObservableAuditoriasDirigidas.map(
				async (asp: IAspectoObservableAuditoriasDirigidas) => {
					const { nivel, descripcion } = await detalleAspectoObservable(asp.catalogoId);
					const result = {
						idAspectoObservado: asp.catalogoId,
						aspectoObservado: nivel === 1 ? descripcion : '-----------',
						nivelDos: nivel === 2 ? descripcion : '-----------',
						nivelTres: nivel === 3 ? descripcion : '-----------',
						gerenciasJefaturasAsignadas: detalleMeta?.aspectosObservableAuditoriasDirigidas
							?.filter(
								(adt: IAspectoObservableAuditoriasDirigidas) =>
									adt.catalogoId === asp.catalogoId && adt.organigramaId
							)
							?.map(adtFormat => adtFormat.organigramaId),
					};
					return result;
				}
			);

		//i Se quitan valores repetidos y se deja la lista preparada para pasarse a la tabla
		//i de aspectos observados.
		Promise.all(listaAspectosObservados).then(resolve => {
			const listaAspectoObservadoFormat = [
				...[...new Set(resolve.map(val => JSON.stringify(val)))].map(ap => JSON.parse(ap)),
			];
			setAuditoriasRegistradas(listaAspectoObservadoFormat);
		});
	};

	useEffect(() => {
		if (detalleMeta?.id && isEditar) {
			cargarTablaAspectosObservados();
		}
	}, [detalleMeta]);

	useEffect(() => {
		recuperarMetasDirigidas(auditoriasRegistradas);
	}, [auditoriasRegistradas]);

	return (
		<>
			<Row>
				<Col
					xs={{ offset: 2, span: 3 }}
					sm={{ offset: 2, span: 3 }}
					md={{ offset: 2, span: 3 }}
					lg={{ offset: 2, span: 3 }}
				>
					<AspectoObservadoFormulario
						formik={formik}
						ramaDataAspectoObservado={ramaDataAspectoObservado}
						setRamaDataAspectoObservado={setRamaDataAspectoObservado}
					/>
				</Col>
				<Col xs={5} sm={5} md={5} lg={5}>
					<DireccionFormulario formik={formik} />
				</Col>
			</Row>
			<Row>
				<Col
					xs={{ offset: 2, span: 8 }}
					sm={{ offset: 2, span: 8 }}
					md={{ offset: 2, span: 8 }}
					lg={{ offset: 2, span: 8 }}
					className="d-flex align-items-end justify-content-end"
				>
					<Button
						variant="secondary"
						onClick={() => agregarAuditoria()}
						disabled={
							cargando.cargando || !formik.values.aspectoObservado || !formik.values.direccion
						}
					>
						{cargando.cargando && (
							<>
								<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
								&nbsp;&nbsp;
							</>
						)}
						Agregar Auditoria
					</Button>
				</Col>
			</Row>
			<Row className="mt-4 mb-3">
				<Col
					xs={{ offset: 2, span: 8 }}
					sm={{ offset: 2, span: 8 }}
					md={{ offset: 2, span: 8 }}
					lg={{ offset: 2, span: 8 }}
				>
					<TablaAuditorias
						auditoriasRegistradas={auditoriasRegistradas}
						setAuditoriasRegistradas={setAuditoriasRegistradas}
						ramasAuditorias={ramas}
						formik={formik}
						hayError={hayError}
						isEditar={isEditar}
					/>
				</Col>
			</Row>
			<Row className="mt-4 mb-3">
				<Col
					xs={{ offset: 2, span: 4 }}
					sm={{ offset: 2, span: 4 }}
					md={{ offset: 2, span: 4 }}
					lg={{ offset: 2, span: 4 }}
				>
					<p className="mx-0 fw-bold">
						Los campos con <span className="meta__required">*</span> son requeridos.
					</p>
				</Col>
				<Col className="d-flex align-items-end justify-content-end" lg={4} md={4} sm={4} xs={4}>
					<Button variant="outline-secondary" onClick={() => avance(2, 'atras')}>
						Cancelar
					</Button>
					&nbsp;&nbsp;
					<Button variant="secondary" type="submit">
						Guardar
					</Button>
				</Col>
			</Row>
		</>
	);
}
