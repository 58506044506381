import { Alert } from 'react-bootstrap';

import { IMensaje } from '../models/IMensaje';
import { MensajeProps } from '../models/types/mensaje.type';

export default function Mensaje(props: MensajeProps) {
  const { mensaje, abierto, estadoMensaje } = props;
  if (abierto) {
    return (
      <Alert
        className="mt-5"
        variant="info"
        onClick={() => estadoMensaje((mensaje: IMensaje) => ({ ...mensaje, abierto: false }))}
        dismissible
      >
        <p className="m-0">{mensaje}</p>
      </Alert>
    );
  }
}
