import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Button, Col, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';

import Loading from '@app/components/Loading';
import AuditoriasModal from '@app/components/Modals/MetasModals/AuditoriasModal';
import RegistrarMetaModal from '@app/components/Modals/MetasModals/RegistrarMetaModal';
import TitleComponent from '@app/components/Title';
import useData from '@app/hooks/useData';
import useError from '@app/hooks/useError';
import useMetaDirigida from '@app/hooks/useMetaDirigida';
import {
	IAspectoObservableAuditoriasDirigidas,
	IDetalleMeta,
} from '@app/models/interfaces/metas/IMeta';
import { numeroSemanaActual, rangosSemanaActual } from '@app/utils/fechas';

export default function Metas() {
	const { inicio, fin } = rangosSemanaActual;
	const [estadoModalAuditoria, setEstadoModalAuditorias] = useState<boolean>(false);
	const [estadoModalRegistrarMeta, setEstadoModalRegistrarMeta] = useState<{
		estado: boolean;
		meta: IDetalleMeta | null;
	}>({
		estado: false,
		meta: null,
	});
	const [auditoriaDirigida, setAuditoriaDirigida] = useState<
		IAspectoObservableAuditoriasDirigidas[]
	>([]);
	const {
		dataFpsContext: { idioma },
	} = useData();
	const {
		data: metas,
		isLoading: cargando,
		isFetching: metasIsFetching,
		isError,
		error,
		refetch,
	} = useMetaDirigida();
	const { setError, ErrorAlert } = useError();

	useEffect(() => {
		setError(error);
	}, [isError]);

	useEffect(() => {
		return () => {
			setEstadoModalAuditorias(false);
			setAuditoriaDirigida([]);
		};
	}, []);

	return (
		<div className="d-flex flex-column mx-5">
			{(cargando || metasIsFetching) && <Loading mensaje="Cargando metas" />}
			<TitleComponent titulo="Metas Auditoría" />
			<h2 className="text-center">Semana {numeroSemanaActual}</h2>
			<h3 className="text-center">
				{inicio} al {fin}
			</h3>
			<ErrorAlert />
			<Row>
				<Col
					xs={12}
					sm={12}
					md={12}
					lg={12}
					className="d-flex align-items-center justify-content-end"
				>
					{/* <Button variant="secondary">Descargar Reporte Metas</Button>&nbsp;&nbsp; */}
					<Button
						variant="secondary"
						onClick={() => setEstadoModalRegistrarMeta(est => ({ ...est, estado: true }))}
					>
						Registrar Meta
					</Button>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col xs={12} sm={12} md={12} lg={12}>
					<Table striped hover bordered>
						<thead>
							<tr>
								<th>Semana Inicio</th>
								<th>Semana Fin</th>
								<th>Auditor</th>
								<th>Fecha Inicio</th>
								<th>Fecha Termino</th>
								<th>Buenas Prácticas</th>
								<th>Actos Inseguros</th>
								<th>Condiciones Inseguras</th>
								<th>Reforzamientos Positivos</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{metas?.length ? (
								<>
									{metas.map((meta: IDetalleMeta) => (
										<tr key={meta.id}>
											<td>{meta.semana}</td>
											<td>{meta.semanaFin}</td>
											<td>{meta.persona}</td>
											<td>
												{format(
													new Date(meta.fechaInicio),
													idioma === 'es' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
												)}
											</td>
											<td>
												{format(
													new Date(meta.fechaTermino),
													idioma === 'es' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
												)}
											</td>
											<td>{meta.aspectoObservableId ? meta.aspectoObservable : 'Todas'}</td>
											<td>{meta.actosInseguros}</td>
											<td>{meta.codicionesInseguras}</td>
											<td>{meta.reforzamientosPositivos}</td>
											<td>
												<OverlayTrigger
													placement="top"
													overlay={<Tooltip id="overlay-example">Editar Meta</Tooltip>}
												>
													<IconButton
														size="small"
														onClick={() => setEstadoModalRegistrarMeta({ estado: true, meta })}
													>
														<EditIcon fontSize="small" />
													</IconButton>
												</OverlayTrigger>
											</td>
										</tr>
									))}
								</>
							) : (
								<tr>
									<td colSpan={10}>No hay metas registradas</td>
								</tr>
							)}
						</tbody>
					</Table>
				</Col>
			</Row>
			<AuditoriasModal
				estadoModal={estadoModalAuditoria}
				cambiarEstadoModal={setEstadoModalAuditorias}
				auditoriasDirigidas={auditoriaDirigida}
			/>
			<RegistrarMetaModal
				tituloModal="Registrar Metas"
				estadoModal={estadoModalRegistrarMeta}
				cambiarEstadoModal={setEstadoModalRegistrarMeta}
				recargar={refetch}
			/>
		</div>
	);
}
