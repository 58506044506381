import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import LoadingComponent from '../components/Loading';
import TablaReacciones from '../components/ReaccionPersonaObservada/TablaReacciones';
import Title from '../components/Title';
import useData from '../hooks/useData';
import useError from '../hooks/useError';
import { ICargando } from '../models/ICargando';
import { IDataFpsContext } from '../models/IDataContext';
import { IReaccion } from '../models/interfaces/reacciones/IReaccion';
import { getAllReaccionesService } from '../services/reacciones.service';

export default function Reacciones() {
	const [cargando, setCargando] = useState<ICargando>({
		cargando: false,
		mensaje: '',
	});
	const [filasAbiertas, setFilasAbiertas] = useState<number[]>([]);
	const [filaSeleccionada, setFilaSeleccionada] = useState<number>(0);
	const { dataFpsContext, setDataFpsContext } = useData();
	const { setError, ErrorAlert } = useError();

	const obtenerReacciones = async () => {
		try {
			setCargando({
				cargando: true,
				mensaje: 'Obteniendo Reacciones',
			});
			const response: IReaccion[] = await getAllReaccionesService();
			setDataFpsContext((data: IDataFpsContext) => ({
				...data,
				reacciones: response,
			}));
		} catch (ex) {
			setError(ex);
		} finally {
			setCargando({
				cargando: false,
				mensaje: '',
			});
		}
	};

	useEffect(() => {
		obtenerReacciones();
		return () => {
			setCargando({
				cargando: false,
				mensaje: '',
			});
		};
	}, []);

	return (
		<Container className="d-flex flex-column">
			<ErrorAlert />
			{cargando.cargando && <LoadingComponent mensaje={cargando.mensaje} />}
			<Title titulo="Reacciones de Personas" />
			<Row className="mt-5">
				<Col xs={12} sm={12} md={12} lg={12}>
					{dataFpsContext.reacciones.length ? (
						<Row className="mt-5">
							<Col xs={12} sm={12} md={12} lg={12}>
								<TablaReacciones
									filaSeleccionada={filaSeleccionada}
									seleccionarFila={setFilaSeleccionada}
									asignarFilaAbierta={setFilasAbiertas}
									filasAbiertas={filasAbiertas}
									reacciones={dataFpsContext.reacciones}
								/>
							</Col>
						</Row>
					) : (
						<h4 className="text-center">No se encontraron reacciones</h4>
					)}
				</Col>
			</Row>
		</Container>
	);
}
