import { ICatalogoMots } from '@app/models/interfaces/mots/ICatalogoMots';
import {
	IAgregarCatalogoMots,
	IEditarCatalogoMots,
} from '../../models/interfaces/mots/IAgregarCatalogoMots';
import http from '../../utils/http';

export const getAllPermisoAplicableService = async () => {
	try {
		const response = await http.get<ICatalogoMots[]>('PermisoAplicable');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addPermisoAplicableService = async (dataRequest: IAgregarCatalogoMots) => {
	try {
		const response = await http.post('PermisoAplicable', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editPermisoAplicableService = async (dataRequest: IEditarCatalogoMots) => {
	try {
		const response = await http.put(`PermisoAplicable/${dataRequest.id}`, dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deletePermisoAplicableService = async (id: number) => {
	try {
		const response = await http.delete(`PermisoAplicable/${id}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
