import { useQuery } from '@tanstack/react-query';

import { getFactorRiesgo } from '@app/services/catalogos.service';

export default function useFactorRiesgo() {
	return useQuery({
		queryKey: ['factor-riesgo'],
		queryFn: getFactorRiesgo,
		refetchOnWindowFocus: false,
		retry: 0,
		cacheTime: 0,
	});
}
