import { CatalogoGenericoProps } from '@app/models/types/catalogoGenerico.type';
import { agregarCatalogoGenericoValidationSchema } from '@app/utils/validations/modals/agregar-catalogo-generico.validation';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

import useError from '@app/hooks/useError';
import { ICatalogoGenerico } from '@app/models/interfaces/catalogos/ICatalogoGenerico';
import { addCatalogoGenerico, updateCatalogoGenerico } from '@app/services/catalogos.service';

import '../../../styles/components/modals/AgregarCatalogoModal.scss';

export default function CatalogoGenerico(props: CatalogoGenericoProps) {
	const {
		estadoModal,
		cambiarEstadoModal,
		tipoCatalogo,
		isFactorRiesgo,
		editarCatalogo,
		refetch,
		catalogo: { id, descripcion },
	} = props;
	const { setError, ErrorAlert } = useError();

	const { mutate, isLoading } = useMutation({
		mutationFn: async (requestData: ICatalogoGenerico) =>
			editarCatalogo
				? await updateCatalogoGenerico(isFactorRiesgo, requestData)
				: await addCatalogoGenerico(isFactorRiesgo, requestData),
		onSuccess: () => {
			refetch();
			cambiarEstadoModal(false);
		},
		onError: error => setError(error),
	});

	const { handleSubmit, values, handleChange, errors, setFieldValue, resetForm } = useFormik({
		initialValues: {
			descripcion: descripcion,
		},
		validationSchema: agregarCatalogoGenericoValidationSchema,
		onSubmit: (catalogoRequest: { descripcion: string }) => {
			mutate({ ...catalogoRequest, id });
		},
	});

	useEffect(() => {
		if (estadoModal) {
			setFieldValue('descripcion', descripcion, false);
		} else {
			resetForm();
			setError('');
		}
	}, [estadoModal]);

	return (
		<Modal
			className="modal-agregar-catalogo"
			show={estadoModal}
			onHide={() => cambiarEstadoModal(false)}
		>
			<Form onSubmit={handleSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>
						{editarCatalogo ? 'Editar' : 'Agregar'} {tipoCatalogo}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ErrorAlert />
					<Row>
						<Col xs={12}>
							<Form.Group>
								<Form.Label>
									{tipoCatalogo}&nbsp;
									<span className="modal-agregar-catalogo__requerido">*</span>
								</Form.Label>
								<Form.Control
									name="descripcion"
									isInvalid={!!errors.descripcion}
									value={values.descripcion}
									onChange={handleChange}
									placeholder={tipoCatalogo}
								/>
								<Form.Control.Feedback className="d-block" type="invalid">
									{errors?.descripcion}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => cambiarEstadoModal(false)}>
						Cancelar
					</Button>
					<Button variant="primary" disabled={isLoading} type="submit">
						{isLoading ? 'Guardando' : 'Guardar'}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}
