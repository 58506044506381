import { useQuery } from '@tanstack/react-query';

import { getCatalogoGenerico } from '@app/services/catalogos.service';

export default function useCatalogoGenerico(isFactorRiesgo: boolean) {
	return useQuery({
		queryKey: ['catalogo-generico'],
		queryFn: async () => await getCatalogoGenerico(isFactorRiesgo),
		refetchOnWindowFocus: false,
		retry: 0,
		cacheTime: 0,
	});
}
