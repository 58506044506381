import { useQuery } from '@tanstack/react-query';

import { getAllArbolJefaturasGerenciasService } from '@app/services/organigrama.service';

export default function useOrganigrama() {
	const response = useQuery({
		queryKey: ['organigrama'],
		queryFn: getAllArbolJefaturasGerenciasService,
		refetchOnWindowFocus: false,
	});
	return response;
}
