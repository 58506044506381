import { getCatalogoByIdService } from '@app/services/catalogos.service';
import { useQuery } from '@tanstack/react-query';

export default function useAspectoObservableId(id: number) {
	return useQuery({
		queryKey: ['rama-aspecto-observable', id],
		queryFn: async () => await getCatalogoByIdService(id),
		refetchOnWindowFocus: false,
	});
}
