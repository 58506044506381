import { ICatalogoMots } from '@app/models/interfaces/mots/ICatalogoMots';
import { getAllFactorSeveridadService } from '@app/services/mots/factor-severidad.service';
import { useQuery } from '@tanstack/react-query';

export function useFactorSeveridadQuery() {
	return useQuery<ICatalogoMots[]>({
		queryKey: ['mots', 'factor', 'severidad'],
		queryFn: getAllFactorSeveridadService,
	});
}
