import * as Yup from 'yup';
import { ErrorMessages } from '../../constants/errorMessages';

export const auditoriaValidationSchema = (otroTipoObservacion: boolean = false) =>
	Yup.object().shape({
		fecha: Yup.string().required(ErrorMessages.Required),
		idAreaZona: Yup.number().min(1, ErrorMessages.Required),
		idCatalogo: Yup.number().min(1, ErrorMessages.Required),
		idReaccion: Yup.number(),
		idOrganigrama: Yup.number().min(1, ErrorMessages.Required),
		abierto: Yup.boolean(),
		JefeInmediatoEnterado: Yup.boolean(),
		responsables: Yup.array(),
		descripcionEvidencia: Yup.string().nullable(),
		fotoEvidencia: Yup.mixed(),
		esOtroTipoObservacion: Yup.boolean(),
		descripcion: otroTipoObservacion ? Yup.string().required(ErrorMessages.Required) : Yup.string(),
		severidad: otroTipoObservacion ? Yup.string().required(ErrorMessages.Required) : Yup.string(),
		tipoLevantamiento: Yup.string(),
		direccion: Yup.string(),
		trabajador: Yup.string().required(ErrorMessages.Required),
		empresa: Yup.string().required(ErrorMessages.Required),
		esRechadada: Yup.bool(),
		descripcionRechazoTarea: Yup.string(),
		accionesTomadasRechazoTarea: Yup.string(),
		factorDeRiesgoFpsId: Yup.number(),
		precursorFpsId: Yup.number(),
	});
