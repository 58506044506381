import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';

import useData from '@app/hooks/useData';
import { ModalCerrarAuditoriaProps } from '../../models/types/modal.type';

const formatDateTime: Intl.DateTimeFormatOptions = {
	day: 'numeric',
	month: '2-digit',
	year: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
	hourCycle: 'h12',
};

export default function CerrarAuditoriaModal(props: ModalCerrarAuditoriaProps) {
	const { estadoModal, cambiarEstadoModal, recuperarData } = props;
	const [fechaHora, setFechaHora] = useState<string>('');
	const [eliminando, setEliminando] = useState<boolean>(false);
	const [esCorrecto, setEsCorrecto] = useState<boolean>(false);
	const [respuestaCierre, setRespuestaCierre] = useState<string>('');
	const {
		dataFpsContext: { idioma },
	} = useData();

	useEffect(() => {
		if (!!estadoModal.auditoria && estadoModal.estado) {
			try {
				const fechaSeleccionada = new Date(fechaHora).toLocaleString(idioma, formatDateTime);
				const fechaRecuperada = new Date(estadoModal.auditoria?.fecha).toLocaleString(
					idioma,
					formatDateTime
				);
				setEsCorrecto(fechaSeleccionada === fechaRecuperada);
			} catch (ex) {
				ex;
			}
		}
	}, [fechaHora]);

	useEffect(() => {
		if (!estadoModal.estado) {
			setEliminando(false);
			setEsCorrecto(false);
			setFechaHora('');
		}
	}, [estadoModal.estado]);

	return (
		<Modal
			show={estadoModal.estado}
			onHide={() => cambiarEstadoModal(est => ({ ...est, estado: false }))}
		>
			<Modal.Header closeButton>
				<Modal.Title>Cerrar Auditoría</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>
						<h5>
							<span style={{ color: '#c6361b' }}>
								{estadoModal.auditoria?.fecha
									? new Date(estadoModal.auditoria?.fecha).toLocaleString(idioma, formatDateTime)
									: ''}
							</span>{' '}
							debe escribir la fecha y hora de la misma en el siguiente campo.
						</h5>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col xs={12} sm={12} md={12} lg={12}>
						<Form.Group>
							<Form.Label>Fecha/Hora</Form.Label>
							<Form.Control
								type="datetime-local"
								onChange={({ target }) => setFechaHora(target.value)}
								value={fechaHora}
								name="fechaHora"
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col xs={12} sm={12} md={12} lg={12}>
						<Form.Label>Respuesta Cierre</Form.Label>
						<Form.Control
							name="respuestaCierre"
							value={respuestaCierre}
							onChange={({ target }) => setRespuestaCierre(target.value)}
							placeholder="Respuesta Cierre"
						/>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="outline-secondary"
					onClick={() => cambiarEstadoModal(est => ({ ...est, estado: false }))}
				>
					Cancelar
				</Button>
				<Button
					variant="primary"
					onClick={() => {
						setEliminando(true);
						recuperarData(estadoModal.auditoria, respuestaCierre);
					}}
					disabled={eliminando || !esCorrecto || !respuestaCierre}
				>
					{eliminando ? (
						<>
							{' '}
							<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
							&nbsp;&nbsp;{'Cerrando Auditoría'}
						</>
					) : (
						'Cerrar Auditoría'
					)}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
