import { Button, Card, Spinner } from 'react-bootstrap';

import useRole from '@app/hooks/useRole';
import { ICatalogo } from '@app/models/interfaces/catalogos/ICatalogo';

import '../../styles/components/DescargarGuia.scss';

type descargaGuiaProps = {
	descargarGuiaSemana: Function;
	descargando: boolean;
	aspectoObservable: ICatalogo;
};

export default function DescargarGuia(props: descargaGuiaProps) {
	const { descargarGuiaSemana, descargando, aspectoObservable } = props;
	const { esRole } = useRole();

	return (
		<div className="descargar-guia">
			{esRole('auditor') && (
				<Card className="descargar-guia__contenedor">
					<Card.Body className="d-flex align-items-center justify-content-center flex-column">
						<h4 className="text-center my-3">
							{aspectoObservable?.archivoGuia
								? `Guía de ${aspectoObservable?.descripcion}`
								: 'No hay guía disponible'}
						</h4>
						<Button
							className="my-2 mx-auto"
							disabled={descargando || !aspectoObservable?.archivoGuia}
							onClick={() => descargarGuiaSemana()}
						>
							{descargando ? (
								<>
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									&nbsp;&nbsp;Descargando Guía...
								</>
							) : (
								'Descargar Guía'
							)}
						</Button>
					</Card.Body>
				</Card>
			)}
		</div>
	);
}
