import { getAllCatalogosService } from '@app/services/catalogos.service';
import { useQuery } from '@tanstack/react-query';

export default function useAspectosObservables() {
	return useQuery({
		queryKey: ['aspectos-evaluados'],
		queryFn: getAllCatalogosService,
		refetchOnWindowFocus: false,
	});
}
