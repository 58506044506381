import {
	IAgregarCatalogoMots,
	IEditarCatalogoMots,
} from '../../models/interfaces/mots/IAgregarCatalogoMots';
import http from '../../utils/http';

export const getAllObservacionMedicaService = async () => {
	try {
		const response = await http.get('observacionMedica');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addObservacionMedicaService = async (
	dataRequest: IAgregarCatalogoMots
) => {
	try {
		const response = await http.post('observacionMedica', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editObservacionMedicaService = async (
	dataRequest: IEditarCatalogoMots
) => {
	try {
		const response = await http.put(
			`observacionMedica/${dataRequest.id}`,
			dataRequest
		);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteObservacionMedicaService = async (id: number) => {
	try {
		const response = await http.delete(`observacionMedica/${id}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
