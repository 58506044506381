import { ChartTipAuditProps } from '@app/models/types/chartTipAudit.type';
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	ChartOptions,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options: ChartOptions<any> = {
	indexAxis: 'y' as const,
	responsive: true,
	plugins: {
		legend: {
			display: false,
			position: 'top' as const,
		},
		title: {
			display: true,
			text: 'Tipo de Audítorias',
		},
		datalabels: {
			display: function (context) {
				return context.dataset.data[context.dataIndex] > 0;
			},
			color: 'white',
			labels: {
				title: {
					font: {
						size: '13px',
					},
				},
			},
		},
	},
};

const ChartTipAudit = ({ tiposAuditoria }: ChartTipAuditProps) => {
	const labels = ['Condiciones Inseguras', 'Acto Inseguro', 'Reforzamiento Positivo', 'Rechazadas'];

	const data = {
		labels,
		datasets: [
			{
				label: '',
				data: [
					tiposAuditoria?.condicionesInseguras,
					tiposAuditoria?.actosInseguros,
					tiposAuditoria?.reforzamientosPositivos,
					tiposAuditoria?.rechazadas,
				],
				backgroundColor: ['#B52626', '#596068', '#007934', '#305496'],
			},
		],
	};

	return <Bar options={options} data={data} />;
};

export default ChartTipAudit;
