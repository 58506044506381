import {
	IAgregarCatalogoMots,
	IEditarCatalogoMots,
} from '../../models/interfaces/mots/IAgregarCatalogoMots';
import http from '../../utils/http';

export const getAllAreaService = async () => {
	try {
		const response = await http.get('Area');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addAreaService = async (dataRequest: IAgregarCatalogoMots) => {
	try {
		const response = await http.post('Area', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editAreaService = async (dataRequest: IEditarCatalogoMots) => {
	try {
		const response = await http.put(`Area/${dataRequest.id}`, dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteAreaService = async (id: number) => {
	try {
		const response = await http.delete(`Area/${id}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
