import { differenceInCalendarISOWeeks, format } from 'date-fns';
import { es } from 'date-fns/locale';

const anioActual = new Date().getFullYear();

export const numeroSemanaActual = differenceInCalendarISOWeeks(
	new Date(),
	new Date(new Date().getFullYear(), 0, 1)
);

export const numeroSemanaByFecha = (fecha: string) =>
	differenceInCalendarISOWeeks(new Date(fecha), new Date(new Date(fecha).getFullYear(), 0, 1));

export const rangosSemanaActual = {
	inicio: format(new Date(anioActual, 0, (numeroSemanaActual - 1) * 7 + 1), 'dd MMMM', {
		locale: es,
	}),
	fin: format(new Date(anioActual, 0, (numeroSemanaActual - 1) * 7 + 7), 'dd MMMM yyyy', {
		locale: es,
	}),
};

export function getLastDayOfMonth(year: number, month: number) {
	return new Date(year, month + 1, 0).getDate();
}
