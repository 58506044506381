import {
	IAgregarCatalogoCapacitacionMots,
	IEditarCatalogoCapacitacionMots,
} from '../../models/interfaces/mots/IAgregarCatalogoMots';
import http from '../../utils/http';

export const getAllCapacitacionesService = async () => {
	try {
		const response = await http.get('Capacitacion');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addCapacitacionService = async (dataRequest: IAgregarCatalogoCapacitacionMots) => {
	try {
		const response = await http.post('Capacitacion', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editCapacitacionService = async (dataRequest: IEditarCatalogoCapacitacionMots) => {
	try {
		const response = await http.put(`Capacitacion/${dataRequest.id}`, dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteCapacitacionService = async (id: number) => {
	try {
		const response = await http.delete(`Capacitacion/${id}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
