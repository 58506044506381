import {
	IAgregarCatalogoMots,
	IEditarCatalogoMots,
} from '../../models/interfaces/mots/IAgregarCatalogoMots';
import http from '../../utils/http';

export const getAllEquipoProteccionPersonalService = async () => {
	try {
		const response = await http.get('EquipoProteccionPersonal');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const addEquipoProteccionPersonalService = async (
	dataRequest: IAgregarCatalogoMots
) => {
	try {
		const response = await http.post('EquipoProteccionPersonal', dataRequest);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const editEquipoProteccionPersonalService = async (
	dataRequest: IEditarCatalogoMots
) => {
	try {
		const response = await http.put(
			`EquipoProteccionPersonal/${dataRequest.id}`,
			dataRequest
		);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const deleteEquipoProteccionPersonalService = async (id: number) => {
	try {
		const response = await http.delete(`EquipoProteccionPersonal/${id}`);
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};
