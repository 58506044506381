import { useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';

import ChartEstAudit from '@app/components/Graficas/EstAudit';
import ChartFactRiesgo from '@app/components/Graficas/FactRiesgo';
import ChartOtroTipoObservacion from '@app/components/Graficas/OtroTipoObservacion';
import ChartPrecursores from '@app/components/Graficas/Precursores';
import ChartSeveridad from '@app/components/Graficas/Severidad';
import ChartTipAudit from '@app/components/Graficas/TipAudit';
import Loading from '@app/components/Loading';
import useGraficaDashboard from '@app/hooks/useGraficasDashboard';
import useOrganigrama from '@app/hooks/useOrganigrama';
import { IFiltrosDashboard } from '@app/models/interfaces/dahsboard/IFiltrosDashboard';
import { IJefaturaGerencia } from '@app/models/interfaces/jefaturasGerencias/IJefaturaGerencia';
import { format, lastDayOfMonth } from 'date-fns';
import TitleComponent from '../components/Title';

const isMozilla = navigator.userAgent.toLowerCase().includes('firefox');

export default function Inicio() {
	const { data: direcciones, isLoading: organigramaIsLoading } = useOrganigrama();
	const [filtros, setFiltros] = useState<IFiltrosDashboard>({
		fechaInicial: '',
		fechaFinal: '',
		direccionId: 0,
		gerenciaJefaturaId: 0,
		jefaturaId: 0,
		indicadores: {
			estatus: true,
			tipos: true,
			riesgo: true,
			precursores: true,
			severidad: true,
			otroTipoObservacion: true,
		},
	});

	const { fechaInicioBuena, fechaFinalBuena } = useMemo(() => {
		const [anio, mes] = filtros.fechaInicial
			? filtros.fechaInicial.split('-')
			: `${new Date().getFullYear()}-01`.split('-');

		const [anioFinal, mesFinal] = filtros.fechaFinal
			? filtros.fechaFinal.split('-')
			: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`.split('-');

		const fechaInicioBuena = new Date(+anio, +mes - 1, 1);
		const fechaFinalBuena = new Date(+anioFinal, +mesFinal - 1, 1);

		return { fechaInicioBuena, fechaFinalBuena };
	}, [filtros.fechaInicial, filtros.fechaFinal]);

	const obtenerDireccionJefatura = () => {
		if (filtros.jefaturaId) return filtros.jefaturaId;
		if (filtros.gerenciaJefaturaId) return filtros.gerenciaJefaturaId;
		if (filtros.direccionId) return filtros.direccionId;

		return null;
	};

	const { data: graficaData, isFetching: graficaDataIsLoading } = useGraficaDashboard({
		mesAnioInicio: format(fechaInicioBuena, 'yyyy-MM-dd'),
		mesAnioTermino: format(lastDayOfMonth(fechaFinalBuena), 'yyyy-MM-dd'),
		direccionJefatura: obtenerDireccionJefatura(),
	});

	const gerencias = useMemo(() => {
		if (!!direcciones?.length && !!filtros.direccionId) {
			const direccion = direcciones.find(direccion => direccion.id === +filtros.direccionId);
			return direccion.organigramasChildren;
		}
		return [];
	}, [filtros.direccionId, direcciones]);

	const jefaturas = useMemo(() => {
		if (!!gerencias?.length && !!filtros.gerenciaJefaturaId) {
			const gerenciaJefatura = gerencias.find(
				gerencia => gerencia.id === +filtros.gerenciaJefaturaId
			);
			return gerenciaJefatura.organigramasChildren;
		}
		return [];
	}, [filtros.gerenciaJefaturaId, gerencias]);

	useEffect(() => {
		setFiltros((filtros: IFiltrosDashboard) => ({
			...filtros,
			fechaInicial: format(new Date(fechaInicioBuena), 'yyyy-MM'),
			fechaFinal: format(new Date(fechaFinalBuena), 'yyyy-MM'),
		}));

		return () => {
			setFiltros({
				fechaInicial: '',
				fechaFinal: '',
				direccionId: 0,
				gerenciaJefaturaId: 0,
				jefaturaId: 0,
				indicadores: {
					estatus: true,
					tipos: true,
					riesgo: true,
					precursores: true,
					severidad: true,
					otroTipoObservacion: true,
				},
			});
		};
	}, []);

	return (
		<Container className="d-flex flex-column">
			{(organigramaIsLoading || graficaDataIsLoading) && <Loading mensaje="Cargando..." />}
			<TitleComponent titulo="Estadísticas" />
			<Row className="mt-4">
				<Col xs={6} sm={6} md={2} lg={2}>
					<Form.Group>
						<Form.Label>Mes/Año Inicio:</Form.Label>
						<Form.Control
							type="month"
							name="mesInicio"
							value={filtros.fechaInicial}
							onChange={({ target }) => setFiltros({ ...filtros, fechaInicial: target.value })}
							disabled={isMozilla}
						/>
					</Form.Group>
				</Col>
				<Col xs={6} sm={6} md={2} lg={2}>
					<Form.Group>
						<Form.Label>Mes/Año Termino:</Form.Label>
						<Form.Control
							type="month"
							name="mesTermino"
							value={filtros.fechaFinal}
							onChange={({ target }) => setFiltros({ ...filtros, fechaFinal: target.value })}
							disabled={isMozilla}
						/>
					</Form.Group>
				</Col>
				<Col xs={12} sm={12} md={3} lg={3}>
					<Form.Group>
						<Form.Label>Dirección Responsable</Form.Label>
						<Form.Select
							name="direccionResponsable"
							value={filtros.direccionId}
							onChange={({ target }) =>
								setFiltros(oldFiltros => ({
									...oldFiltros,
									direccionId: target.value ? +target.value : 0,
									gerenciaJefaturaId: 0,
									jefaturaId: 0,
								}))
							}
						>
							<option value="">Seleccionar Dirección Responsable</option>
							{direcciones?.map((direccion: IJefaturaGerencia) => (
								<option key={`${direccion.id}`} value={direccion.id}>
									{direccion.descripcion}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				</Col>
				<Col xs={12} sm={12} md={3} lg={3}>
					<Form.Group>
						<Form.Label>Gerencia / Jefatura</Form.Label>
						<Form.Select
							name="gerenciaJefaturaId"
							value={filtros.gerenciaJefaturaId}
							onChange={({ target }) =>
								setFiltros(oldFiltros => ({
									...oldFiltros,
									gerenciaJefaturaId: target.value ? +target.value : 0,
									jefaturaId: 0,
								}))
							}
						>
							<option value="">Seleccionar Gerencia/Jefatura</option>
							{gerencias.map(gerencia => (
								<option key={gerencia.id} value={gerencia.id}>
									{gerencia.descripcion}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				</Col>
				<Col xs={12} sm={12} md={2} lg={2}>
					<Form.Group>
						<Form.Label>Jefatura</Form.Label>
						<Form.Select
							name="jefaturaId"
							value={filtros.jefaturaId}
							onChange={({ target }) =>
								setFiltros(oldFiltros => ({
									...oldFiltros,
									jefaturaId: target.value ? +target.value : 0,
								}))
							}
						>
							<option value="">Seleccionar Jefatura</option>
							{jefaturas.map(jefatura => (
								<option key={jefatura.id} value={jefatura.id}>
									{jefatura.descripcion}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				</Col>
			</Row>
			<Row className="mt-3">
				<Col xs={12} sm={12} md={12} lg={12}>
					<Card style={{ backgroundColor: '#E3E9ED', borderColor: '#E3E9ED' }}>
						<Card.Body>
							<h4 className="text-center">Indicadores</h4>
							<div className="w-100 my-2 d-flex align-items-center justify-content-around">
								<Form.Group>
									<Form.Check.Label>Estatus de Auditoría</Form.Check.Label>&nbsp;&nbsp;
									<Form.Check
										inline
										type="checkbox"
										name="estatus"
										onChange={({ target }) => {
											setFiltros((fil: IFiltrosDashboard) => ({
												...fil,
												indicadores: { ...fil.indicadores, estatus: target.checked },
											}));
										}}
										value={`${filtros.indicadores.estatus}`}
										checked={filtros.indicadores.estatus}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Check.Label>Tipos de Auditoría</Form.Check.Label>&nbsp;&nbsp;
									<Form.Check
										inline
										type="checkbox"
										name="tipos"
										onChange={({ target }) => {
											setFiltros((fil: IFiltrosDashboard) => ({
												...fil,
												indicadores: { ...fil.indicadores, tipos: target.checked },
											}));
										}}
										value={`${filtros.indicadores.tipos}`}
										checked={filtros.indicadores.tipos}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Check.Label>Factores de Riesgo</Form.Check.Label>&nbsp;&nbsp;
									<Form.Check
										inline
										type="checkbox"
										name="riesgo"
										onChange={({ target }) => {
											setFiltros((fil: IFiltrosDashboard) => ({
												...fil,
												indicadores: { ...fil.indicadores, riesgo: target.checked },
											}));
										}}
										value={`${filtros.indicadores.riesgo}`}
										checked={filtros.indicadores.riesgo}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Check.Label>Precursores</Form.Check.Label>&nbsp;&nbsp;
									<Form.Check
										inline
										type="checkbox"
										name="precursores"
										onChange={({ target }) => {
											setFiltros((fil: IFiltrosDashboard) => ({
												...fil,
												indicadores: { ...fil.indicadores, precursores: target.checked },
											}));
										}}
										value={`${filtros.indicadores.precursores}`}
										checked={filtros.indicadores.precursores}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Check.Label>Severidad</Form.Check.Label>&nbsp;&nbsp;
									<Form.Check
										inline
										type="checkbox"
										name="severidad"
										onChange={({ target }) => {
											setFiltros((fil: IFiltrosDashboard) => ({
												...fil,
												indicadores: { ...fil.indicadores, severidad: target.checked },
											}));
										}}
										value={`${filtros.indicadores.severidad}`}
										checked={filtros.indicadores.severidad}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Check.Label>Otro Tipo Observación</Form.Check.Label>&nbsp;&nbsp;
									<Form.Check
										inline
										type="checkbox"
										name="otroTipoObservacion"
										onChange={({ target }) => {
											setFiltros((fil: IFiltrosDashboard) => ({
												...fil,
												indicadores: { ...fil.indicadores, otroTipoObservacion: target.checked },
											}));
										}}
										value={`${filtros.indicadores.otroTipoObservacion}`}
										checked={filtros.indicadores.otroTipoObservacion}
									/>
								</Form.Group>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="w-100 mt-4 d-flex flex-row d-flex flex-wrap">
				<Col xs={12} sm={12} md={6} lg={6}>
					{filtros.indicadores.estatus && (
						<Card>
							<Card.Body>
								<ChartEstAudit statusAuditoria={graficaData?.statusAuditoria} />
							</Card.Body>
						</Card>
					)}
				</Col>
				<Col xs={12} sm={12} md={6} lg={6}>
					{filtros.indicadores.tipos && (
						<Card>
							<Card.Body>
								<ChartTipAudit tiposAuditoria={graficaData?.tiposAuditoria} />
							</Card.Body>
						</Card>
					)}
				</Col>
			</Row>
			<Row className="mt-3">
				<Col xs={12} sm={12} md={12} lg={12}>
					{filtros.indicadores.severidad && (
						<Card>
							<Card.Body>
								<ChartSeveridad severidades={graficaData?.severidad} />
							</Card.Body>
						</Card>
					)}
				</Col>
			</Row>
			<Row className="mt-3">
				<Col xs={12} sm={12} md={12} lg={12}>
					{filtros.indicadores.otroTipoObservacion && (
						<Card>
							<Card.Body>
								<ChartOtroTipoObservacion
									auditoriasOtroTipoObservacion={graficaData?.totalAuditoriasEsOtroTipoObservacion}
								/>
							</Card.Body>
						</Card>
					)}
				</Col>
			</Row>
			<Row className="mt-3">
				<Col xs={12} sm={12} md={12} lg={12}>
					{filtros.indicadores.riesgo && (
						<Card>
							<Card.Body>
								<ChartFactRiesgo factoresRiesgo={graficaData?.factoresRiesgo} />
							</Card.Body>
						</Card>
					)}
				</Col>
			</Row>
			<Row className="my-3">
				<Col xs={12} sm={12} md={12} lg={12}>
					{filtros.indicadores.precursores && (
						<Card className="p-3">
							<Card.Body>
								<ChartPrecursores precursores={graficaData?.precursores} />
							</Card.Body>
						</Card>
					)}
				</Col>
			</Row>
		</Container>
	);
}
