import { getMetaByUserAuditoria } from '@app/services/auditorias.service';
import { useQuery } from '@tanstack/react-query';

export default function useMetaUsuario(isNotAdministrador: boolean) {
	return useQuery({
		queryKey: ['meta-usuario'],
		queryFn: getMetaByUserAuditoria,
		refetchOnWindowFocus: false,
		retry: 2,
		enabled: isNotAdministrador,
	});
}
