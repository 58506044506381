import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import Loading from '@app/components/Loading';
import CatalogoGenericoModal from '@app/components/Modals/Catalogos/CatalogoGenerico';
import DeleteCatalogoGenerico from '@app/components/Modals/Catalogos/DeleteCatalogoGenerico';
import Title from '@app/components/Title';
import { ROUTES } from '@app/constants/routes';
import useCatalogoGenerico from '@app/hooks/useCatalogoGenerico';
import useError from '@app/hooks/useError';
import { ICatalogoGenerico } from '@app/models/interfaces/catalogos/ICatalogoGenerico';

export default function CatalogoGenerico() {
	const { pathname } = useLocation();
	const isFactorRiesgo = pathname === ROUTES.FACTOR_RIESGO;
	const {
		isError,
		error,
		refetch,
		isFetching,
		data: catalogos,
	} = useCatalogoGenerico(isFactorRiesgo);
	const [estadoModal, setEstadoModal] = useState<boolean>(false);
	const [eliminarCatalogo, setEliminarModal] = useState<boolean>(false);
	const [catalogoSeleccionado, setCatalogoSeleccionado] = useState<ICatalogoGenerico>({
		id: 0,
		descripcion: '',
	});
	const { setError, ErrorAlert } = useError();

	useEffect(() => {
		setError(error);
	}, [isError]);

	useEffect(() => {
		if (!estadoModal) {
			setCatalogoSeleccionado({ id: 0, descripcion: '' });
		}
	}, [estadoModal]);

	useEffect(() => {
		if (!eliminarCatalogo) {
			setCatalogoSeleccionado({ id: 0, descripcion: '' });
		}
	}, [eliminarCatalogo]);

	useEffect(() => {
		refetch();
	}, [isFactorRiesgo]);

	return (
		<div className="d-flex flex-column container">
			{isFetching && (
				<Loading
					mensaje={isFactorRiesgo ? 'Cargando Factores de Riesgo' : 'Cargando Precursores'}
				/>
			)}
			<Title titulo={isFactorRiesgo ? 'Factores de Riesgo' : 'Precursores'} />
			<ErrorAlert />
			<Row className="mt-4">
				<Col className="d-flex align-items-center justify-content-end" xs={12}>
					<Button onClick={() => setEstadoModal(true)}>
						Agregar {isFactorRiesgo ? 'Factor de Riesgo' : 'Precursor'}
					</Button>
				</Col>
			</Row>
			<Row className="mt-3">
				<Col xs={12} sm={12} md={12}>
					<Table striped hover>
						<thead>
							<tr>
								<th>{isFactorRiesgo ? 'Factor de Riesgo' : 'Precursor'}</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{catalogos?.length ? (
								<>
									{catalogos
										?.sort((primero: ICatalogoGenerico, segundo: ICatalogoGenerico) =>
											primero.descripcion > segundo.descripcion ? 1 : -1
										)
										?.map(({ id, descripcion }: ICatalogoGenerico) => (
											<tr key={id}>
												<td>{descripcion}</td>
												<td>
													<IconButton
														onClick={() => {
															setCatalogoSeleccionado({ id, descripcion });
															setEstadoModal(true);
														}}
													>
														<EditIcon fontSize="small" />
													</IconButton>
													<IconButton
														onClick={() => {
															setCatalogoSeleccionado({ id, descripcion });
															setEliminarModal(true);
														}}
													>
														<DeleteIcon fontSize="small" />
													</IconButton>
												</td>
											</tr>
										))}
								</>
							) : (
								<tr>
									<td colSpan={2}>
										No hay {isFactorRiesgo ? 'Factores de Riesgo' : 'Precursores'}
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</Col>
			</Row>
			<CatalogoGenericoModal
				estadoModal={estadoModal}
				cambiarEstadoModal={setEstadoModal}
				isFactorRiesgo={isFactorRiesgo}
				catalogo={catalogoSeleccionado}
				tipoCatalogo={isFactorRiesgo ? 'Factor de Riesgo' : 'Precursor'}
				editarCatalogo={!!catalogoSeleccionado.id}
				refetch={refetch}
			/>
			<DeleteCatalogoGenerico
				estadoModal={eliminarCatalogo}
				cambiarEstadoModal={setEliminarModal}
				tipoCatalogo={isFactorRiesgo ? 'Factor de Riesgo' : 'Precursor'}
				refetch={refetch}
				isFactorRiesgo={isFactorRiesgo}
				catalogo={catalogoSeleccionado}
			/>
		</div>
	);
}
