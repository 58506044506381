import { IPersonalAuditor } from '@app/models/interfaces/personal/IPersonalAuditor';
import http from '../utils/http';

export const getAllPersonalService = async () => {
	try {
		const response = await http.get('Personal');
		return response?.data;
	} catch (ex) {
		throw ex;
	}
};

export const getAllPersonalAuditorService = async () => {
	const response = await http.get<IPersonalAuditor[]>('Personal/GetPersonalParaAuditores');
	return response?.data;
};
