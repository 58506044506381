import { getUsuarioByIdService } from '@app/services/usuarios.service';
import { useQuery } from '@tanstack/react-query';

export default function useDetalleUsuario(idUser: string) {
	return useQuery({
		queryKey: ['usuario', idUser],
		queryFn: async () => await getUsuarioByIdService(idUser),
		refetchOnWindowFocus: false,
		retry: !idUser ? 0 : 2,
	});
}
