import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { IAreaZona } from '../models/interfaces/areasZonas/IAreaZona';
import { getAllAreasZonasService } from '../services/areas-zonas.service';

import TablaAreaZonaComponent from '../components/AreasZonas/TablaAreaZona';
import LoadingComponent from '../components/Loading';
import Title from '../components/Title';
import useData from '../hooks/useData';
import useError from '../hooks/useError';
import { ICargando } from '../models/ICargando';
import { IDataFpsContext } from '../models/IDataContext';

export default function AreasZonas() {
  const [cargando, setCargando] = useState<ICargando>({
    cargando: false,
    mensaje: '',
  });
  const [filasAbiertas, setFilasAbiertas] = useState<number[]>([]);
  const [filaSeleccionada, setFilaSeleccionada] = useState<number>(0);
  const { setDataFpsContext, dataFpsContext } = useData();
  const { setError, ErrorAlert } = useError();

  const obtenerAreasZonas = async () => {
    try{
      setCargando({
        cargando: true,
        mensaje: 'Obteniendo Áreas y Zonas'
      });
      const response: IAreaZona[] = await getAllAreasZonasService();
      setDataFpsContext((data: IDataFpsContext) => ({
        ...data,
        areasZonas: response,
      }));
    }
    catch(ex){
      setError(ex);
    }
    finally{
      setCargando({
        cargando: false,
        mensaje: ''
      });
    }
  }

  useEffect(() => {
    obtenerAreasZonas();
    return () => {
      setCargando({
        cargando: false,
        mensaje: '',
      });
    }
  }, []);

  return (
    <Container className="d-flex flex-column">
      <ErrorAlert/>
      {cargando.cargando && <LoadingComponent mensaje={cargando.mensaje} />}
      <Title titulo='Áreas y Zonas'/>
      <Row className="mt-5">
        <Col xs={12} sm={12} md={12} lg={12}>
          {dataFpsContext.areasZonas.length ? 
            <Row className="mt-5">
              <Col xs={12} sm={12} md={12} lg={12}>
                <TablaAreaZonaComponent
                  filaSeleccionada={filaSeleccionada}
                  seleccionarFila={setFilaSeleccionada}
                  asignarFilaAbierta={setFilasAbiertas}
                  filasAbiertas={filasAbiertas}
                  areasZonas={dataFpsContext.areasZonas}
                />
              </Col>
            </Row>
          : <h4 className='text-center'>No se encontraron áreas y zonas</h4>}
        </Col>
      </Row>
    </Container>
  )
}
