import { getISOWeek } from 'date-fns';
import * as Yup from 'yup';

import { ErrorMessages } from '@app/constants/errorMessages';

export const agregarMetaValidationSchema = (esEditar: boolean) =>
	Yup.object().shape({
		applicationUserId: Yup.string().required(ErrorMessages.Required),
		aspectoObservableId: Yup.number().min(1, ErrorMessages.Required),
		semana: Yup.number()
			.min(1, ErrorMessages.MinLengthNumber(1))
			.max(52, ErrorMessages.MaxLengthNumber(52))
			.test(
				'No puede ser mayor a final',
				'La semana inicio no puede ser mayor a la semana fin',
				function (semanaInicial) {
					const semanaActual: number = getISOWeek(new Date());
					const semanaFinal: number = this.resolve(Yup.ref('semanaFin'));

					if ((esEditar && semanaInicial <= semanaActual) || semanaFinal === semanaInicial) {
						return true;
					}
					return semanaInicial <= semanaFinal;
				}
			)
			.test(
				'Semana inicio no puede ser menor a la actual',
				'La semana inicio no puede ser menor a la semana actual',
				function (semanaInicial) {
					const semanaActual: number = getISOWeek(new Date());
					const semanaFinal: number = this.resolve(Yup.ref('semanaFin'));

					if ((esEditar && semanaInicial <= semanaActual) || semanaFinal === semanaInicial) {
						return true;
					}
					return semanaInicial >= semanaActual;
				}
			),
		semanaFin: Yup.number()
			.min(1, ErrorMessages.MinLengthNumber(1))
			.max(52, ErrorMessages.MaxLengthNumber(52))
			.test(
				'No puede ser menor a inicio',
				'La semana termino no puede ser menor a la semana inicio',
				function (semanaTermino) {
					const semanaActual: number = getISOWeek(new Date());
					const semanaInicio: number = this.resolve(Yup.ref('semana'));

					if ((esEditar && semanaTermino <= semanaActual) || semanaTermino === semanaInicio) {
						return true;
					}
					return semanaTermino > semanaInicio;
				}
			)
			.test(
				'Semana termino no puede ser menor a la actual',
				'La semana termino no puede ser menor a la semana actual',
				function (semanaTermino) {
					const semanaActual: number = getISOWeek(new Date());
					const semanaInicio: number = this.resolve(Yup.ref('semana'));

					if ((esEditar && semanaTermino <= semanaActual) || semanaTermino === semanaInicio) {
						return true;
					}
					return semanaTermino >= semanaActual;
				}
			),
		fechaInicio: Yup.string(),
		fechaTermino: Yup.string(),
		actosInseguros: Yup.number().min(1, ErrorMessages.Required),
		codicionesInseguras: Yup.number().min(1, ErrorMessages.Required),
		reforzamientosPositivos: Yup.number().min(1, ErrorMessages.Required),
	});
