import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';

import useData from '@app/hooks/useData';
import useError from '@app/hooks/useError';
import { IDataFpsContext } from '@app/models/IDataContext';
import { cargarGuiaCatalogo } from '@app/services/catalogos.service';
import DocIcon from '../../assets/images/iconos/docx.svg';
import XslxIcon from '../../assets/images/iconos/excel.svg';
import DefaultIcon from '../../assets/images/iconos/nothing.svg';

type previewDocumentProps = {
	estadoModal: { estado: boolean; idAspectoObservable: number; aspectoObservable: string };
	cambiarEstadoModal: Function;
};

export default function PreviewDocumentModal(props: previewDocumentProps) {
	const {
		estadoModal: { estado, idAspectoObservable, aspectoObservable },
		cambiarEstadoModal,
	} = props;

	const [cargandoDocumento, setCargandoDocumento] = useState<boolean>(false);
	const [documentos, setDocumentos] = useState<any[]>([]);
	const [documento, setDocumento] = useState<File>(null);
	const { setDataFpsContext } = useData();
	const refDocumento = useRef(null);
	const { setError, ErrorAlert } = useError();

	const { mutate, isLoading } = useMutation({
		mutationFn: cargarGuiaCatalogo,
		onSuccess: response => {
			cambiarEstadoModal({ estado: false, idAspectoObservable: 0 });
			setDataFpsContext((data: IDataFpsContext) => ({ ...data, nombreGuia: response }));
		},
		onError: error => setError(error),
	});

	const renderIcono = (extension: string) => {
		if (extension === 'doc' || extension === 'docx') {
			return <img alt="word" src={DocIcon} />;
		} else if (extension === 'xls' || extension === 'xlsx') {
			return <img alt="excel" src={XslxIcon} />;
		} else {
			return <img alt="excel" src={DefaultIcon} />;
		}
	};

	const cargarDocumento = () => {
		if (refDocumento) {
			const { current } = refDocumento;
			current.click();
		}
	};

	const documentoCargado = event => {
		try {
			setCargandoDocumento(true);
			if (event) {
				const { files } = event.target;
				setDocumento(files[0]);
				const documentoActual = URL.createObjectURL(files[0]);
				if (files.length) {
					setDocumentos([
						{
							uri: documentoActual,
							fileName: files[0].name,
						},
					]);
				}
			}
		} catch (ex) {
			setError(ex);
		} finally {
			setCargandoDocumento(false);
		}
	};

	const guardarGuia = () => {
		if (documento) {
			const formData = new FormData();
			formData.append('catalogoId', `${idAspectoObservable}`);
			formData.append('archivoGuia', documento, documento.name);
			mutate(formData);
		}
	};

	useEffect(() => {
		if (!estado) {
			if (refDocumento) {
				refDocumento.current = null;
				setDocumento(null);
				setDocumentos([]);
			}
		}
	}, [estado]);

	return (
		<Modal
			className="mostrar-contrasena-modal"
			show={estado}
			onHide={() => cambiarEstadoModal(state => ({ ...state, estado: false }))}
			backdrop="static"
			size="lg"
		>
			<Modal.Header closeButton>
				<Modal.Title>Guía {aspectoObservable}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ErrorAlert />
				<Row>
					<Col xs={12} sm={12} md={12}>
						<input
							className="formulario-evidencias__carga-imagen"
							ref={refDocumento}
							type="file"
							accept="*"
							onChange={event => documentoCargado(event)}
							name="guia"
						/>
						<div className="d-flex align-items-center justify-content-center">
							<Button disabled={isLoading} onClick={cargarDocumento}>
								{cargandoDocumento ? (
									<>
										<Spinner
											as="span"
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
										/>
										&nbsp;&nbsp;Cargando Documento...
									</>
								) : (
									'Cargar Documento'
								)}
							</Button>
						</div>
						{documentos.length ? (
							<>
								{documento.type.includes('pdf') ||
								documento.type.includes('jpeg') ||
								documento.type.includes('jpg') ||
								documento.type.includes('png') ||
								documento.type.includes('gif') ? (
									<div style={{ marginTop: '10px', marginBottom: '20px' }}>
										<DocViewer documents={documentos} pluginRenderers={DocViewerRenderers} />
									</div>
								) : (
									<div className="my-4 d-flex align-items-center justify-content-center flex-column">
										{renderIcono(documento.name.split('.')[1].toLowerCase())}
										<h5 className="mt-3">{documento.name}</h5>
									</div>
								)}
							</>
						) : null}
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="outline-secondary"
					onClick={() => cambiarEstadoModal(state => ({ ...state, estado: false }))}
				>
					Cancelar
				</Button>
				<Button disabled={!documento || isLoading} onClick={() => guardarGuia()}>
					{isLoading ? (
						<>
							<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
							&nbsp;&nbsp;Guardando Guía...
						</>
					) : (
						'Guardar Guía'
					)}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
