import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';

import useData from '../../hooks/useData';
import useError from '../../hooks/useError';
import { ICargando } from '../../models/ICargando';
import { IDataFpsContext } from '../../models/IDataContext';
import { IAgregarAreaZona } from '../../models/interfaces/areasZonas/IAgregarAreaZona';
import { ModalAreaZonaProps } from '../../models/types/modal.type';
import {
	addAreaZonaService,
	editAreaZonaService,
	getAllAreasZonasService,
} from '../../services/areas-zonas.service';
import { agregarAreaZonaValidationSchema } from '../../utils/validations/modals/agregar-area-zona-modal.validation';

import '../../styles/components/modals/AgregarAreaZonaModal.scss';

export default function AgregarAreaZonaModal(props: ModalAreaZonaProps) {
	const { tituloModal, estadoModal, cambiarEstadoModal, areaZona, idAreaZona } =
		props;
	const initialValues: IAgregarAreaZona = {
		areaZonaId: 0,
		codigo: '',
		descripcion: '',
	};
	const { setDataFpsContext } = useData();
	const { setError, ErrorAlert } = useError();
	const formik = useFormik({
		initialValues,
		validationSchema: agregarAreaZonaValidationSchema,
		onSubmit: (values: IAgregarAreaZona) => guardarAreaZona(values),
	});
	const [guardando, setGuardando] = useState<ICargando>({
		cargando: false,
		mensaje: '',
	});

	const guardarAreaZona = async (requestData: IAgregarAreaZona) => {
		try {
			setGuardando({
				cargando: true,
				mensaje: 'Guardando',
			});
			if (!!areaZona) {
				await editAreaZonaService(
					{
						codigo: requestData.codigo,
						descripcion: requestData.descripcion,
					},
					areaZona.id
				);
			} else {
				await addAreaZonaService({
					...requestData,
					areaZonaId: +idAreaZona === 0 ? null : +idAreaZona,
				});
			}
			const areasActualizados = await getAllAreasZonasService();
			setDataFpsContext((data: IDataFpsContext) => ({
				...data,
				areasZonas: areasActualizados,
			}));
			cambiarEstadoModal(false);
		} catch (ex) {
			setError(ex);
		} finally {
			setGuardando({ cargando: false, mensaje: '' });
		}
	};

	useEffect(() => {
		if (!estadoModal) {
			formik.resetForm();
			setError('');
		} else {
			if (!!areaZona) {
				formik.setFieldValue('codigo', areaZona.codigo, false);
				formik.setFieldValue('descripcion', areaZona.descripcion, false);
			}
		}
	}, [estadoModal]);

	useEffect(() => {
		return () => {
			formik.resetForm();
			setGuardando({
				cargando: false,
				mensaje: '',
			});
			setError('');
		};
	}, []);

	return (
		<Modal
			className="modal-agregar-area-zona"
			show={estadoModal}
			onHide={() => cambiarEstadoModal(false)}
		>
			<Modal.Header closeButton>
				<Modal.Title>{tituloModal}</Modal.Title>
			</Modal.Header>
			<form onSubmit={formik.handleSubmit}>
				<Modal.Body>
					<ErrorAlert />
					{/* <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Form.Group>
                <Form.Label>
                  Nivel&nbsp;<span className="modal-agregar-catalogo__requerido">*</span>
                </Form.Label>
                <Form.Control
                  ref={refInputCodigo}
                  isInvalid={!!formik.errors.codigo}
                  name="codigo"
                  value={formik.values.codigo}
                  onChange={formik.handleChange}
                  placeholder="Nivel"
                />
                <Form.Control.Feedback className="d-block" type="invalid">
                  {formik.errors.codigo}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row> */}
					<Row className="my-3">
						<Col xs={12} sm={12} md={12} lg={12}>
							<Form.Group>
								<Form.Label>
									Descripción&nbsp;
									<span className="modal-agregar-catalogo__requerido">*</span>
								</Form.Label>
								<Form.Control
									isInvalid={!!formik.errors.descripcion}
									name="descripcion"
									value={formik.values.descripcion}
									onChange={formik.handleChange}
									placeholder="Descripción"
								/>
								<Form.Control.Feedback className="d-block" type="invalid">
									{formik.errors.descripcion}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row className="mt-3">
						<p className="modal-agregar-catalogo__leyenda">
							Todos los campos{' '}
							<span className="modal-agregar-catalogo__requerido">*</span> son
							obligatorios.
						</p>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-primary"
						onClick={() => cambiarEstadoModal(false)}
					>
						Cancelar
					</Button>
					<Button
						variant="secondary"
						disabled={guardando.cargando}
						type="submit"
					>
						{guardando.cargando ? (
							<>
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
								/>
								&nbsp;&nbsp;
								{`${guardando.mensaje}`}
							</>
						) : (
							'Guardar'
						)}
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}
