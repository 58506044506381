import { ICatalogoMots } from '@app/models/interfaces/mots/ICatalogoMots';
import { getAllFactorExposicionService } from '@app/services/mots/factor-exposicion.service';
import { useQuery } from '@tanstack/react-query';

export function useFactorExposicionQuery() {
	return useQuery<ICatalogoMots[]>({
		queryKey: ['mots', 'factor', 'exposicion'],
		queryFn: getAllFactorExposicionService,
	});
}
