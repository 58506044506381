import { Container } from 'react-bootstrap';

import Title from '../components/Title';

export default function Ambiental() {
  return (
    <Container className="d-flex flex-column">
      <Title titulo="Ambiental" />
    </Container>
  );
}
